 
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap");
:root {
  --color-primary: #00529b;
  --color-secondary: #a61c3c;
  --color-secondary-alt: #FFF6F4;
  --color-tertiary: #FFA41B;
  --color-tertiary-2: #7152E9;
  --color-dark: #a61c3c;
  --color-body: #6F6B80;
  --color-heading: #231F40;
  --color-white: #ffffff;
  --color-shape: #EEEEEE;
  --color-shade: #F5F5F5;
  --color-border: #EEEEEE;
  --color-black: #000000;
  --color-blackest: #0f0f11;
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #FBFBFD;
  --color-extra04: #1A1A1A;
  --color-extra05: #242424;
  --color-extra06: #DBF1C0;
  --color-extra07: #C6ECE8;
  --color-extra08: #C3ECCE;
  --color-success: #3EB75E;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;
  --border-width: 2px;
  --radius-small: 5px;
  --radius: 10px;
  --radius-big: 16px;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --shadow-primary: 0px 8px 30px rgba(0, 0, 0, 0.04);
  --shadow-light: 1px 1px 6px rgba(0,0,0,0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0,0,0,0.01);
  --transition: 0.3s;
  --transition-2: 0.7s;
  --transition-transform: transform .65s cubic-bezier(.23,1,.32,1);
  --font-primary: 'Urbanist', sans-serif;
  --font-secondary: 'Urbanist', sans-serif;
  --font-awesome: 'Font Awesome 5 Pro';
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;
  --line-height-b1: 1.67;
  --line-height-b2: 1.63;
  --line-height-b3: 1.6;
  --line-height-b4: 1.3;
  --h1: 72px;
  --h2: 68px;
  --h3: 40px;
  --h4: 32px;
  --h5: 24px;
  --h6: 20px;
  --h1-lineHeight: 1.25;
  --h2-lineHeight: 1.24;
  --h3-lineHeight: 1.4;
  --h4-lineHeight: 1.38;
  --h5-lineHeight: 1.5;
  --h6-lineHeight: 1.6; }

/**
 * Mixins Styels
 */
/**
 * Reset Styels
 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

a {
  color: var(--color-heading);
  text-decoration: none;
  outline: none; }

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: var(--color-primary); }

a:focus {
  outline: none; }

address {
  margin: 0 0 24px; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

mark {
  background: var(--color-primary);
  color: #ffffff; }

code,
kbd,
pre,
samp {
  font-size: var(--font-size-b3);
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  color: var(--color-primary); }

kbd,
ins {
  color: #ffffff; }

pre,
pre.wp-block-code {
  font-family: "Courier 10 Pitch", Courier, monospace !important;
  font-size: 15px !important;
  margin: 40px 0 !important;
  overflow: auto;
  padding: 20px !important;
  white-space: pre !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  color: var(--color-body) !important;
  background: var(--color-light);
  border-radius: 4px; }

small {
  font-size: smaller; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dl {
  margin-top: 0;
  margin-bottom: 10px; }

dd {
  margin: 0 15px 15px; }

dt {
  font-weight: bold;
  color: var(--color-heading); }

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

li > ul,
li > ol {
  margin: 0; }

ol ul {
  margin-bottom: 0; }

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid var(--color-border);
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline; }

button,
input {
  line-height: normal; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

caption,
th,
td {
  font-weight: normal; }

table thead th {
  font-weight: 700; }

td,
.wp-block-calendar tfoot td {
  border: 1px solid var(--color-border);
  padding: 7px 10px; }

del {
  color: #333; }

ins {
  background: rgba(56, 88, 246, 0.6);
  text-decoration: none;
  padding: 0 5px; }

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px;
  opacity: 0.6; }

table a,
table a:link,
table a:visited {
  text-decoration: underline; }

dt {
  font-weight: bold;
  margin-bottom: 10px; }

dd {
  margin: 0 15px 15px; }

caption {
  caption-side: top; }

kbd {
  background: var(--heading-color); }

dfn,
cite,
em {
  font-style: italic; }

/* BlockQuote  */
blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

blockquote {
  font-size: var(--font-size-b1);
  font-style: italic;
  font-weight: var(--p-light);
  margin: 24px 40px; }

blockquote blockquote {
  margin-right: 0; }

blockquote cite,
blockquote small {
  font-size: var(--font-size-b3);
  font-weight: normal; }

blockquote strong,
blockquote b {
  font-weight: 700; }

/**
 * Typography
 */
* {
  box-sizing: border-box; }

html {
  margin: 0;
  padding: 0;
  font-size: 10px; }

body {
  overflow-x: hidden;
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-primary)!important;
  color: var(--color-body);
  font-weight: 500;
  background-color: var(--color-white);
  margin: 0;
  padding: 0; }
  body.modal-open {
    padding: 0 !important; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
  margin: 0;
  margin-bottom: 20px; }

.display-one {
  font-size: 112px; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  word-break: break-word;
  font-family: var(--font-secondary);
  line-height: 1.4074;
  color: var(--color-heading); }

h1,
.h1 {
  font-size: var(--h1);
  line-height: var(--h1-lineHeight); }

h2,
.h2 {
  font-size: var(--h2);
  line-height: var(--h2-lineHeight); }

h3,
.h3 {
  font-size: var(--h3);
  line-height: var(--h3-lineHeight); }

h4,
.h4 {
  font-size: var(--h4);
  line-height: var(--h4-lineHeight); }

h5,
.h5 {
  font-size: var(--h5);
  line-height: var(--h5-lineHeight); }

h6,
.h6 {
  font-size: var(--h6);
  line-height: var(--h6-lineHeight); }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: inherit;
  transition: 0.5s; }

a {
  color: var(--color-heading);
  transition: 0.3s; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 38px; }
  h2,
  .h2 {
    font-size: 32px; }
  h3,
  .h3 {
    font-size: 28px; }
  h4,
  .h4 {
    font-size: 24px; } }

@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 34px; }
  h2,
  .h2 {
    font-size: 28px; }
  h3,
  .h3 {
    font-size: 24px; }
  h4,
  .h4 {
    font-size: 20px; } }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: var(--p-extra-bold); }

h4,
.h4 {
  font-weight: var(--p-bold); }

h5,
.h5 {
  font-weight: var(--p-bold); }

h6,
.h6 {
  font-weight: var(--p-bold); }

p {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: 500;
  color: var(--color-body);
  margin: 0 0 30px; }
  @media only screen and (max-width: 767px) {
    p {
      margin: 0 0 20px;
      font-size: var(--font-size-b2);
      line-height: var(--line-height-b2); } }
  p.has-large-font-size {
    line-height: 1.5;
    font-size: 36px; }
  p.has-medium-font-size {
    font-size: 24px;
    line-height: 36px; }
  p.has-small-font-size {
    font-size: 13px; }
  p.has-very-light-gray-color {
    color: var(--color-white); }
  p.has-background {
    padding: 20px 30px; }
  p.b1 {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1); }
  p.b2 {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2); }
  p.b3 {
    font-size: var(--font-size-b3);
    line-height: var(--line-height-b3); }
  p.b4 {
    font-size: var(--font-size-b4);
    line-height: var(--line-height-b4); }

.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1); }

.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2); }

.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3); }

.b4 {
  font-size: var(--font-size-b4);
  line-height: var(--line-height-b4); }

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 40px;
  width: 100%; }

table a,
table a:link,
table a:visited {
  text-decoration: none; }

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
  color: var(--color-heading); }

var {
  font-family: "Courier 10 Pitch", Courier, monospace; }

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
  padding-left: 18px; }

ul {
  list-style: square;
  margin-bottom: 30px;
  padding-left: 20px; }
  ul.liststyle.bullet li {
    font-size: 18px;
    line-height: 30px;
    color: var(--color-body);
    position: relative;
    padding-left: 30px; }
    @media only screen and (max-width: 767px) {
      ul.liststyle.bullet li {
        padding-left: 19px; } }
    ul.liststyle.bullet li::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: var(--color-body);
      left: 0;
      top: 10px; }
    ul.liststyle.bullet li + li {
      margin-top: 8px; }
  ul li {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--color-body);
    font-weight: 500; }
    ul li a {
      text-decoration: none;
      color: var(--color-body); }
      ul li a:hover {
        color: var(--color-primary); }
  ul ul {
    margin-bottom: 0; }

ol {
  margin-bottom: 30px; }
  ol li {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    color: var(--color-gray);
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400; }
    ol li a {
      color: var(--heading-color);
      text-decoration: none; }
      ol li a:hover {
        color: var(--color-primary); }
  ol ul {
    padding-left: 30px; }

hr {
  display: block;
  height: 0;
  margin: 15px 0;
  border-top: 1px solid var(--color-border);
  background: transparent; }

kbd {
  background: var(--color-primary); }

/*=====================
All Extend Here
=======================*/
.color-primary {
  color: var(--color-primary) !important; }

.color-secondary {
  color: var(--color-secondary) !important; }

.color-tertiary {
  color: var(--color-tertiary) !important; }

.color-heading {
  color: var(--color-heading) !important; }

.color-body {
  color: var(--color-body) !important; }

.color-dark {
  color: var(--color-dark) !important; }

[data-overlay]:before {
  background: var(--color-primary); }

/* Radius */
.post-scale, .radius {
  border-radius: var(--radius); }

.radius-big {
  border-radius: var(--radius-big); }

/* Font Weight */
.w-300 {
  font-weight: 300 !important; }

.w-400 {
  font-weight: 400 !important; }

.w-500 {
  font-weight: 500 !important; }

.w-600 {
  font-weight: 600 !important; }

.w-700 {
  font-weight: 700 !important; }

.w-800 {
  font-weight: 800 !important; }

.w-900 {
  font-weight: 900 !important; }

/* Shadows */
.shadow-primary {
  box-shadow: var(--shadow-primary); }

.shadow-light {
  box-shadow: var(--shadow-light); }

.shadow-lighter {
  box-shadow: var(--shadow-lighter); }

/*=============== Style Css =============*/
.edu-rating.rating-default .rating, .edu-event .event-meta, .edu-accordion-02 .edu-accordion-body ul, .pricing-billing-duration ul, .pricing-table .pricing-body .list-item, .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info ul, .brand-list, .blog-meta, .edu-blog-widget.widget-categories .category-list, .edu-blog-widget-2.widget-categories .category-list, .blog-main-content .gallery, .list-style-1, .list-style-2, .column-gallery, .banner-style-4 .social-text-share, .eduvibe-widget-details .widget-content ul, .edu-product-details-style .content .product-feature, .cart-summary .cart-summary-inner, .footer-style-default .information-list {
  padding: 0;
  margin: 0;
  list-style: none; }

ul li a, ol li a, #scrollUp, .form-group input, .form-group textarea, .mainmenu-nav .mainmenu li.has-droupdown .submenu li a {
  transition: var(--transition); }

.bgImagePosition, .bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

/*----------------------
    Animation Css  
-----------------------*/
.post-scale {
  overflow: hidden; }
  .post-scale img {
    transition: 0.5s; }
  .post-scale:hover img {
    transform: scale(1.1); }

@keyframes line1 {
  0% {
    opacity: 1;
    bottom: 0; }
  30% {
    bottom: 30%; }
  50% {
    bottom: 50%; }
  70% {
    bottom: 70%; }
  100% {
    bottom: 100%;
    opacity: 0.5; } }

@keyframes bounceSlide {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

.bounce-slide {
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: bounceSlide; }

@keyframes line5 {
  0% {
    opacity: 1;
    bottom: 400px; }
  50% {
    bottom: 50%; }
  100% {
    bottom: 100%;
    opacity: 0.5; } }

@keyframes line2 {
  0% {
    opacity: 1;
    top: 0; }
  30% {
    bottom: 30%; }
  50% {
    bottom: 50%; }
  70% {
    bottom: 70%; }
  100% {
    top: 100%;
    opacity: 0.5; } }

@keyframes line3 {
  0% {
    opacity: 1;
    top: 500px; }
  50% {
    top: 50%; }
  100% {
    top: 100%;
    opacity: 0.5; } }

@keyframes line4 {
  0% {
    opacity: 1;
    top: 300px; }
  50% {
    top: 50%; }
  100% {
    top: 100%;
    opacity: 0.5; } }

@keyframes customOne {
  from {
    transform: scale(1); }
  50% {
    transform: scale(0.9); }
  to {
    transform: scale(1); } }

@keyframes customTwo {
  0% {
    transform: translate(0px, 0px); }
  50% {
    transform: translate(100px, 0px); }
  100% {
    transform: translate(50px, 50px); } }

.customOne {
  animation: customOne 2s infinite; }

/* ------------------------
    Custom Animation 01 
----------------------------*/
@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -100px; }
  to {
    margin-top: 0; } }

@keyframes headerSlideDown {
  0% {
    margin-top: -100px; }
  to {
    margin-top: 0; } }

/*------------------------
	slidefadeinup
--------------------------*/
@-webkit-keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.slideFadeInUp {
  -webkit-animation-name: slideFadeInUp;
  animation-name: slideFadeInUp; }

/* -----------------------------------
    Custom Animation For All Page
---------------------------------------*/
@-webkit-keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

/*--------------------------------
Scroll Down Button Animation  
----------------------------------*/
@keyframes scrollDown {
  0% {
    opacity: 0; }
  10% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(10px);
    opacity: 0; } }

/*==============================
 *  Utilities
=================================*/
/*------------------------------------- 

font-family: 'Urbanist', sans-serif;

--------------------------------------*/
@media only screen and (max-width: 767px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-right: 15px;
    padding-left: 15px; } }

iframe {
  width: 100%; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.fix {
  overflow: hidden; }

.slick-initialized .slick-slide {
  margin-bottom: 0; }

.edu-line-separator {
  position: relative; }
  .edu-line-separator::after {
    position: absolute;
    content: "";
    background: #EBE7E5;
    width: 1170px;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto; }
    @media only screen and (max-width: 1199px) {
      .edu-line-separator::after {
        width: 100%; } }

/*===============================
    Background Color 
=================================*/
.bg-color-primary {
  background-color: var(--color-primary); }

.bg-color-secondary {
  background-color: var(--color-secondary); }

.bg-color-secondary-alt {
  background-color: var(--color-secondary-alt); }

.bg-color-tertiary {
  background-color: var(--color-tertiary); }

.bg-color-grey {
  background-color: #F0F2F5; }

.bg-color-white {
  background-color: #FFFFFF; }

.bg-color-black {
  background-color: #1A1A1A; }

.bg-color-grey {
  background-color: #E5E5E5; }

.bg-color-extra03 {
  background-color: var(--color-extra03); }

.bg-color-lessdark {
  background-color: var(--color-lessdark); }

.bg-color-2 {
  backdrop-filter: saturate(180%) blur(8px);
  background-color: var(--color-darker);
  background-image: linear-gradient(180deg, var(--color-black), transparent); }

/*===========================
    Background Image 
=============================*/
.bg-image_fixed {
  background-attachment: fixed !important; }

/* Height and width */
.fullscreen {
  min-height: 100vh;
  width: 100%; }

/*===================
Custom Row
======================*/
.row--0 {
  margin-left: -0px;
  margin-right: -0px; }
  .row--0 > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px; }

.row--5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row--5 > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px; }

.row--10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row--10 > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row--15 {
  margin-left: -15px;
  margin-right: -15px; }
  .row--15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; }

.row--20 {
  margin-left: -20px;
  margin-right: -20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--20 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--25 {
  margin-left: -25px;
  margin-right: -25px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--25 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--30 {
  margin-left: -30px;
  margin-right: -30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--30 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--35 {
  margin-left: -35px;
  margin-right: -35px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--35 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 35px;
    padding-right: 35px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--40 {
  margin-left: -40px;
  margin-right: -40px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--40 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 40px;
    padding-right: 40px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--45 {
  margin-left: -45px;
  margin-right: -45px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--45 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--45 > [class*="col"],
  .row--45 > [class*="col-"] {
    padding-left: 45px;
    padding-right: 45px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--50 {
  margin-left: -50px;
  margin-right: -50px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--50 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--50 > [class*="col"],
  .row--50 > [class*="col-"] {
    padding-left: 50px;
    padding-right: 50px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--50 > [class*="col"],
      .row--50 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--50 > [class*="col"],
      .row--50 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--50 > [class*="col"],
      .row--50 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--50 > [class*="col"],
      .row--50 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--60 {
  margin-left: -60px;
  margin-right: -60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--60 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--60 > [class*="col"],
  .row--60 > [class*="col-"] {
    padding-left: 60px;
    padding-right: 60px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--60 > [class*="col"],
      .row--60 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--60 > [class*="col"],
      .row--60 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--60 > [class*="col"],
      .row--60 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--60 > [class*="col"],
      .row--60 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

/*===========================
    Input Placeholder
=============================*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

/*=============================
	Overlay styles 
==============================*/
[data-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative; }

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2; }

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2; }

[data-black-overlay]:before {
  background-color: #000000; }

[data-white-overlay]:before {
  background-color: #ffffff; }

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1; }

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2; }

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3; }

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4; }

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5; }

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6; }

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7; }

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8; }

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9; }

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1; }

/*------------------------------
    Scroll Up 
--------------------------------*/
#scrollUp {
  width: 70px;
  height: 80px;
  right: 100px;
  bottom: 60px;
  text-align: center;
  z-index: 9811 !important;
  text-decoration: none;
  background: #fff;
  line-height: 80px;
  color: #757589;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  background: #ffffff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #scrollUp {
      right: 20px;
      bottom: 40px; } }
  @media only screen and (max-width: 767px) {
    #scrollUp {
      right: 20px;
      bottom: 40px; } }
  #scrollUp::before {
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background: rgba(0, 2, 72, 0.1);
    content: "";
    position: absolute;
    z-index: -1;
    transform-style: preserve-3d;
    transform: rotateY(-10deg);
    filter: blur(50px); }
  #scrollUp::after {
    background: #ffffff;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform-style: preserve-3d;
    transform: rotateY(-10deg); }
  @media only screen and (max-width: 767px) {
    #scrollUp {
      right: 20px;
      bottom: 30px;
      width: 50px;
      height: 60px;
      line-height: 60px; } }
  #scrollUp span.text {
    position: relative;
    display: inline-block;
    margin-top: 7px; }
    @media only screen and (max-width: 767px) {
      #scrollUp span.text {
        margin-top: 3px; } }
    #scrollUp span.text::after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 7px 5px;
      border-color: transparent transparent var(--color-primary) transparent;
      position: absolute;
      content: "";
      left: 50%;
      top: 21%;
      transform: translateX(-50%); }
  #scrollUp:hover span.text {
    color: var(--color-primary); }

/*--------------------
    Contact Form 
----------------------*/
.form-message {
  margin-bottom: 0;
  text-align: center; }
  .form-message.error {
    margin-top: 20px;
    color: #f80707; }
  .form-message.success {
    margin-top: 20px;
    color: #0d8d2d; }

/*=========================
    Section Separation 
==========================*/
.mt_dec--30 {
  margin-top: -30px; }

.mt_dec--20 {
  margin-top: -20px; }

.edu-section-gap {
  padding: 50px 0 !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-section-gap {
      padding: 80px 0 !important; } }
  @media only screen and (max-width: 767px) {
    .edu-section-gap {
      padding: 80px 0 !important; } }
  .edu-section-gap.slider-dots {
    padding: 60px 0 205px !important; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .edu-section-gap.slider-dots {
        padding: 80px 0 130px !important; } }
    @media only screen and (max-width: 767px) {
      .edu-section-gap.slider-dots {
        padding: 80px 0 130px !important; } }

.edu-section-gapBottom {
  padding-bottom: 130px !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-section-gapBottom {
      padding-bottom: 80px !important; } }
  @media only screen and (max-width: 767px) {
    .edu-section-gapBottom {
      padding-bottom: 80px  !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb--165,
  .pb--85 {
    padding-bottom: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pb--165,
  .pb--85 {
    padding-bottom: 80px  !important; } }

.edu-section-gapTop {
  padding-top: 60px !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-section-gapTop {
      padding-top: 80px !important; } }
  @media only screen and (max-width: 767px) {
    .edu-section-gapTop {
      padding-top: 80px  !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--120 {
    padding: 80px 0 !important; } }

@media only screen and (max-width: 767px) {
  .ptb--120 {
    padding: 60px 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb--165,
  .pb--120,
  .pb--85,
  .pb--165 {
    padding-bottom: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pb--165,
  .pb--120,
  .pb--85,
  .pb--165 {
    padding-bottom: 60px !important; } }

@media only screen and (max-width: 767px) {
  .pb--70 {
    padding-bottom: 60px !important; } }

.pl--0 {
  padding-left: 0 !important; }

.pr--0 {
  padding-right: 0 !important; }

.pt--0 {
  padding-top: 0 !important; }

.pb--0 {
  padding-bottom: 0 !important; }

.mr--0 {
  margin-right: 0 !important; }

.ml--0 {
  margin-left: 0 !important; }

.mt--0 {
  margin-top: 0 !important; }

.mb--0 {
  margin-bottom: 0 !important; }

.pt--260 {
  padding-top: 260px !important; }

.pt--250 {
  padding-top: 250px !important; }

.pb--240 {
  padding-bottom: 240px !important; }

.pb--220 {
  padding-bottom: 220px !important; }

.plr--270 {
  padding: 0 270px !important; }

.plr--300 {
  padding: 0 300px !important; }

.plr--340 {
  padding: 0 340px !important; }

.ptb--300 {
  padding: 300px 0 !important; }

.ptb--340 {
  padding: 340px 0 !important; }

.ptb--450 {
  padding: 450px 0 !important; }

.plr_dec--15 {
  margin: 0 -15px !important; }

.pt--300 {
  padding-top: 300px !important; }

.ptb--5 {
  padding: 5px 0 !important; }

.plr--5 {
  padding: 0 5px !important; }

.pt--5 {
  padding-top: 5px !important; }

.pb--5 {
  padding-bottom: 5px !important; }

.pl--5 {
  padding-left: 5px !important; }

.pr--5 {
  padding-right: 5px !important; }

.mt--5 {
  margin-top: 5px !important; }

.mb--5 {
  margin-bottom: 5px !important; }

.mr--5 {
  margin-right: 5px !important; }

.ml--5 {
  margin-left: 5px !important; }

.ptb--10 {
  padding: 10px 0 !important; }

.plr--10 {
  padding: 0 10px !important; }

.pt--10 {
  padding-top: 10px !important; }

.pb--10 {
  padding-bottom: 10px !important; }

.pl--10 {
  padding-left: 10px !important; }

.pr--10 {
  padding-right: 10px !important; }

.mt--10 {
  margin-top: 10px !important; }

.mb--10 {
  margin-bottom: 10px !important; }

.mr--10 {
  margin-right: 10px !important; }

.ml--10 {
  margin-left: 10px !important; }

.ptb--15 {
  padding: 15px 0 !important; }

.plr--15 {
  padding: 0 15px !important; }

.pt--15 {
  padding-top: 15px !important; }

.pb--15 {
  padding-bottom: 15px !important; }

.pl--15 {
  padding-left: 15px !important; }

.pr--15 {
  padding-right: 15px !important; }

.mt--15 {
  margin-top: 15px !important; }

.mb--15 {
  margin-bottom: 15px !important; }

.mr--15 {
  margin-right: 15px !important; }

.ml--15 {
  margin-left: 15px !important; }

.ptb--20 {
  padding: 20px 0 !important; }

.plr--20 {
  padding: 0 20px !important; }

.pt--20 {
  padding-top: 20px !important; }

.pb--20 {
  padding-bottom: 20px !important; }

.pl--20 {
  padding-left: 20px !important; }

.pr--20 {
  padding-right: 20px !important; }

.mt--20 {
  margin-top: 20px !important; }

.mb--20 {
  margin-bottom: 20px !important; }

.mr--20 {
  margin-right: 20px !important; }

.ml--20 {
  margin-left: 20px !important; }

.ptb--25 {
  padding: 25px 0 !important; }

.plr--25 {
  padding: 0 25px !important; }

.pt--25 {
  padding-top: 25px !important; }

.pb--25 {
  padding-bottom: 25px !important; }

.pl--25 {
  padding-left: 25px !important; }

.pr--25 {
  padding-right: 25px !important; }

.mt--25 {
  margin-top: 25px !important; }

.mb--25 {
  margin-bottom: 25px !important; }

.mr--25 {
  margin-right: 25px !important; }

.ml--25 {
  margin-left: 25px !important; }

.ptb--30 {
  padding: 30px 0 !important; }

.plr--30 {
  padding: 0 30px !important; }

.pt--30 {
  padding-top: 30px !important; }

.pb--30 {
  padding-bottom: 30px !important; }

.pl--30 {
  padding-left: 30px !important; }

.pr--30 {
  padding-right: 30px !important; }

.mt--30 {
  margin-top: 30px !important; }

.mb--30 {
  margin-bottom: 30px !important; }

.mr--30 {
  margin-right: 30px !important; }

.ml--30 {
  margin-left: 30px !important; }

.ptb--35 {
  padding: 35px 0 !important; }

.plr--35 {
  padding: 0 35px !important; }

.pt--35 {
  padding-top: 35px !important; }

.pb--35 {
  padding-bottom: 35px !important; }

.pl--35 {
  padding-left: 35px !important; }

.pr--35 {
  padding-right: 35px !important; }

.mt--35 {
  margin-top: 35px !important; }

.mb--35 {
  margin-bottom: 35px !important; }

.mr--35 {
  margin-right: 35px !important; }

.ml--35 {
  margin-left: 35px !important; }

.ptb--40 {
  padding: 40px 0 !important; }

.plr--40 {
  padding: 0 40px !important; }

.pt--40 {
  padding-top: 40px !important; }

.pb--40 {
  padding-bottom: 40px !important; }

.pl--40 {
  padding-left: 40px !important; }

.pr--40 {
  padding-right: 40px !important; }

.mt--40 {
  margin-top: 40px !important; }

.mb--40 {
  margin-bottom: 40px !important; }

.mr--40 {
  margin-right: 40px !important; }

.ml--40 {
  margin-left: 40px !important; }

.ptb--45 {
  padding: 45px 0 !important; }

.plr--45 {
  padding: 0 45px !important; }

.pt--45 {
  padding-top: 45px !important; }

.pb--45 {
  padding-bottom: 45px !important; }

.pl--45 {
  padding-left: 45px !important; }

.pr--45 {
  padding-right: 45px !important; }

.mt--45 {
  margin-top: 45px !important; }

.mb--45 {
  margin-bottom: 45px !important; }

.mr--45 {
  margin-right: 45px !important; }

.ml--45 {
  margin-left: 45px !important; }

.ptb--50 {
  padding: 50px 0 !important; }

.plr--50 {
  padding: 0 50px !important; }

.pt--50 {
  padding-top: 50px !important; }

.pb--50 {
  padding-bottom: 50px !important; }

.pl--50 {
  padding-left: 50px !important; }

.pr--50 {
  padding-right: 50px !important; }

.mt--50 {
  margin-top: 50px !important; }

.mb--50 {
  margin-bottom: 50px !important; }

.mr--50 {
  margin-right: 50px !important; }

.ml--50 {
  margin-left: 50px !important; }

.ptb--55 {
  padding: 55px 0 !important; }

.plr--55 {
  padding: 0 55px !important; }

.pt--55 {
  padding-top: 55px !important; }

.pb--55 {
  padding-bottom: 55px !important; }

.pl--55 {
  padding-left: 55px !important; }

.pr--55 {
  padding-right: 55px !important; }

.mt--55 {
  margin-top: 55px !important; }

.mb--55 {
  margin-bottom: 55px !important; }

.mr--55 {
  margin-right: 55px !important; }

.ml--55 {
  margin-left: 55px !important; }

.ptb--60 {
  padding: 60px 0 !important; }

.plr--60 {
  padding: 0 60px !important; }

.pt--60 {
  padding-top: 60px !important; }

.pb--60 {
  padding-bottom: 60px !important; }

.pl--60 {
  padding-left: 60px !important; }

.pr--60 {
  padding-right: 60px !important; }

.mt--60 {
  margin-top: 60px !important; }

.mb--60 {
  margin-bottom: 60px !important; }

.mr--60 {
  margin-right: 60px !important; }

.ml--60 {
  margin-left: 60px !important; }

.ptb--65 {
  padding: 65px 0 !important; }

.plr--65 {
  padding: 0 65px !important; }

.pt--65 {
  padding-top: 65px !important; }

.pb--65 {
  padding-bottom: 65px !important; }

.pl--65 {
  padding-left: 65px !important; }

.pr--65 {
  padding-right: 65px !important; }

.mt--65 {
  margin-top: 65px !important; }

.mb--65 {
  margin-bottom: 65px !important; }

.mr--65 {
  margin-right: 65px !important; }

.ml--65 {
  margin-left: 65px !important; }

.ptb--70 {
  padding: 70px 0 !important; }

.plr--70 {
  padding: 0 70px !important; }

.pt--70 {
  padding-top: 70px !important; }

.pb--70 {
  padding-bottom: 70px !important; }

.pl--70 {
  padding-left: 70px !important; }

.pr--70 {
  padding-right: 70px !important; }

.mt--70 {
  margin-top: 70px !important; }

.mb--70 {
  margin-bottom: 70px !important; }

.mr--70 {
  margin-right: 70px !important; }

.ml--70 {
  margin-left: 70px !important; }

.ptb--75 {
  padding: 75px 0 !important; }

.plr--75 {
  padding: 0 75px !important; }

.pt--75 {
  padding-top: 75px !important; }

.pb--75 {
  padding-bottom: 75px !important; }

.pl--75 {
  padding-left: 75px !important; }

.pr--75 {
  padding-right: 75px !important; }

.mt--75 {
  margin-top: 75px !important; }

.mb--75 {
  margin-bottom: 75px !important; }

.mr--75 {
  margin-right: 75px !important; }

.ml--75 {
  margin-left: 75px !important; }

.ptb--80 {
  padding: 80px 0 !important; }

.plr--80 {
  padding: 0 80px !important; }

.pt--80 {
  padding-top: 80px !important; }

.pb--80 {
  padding-bottom: 80px !important; }

.pl--80 {
  padding-left: 80px !important; }

.pr--80 {
  padding-right: 80px !important; }

.mt--80 {
  margin-top: 80px !important; }

.mb--80 {
  margin-bottom: 80px !important; }

.mr--80 {
  margin-right: 80px !important; }

.ml--80 {
  margin-left: 80px !important; }

.ptb--85 {
  padding: 85px 0 !important; }

.plr--85 {
  padding: 0 85px !important; }

.pt--85 {
  padding-top: 85px !important; }

.pb--85 {
  padding-bottom: 85px !important; }

.pl--85 {
  padding-left: 85px !important; }

.pr--85 {
  padding-right: 85px !important; }

.mt--85 {
  margin-top: 85px !important; }

.mb--85 {
  margin-bottom: 85px !important; }

.mr--85 {
  margin-right: 85px !important; }

.ml--85 {
  margin-left: 85px !important; }

.ptb--90 {
  padding: 90px 0 !important; }

.plr--90 {
  padding: 0 90px !important; }

.pt--90 {
  padding-top: 90px !important; }

.pb--90 {
  padding-bottom: 90px !important; }

.pl--90 {
  padding-left: 90px !important; }

.pr--90 {
  padding-right: 90px !important; }

.mt--90 {
  margin-top: 90px !important; }

.mb--90 {
  margin-bottom: 90px !important; }

.mr--90 {
  margin-right: 90px !important; }

.ml--90 {
  margin-left: 90px !important; }

.ptb--95 {
  padding: 95px 0 !important; }

.plr--95 {
  padding: 0 95px !important; }

.pt--95 {
  padding-top: 95px !important; }

.pb--95 {
  padding-bottom: 95px !important; }

.pl--95 {
  padding-left: 95px !important; }

.pr--95 {
  padding-right: 95px !important; }

.mt--95 {
  margin-top: 95px !important; }

.mb--95 {
  margin-bottom: 95px !important; }

.mr--95 {
  margin-right: 95px !important; }

.ml--95 {
  margin-left: 95px !important; }

.ptb--100 {
  padding: 100px 0 !important; }

.plr--100 {
  padding: 0 100px !important; }

.pt--100 {
  padding-top: 100px !important; }

.pb--100 {
  padding-bottom: 100px !important; }

.pl--100 {
  padding-left: 100px !important; }

.pr--100 {
  padding-right: 100px !important; }

.mt--100 {
  margin-top: 100px !important; }

.mb--100 {
  margin-bottom: 100px !important; }

.mr--100 {
  margin-right: 100px !important; }

.ml--100 {
  margin-left: 100px !important; }

.ptb--105 {
  padding: 105px 0 !important; }

.plr--105 {
  padding: 0 105px !important; }

.pt--105 {
  padding-top: 105px !important; }

.pb--105 {
  padding-bottom: 105px !important; }

.pl--105 {
  padding-left: 105px !important; }

.pr--105 {
  padding-right: 105px !important; }

.mt--105 {
  margin-top: 105px !important; }

.mb--105 {
  margin-bottom: 105px !important; }

.mr--105 {
  margin-right: 105px !important; }

.ml--105 {
  margin-left: 105px !important; }

.ptb--110 {
  padding: 110px 0 !important; }

.plr--110 {
  padding: 0 110px !important; }

.pt--110 {
  padding-top: 110px !important; }

.pb--110 {
  padding-bottom: 110px !important; }

.pl--110 {
  padding-left: 110px !important; }

.pr--110 {
  padding-right: 110px !important; }

.mt--110 {
  margin-top: 110px !important; }

.mb--110 {
  margin-bottom: 110px !important; }

.mr--110 {
  margin-right: 110px !important; }

.ml--110 {
  margin-left: 110px !important; }

.ptb--115 {
  padding: 115px 0 !important; }

.plr--115 {
  padding: 0 115px !important; }

.pt--115 {
  padding-top: 115px !important; }

.pb--115 {
  padding-bottom: 115px !important; }

.pl--115 {
  padding-left: 115px !important; }

.pr--115 {
  padding-right: 115px !important; }

.mt--115 {
  margin-top: 115px !important; }

.mb--115 {
  margin-bottom: 115px !important; }

.mr--115 {
  margin-right: 115px !important; }

.ml--115 {
  margin-left: 115px !important; }

.ptb--120 {
  padding: 120px 0 !important; }

.plr--120 {
  padding: 0 120px !important; }

.pt--120 {
  padding-top: 120px !important; }

.pb--120 {
  padding-bottom: 120px !important; }

.pl--120 {
  padding-left: 120px !important; }

.pr--120 {
  padding-right: 120px !important; }

.mt--120 {
  margin-top: 120px !important; }

.mb--120 {
  margin-bottom: 120px !important; }

.mr--120 {
  margin-right: 120px !important; }

.ml--120 {
  margin-left: 120px !important; }

.ptb--125 {
  padding: 125px 0 !important; }

.plr--125 {
  padding: 0 125px !important; }

.pt--125 {
  padding-top: 125px !important; }

.pb--125 {
  padding-bottom: 125px !important; }

.pl--125 {
  padding-left: 125px !important; }

.pr--125 {
  padding-right: 125px !important; }

.mt--125 {
  margin-top: 125px !important; }

.mb--125 {
  margin-bottom: 125px !important; }

.mr--125 {
  margin-right: 125px !important; }

.ml--125 {
  margin-left: 125px !important; }

.ptb--130 {
  padding: 130px 0 !important; }

.plr--130 {
  padding: 0 130px !important; }

.pt--130 {
  padding-top: 130px !important; }

.pb--130 {
  padding-bottom: 130px !important; }

.pl--130 {
  padding-left: 130px !important; }

.pr--130 {
  padding-right: 130px !important; }

.mt--130 {
  margin-top: 130px !important; }

.mb--130 {
  margin-bottom: 130px !important; }

.mr--130 {
  margin-right: 130px !important; }

.ml--130 {
  margin-left: 130px !important; }

.ptb--135 {
  padding: 135px 0 !important; }

.plr--135 {
  padding: 0 135px !important; }

.pt--135 {
  padding-top: 135px !important; }

.pb--135 {
  padding-bottom: 135px !important; }

.pl--135 {
  padding-left: 135px !important; }

.pr--135 {
  padding-right: 135px !important; }

.mt--135 {
  margin-top: 135px !important; }

.mb--135 {
  margin-bottom: 135px !important; }

.mr--135 {
  margin-right: 135px !important; }

.ml--135 {
  margin-left: 135px !important; }

.ptb--140 {
  padding: 140px 0 !important; }

.plr--140 {
  padding: 0 140px !important; }

.pt--140 {
  padding-top: 140px !important; }

.pb--140 {
  padding-bottom: 140px !important; }

.pl--140 {
  padding-left: 140px !important; }

.pr--140 {
  padding-right: 140px !important; }

.mt--140 {
  margin-top: 140px !important; }

.mb--140 {
  margin-bottom: 140px !important; }

.mr--140 {
  margin-right: 140px !important; }

.ml--140 {
  margin-left: 140px !important; }

.ptb--145 {
  padding: 145px 0 !important; }

.plr--145 {
  padding: 0 145px !important; }

.pt--145 {
  padding-top: 145px !important; }

.pb--145 {
  padding-bottom: 145px !important; }

.pl--145 {
  padding-left: 145px !important; }

.pr--145 {
  padding-right: 145px !important; }

.mt--145 {
  margin-top: 145px !important; }

.mb--145 {
  margin-bottom: 145px !important; }

.mr--145 {
  margin-right: 145px !important; }

.ml--145 {
  margin-left: 145px !important; }

.ptb--150 {
  padding: 150px 0 !important; }

.plr--150 {
  padding: 0 150px !important; }

.pt--150 {
  padding-top: 150px !important; }

.pb--150 {
  padding-bottom: 150px !important; }

.pl--150 {
  padding-left: 150px !important; }

.pr--150 {
  padding-right: 150px !important; }

.mt--150 {
  margin-top: 150px !important; }

.mb--150 {
  margin-bottom: 150px !important; }

.mr--150 {
  margin-right: 150px !important; }

.ml--150 {
  margin-left: 150px !important; }

.ptb--155 {
  padding: 155px 0 !important; }

.plr--155 {
  padding: 0 155px !important; }

.pt--155 {
  padding-top: 155px !important; }

.pb--155 {
  padding-bottom: 155px !important; }

.pl--155 {
  padding-left: 155px !important; }

.pr--155 {
  padding-right: 155px !important; }

.mt--155 {
  margin-top: 155px !important; }

.mb--155 {
  margin-bottom: 155px !important; }

.mr--155 {
  margin-right: 155px !important; }

.ml--155 {
  margin-left: 155px !important; }

.ptb--160 {
  padding: 160px 0 !important; }

.plr--160 {
  padding: 0 160px !important; }

.pt--160 {
  padding-top: 160px !important; }

.pb--160 {
  padding-bottom: 160px !important; }

.pl--160 {
  padding-left: 160px !important; }

.pr--160 {
  padding-right: 160px !important; }

.mt--160 {
  margin-top: 160px !important; }

.mb--160 {
  margin-bottom: 160px !important; }

.mr--160 {
  margin-right: 160px !important; }

.ml--160 {
  margin-left: 160px !important; }

.ptb--165 {
  padding: 165px 0 !important; }

.plr--165 {
  padding: 0 165px !important; }

.pt--165 {
  padding-top: 165px !important; }

.pb--165 {
  padding-bottom: 165px !important; }

.pl--165 {
  padding-left: 165px !important; }

.pr--165 {
  padding-right: 165px !important; }

.mt--165 {
  margin-top: 165px !important; }

.mb--165 {
  margin-bottom: 165px !important; }

.mr--165 {
  margin-right: 165px !important; }

.ml--165 {
  margin-left: 165px !important; }

.ptb--170 {
  padding: 170px 0 !important; }

.plr--170 {
  padding: 0 170px !important; }

.pt--170 {
  padding-top: 170px !important; }

.pb--170 {
  padding-bottom: 170px !important; }

.pl--170 {
  padding-left: 170px !important; }

.pr--170 {
  padding-right: 170px !important; }

.mt--170 {
  margin-top: 170px !important; }

.mb--170 {
  margin-bottom: 170px !important; }

.mr--170 {
  margin-right: 170px !important; }

.ml--170 {
  margin-left: 170px !important; }

.ptb--175 {
  padding: 175px 0 !important; }

.plr--175 {
  padding: 0 175px !important; }

.pt--175 {
  padding-top: 175px !important; }

.pb--175 {
  padding-bottom: 175px !important; }

.pl--175 {
  padding-left: 175px !important; }

.pr--175 {
  padding-right: 175px !important; }

.mt--175 {
  margin-top: 175px !important; }

.mb--175 {
  margin-bottom: 175px !important; }

.mr--175 {
  margin-right: 175px !important; }

.ml--175 {
  margin-left: 175px !important; }

.ptb--180 {
  padding: 180px 0 !important; }

.plr--180 {
  padding: 0 180px !important; }

.pt--180 {
  padding-top: 180px !important; }

.pb--180 {
  padding-bottom: 180px !important; }

.pl--180 {
  padding-left: 180px !important; }

.pr--180 {
  padding-right: 180px !important; }

.mt--180 {
  margin-top: 180px !important; }

.mb--180 {
  margin-bottom: 180px !important; }

.mr--180 {
  margin-right: 180px !important; }

.ml--180 {
  margin-left: 180px !important; }

.ptb--185 {
  padding: 185px 0 !important; }

.plr--185 {
  padding: 0 185px !important; }

.pt--185 {
  padding-top: 185px !important; }

.pb--185 {
  padding-bottom: 185px !important; }

.pl--185 {
  padding-left: 185px !important; }

.pr--185 {
  padding-right: 185px !important; }

.mt--185 {
  margin-top: 185px !important; }

.mb--185 {
  margin-bottom: 185px !important; }

.mr--185 {
  margin-right: 185px !important; }

.ml--185 {
  margin-left: 185px !important; }

.ptb--190 {
  padding: 190px 0 !important; }

.plr--190 {
  padding: 0 190px !important; }

.pt--190 {
  padding-top: 190px !important; }

.pb--190 {
  padding-bottom: 190px !important; }

.pl--190 {
  padding-left: 190px !important; }

.pr--190 {
  padding-right: 190px !important; }

.mt--190 {
  margin-top: 190px !important; }

.mb--190 {
  margin-bottom: 190px !important; }

.mr--190 {
  margin-right: 190px !important; }

.ml--190 {
  margin-left: 190px !important; }

.ptb--195 {
  padding: 195px 0 !important; }

.plr--195 {
  padding: 0 195px !important; }

.pt--195 {
  padding-top: 195px !important; }

.pb--195 {
  padding-bottom: 195px !important; }

.pl--195 {
  padding-left: 195px !important; }

.pr--195 {
  padding-right: 195px !important; }

.mt--195 {
  margin-top: 195px !important; }

.mb--195 {
  margin-bottom: 195px !important; }

.mr--195 {
  margin-right: 195px !important; }

.ml--195 {
  margin-left: 195px !important; }

.ptb--200 {
  padding: 200px 0 !important; }

.plr--200 {
  padding: 0 200px !important; }

.pt--200 {
  padding-top: 200px !important; }

.pb--200 {
  padding-bottom: 200px !important; }

.pl--200 {
  padding-left: 200px !important; }

.pr--200 {
  padding-right: 200px !important; }

.mt--200 {
  margin-top: 200px !important; }

.mb--200 {
  margin-bottom: 200px !important; }

.mr--200 {
  margin-right: 200px !important; }

.ml--200 {
  margin-left: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0 !important; }
  .plr_lp--5 {
    padding: 0 5px !important; }
  .pt_lp--5 {
    padding-top: 5px !important; }
  .pb_lp--5 {
    padding-bottom: 5px !important; }
  .pl_lp--5 {
    padding-left: 5px !important; }
  .pr_lp--5 {
    padding-right: 5px !important; }
  .mt_lp--5 {
    margin-top: 5px !important; }
  .mb_lp--5 {
    margin-bottom: 5px !important; }
  .ptb_lp--10 {
    padding: 10px 0 !important; }
  .plr_lp--10 {
    padding: 0 10px !important; }
  .pt_lp--10 {
    padding-top: 10px !important; }
  .pb_lp--10 {
    padding-bottom: 10px !important; }
  .pl_lp--10 {
    padding-left: 10px !important; }
  .pr_lp--10 {
    padding-right: 10px !important; }
  .mt_lp--10 {
    margin-top: 10px !important; }
  .mb_lp--10 {
    margin-bottom: 10px !important; }
  .ptb_lp--15 {
    padding: 15px 0 !important; }
  .plr_lp--15 {
    padding: 0 15px !important; }
  .pt_lp--15 {
    padding-top: 15px !important; }
  .pb_lp--15 {
    padding-bottom: 15px !important; }
  .pl_lp--15 {
    padding-left: 15px !important; }
  .pr_lp--15 {
    padding-right: 15px !important; }
  .mt_lp--15 {
    margin-top: 15px !important; }
  .mb_lp--15 {
    margin-bottom: 15px !important; }
  .ptb_lp--20 {
    padding: 20px 0 !important; }
  .plr_lp--20 {
    padding: 0 20px !important; }
  .pt_lp--20 {
    padding-top: 20px !important; }
  .pb_lp--20 {
    padding-bottom: 20px !important; }
  .pl_lp--20 {
    padding-left: 20px !important; }
  .pr_lp--20 {
    padding-right: 20px !important; }
  .mt_lp--20 {
    margin-top: 20px !important; }
  .mb_lp--20 {
    margin-bottom: 20px !important; }
  .ptb_lp--25 {
    padding: 25px 0 !important; }
  .plr_lp--25 {
    padding: 0 25px !important; }
  .pt_lp--25 {
    padding-top: 25px !important; }
  .pb_lp--25 {
    padding-bottom: 25px !important; }
  .pl_lp--25 {
    padding-left: 25px !important; }
  .pr_lp--25 {
    padding-right: 25px !important; }
  .mt_lp--25 {
    margin-top: 25px !important; }
  .mb_lp--25 {
    margin-bottom: 25px !important; }
  .ptb_lp--30 {
    padding: 30px 0 !important; }
  .plr_lp--30 {
    padding: 0 30px !important; }
  .pt_lp--30 {
    padding-top: 30px !important; }
  .pb_lp--30 {
    padding-bottom: 30px !important; }
  .pl_lp--30 {
    padding-left: 30px !important; }
  .pr_lp--30 {
    padding-right: 30px !important; }
  .mt_lp--30 {
    margin-top: 30px !important; }
  .mb_lp--30 {
    margin-bottom: 30px !important; }
  .ptb_lp--35 {
    padding: 35px 0 !important; }
  .plr_lp--35 {
    padding: 0 35px !important; }
  .pt_lp--35 {
    padding-top: 35px !important; }
  .pb_lp--35 {
    padding-bottom: 35px !important; }
  .pl_lp--35 {
    padding-left: 35px !important; }
  .pr_lp--35 {
    padding-right: 35px !important; }
  .mt_lp--35 {
    margin-top: 35px !important; }
  .mb_lp--35 {
    margin-bottom: 35px !important; }
  .ptb_lp--40 {
    padding: 40px 0 !important; }
  .plr_lp--40 {
    padding: 0 40px !important; }
  .pt_lp--40 {
    padding-top: 40px !important; }
  .pb_lp--40 {
    padding-bottom: 40px !important; }
  .pl_lp--40 {
    padding-left: 40px !important; }
  .pr_lp--40 {
    padding-right: 40px !important; }
  .mt_lp--40 {
    margin-top: 40px !important; }
  .mb_lp--40 {
    margin-bottom: 40px !important; }
  .ptb_lp--45 {
    padding: 45px 0 !important; }
  .plr_lp--45 {
    padding: 0 45px !important; }
  .pt_lp--45 {
    padding-top: 45px !important; }
  .pb_lp--45 {
    padding-bottom: 45px !important; }
  .pl_lp--45 {
    padding-left: 45px !important; }
  .pr_lp--45 {
    padding-right: 45px !important; }
  .mt_lp--45 {
    margin-top: 45px !important; }
  .mb_lp--45 {
    margin-bottom: 45px !important; }
  .ptb_lp--50 {
    padding: 50px 0 !important; }
  .plr_lp--50 {
    padding: 0 50px !important; }
  .pt_lp--50 {
    padding-top: 50px !important; }
  .pb_lp--50 {
    padding-bottom: 50px !important; }
  .pl_lp--50 {
    padding-left: 50px !important; }
  .pr_lp--50 {
    padding-right: 50px !important; }
  .mt_lp--50 {
    margin-top: 50px !important; }
  .mb_lp--50 {
    margin-bottom: 50px !important; }
  .ptb_lp--55 {
    padding: 55px 0 !important; }
  .plr_lp--55 {
    padding: 0 55px !important; }
  .pt_lp--55 {
    padding-top: 55px !important; }
  .pb_lp--55 {
    padding-bottom: 55px !important; }
  .pl_lp--55 {
    padding-left: 55px !important; }
  .pr_lp--55 {
    padding-right: 55px !important; }
  .mt_lp--55 {
    margin-top: 55px !important; }
  .mb_lp--55 {
    margin-bottom: 55px !important; }
  .ptb_lp--60 {
    padding: 60px 0 !important; }
  .plr_lp--60 {
    padding: 0 60px !important; }
  .pt_lp--60 {
    padding-top: 60px !important; }
  .pb_lp--60 {
    padding-bottom: 60px !important; }
  .pl_lp--60 {
    padding-left: 60px !important; }
  .pr_lp--60 {
    padding-right: 60px !important; }
  .mt_lp--60 {
    margin-top: 60px !important; }
  .mb_lp--60 {
    margin-bottom: 60px !important; }
  .ptb_lp--65 {
    padding: 65px 0 !important; }
  .plr_lp--65 {
    padding: 0 65px !important; }
  .pt_lp--65 {
    padding-top: 65px !important; }
  .pb_lp--65 {
    padding-bottom: 65px !important; }
  .pl_lp--65 {
    padding-left: 65px !important; }
  .pr_lp--65 {
    padding-right: 65px !important; }
  .mt_lp--65 {
    margin-top: 65px !important; }
  .mb_lp--65 {
    margin-bottom: 65px !important; }
  .ptb_lp--70 {
    padding: 70px 0 !important; }
  .plr_lp--70 {
    padding: 0 70px !important; }
  .pt_lp--70 {
    padding-top: 70px !important; }
  .pb_lp--70 {
    padding-bottom: 70px !important; }
  .pl_lp--70 {
    padding-left: 70px !important; }
  .pr_lp--70 {
    padding-right: 70px !important; }
  .mt_lp--70 {
    margin-top: 70px !important; }
  .mb_lp--70 {
    margin-bottom: 70px !important; }
  .ptb_lp--75 {
    padding: 75px 0 !important; }
  .plr_lp--75 {
    padding: 0 75px !important; }
  .pt_lp--75 {
    padding-top: 75px !important; }
  .pb_lp--75 {
    padding-bottom: 75px !important; }
  .pl_lp--75 {
    padding-left: 75px !important; }
  .pr_lp--75 {
    padding-right: 75px !important; }
  .mt_lp--75 {
    margin-top: 75px !important; }
  .mb_lp--75 {
    margin-bottom: 75px !important; }
  .ptb_lp--80 {
    padding: 80px 0 !important; }
  .plr_lp--80 {
    padding: 0 80px !important; }
  .pt_lp--80 {
    padding-top: 80px !important; }
  .pb_lp--80 {
    padding-bottom: 80px !important; }
  .pl_lp--80 {
    padding-left: 80px !important; }
  .pr_lp--80 {
    padding-right: 80px !important; }
  .mt_lp--80 {
    margin-top: 80px !important; }
  .mb_lp--80 {
    margin-bottom: 80px !important; }
  .ptb_lp--85 {
    padding: 85px 0 !important; }
  .plr_lp--85 {
    padding: 0 85px !important; }
  .pt_lp--85 {
    padding-top: 85px !important; }
  .pb_lp--85 {
    padding-bottom: 85px !important; }
  .pl_lp--85 {
    padding-left: 85px !important; }
  .pr_lp--85 {
    padding-right: 85px !important; }
  .mt_lp--85 {
    margin-top: 85px !important; }
  .mb_lp--85 {
    margin-bottom: 85px !important; }
  .ptb_lp--90 {
    padding: 90px 0 !important; }
  .plr_lp--90 {
    padding: 0 90px !important; }
  .pt_lp--90 {
    padding-top: 90px !important; }
  .pb_lp--90 {
    padding-bottom: 90px !important; }
  .pl_lp--90 {
    padding-left: 90px !important; }
  .pr_lp--90 {
    padding-right: 90px !important; }
  .mt_lp--90 {
    margin-top: 90px !important; }
  .mb_lp--90 {
    margin-bottom: 90px !important; }
  .ptb_lp--95 {
    padding: 95px 0 !important; }
  .plr_lp--95 {
    padding: 0 95px !important; }
  .pt_lp--95 {
    padding-top: 95px !important; }
  .pb_lp--95 {
    padding-bottom: 95px !important; }
  .pl_lp--95 {
    padding-left: 95px !important; }
  .pr_lp--95 {
    padding-right: 95px !important; }
  .mt_lp--95 {
    margin-top: 95px !important; }
  .mb_lp--95 {
    margin-bottom: 95px !important; }
  .ptb_lp--100 {
    padding: 100px 0 !important; }
  .plr_lp--100 {
    padding: 0 100px !important; }
  .pt_lp--100 {
    padding-top: 100px !important; }
  .pb_lp--100 {
    padding-bottom: 100px !important; }
  .pl_lp--100 {
    padding-left: 100px !important; }
  .pr_lp--100 {
    padding-right: 100px !important; }
  .mt_lp--100 {
    margin-top: 100px !important; }
  .mb_lp--100 {
    margin-bottom: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptb_lg--5 {
    padding: 5px 0 !important; }
  .plr_lg--5 {
    padding: 0 5px !important; }
  .pt_lg--5 {
    padding-top: 5px !important; }
  .pb_lg--5 {
    padding-bottom: 5px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--5 {
    padding-right: 5px !important; }
  .pl_lg--5 {
    padding-left: 5px !important; }
  .pr_lg--5 {
    padding-right: 5px !important; }
  .mt_lg--5 {
    margin-top: 5px !important; }
  .mb_lg--5 {
    margin-bottom: 5px !important; }
  .ml_lg--5 {
    margin-left: 5px !important; }
  .ptb_lg--10 {
    padding: 10px 0 !important; }
  .plr_lg--10 {
    padding: 0 10px !important; }
  .pt_lg--10 {
    padding-top: 10px !important; }
  .pb_lg--10 {
    padding-bottom: 10px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--10 {
    padding-right: 10px !important; }
  .pl_lg--10 {
    padding-left: 10px !important; }
  .pr_lg--10 {
    padding-right: 10px !important; }
  .mt_lg--10 {
    margin-top: 10px !important; }
  .mb_lg--10 {
    margin-bottom: 10px !important; }
  .ml_lg--10 {
    margin-left: 10px !important; }
  .ptb_lg--15 {
    padding: 15px 0 !important; }
  .plr_lg--15 {
    padding: 0 15px !important; }
  .pt_lg--15 {
    padding-top: 15px !important; }
  .pb_lg--15 {
    padding-bottom: 15px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--15 {
    padding-right: 15px !important; }
  .pl_lg--15 {
    padding-left: 15px !important; }
  .pr_lg--15 {
    padding-right: 15px !important; }
  .mt_lg--15 {
    margin-top: 15px !important; }
  .mb_lg--15 {
    margin-bottom: 15px !important; }
  .ml_lg--15 {
    margin-left: 15px !important; }
  .ptb_lg--20 {
    padding: 20px 0 !important; }
  .plr_lg--20 {
    padding: 0 20px !important; }
  .pt_lg--20 {
    padding-top: 20px !important; }
  .pb_lg--20 {
    padding-bottom: 20px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--20 {
    padding-right: 20px !important; }
  .pl_lg--20 {
    padding-left: 20px !important; }
  .pr_lg--20 {
    padding-right: 20px !important; }
  .mt_lg--20 {
    margin-top: 20px !important; }
  .mb_lg--20 {
    margin-bottom: 20px !important; }
  .ml_lg--20 {
    margin-left: 20px !important; }
  .ptb_lg--25 {
    padding: 25px 0 !important; }
  .plr_lg--25 {
    padding: 0 25px !important; }
  .pt_lg--25 {
    padding-top: 25px !important; }
  .pb_lg--25 {
    padding-bottom: 25px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--25 {
    padding-right: 25px !important; }
  .pl_lg--25 {
    padding-left: 25px !important; }
  .pr_lg--25 {
    padding-right: 25px !important; }
  .mt_lg--25 {
    margin-top: 25px !important; }
  .mb_lg--25 {
    margin-bottom: 25px !important; }
  .ml_lg--25 {
    margin-left: 25px !important; }
  .ptb_lg--30 {
    padding: 30px 0 !important; }
  .plr_lg--30 {
    padding: 0 30px !important; }
  .pt_lg--30 {
    padding-top: 30px !important; }
  .pb_lg--30 {
    padding-bottom: 30px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--30 {
    padding-right: 30px !important; }
  .pl_lg--30 {
    padding-left: 30px !important; }
  .pr_lg--30 {
    padding-right: 30px !important; }
  .mt_lg--30 {
    margin-top: 30px !important; }
  .mb_lg--30 {
    margin-bottom: 30px !important; }
  .ml_lg--30 {
    margin-left: 30px !important; }
  .ptb_lg--35 {
    padding: 35px 0 !important; }
  .plr_lg--35 {
    padding: 0 35px !important; }
  .pt_lg--35 {
    padding-top: 35px !important; }
  .pb_lg--35 {
    padding-bottom: 35px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--35 {
    padding-right: 35px !important; }
  .pl_lg--35 {
    padding-left: 35px !important; }
  .pr_lg--35 {
    padding-right: 35px !important; }
  .mt_lg--35 {
    margin-top: 35px !important; }
  .mb_lg--35 {
    margin-bottom: 35px !important; }
  .ml_lg--35 {
    margin-left: 35px !important; }
  .ptb_lg--40 {
    padding: 40px 0 !important; }
  .plr_lg--40 {
    padding: 0 40px !important; }
  .pt_lg--40 {
    padding-top: 40px !important; }
  .pb_lg--40 {
    padding-bottom: 40px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--40 {
    padding-right: 40px !important; }
  .pl_lg--40 {
    padding-left: 40px !important; }
  .pr_lg--40 {
    padding-right: 40px !important; }
  .mt_lg--40 {
    margin-top: 40px !important; }
  .mb_lg--40 {
    margin-bottom: 40px !important; }
  .ml_lg--40 {
    margin-left: 40px !important; }
  .ptb_lg--45 {
    padding: 45px 0 !important; }
  .plr_lg--45 {
    padding: 0 45px !important; }
  .pt_lg--45 {
    padding-top: 45px !important; }
  .pb_lg--45 {
    padding-bottom: 45px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--45 {
    padding-right: 45px !important; }
  .pl_lg--45 {
    padding-left: 45px !important; }
  .pr_lg--45 {
    padding-right: 45px !important; }
  .mt_lg--45 {
    margin-top: 45px !important; }
  .mb_lg--45 {
    margin-bottom: 45px !important; }
  .ml_lg--45 {
    margin-left: 45px !important; }
  .ptb_lg--50 {
    padding: 50px 0 !important; }
  .plr_lg--50 {
    padding: 0 50px !important; }
  .pt_lg--50 {
    padding-top: 50px !important; }
  .pb_lg--50 {
    padding-bottom: 50px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--50 {
    padding-right: 50px !important; }
  .pl_lg--50 {
    padding-left: 50px !important; }
  .pr_lg--50 {
    padding-right: 50px !important; }
  .mt_lg--50 {
    margin-top: 50px !important; }
  .mb_lg--50 {
    margin-bottom: 50px !important; }
  .ml_lg--50 {
    margin-left: 50px !important; }
  .ptb_lg--55 {
    padding: 55px 0 !important; }
  .plr_lg--55 {
    padding: 0 55px !important; }
  .pt_lg--55 {
    padding-top: 55px !important; }
  .pb_lg--55 {
    padding-bottom: 55px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--55 {
    padding-right: 55px !important; }
  .pl_lg--55 {
    padding-left: 55px !important; }
  .pr_lg--55 {
    padding-right: 55px !important; }
  .mt_lg--55 {
    margin-top: 55px !important; }
  .mb_lg--55 {
    margin-bottom: 55px !important; }
  .ml_lg--55 {
    margin-left: 55px !important; }
  .ptb_lg--60 {
    padding: 60px 0 !important; }
  .plr_lg--60 {
    padding: 0 60px !important; }
  .pt_lg--60 {
    padding-top: 60px !important; }
  .pb_lg--60 {
    padding-bottom: 60px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--60 {
    padding-right: 60px !important; }
  .pl_lg--60 {
    padding-left: 60px !important; }
  .pr_lg--60 {
    padding-right: 60px !important; }
  .mt_lg--60 {
    margin-top: 60px !important; }
  .mb_lg--60 {
    margin-bottom: 60px !important; }
  .ml_lg--60 {
    margin-left: 60px !important; }
  .ptb_lg--65 {
    padding: 65px 0 !important; }
  .plr_lg--65 {
    padding: 0 65px !important; }
  .pt_lg--65 {
    padding-top: 65px !important; }
  .pb_lg--65 {
    padding-bottom: 65px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--65 {
    padding-right: 65px !important; }
  .pl_lg--65 {
    padding-left: 65px !important; }
  .pr_lg--65 {
    padding-right: 65px !important; }
  .mt_lg--65 {
    margin-top: 65px !important; }
  .mb_lg--65 {
    margin-bottom: 65px !important; }
  .ml_lg--65 {
    margin-left: 65px !important; }
  .ptb_lg--70 {
    padding: 70px 0 !important; }
  .plr_lg--70 {
    padding: 0 70px !important; }
  .pt_lg--70 {
    padding-top: 70px !important; }
  .pb_lg--70 {
    padding-bottom: 70px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--70 {
    padding-right: 70px !important; }
  .pl_lg--70 {
    padding-left: 70px !important; }
  .pr_lg--70 {
    padding-right: 70px !important; }
  .mt_lg--70 {
    margin-top: 70px !important; }
  .mb_lg--70 {
    margin-bottom: 70px !important; }
  .ml_lg--70 {
    margin-left: 70px !important; }
  .ptb_lg--75 {
    padding: 75px 0 !important; }
  .plr_lg--75 {
    padding: 0 75px !important; }
  .pt_lg--75 {
    padding-top: 75px !important; }
  .pb_lg--75 {
    padding-bottom: 75px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--75 {
    padding-right: 75px !important; }
  .pl_lg--75 {
    padding-left: 75px !important; }
  .pr_lg--75 {
    padding-right: 75px !important; }
  .mt_lg--75 {
    margin-top: 75px !important; }
  .mb_lg--75 {
    margin-bottom: 75px !important; }
  .ml_lg--75 {
    margin-left: 75px !important; }
  .ptb_lg--80 {
    padding: 80px 0 !important; }
  .plr_lg--80 {
    padding: 0 80px !important; }
  .pt_lg--80 {
    padding-top: 80px !important; }
  .pb_lg--80 {
    padding-bottom: 80px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--80 {
    padding-right: 80px !important; }
  .pl_lg--80 {
    padding-left: 80px !important; }
  .pr_lg--80 {
    padding-right: 80px !important; }
  .mt_lg--80 {
    margin-top: 80px !important; }
  .mb_lg--80 {
    margin-bottom: 80px !important; }
  .ml_lg--80 {
    margin-left: 80px !important; }
  .ptb_lg--85 {
    padding: 85px 0 !important; }
  .plr_lg--85 {
    padding: 0 85px !important; }
  .pt_lg--85 {
    padding-top: 85px !important; }
  .pb_lg--85 {
    padding-bottom: 85px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--85 {
    padding-right: 85px !important; }
  .pl_lg--85 {
    padding-left: 85px !important; }
  .pr_lg--85 {
    padding-right: 85px !important; }
  .mt_lg--85 {
    margin-top: 85px !important; }
  .mb_lg--85 {
    margin-bottom: 85px !important; }
  .ml_lg--85 {
    margin-left: 85px !important; }
  .ptb_lg--90 {
    padding: 90px 0 !important; }
  .plr_lg--90 {
    padding: 0 90px !important; }
  .pt_lg--90 {
    padding-top: 90px !important; }
  .pb_lg--90 {
    padding-bottom: 90px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--90 {
    padding-right: 90px !important; }
  .pl_lg--90 {
    padding-left: 90px !important; }
  .pr_lg--90 {
    padding-right: 90px !important; }
  .mt_lg--90 {
    margin-top: 90px !important; }
  .mb_lg--90 {
    margin-bottom: 90px !important; }
  .ml_lg--90 {
    margin-left: 90px !important; }
  .ptb_lg--95 {
    padding: 95px 0 !important; }
  .plr_lg--95 {
    padding: 0 95px !important; }
  .pt_lg--95 {
    padding-top: 95px !important; }
  .pb_lg--95 {
    padding-bottom: 95px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--95 {
    padding-right: 95px !important; }
  .pl_lg--95 {
    padding-left: 95px !important; }
  .pr_lg--95 {
    padding-right: 95px !important; }
  .mt_lg--95 {
    margin-top: 95px !important; }
  .mb_lg--95 {
    margin-bottom: 95px !important; }
  .ml_lg--95 {
    margin-left: 95px !important; }
  .ptb_lg--100 {
    padding: 100px 0 !important; }
  .plr_lg--100 {
    padding: 0 100px !important; }
  .pt_lg--100 {
    padding-top: 100px !important; }
  .pb_lg--100 {
    padding-bottom: 100px !important; }
  .pr_lg--0 {
    padding-right: 0 !important; }
  .pr_lg--100 {
    padding-right: 100px !important; }
  .pl_lg--100 {
    padding-left: 100px !important; }
  .pr_lg--100 {
    padding-right: 100px !important; }
  .mt_lg--100 {
    margin-top: 100px !important; }
  .mb_lg--100 {
    margin-bottom: 100px !important; }
  .ml_lg--100 {
    margin-left: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb_md--0 {
    padding: 0 !important; }
  .pl_md--0 {
    padding-left: 0 !important; }
  .pr_md--0 {
    padding-right: 0 !important; }
  .pt_md--0 {
    padding-top: 0 !important; }
  .pb_md--0 {
    padding-bottom: 0 !important; }
  .mr_md--0 {
    margin-right: 0 !important; }
  .ml_md--0 {
    margin-left: 0 !important; }
  .mt_md--0 {
    margin-top: 0 !important; }
  .mb_md--0 {
    margin-bottom: 0 !important; }
  .ptb_md--250 {
    padding: 250px 0 !important; }
  .ptb_md--5 {
    padding: 5px 0 !important; }
  .plr_md--5 {
    padding: 0 5px !important; }
  .pt_md--5 {
    padding-top: 5px !important; }
  .pb_md--5 {
    padding-bottom: 5px !important; }
  .pl_md--5 {
    padding-left: 5px !important; }
  .pr_md--5 {
    padding-right: 5px !important; }
  .mt_md--5 {
    margin-top: 5px !important; }
  .mb_md--5 {
    margin-bottom: 5px !important; }
  .ptb_md--10 {
    padding: 10px 0 !important; }
  .plr_md--10 {
    padding: 0 10px !important; }
  .pt_md--10 {
    padding-top: 10px !important; }
  .pb_md--10 {
    padding-bottom: 10px !important; }
  .pl_md--10 {
    padding-left: 10px !important; }
  .pr_md--10 {
    padding-right: 10px !important; }
  .mt_md--10 {
    margin-top: 10px !important; }
  .mb_md--10 {
    margin-bottom: 10px !important; }
  .ptb_md--15 {
    padding: 15px 0 !important; }
  .plr_md--15 {
    padding: 0 15px !important; }
  .pt_md--15 {
    padding-top: 15px !important; }
  .pb_md--15 {
    padding-bottom: 15px !important; }
  .pl_md--15 {
    padding-left: 15px !important; }
  .pr_md--15 {
    padding-right: 15px !important; }
  .mt_md--15 {
    margin-top: 15px !important; }
  .mb_md--15 {
    margin-bottom: 15px !important; }
  .ptb_md--20 {
    padding: 20px 0 !important; }
  .plr_md--20 {
    padding: 0 20px !important; }
  .pt_md--20 {
    padding-top: 20px !important; }
  .pb_md--20 {
    padding-bottom: 20px !important; }
  .pl_md--20 {
    padding-left: 20px !important; }
  .pr_md--20 {
    padding-right: 20px !important; }
  .mt_md--20 {
    margin-top: 20px !important; }
  .mb_md--20 {
    margin-bottom: 20px !important; }
  .ptb_md--25 {
    padding: 25px 0 !important; }
  .plr_md--25 {
    padding: 0 25px !important; }
  .pt_md--25 {
    padding-top: 25px !important; }
  .pb_md--25 {
    padding-bottom: 25px !important; }
  .pl_md--25 {
    padding-left: 25px !important; }
  .pr_md--25 {
    padding-right: 25px !important; }
  .mt_md--25 {
    margin-top: 25px !important; }
  .mb_md--25 {
    margin-bottom: 25px !important; }
  .ptb_md--30 {
    padding: 30px 0 !important; }
  .plr_md--30 {
    padding: 0 30px !important; }
  .pt_md--30 {
    padding-top: 30px !important; }
  .pb_md--30 {
    padding-bottom: 30px !important; }
  .pl_md--30 {
    padding-left: 30px !important; }
  .pr_md--30 {
    padding-right: 30px !important; }
  .mt_md--30 {
    margin-top: 30px !important; }
  .mb_md--30 {
    margin-bottom: 30px !important; }
  .ptb_md--35 {
    padding: 35px 0 !important; }
  .plr_md--35 {
    padding: 0 35px !important; }
  .pt_md--35 {
    padding-top: 35px !important; }
  .pb_md--35 {
    padding-bottom: 35px !important; }
  .pl_md--35 {
    padding-left: 35px !important; }
  .pr_md--35 {
    padding-right: 35px !important; }
  .mt_md--35 {
    margin-top: 35px !important; }
  .mb_md--35 {
    margin-bottom: 35px !important; }
  .ptb_md--40 {
    padding: 40px 0 !important; }
  .plr_md--40 {
    padding: 0 40px !important; }
  .pt_md--40 {
    padding-top: 40px !important; }
  .pb_md--40 {
    padding-bottom: 40px !important; }
  .pl_md--40 {
    padding-left: 40px !important; }
  .pr_md--40 {
    padding-right: 40px !important; }
  .mt_md--40 {
    margin-top: 40px !important; }
  .mb_md--40 {
    margin-bottom: 40px !important; }
  .ptb_md--45 {
    padding: 45px 0 !important; }
  .plr_md--45 {
    padding: 0 45px !important; }
  .pt_md--45 {
    padding-top: 45px !important; }
  .pb_md--45 {
    padding-bottom: 45px !important; }
  .pl_md--45 {
    padding-left: 45px !important; }
  .pr_md--45 {
    padding-right: 45px !important; }
  .mt_md--45 {
    margin-top: 45px !important; }
  .mb_md--45 {
    margin-bottom: 45px !important; }
  .ptb_md--50 {
    padding: 50px 0 !important; }
  .plr_md--50 {
    padding: 0 50px !important; }
  .pt_md--50 {
    padding-top: 50px !important; }
  .pb_md--50 {
    padding-bottom: 50px !important; }
  .pl_md--50 {
    padding-left: 50px !important; }
  .pr_md--50 {
    padding-right: 50px !important; }
  .mt_md--50 {
    margin-top: 50px !important; }
  .mb_md--50 {
    margin-bottom: 50px !important; }
  .ptb_md--55 {
    padding: 55px 0 !important; }
  .plr_md--55 {
    padding: 0 55px !important; }
  .pt_md--55 {
    padding-top: 55px !important; }
  .pb_md--55 {
    padding-bottom: 55px !important; }
  .pl_md--55 {
    padding-left: 55px !important; }
  .pr_md--55 {
    padding-right: 55px !important; }
  .mt_md--55 {
    margin-top: 55px !important; }
  .mb_md--55 {
    margin-bottom: 55px !important; }
  .ptb_md--60 {
    padding: 60px 0 !important; }
  .plr_md--60 {
    padding: 0 60px !important; }
  .pt_md--60 {
    padding-top: 60px !important; }
  .pb_md--60 {
    padding-bottom: 60px !important; }
  .pl_md--60 {
    padding-left: 60px !important; }
  .pr_md--60 {
    padding-right: 60px !important; }
  .mt_md--60 {
    margin-top: 60px !important; }
  .mb_md--60 {
    margin-bottom: 60px !important; }
  .ptb_md--65 {
    padding: 65px 0 !important; }
  .plr_md--65 {
    padding: 0 65px !important; }
  .pt_md--65 {
    padding-top: 65px !important; }
  .pb_md--65 {
    padding-bottom: 65px !important; }
  .pl_md--65 {
    padding-left: 65px !important; }
  .pr_md--65 {
    padding-right: 65px !important; }
  .mt_md--65 {
    margin-top: 65px !important; }
  .mb_md--65 {
    margin-bottom: 65px !important; }
  .ptb_md--70 {
    padding: 70px 0 !important; }
  .plr_md--70 {
    padding: 0 70px !important; }
  .pt_md--70 {
    padding-top: 70px !important; }
  .pb_md--70 {
    padding-bottom: 70px !important; }
  .pl_md--70 {
    padding-left: 70px !important; }
  .pr_md--70 {
    padding-right: 70px !important; }
  .mt_md--70 {
    margin-top: 70px !important; }
  .mb_md--70 {
    margin-bottom: 70px !important; }
  .ptb_md--75 {
    padding: 75px 0 !important; }
  .plr_md--75 {
    padding: 0 75px !important; }
  .pt_md--75 {
    padding-top: 75px !important; }
  .pb_md--75 {
    padding-bottom: 75px !important; }
  .pl_md--75 {
    padding-left: 75px !important; }
  .pr_md--75 {
    padding-right: 75px !important; }
  .mt_md--75 {
    margin-top: 75px !important; }
  .mb_md--75 {
    margin-bottom: 75px !important; }
  .ptb_md--80 {
    padding: 80px 0 !important; }
  .plr_md--80 {
    padding: 0 80px !important; }
  .pt_md--80 {
    padding-top: 80px !important; }
  .pb_md--80 {
    padding-bottom: 80px !important; }
  .pl_md--80 {
    padding-left: 80px !important; }
  .pr_md--80 {
    padding-right: 80px !important; }
  .mt_md--80 {
    margin-top: 80px !important; }
  .mb_md--80 {
    margin-bottom: 80px !important; }
  .ptb_md--85 {
    padding: 85px 0 !important; }
  .plr_md--85 {
    padding: 0 85px !important; }
  .pt_md--85 {
    padding-top: 85px !important; }
  .pb_md--85 {
    padding-bottom: 85px !important; }
  .pl_md--85 {
    padding-left: 85px !important; }
  .pr_md--85 {
    padding-right: 85px !important; }
  .mt_md--85 {
    margin-top: 85px !important; }
  .mb_md--85 {
    margin-bottom: 85px !important; }
  .ptb_md--90 {
    padding: 90px 0 !important; }
  .plr_md--90 {
    padding: 0 90px !important; }
  .pt_md--90 {
    padding-top: 90px !important; }
  .pb_md--90 {
    padding-bottom: 90px !important; }
  .pl_md--90 {
    padding-left: 90px !important; }
  .pr_md--90 {
    padding-right: 90px !important; }
  .mt_md--90 {
    margin-top: 90px !important; }
  .mb_md--90 {
    margin-bottom: 90px !important; }
  .ptb_md--95 {
    padding: 95px 0 !important; }
  .plr_md--95 {
    padding: 0 95px !important; }
  .pt_md--95 {
    padding-top: 95px !important; }
  .pb_md--95 {
    padding-bottom: 95px !important; }
  .pl_md--95 {
    padding-left: 95px !important; }
  .pr_md--95 {
    padding-right: 95px !important; }
  .mt_md--95 {
    margin-top: 95px !important; }
  .mb_md--95 {
    margin-bottom: 95px !important; }
  .ptb_md--100 {
    padding: 100px 0 !important; }
  .plr_md--100 {
    padding: 0 100px !important; }
  .pt_md--100 {
    padding-top: 100px !important; }
  .pb_md--100 {
    padding-bottom: 100px !important; }
  .pl_md--100 {
    padding-left: 100px !important; }
  .pr_md--100 {
    padding-right: 100px !important; }
  .mt_md--100 {
    margin-top: 100px !important; }
  .mb_md--100 {
    margin-bottom: 100px !important; } }

@media only screen and (max-width: 767px) {
  .ptb_sm--250 {
    padding: 250px 0 !important; }
  .ptb_sm--0 {
    padding: 0 !important; }
  .pl_sm--0 {
    padding-left: 0 !important; }
  .pr_sm--0 {
    padding-right: 0 !important; }
  .pt_sm--0 {
    padding-top: 0 !important; }
  .pb_sm--0 {
    padding-bottom: 0 !important; }
  .mr_sm--0 {
    margin-right: 0 !important; }
  .ml_sm--0 {
    margin-left: 0 !important; }
  .mt_sm--0 {
    margin-top: 0 !important; }
  .mb_sm--0 {
    margin-bottom: 0 !important; }
  .pt_sm--150 {
    padding-top: 150px !important; }
  .pb_sm--110 {
    padding-bottom: 110px !important; }
  .ptb_sm--5 {
    padding: 5px 0 !important; }
  .plr_sm--5 {
    padding: 0 5px !important; }
  .pt_sm--5 {
    padding-top: 5px !important; }
  .pb_sm--5 {
    padding-bottom: 5px !important; }
  .pl_sm--5 {
    padding-left: 5px !important; }
  .pr_sm--5 {
    padding-right: 5px !important; }
  .mt_sm--5 {
    margin-top: 5px !important; }
  .ml_sm--5 {
    margin-left: 5px !important; }
  .mr_sm--5 {
    margin-right: 5px !important; }
  .mb_sm--5 {
    margin-bottom: 5px !important; }
  .ptb_sm--10 {
    padding: 10px 0 !important; }
  .plr_sm--10 {
    padding: 0 10px !important; }
  .pt_sm--10 {
    padding-top: 10px !important; }
  .pb_sm--10 {
    padding-bottom: 10px !important; }
  .pl_sm--10 {
    padding-left: 10px !important; }
  .pr_sm--10 {
    padding-right: 10px !important; }
  .mt_sm--10 {
    margin-top: 10px !important; }
  .ml_sm--10 {
    margin-left: 10px !important; }
  .mr_sm--10 {
    margin-right: 10px !important; }
  .mb_sm--10 {
    margin-bottom: 10px !important; }
  .ptb_sm--15 {
    padding: 15px 0 !important; }
  .plr_sm--15 {
    padding: 0 15px !important; }
  .pt_sm--15 {
    padding-top: 15px !important; }
  .pb_sm--15 {
    padding-bottom: 15px !important; }
  .pl_sm--15 {
    padding-left: 15px !important; }
  .pr_sm--15 {
    padding-right: 15px !important; }
  .mt_sm--15 {
    margin-top: 15px !important; }
  .ml_sm--15 {
    margin-left: 15px !important; }
  .mr_sm--15 {
    margin-right: 15px !important; }
  .mb_sm--15 {
    margin-bottom: 15px !important; }
  .ptb_sm--20 {
    padding: 20px 0 !important; }
  .plr_sm--20 {
    padding: 0 20px !important; }
  .pt_sm--20 {
    padding-top: 20px !important; }
  .pb_sm--20 {
    padding-bottom: 20px !important; }
  .pl_sm--20 {
    padding-left: 20px !important; }
  .pr_sm--20 {
    padding-right: 20px !important; }
  .mt_sm--20 {
    margin-top: 20px !important; }
  .ml_sm--20 {
    margin-left: 20px !important; }
  .mr_sm--20 {
    margin-right: 20px !important; }
  .mb_sm--20 {
    margin-bottom: 20px !important; }
  .ptb_sm--25 {
    padding: 25px 0 !important; }
  .plr_sm--25 {
    padding: 0 25px !important; }
  .pt_sm--25 {
    padding-top: 25px !important; }
  .pb_sm--25 {
    padding-bottom: 25px !important; }
  .pl_sm--25 {
    padding-left: 25px !important; }
  .pr_sm--25 {
    padding-right: 25px !important; }
  .mt_sm--25 {
    margin-top: 25px !important; }
  .ml_sm--25 {
    margin-left: 25px !important; }
  .mr_sm--25 {
    margin-right: 25px !important; }
  .mb_sm--25 {
    margin-bottom: 25px !important; }
  .ptb_sm--30 {
    padding: 30px 0 !important; }
  .plr_sm--30 {
    padding: 0 30px !important; }
  .pt_sm--30 {
    padding-top: 30px !important; }
  .pb_sm--30 {
    padding-bottom: 30px !important; }
  .pl_sm--30 {
    padding-left: 30px !important; }
  .pr_sm--30 {
    padding-right: 30px !important; }
  .mt_sm--30 {
    margin-top: 30px !important; }
  .ml_sm--30 {
    margin-left: 30px !important; }
  .mr_sm--30 {
    margin-right: 30px !important; }
  .mb_sm--30 {
    margin-bottom: 30px !important; }
  .ptb_sm--35 {
    padding: 35px 0 !important; }
  .plr_sm--35 {
    padding: 0 35px !important; }
  .pt_sm--35 {
    padding-top: 35px !important; }
  .pb_sm--35 {
    padding-bottom: 35px !important; }
  .pl_sm--35 {
    padding-left: 35px !important; }
  .pr_sm--35 {
    padding-right: 35px !important; }
  .mt_sm--35 {
    margin-top: 35px !important; }
  .ml_sm--35 {
    margin-left: 35px !important; }
  .mr_sm--35 {
    margin-right: 35px !important; }
  .mb_sm--35 {
    margin-bottom: 35px !important; }
  .ptb_sm--40 {
    padding: 40px 0 !important; }
  .plr_sm--40 {
    padding: 0 40px !important; }
  .pt_sm--40 {
    padding-top: 40px !important; }
  .pb_sm--40 {
    padding-bottom: 40px !important; }
  .pl_sm--40 {
    padding-left: 40px !important; }
  .pr_sm--40 {
    padding-right: 40px !important; }
  .mt_sm--40 {
    margin-top: 40px !important; }
  .ml_sm--40 {
    margin-left: 40px !important; }
  .mr_sm--40 {
    margin-right: 40px !important; }
  .mb_sm--40 {
    margin-bottom: 40px !important; }
  .ptb_sm--45 {
    padding: 45px 0 !important; }
  .plr_sm--45 {
    padding: 0 45px !important; }
  .pt_sm--45 {
    padding-top: 45px !important; }
  .pb_sm--45 {
    padding-bottom: 45px !important; }
  .pl_sm--45 {
    padding-left: 45px !important; }
  .pr_sm--45 {
    padding-right: 45px !important; }
  .mt_sm--45 {
    margin-top: 45px !important; }
  .ml_sm--45 {
    margin-left: 45px !important; }
  .mr_sm--45 {
    margin-right: 45px !important; }
  .mb_sm--45 {
    margin-bottom: 45px !important; }
  .ptb_sm--50 {
    padding: 50px 0 !important; }
  .plr_sm--50 {
    padding: 0 50px !important; }
  .pt_sm--50 {
    padding-top: 50px !important; }
  .pb_sm--50 {
    padding-bottom: 50px !important; }
  .pl_sm--50 {
    padding-left: 50px !important; }
  .pr_sm--50 {
    padding-right: 50px !important; }
  .mt_sm--50 {
    margin-top: 50px !important; }
  .ml_sm--50 {
    margin-left: 50px !important; }
  .mr_sm--50 {
    margin-right: 50px !important; }
  .mb_sm--50 {
    margin-bottom: 50px !important; }
  .ptb_sm--55 {
    padding: 55px 0 !important; }
  .plr_sm--55 {
    padding: 0 55px !important; }
  .pt_sm--55 {
    padding-top: 55px !important; }
  .pb_sm--55 {
    padding-bottom: 55px !important; }
  .pl_sm--55 {
    padding-left: 55px !important; }
  .pr_sm--55 {
    padding-right: 55px !important; }
  .mt_sm--55 {
    margin-top: 55px !important; }
  .ml_sm--55 {
    margin-left: 55px !important; }
  .mr_sm--55 {
    margin-right: 55px !important; }
  .mb_sm--55 {
    margin-bottom: 55px !important; }
  .ptb_sm--60 {
    padding: 60px 0 !important; }
  .plr_sm--60 {
    padding: 0 60px !important; }
  .pt_sm--60 {
    padding-top: 60px !important; }
  .pb_sm--60 {
    padding-bottom: 60px !important; }
  .pl_sm--60 {
    padding-left: 60px !important; }
  .pr_sm--60 {
    padding-right: 60px !important; }
  .mt_sm--60 {
    margin-top: 60px !important; }
  .ml_sm--60 {
    margin-left: 60px !important; }
  .mr_sm--60 {
    margin-right: 60px !important; }
  .mb_sm--60 {
    margin-bottom: 60px !important; }
  .ptb_sm--65 {
    padding: 65px 0 !important; }
  .plr_sm--65 {
    padding: 0 65px !important; }
  .pt_sm--65 {
    padding-top: 65px !important; }
  .pb_sm--65 {
    padding-bottom: 65px !important; }
  .pl_sm--65 {
    padding-left: 65px !important; }
  .pr_sm--65 {
    padding-right: 65px !important; }
  .mt_sm--65 {
    margin-top: 65px !important; }
  .ml_sm--65 {
    margin-left: 65px !important; }
  .mr_sm--65 {
    margin-right: 65px !important; }
  .mb_sm--65 {
    margin-bottom: 65px !important; }
  .ptb_sm--70 {
    padding: 70px 0 !important; }
  .plr_sm--70 {
    padding: 0 70px !important; }
  .pt_sm--70 {
    padding-top: 70px !important; }
  .pb_sm--70 {
    padding-bottom: 70px !important; }
  .pl_sm--70 {
    padding-left: 70px !important; }
  .pr_sm--70 {
    padding-right: 70px !important; }
  .mt_sm--70 {
    margin-top: 70px !important; }
  .ml_sm--70 {
    margin-left: 70px !important; }
  .mr_sm--70 {
    margin-right: 70px !important; }
  .mb_sm--70 {
    margin-bottom: 70px !important; }
  .ptb_sm--75 {
    padding: 75px 0 !important; }
  .plr_sm--75 {
    padding: 0 75px !important; }
  .pt_sm--75 {
    padding-top: 75px !important; }
  .pb_sm--75 {
    padding-bottom: 75px !important; }
  .pl_sm--75 {
    padding-left: 75px !important; }
  .pr_sm--75 {
    padding-right: 75px !important; }
  .mt_sm--75 {
    margin-top: 75px !important; }
  .ml_sm--75 {
    margin-left: 75px !important; }
  .mr_sm--75 {
    margin-right: 75px !important; }
  .mb_sm--75 {
    margin-bottom: 75px !important; }
  .ptb_sm--80 {
    padding: 80px 0 !important; }
  .plr_sm--80 {
    padding: 0 80px !important; }
  .pt_sm--80 {
    padding-top: 80px !important; }
  .pb_sm--80 {
    padding-bottom: 80px !important; }
  .pl_sm--80 {
    padding-left: 80px !important; }
  .pr_sm--80 {
    padding-right: 80px !important; }
  .mt_sm--80 {
    margin-top: 80px !important; }
  .ml_sm--80 {
    margin-left: 80px !important; }
  .mr_sm--80 {
    margin-right: 80px !important; }
  .mb_sm--80 {
    margin-bottom: 80px !important; }
  .ptb_sm--85 {
    padding: 85px 0 !important; }
  .plr_sm--85 {
    padding: 0 85px !important; }
  .pt_sm--85 {
    padding-top: 85px !important; }
  .pb_sm--85 {
    padding-bottom: 85px !important; }
  .pl_sm--85 {
    padding-left: 85px !important; }
  .pr_sm--85 {
    padding-right: 85px !important; }
  .mt_sm--85 {
    margin-top: 85px !important; }
  .ml_sm--85 {
    margin-left: 85px !important; }
  .mr_sm--85 {
    margin-right: 85px !important; }
  .mb_sm--85 {
    margin-bottom: 85px !important; }
  .ptb_sm--90 {
    padding: 90px 0 !important; }
  .plr_sm--90 {
    padding: 0 90px !important; }
  .pt_sm--90 {
    padding-top: 90px !important; }
  .pb_sm--90 {
    padding-bottom: 90px !important; }
  .pl_sm--90 {
    padding-left: 90px !important; }
  .pr_sm--90 {
    padding-right: 90px !important; }
  .mt_sm--90 {
    margin-top: 90px !important; }
  .ml_sm--90 {
    margin-left: 90px !important; }
  .mr_sm--90 {
    margin-right: 90px !important; }
  .mb_sm--90 {
    margin-bottom: 90px !important; }
  .ptb_sm--95 {
    padding: 95px 0 !important; }
  .plr_sm--95 {
    padding: 0 95px !important; }
  .pt_sm--95 {
    padding-top: 95px !important; }
  .pb_sm--95 {
    padding-bottom: 95px !important; }
  .pl_sm--95 {
    padding-left: 95px !important; }
  .pr_sm--95 {
    padding-right: 95px !important; }
  .mt_sm--95 {
    margin-top: 95px !important; }
  .ml_sm--95 {
    margin-left: 95px !important; }
  .mr_sm--95 {
    margin-right: 95px !important; }
  .mb_sm--95 {
    margin-bottom: 95px !important; }
  .ptb_sm--100 {
    padding: 100px 0 !important; }
  .plr_sm--100 {
    padding: 0 100px !important; }
  .pt_sm--100 {
    padding-top: 100px !important; }
  .pb_sm--100 {
    padding-bottom: 100px !important; }
  .pl_sm--100 {
    padding-left: 100px !important; }
  .pr_sm--100 {
    padding-right: 100px !important; }
  .mt_sm--100 {
    margin-top: 100px !important; }
  .ml_sm--100 {
    margin-left: 100px !important; }
  .mr_sm--100 {
    margin-right: 100px !important; }
  .mb_sm--100 {
    margin-bottom: 100px !important; }
  .pl_sm--0 {
    padding-left: 0 !important; }
  .pr_sm--0 {
    padding-right: 0 !important; }
  .pt_sm--0 {
    padding-top: 0 !important; }
  .pb_sm--0 {
    padding-bottom: 0 !important; }
  .mr_sm--0 {
    margin-right: 0 !important; }
  .ml_sm--0 {
    margin-left: 0 !important; }
  .mt_sm--0 {
    margin-top: 0 !important; }
  .mb_sm--0 {
    margin-bottom: 0 !important; } }

@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0 !important; }
  .plr_mobile--5 {
    padding: 0 5px !important; }
  .pt_mobile--5 {
    padding-top: 5px !important; }
  .pb_mobile--5 {
    padding-bottom: 5px !important; }
  .pl_mobile--5 {
    padding-left: 5px !important; }
  .pr_mobile--5 {
    padding-right: 5px !important; }
  .mt_mobile--5 {
    margin-top: 5px !important; }
  .mb_mobile--5 {
    margin-bottom: 5px !important; }
  .ptb_mobile--10 {
    padding: 10px 0 !important; }
  .plr_mobile--10 {
    padding: 0 10px !important; }
  .pt_mobile--10 {
    padding-top: 10px !important; }
  .pb_mobile--10 {
    padding-bottom: 10px !important; }
  .pl_mobile--10 {
    padding-left: 10px !important; }
  .pr_mobile--10 {
    padding-right: 10px !important; }
  .mt_mobile--10 {
    margin-top: 10px !important; }
  .mb_mobile--10 {
    margin-bottom: 10px !important; }
  .ptb_mobile--15 {
    padding: 15px 0 !important; }
  .plr_mobile--15 {
    padding: 0 15px !important; }
  .pt_mobile--15 {
    padding-top: 15px !important; }
  .pb_mobile--15 {
    padding-bottom: 15px !important; }
  .pl_mobile--15 {
    padding-left: 15px !important; }
  .pr_mobile--15 {
    padding-right: 15px !important; }
  .mt_mobile--15 {
    margin-top: 15px !important; }
  .mb_mobile--15 {
    margin-bottom: 15px !important; }
  .ptb_mobile--20 {
    padding: 20px 0 !important; }
  .plr_mobile--20 {
    padding: 0 20px !important; }
  .pt_mobile--20 {
    padding-top: 20px !important; }
  .pb_mobile--20 {
    padding-bottom: 20px !important; }
  .pl_mobile--20 {
    padding-left: 20px !important; }
  .pr_mobile--20 {
    padding-right: 20px !important; }
  .mt_mobile--20 {
    margin-top: 20px !important; }
  .mb_mobile--20 {
    margin-bottom: 20px !important; }
  .ptb_mobile--25 {
    padding: 25px 0 !important; }
  .plr_mobile--25 {
    padding: 0 25px !important; }
  .pt_mobile--25 {
    padding-top: 25px !important; }
  .pb_mobile--25 {
    padding-bottom: 25px !important; }
  .pl_mobile--25 {
    padding-left: 25px !important; }
  .pr_mobile--25 {
    padding-right: 25px !important; }
  .mt_mobile--25 {
    margin-top: 25px !important; }
  .mb_mobile--25 {
    margin-bottom: 25px !important; }
  .ptb_mobile--30 {
    padding: 30px 0 !important; }
  .plr_mobile--30 {
    padding: 0 30px !important; }
  .pt_mobile--30 {
    padding-top: 30px !important; }
  .pb_mobile--30 {
    padding-bottom: 30px !important; }
  .pl_mobile--30 {
    padding-left: 30px !important; }
  .pr_mobile--30 {
    padding-right: 30px !important; }
  .mt_mobile--30 {
    margin-top: 30px !important; }
  .mb_mobile--30 {
    margin-bottom: 30px !important; }
  .ptb_mobile--35 {
    padding: 35px 0 !important; }
  .plr_mobile--35 {
    padding: 0 35px !important; }
  .pt_mobile--35 {
    padding-top: 35px !important; }
  .pb_mobile--35 {
    padding-bottom: 35px !important; }
  .pl_mobile--35 {
    padding-left: 35px !important; }
  .pr_mobile--35 {
    padding-right: 35px !important; }
  .mt_mobile--35 {
    margin-top: 35px !important; }
  .mb_mobile--35 {
    margin-bottom: 35px !important; }
  .ptb_mobile--40 {
    padding: 40px 0 !important; }
  .plr_mobile--40 {
    padding: 0 40px !important; }
  .pt_mobile--40 {
    padding-top: 40px !important; }
  .pb_mobile--40 {
    padding-bottom: 40px !important; }
  .pl_mobile--40 {
    padding-left: 40px !important; }
  .pr_mobile--40 {
    padding-right: 40px !important; }
  .mt_mobile--40 {
    margin-top: 40px !important; }
  .mb_mobile--40 {
    margin-bottom: 40px !important; }
  .ptb_mobile--45 {
    padding: 45px 0 !important; }
  .plr_mobile--45 {
    padding: 0 45px !important; }
  .pt_mobile--45 {
    padding-top: 45px !important; }
  .pb_mobile--45 {
    padding-bottom: 45px !important; }
  .pl_mobile--45 {
    padding-left: 45px !important; }
  .pr_mobile--45 {
    padding-right: 45px !important; }
  .mt_mobile--45 {
    margin-top: 45px !important; }
  .mb_mobile--45 {
    margin-bottom: 45px !important; }
  .ptb_mobile--50 {
    padding: 50px 0 !important; }
  .plr_mobile--50 {
    padding: 0 50px !important; }
  .pt_mobile--50 {
    padding-top: 50px !important; }
  .pb_mobile--50 {
    padding-bottom: 50px !important; }
  .pl_mobile--50 {
    padding-left: 50px !important; }
  .pr_mobile--50 {
    padding-right: 50px !important; }
  .mt_mobile--50 {
    margin-top: 50px !important; }
  .mb_mobile--50 {
    margin-bottom: 50px !important; }
  .ptb_mobile--55 {
    padding: 55px 0 !important; }
  .plr_mobile--55 {
    padding: 0 55px !important; }
  .pt_mobile--55 {
    padding-top: 55px !important; }
  .pb_mobile--55 {
    padding-bottom: 55px !important; }
  .pl_mobile--55 {
    padding-left: 55px !important; }
  .pr_mobile--55 {
    padding-right: 55px !important; }
  .mt_mobile--55 {
    margin-top: 55px !important; }
  .mb_mobile--55 {
    margin-bottom: 55px !important; }
  .ptb_mobile--60 {
    padding: 60px 0 !important; }
  .plr_mobile--60 {
    padding: 0 60px !important; }
  .pt_mobile--60 {
    padding-top: 60px !important; }
  .pb_mobile--60 {
    padding-bottom: 60px !important; }
  .pl_mobile--60 {
    padding-left: 60px !important; }
  .pr_mobile--60 {
    padding-right: 60px !important; }
  .mt_mobile--60 {
    margin-top: 60px !important; }
  .mb_mobile--60 {
    margin-bottom: 60px !important; }
  .ptb_mobile--65 {
    padding: 65px 0 !important; }
  .plr_mobile--65 {
    padding: 0 65px !important; }
  .pt_mobile--65 {
    padding-top: 65px !important; }
  .pb_mobile--65 {
    padding-bottom: 65px !important; }
  .pl_mobile--65 {
    padding-left: 65px !important; }
  .pr_mobile--65 {
    padding-right: 65px !important; }
  .mt_mobile--65 {
    margin-top: 65px !important; }
  .mb_mobile--65 {
    margin-bottom: 65px !important; }
  .ptb_mobile--70 {
    padding: 70px 0 !important; }
  .plr_mobile--70 {
    padding: 0 70px !important; }
  .pt_mobile--70 {
    padding-top: 70px !important; }
  .pb_mobile--70 {
    padding-bottom: 70px !important; }
  .pl_mobile--70 {
    padding-left: 70px !important; }
  .pr_mobile--70 {
    padding-right: 70px !important; }
  .mt_mobile--70 {
    margin-top: 70px !important; }
  .mb_mobile--70 {
    margin-bottom: 70px !important; }
  .ptb_mobile--75 {
    padding: 75px 0 !important; }
  .plr_mobile--75 {
    padding: 0 75px !important; }
  .pt_mobile--75 {
    padding-top: 75px !important; }
  .pb_mobile--75 {
    padding-bottom: 75px !important; }
  .pl_mobile--75 {
    padding-left: 75px !important; }
  .pr_mobile--75 {
    padding-right: 75px !important; }
  .mt_mobile--75 {
    margin-top: 75px !important; }
  .mb_mobile--75 {
    margin-bottom: 75px !important; }
  .ptb_mobile--80 {
    padding: 80px 0 !important; }
  .plr_mobile--80 {
    padding: 0 80px !important; }
  .pt_mobile--80 {
    padding-top: 80px !important; }
  .pb_mobile--80 {
    padding-bottom: 80px !important; }
  .pl_mobile--80 {
    padding-left: 80px !important; }
  .pr_mobile--80 {
    padding-right: 80px !important; }
  .mt_mobile--80 {
    margin-top: 80px !important; }
  .mb_mobile--80 {
    margin-bottom: 80px !important; }
  .ptb_mobile--85 {
    padding: 85px 0 !important; }
  .plr_mobile--85 {
    padding: 0 85px !important; }
  .pt_mobile--85 {
    padding-top: 85px !important; }
  .pb_mobile--85 {
    padding-bottom: 85px !important; }
  .pl_mobile--85 {
    padding-left: 85px !important; }
  .pr_mobile--85 {
    padding-right: 85px !important; }
  .mt_mobile--85 {
    margin-top: 85px !important; }
  .mb_mobile--85 {
    margin-bottom: 85px !important; }
  .ptb_mobile--90 {
    padding: 90px 0 !important; }
  .plr_mobile--90 {
    padding: 0 90px !important; }
  .pt_mobile--90 {
    padding-top: 90px !important; }
  .pb_mobile--90 {
    padding-bottom: 90px !important; }
  .pl_mobile--90 {
    padding-left: 90px !important; }
  .pr_mobile--90 {
    padding-right: 90px !important; }
  .mt_mobile--90 {
    margin-top: 90px !important; }
  .mb_mobile--90 {
    margin-bottom: 90px !important; }
  .ptb_mobile--95 {
    padding: 95px 0 !important; }
  .plr_mobile--95 {
    padding: 0 95px !important; }
  .pt_mobile--95 {
    padding-top: 95px !important; }
  .pb_mobile--95 {
    padding-bottom: 95px !important; }
  .pl_mobile--95 {
    padding-left: 95px !important; }
  .pr_mobile--95 {
    padding-right: 95px !important; }
  .mt_mobile--95 {
    margin-top: 95px !important; }
  .mb_mobile--95 {
    margin-bottom: 95px !important; }
  .ptb_mobile--100 {
    padding: 100px 0 !important; }
  .plr_mobile--100 {
    padding: 0 100px !important; }
  .pt_mobile--100 {
    padding-top: 100px !important; }
  .pb_mobile--100 {
    padding-bottom: 100px !important; }
  .pl_mobile--100 {
    padding-left: 100px !important; }
  .pr_mobile--100 {
    padding-right: 100px !important; }
  .mt_mobile--100 {
    margin-top: 100px !important; }
  .mb_mobile--100 {
    margin-bottom: 100px !important; } }

.slick-gutter-5 {
  margin-left: -5px !important;
  margin-right: -5px !important; }
  .slick-gutter-5 .slick-slide {
    padding-left: 5px !important;
    padding-right: 5px !important; }

.slick-gutter-10 {
  margin-left: -10px !important;
  margin-right: -10px !important; }
  .slick-gutter-10 .slick-slide {
    padding-left: 10px !important;
    padding-right: 10px !important; }

.slick-gutter-15 {
  margin-left: -15px !important;
  margin-right: -15px !important; }
  .slick-gutter-15 .slick-slide {
    padding-left: 15px !important;
    padding-right: 15px !important; }

.slick-gutter-20 {
  margin-left: -20px !important;
  margin-right: -20px !important; }
  .slick-gutter-20 .slick-slide {
    padding-left: 20px !important;
    padding-right: 20px !important; }

.slick-gutter-25 {
  margin-left: -25px !important;
  margin-right: -25px !important; }
  .slick-gutter-25 .slick-slide {
    padding-left: 25px !important;
    padding-right: 25px !important; }

.slick-gutter-30 {
  margin-left: -30px !important;
  margin-right: -30px !important; }
  .slick-gutter-30 .slick-slide {
    padding-left: 30px !important;
    padding-right: 30px !important; }

.slick-gutter-35 {
  margin-left: -35px !important;
  margin-right: -35px !important; }
  .slick-gutter-35 .slick-slide {
    padding-left: 35px !important;
    padding-right: 35px !important; }

.slick-gutter-40 {
  margin-left: -40px !important;
  margin-right: -40px !important; }
  .slick-gutter-40 .slick-slide {
    padding-left: 40px !important;
    padding-right: 40px !important; }

.slick-gutter-45 {
  margin-left: -45px !important;
  margin-right: -45px !important; }
  .slick-gutter-45 .slick-slide {
    padding-left: 45px !important;
    padding-right: 45px !important; }

.slick-gutter-50 {
  margin-left: -50px !important;
  margin-right: -50px !important; }
  .slick-gutter-50 .slick-slide {
    padding-left: 50px !important;
    padding-right: 50px !important; }

.slick-gutter-55 {
  margin-left: -55px !important;
  margin-right: -55px !important; }
  .slick-gutter-55 .slick-slide {
    padding-left: 55px !important;
    padding-right: 55px !important; }

.slick-gutter-60 {
  margin-left: -60px !important;
  margin-right: -60px !important; }
  .slick-gutter-60 .slick-slide {
    padding-left: 60px !important;
    padding-right: 60px !important; }

.slick-gutter-65 {
  margin-left: -65px !important;
  margin-right: -65px !important; }
  .slick-gutter-65 .slick-slide {
    padding-left: 65px !important;
    padding-right: 65px !important; }

.slick-gutter-70 {
  margin-left: -70px !important;
  margin-right: -70px !important; }
  .slick-gutter-70 .slick-slide {
    padding-left: 70px !important;
    padding-right: 70px !important; }

.slick-gutter-75 {
  margin-left: -75px !important;
  margin-right: -75px !important; }
  .slick-gutter-75 .slick-slide {
    padding-left: 75px !important;
    padding-right: 75px !important; }

.slick-gutter-80 {
  margin-left: -80px !important;
  margin-right: -80px !important; }
  .slick-gutter-80 .slick-slide {
    padding-left: 80px !important;
    padding-right: 80px !important; }

.slick-gutter-85 {
  margin-left: -85px !important;
  margin-right: -85px !important; }
  .slick-gutter-85 .slick-slide {
    padding-left: 85px !important;
    padding-right: 85px !important; }

.slick-gutter-90 {
  margin-left: -90px !important;
  margin-right: -90px !important; }
  .slick-gutter-90 .slick-slide {
    padding-left: 90px !important;
    padding-right: 90px !important; }

.slick-gutter-95 {
  margin-left: -95px !important;
  margin-right: -95px !important; }
  .slick-gutter-95 .slick-slide {
    padding-left: 95px !important;
    padding-right: 95px !important; }

.slick-gutter-100 {
  margin-left: -100px !important;
  margin-right: -100px !important; }
  .slick-gutter-100 .slick-slide {
    padding-left: 100px !important;
    padding-right: 100px !important; }

/*======================== 
    Common Style Css
==========================*/
/* Heading Font  */
.h1 {
  font-size: var(--h1) !important; }

.h2 {
  font-size: var(--h2) !important; }

.h3 {
  font-size: var(--h3) !important; }

.h4 {
  font-size: var(--h4) !important; }

.h5 {
  font-size: var(--h5) !important; }

.h6 {
  font-size: var(--h6) !important; }

/* Text Color  */
.bg-primary-color {
  background: var(--color-primary) !important; }

.bg-secondary-color {
  background: var(--color-secondary) !important; }

.bg-color-tertiary {
  background-color: var(--color-tertiary) !important; }

.bg-color-white {
  background-color: var(--color-white) !important; }

.color-white {
  color: var(--color-white); }

/* Radius */
/* Font Weight */
/* Shadows */
/* Others  */
.liststyle {
  padding: 0;
  margin: 0;
  list-style: none; }

.transition-transform {
  transition: var(--transition-transform); }

.list-icon {
  list-style: none;
  padding: 0;
  margin: 0; }
  .list-icon li {
    display: flex;
    align-items: center;
    margin: 15px 0; }
    @media only screen and (max-width: 767px) {
      .list-icon li {
        font-size: 16px; } }
    .list-icon li .icon {
      width: 30px;
      background-color: var(--color-blackest);
      height: 30px;
      border-radius: 100%;
      display: inline-block;
      position: relative;
      margin-right: 9px;
      min-width: 30px;
      min-height: 30px; }
      .list-icon li .icon.background-transparent {
        background-color: transparent; }
      .list-icon li .icon i {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

.rn-sub-badge {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 7px 14px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter); }

.radius-small {
  border-radius: var(--radius-small); }

.line-before {
  position: relative;
  padding-left: 18px; }
  .line-before::before {
    position: absolute;
    content: "";
    left: 0;
    width: 2px;
    height: 81%;
    background: var(--color-primary);
    top: 50%;
    transform: translateY(-50%); }

.bg-image--7 {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top 15px left 10%; }

.bg-image--12 {
  background-size: auto; }

.border-bottom-1 {
  border-bottom: 1px solid #EEEEEE; }

/* Breadcrumb  */
.edu-breadcrumb-area {
  background-image: url(../images/bg/new-banner.webp); }
  .edu-breadcrumb-area .shape-image {
    position: absolute;
    z-index: -1; }
    .edu-breadcrumb-area .shape-image.shape-image-1 {
      top: -30px;
      left: -180px; }
    .edu-breadcrumb-area .shape-image.shape-image-2 {
      top: -10px;
      left: 60%; }
    .edu-breadcrumb-area .shape-image.shape-image-3 {
      top: -24px;
      right: -90px; }
    .edu-breadcrumb-area .shape-image.shape-image-4 {
      bottom: -39px;
      right: -220px; }
    .edu-breadcrumb-area .shape-image.shape-image-5 {
      bottom: -28px;
      left: 35%; }
    .edu-breadcrumb-area .shape-image.shape-image-6 {
      bottom: -30px;
      left: -108px; }

/* Animation  */
.eduvibe-animated-shape {
  position: relative;
  z-index: 9; }

@-webkit-keyframes eduvibe-vsm-y-move {
  0% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%); }
  100% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%); } }

@keyframes eduvibe-vsm-y-move {
  0% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%); }
  100% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%); } }

@-webkit-keyframes eduvibe-vsm-y-reverse-move {
  0% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%); }
  100% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%); } }

@keyframes eduvibe-vsm-y-reverse-move {
  0% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%); }
  100% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%); } }

@-webkit-keyframes eduvibe-sm-x-move {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0); }
  100% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); } }

@keyframes eduvibe-sm-x-move {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0); }
  100% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); } }

@-webkit-keyframes eduvibe-sm-x-reverse-move {
  0% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0); } }

@keyframes eduvibe-sm-x-reverse-move {
  0% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0); }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0); } }

@-webkit-keyframes eduvibe-rotate-y {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

@-webkit-keyframes eduvibe-rotate-x {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes eduvibe-rotate-x {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes eduvibe-rotate-y {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

@-webkit-keyframes eduvibe-zoom1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); } }

@keyframes eduvibe-zoom2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); } }

@-webkit-keyframes eduvibe-zoom1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25); } }

@keyframes eduvibe-zoom1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25); } }

/* ========= Forms Styles ========= */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

input,
select,
textarea {
  width: 100%;
  transition: 0.3s; }

input {
  height: 40px;
  padding: 0 15px; }

select {
  transition: 0.3s;
  height: 60px;
  line-height: 28px;
  padding: 10px 20px;
  outline: none;
  color: var(--color-body);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid var(--color-border);
  border-radius: var(--radius);
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 30px; }

.edu-select {
  transition: 0.3s;
  height: 60px;
  line-height: 28px;
  padding: 10px 20px;
  outline: none;
  color: var(--color-body);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0 none;
  border-radius: var(--radius);
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 30px;
  background-color: #F5F5F5;
  border-radius: 5px;
  max-width: 256px; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
  font-size: 16px;
  font-weight: 500;
  height: auto;
  line-height: 28px;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 15px;
  outline: none;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  color: var(--color-body);
  /* -- Placeholder -- */ }
  input[type="text"]::placeholder,
  input[type="password"]::placeholder,
  input[type="email"]::placeholder,
  input[type="number"]::placeholder,
  input[type="tel"]::placeholder,
  textarea::placeholder {
    color: var(--body-color);
    /* Firefox */
    opacity: 1; }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--body-color);
    opacity: 1; }
  input[type="text"]::-ms-input-placeholder,
  input[type="password"]::-ms-input-placeholder,
  input[type="email"]::-ms-input-placeholder,
  input[type="number"]::-ms-input-placeholder,
  input[type="tel"]::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--body-color);
    opacity: 1; }
  input[type="text"].p-holder__active, input.input-active[type="text"], .input-active input[type="text"],
  input[type="password"].p-holder__active,
  input.input-active[type="password"],
  .input-active input[type="password"],
  input[type="email"].p-holder__active,
  input.input-active[type="email"],
  .input-active input[type="email"],
  input[type="number"].p-holder__active,
  input.input-active[type="number"],
  .input-active input[type="number"],
  input[type="tel"].p-holder__active,
  input.input-active[type="tel"],
  .input-active input[type="tel"],
  textarea.p-holder__active,
  textarea.input-active {
    border-color: var(--color-primary);
    /* -- Placeholder -- */ }
    input[type="text"].p-holder__active::placeholder, input.input-active[type="text"]::placeholder, .input-active input[type="text"]::placeholder,
    input[type="password"].p-holder__active::placeholder,
    input.input-active[type="password"]::placeholder,
    .input-active input[type="password"]::placeholder,
    input[type="email"].p-holder__active::placeholder,
    input.input-active[type="email"]::placeholder,
    .input-active input[type="email"]::placeholder,
    input[type="number"].p-holder__active::placeholder,
    input.input-active[type="number"]::placeholder,
    .input-active input[type="number"]::placeholder,
    input[type="tel"].p-holder__active::placeholder,
    input.input-active[type="tel"]::placeholder,
    .input-active input[type="tel"]::placeholder,
    textarea.p-holder__active::placeholder,
    textarea.input-active::placeholder {
      color: var(--color-primary);
      /* Firefox */
      opacity: 1; }
    input[type="text"].p-holder__active:-ms-input-placeholder, input.input-active[type="text"]:-ms-input-placeholder, .input-active input[type="text"]:-ms-input-placeholder,
    input[type="password"].p-holder__active:-ms-input-placeholder,
    input.input-active[type="password"]:-ms-input-placeholder,
    .input-active input[type="password"]:-ms-input-placeholder,
    input[type="email"].p-holder__active:-ms-input-placeholder,
    input.input-active[type="email"]:-ms-input-placeholder,
    .input-active input[type="email"]:-ms-input-placeholder,
    input[type="number"].p-holder__active:-ms-input-placeholder,
    input.input-active[type="number"]:-ms-input-placeholder,
    .input-active input[type="number"]:-ms-input-placeholder,
    input[type="tel"].p-holder__active:-ms-input-placeholder,
    input.input-active[type="tel"]:-ms-input-placeholder,
    .input-active input[type="tel"]:-ms-input-placeholder,
    textarea.p-holder__active:-ms-input-placeholder,
    textarea.input-active:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-primary); }
    input[type="text"].p-holder__active::-ms-input-placeholder, input.input-active[type="text"]::-ms-input-placeholder, .input-active input[type="text"]::-ms-input-placeholder,
    input[type="password"].p-holder__active::-ms-input-placeholder,
    input.input-active[type="password"]::-ms-input-placeholder,
    .input-active input[type="password"]::-ms-input-placeholder,
    input[type="email"].p-holder__active::-ms-input-placeholder,
    input.input-active[type="email"]::-ms-input-placeholder,
    .input-active input[type="email"]::-ms-input-placeholder,
    input[type="number"].p-holder__active::-ms-input-placeholder,
    input.input-active[type="number"]::-ms-input-placeholder,
    .input-active input[type="number"]::-ms-input-placeholder,
    input[type="tel"].p-holder__active::-ms-input-placeholder,
    input.input-active[type="tel"]::-ms-input-placeholder,
    .input-active input[type="tel"]::-ms-input-placeholder,
    textarea.p-holder__active::-ms-input-placeholder,
    textarea.input-active::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--color-primary); }
  input[type="text"].p-holder__error, input.input-error[type="text"], .input-error input[type="text"],
  input[type="password"].p-holder__error,
  input.input-error[type="password"],
  .input-error input[type="password"],
  input[type="email"].p-holder__error,
  input.input-error[type="email"],
  .input-error input[type="email"],
  input[type="number"].p-holder__error,
  input.input-error[type="number"],
  .input-error input[type="number"],
  input[type="tel"].p-holder__error,
  input.input-error[type="tel"],
  .input-error input[type="tel"],
  textarea.p-holder__error,
  textarea.input-error {
    border-color: #f4282d;
    /* -- Placeholder -- */ }
    input[type="text"].p-holder__error::placeholder, input.input-error[type="text"]::placeholder, .input-error input[type="text"]::placeholder,
    input[type="password"].p-holder__error::placeholder,
    input.input-error[type="password"]::placeholder,
    .input-error input[type="password"]::placeholder,
    input[type="email"].p-holder__error::placeholder,
    input.input-error[type="email"]::placeholder,
    .input-error input[type="email"]::placeholder,
    input[type="number"].p-holder__error::placeholder,
    input.input-error[type="number"]::placeholder,
    .input-error input[type="number"]::placeholder,
    input[type="tel"].p-holder__error::placeholder,
    input.input-error[type="tel"]::placeholder,
    .input-error input[type="tel"]::placeholder,
    textarea.p-holder__error::placeholder,
    textarea.input-error::placeholder {
      color: #f4282d;
      /* Firefox */
      opacity: 1; }
    input[type="text"].p-holder__error:-ms-input-placeholder, input.input-error[type="text"]:-ms-input-placeholder, .input-error input[type="text"]:-ms-input-placeholder,
    input[type="password"].p-holder__error:-ms-input-placeholder,
    input.input-error[type="password"]:-ms-input-placeholder,
    .input-error input[type="password"]:-ms-input-placeholder,
    input[type="email"].p-holder__error:-ms-input-placeholder,
    input.input-error[type="email"]:-ms-input-placeholder,
    .input-error input[type="email"]:-ms-input-placeholder,
    input[type="number"].p-holder__error:-ms-input-placeholder,
    input.input-error[type="number"]:-ms-input-placeholder,
    .input-error input[type="number"]:-ms-input-placeholder,
    input[type="tel"].p-holder__error:-ms-input-placeholder,
    input.input-error[type="tel"]:-ms-input-placeholder,
    .input-error input[type="tel"]:-ms-input-placeholder,
    textarea.p-holder__error:-ms-input-placeholder,
    textarea.input-error:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #f4282d; }
    input[type="text"].p-holder__error::-ms-input-placeholder, input.input-error[type="text"]::-ms-input-placeholder, .input-error input[type="text"]::-ms-input-placeholder,
    input[type="password"].p-holder__error::-ms-input-placeholder,
    input.input-error[type="password"]::-ms-input-placeholder,
    .input-error input[type="password"]::-ms-input-placeholder,
    input[type="email"].p-holder__error::-ms-input-placeholder,
    input.input-error[type="email"]::-ms-input-placeholder,
    .input-error input[type="email"]::-ms-input-placeholder,
    input[type="number"].p-holder__error::-ms-input-placeholder,
    input.input-error[type="number"]::-ms-input-placeholder,
    .input-error input[type="number"]::-ms-input-placeholder,
    input[type="tel"].p-holder__error::-ms-input-placeholder,
    input.input-error[type="tel"]::-ms-input-placeholder,
    .input-error input[type="tel"]::-ms-input-placeholder,
    textarea.p-holder__error::-ms-input-placeholder,
    textarea.input-error::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #f4282d; }
    input[type="text"].p-holder__error:focus, input.input-error[type="text"]:focus, .input-error input[type="text"]:focus,
    input[type="password"].p-holder__error:focus,
    input.input-error[type="password"]:focus,
    .input-error input[type="password"]:focus,
    input[type="email"].p-holder__error:focus,
    input.input-error[type="email"]:focus,
    .input-error input[type="email"]:focus,
    input[type="number"].p-holder__error:focus,
    input.input-error[type="number"]:focus,
    .input-error input[type="number"]:focus,
    input[type="tel"].p-holder__error:focus,
    input.input-error[type="tel"]:focus,
    .input-error input[type="tel"]:focus,
    textarea.p-holder__error:focus,
    textarea.input-error:focus {
      border-color: #f4282d; }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  textarea:focus {
    border-color: var(--color-primary); }

input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
  position: absolute; }
  input[type="checkbox"] ~ label,
  input[type="radio"] ~ label {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: var(--body-color);
    font-weight: 500;
    padding-left: 34px;
    cursor: pointer;
    margin-bottom: 0; }
    input[type="checkbox"] ~ label::before,
    input[type="radio"] ~ label::before {
      content: " ";
      position: absolute;
      top: 1px;
      left: -3px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid var(--color-primary);
      border-radius: 2px;
      transition: all .3s; }
    input[type="checkbox"] ~ label::after,
    input[type="radio"] ~ label::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 1px;
      width: 12px;
      height: 8px;
      background-color: transparent;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      border-radius: 2px;
      transform: rotate(-45deg);
      opacity: 0;
      transition: all .3s; }
  input[type="checkbox"]:checked ~ label::before,
  input[type="radio"]:checked ~ label::before {
    background-color: var(--color-primary);
    border-color: var(--color-primary); }
  input[type="checkbox"]:checked ~ label::after,
  input[type="radio"]:checked ~ label::after {
    opacity: 1; }

input[type="radio"] ~ label::before {
  border-radius: 50%; }

input[type="radio"] ~ label::after {
  width: 8px;
  height: 8px;
  left: 3px;
  top: 7px;
  background: #fff;
  border-radius: 50%; }

.form-group {
  margin-bottom: 20px; }
  .form-group input {
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    height: 50px;
    font-size: 16px;
    padding: 0 15px; }
    .form-group input:focus {
      border-color: var(--color-primary); }
  .form-group textarea {
    min-height: 193px;
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    resize: none;
    padding: 15px;
    font-size: 16px; }
    .form-group textarea:focus {
      border-color: var(--color-primary); }

input[type="submit"] {
  width: auto;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 15px 20px;
  border-radius: 6px;
  display: block;
  font-weight: 500;
  transition: 0.3s;
  border: var(--border-width) solid var(--color-primary);
  background: var(--color-primary);
  color: #ffffff;
  height: 60px;
  margin-top: 30px; }
  input[type="submit"]:hover {
    color: #ffffff;
    -webkit-box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
    box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); }

.contact-form--1 form input::-webkit-input-placeholder,
.contact-form--1 form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-body) !important; }

.contact-form--1 form input::-moz-placeholder,
.contact-form--1 form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-body) !important; }

.contact-form--1 form input:-ms-input-placeholder,
.contact-form--1 form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-body) !important; }

.contact-form--1 form input:-moz-placeholder,
.contact-form--1 form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-body) !important; }

.login-register-page-wrapper .login-form-box {
  border: 1px solid #eeeeee;
  padding: 40px;
  border-radius: 10px;
  position: relative; }
  .login-register-page-wrapper .login-form-box input[type="checkbox"] {
    width: auto; }
  .login-register-page-wrapper .login-form-box .lost-password {
    color: var(--color-secondary); }

/**
 * Settings
 */
/**
  * Easings
  */
/**
  * Core
  */
[data-sal] {
  transition-duration: 0.2s;
  transition-delay: 0s;
  transition-duration: var(--sal-duration, 0.2s);
  transition-delay: var(--sal-delay, 0s);
  transition-timing-function: var(--sal-easing, ease); }

[data-sal][data-sal-duration='200'] {
  transition-duration: 0.2s; }

[data-sal][data-sal-duration='250'] {
  transition-duration: 0.25s; }

[data-sal][data-sal-duration='300'] {
  transition-duration: 0.3s; }

[data-sal][data-sal-duration='350'] {
  transition-duration: 0.35s; }

[data-sal][data-sal-duration='400'] {
  transition-duration: 0.4s; }

[data-sal][data-sal-duration='450'] {
  transition-duration: 0.45s; }

[data-sal][data-sal-duration='500'] {
  transition-duration: 0.5s; }

[data-sal][data-sal-duration='550'] {
  transition-duration: 0.55s; }

[data-sal][data-sal-duration='600'] {
  transition-duration: 0.6s; }

[data-sal][data-sal-duration='650'] {
  transition-duration: 0.65s; }

[data-sal][data-sal-duration='700'] {
  transition-duration: 0.7s; }

[data-sal][data-sal-duration='750'] {
  transition-duration: 0.75s; }

[data-sal][data-sal-duration='800'] {
  transition-duration: 0.8s; }

[data-sal][data-sal-duration='850'] {
  transition-duration: 0.85s; }

[data-sal][data-sal-duration='900'] {
  transition-duration: 0.9s; }

[data-sal][data-sal-duration='950'] {
  transition-duration: 0.95s; }

[data-sal][data-sal-duration='1000'] {
  transition-duration: 1s; }

[data-sal][data-sal-duration='1050'] {
  transition-duration: 1.05s; }

[data-sal][data-sal-duration='1100'] {
  transition-duration: 1.1s; }

[data-sal][data-sal-duration='1150'] {
  transition-duration: 1.15s; }

[data-sal][data-sal-duration='1200'] {
  transition-duration: 1.2s; }

[data-sal][data-sal-duration='1250'] {
  transition-duration: 1.25s; }

[data-sal][data-sal-duration='1300'] {
  transition-duration: 1.3s; }

[data-sal][data-sal-duration='1350'] {
  transition-duration: 1.35s; }

[data-sal][data-sal-duration='1400'] {
  transition-duration: 1.4s; }

[data-sal][data-sal-duration='1450'] {
  transition-duration: 1.45s; }

[data-sal][data-sal-duration='1500'] {
  transition-duration: 1.5s; }

[data-sal][data-sal-duration='1550'] {
  transition-duration: 1.55s; }

[data-sal][data-sal-duration='1600'] {
  transition-duration: 1.6s; }

[data-sal][data-sal-duration='1650'] {
  transition-duration: 1.65s; }

[data-sal][data-sal-duration='1700'] {
  transition-duration: 1.7s; }

[data-sal][data-sal-duration='1750'] {
  transition-duration: 1.75s; }

[data-sal][data-sal-duration='1800'] {
  transition-duration: 1.8s; }

[data-sal][data-sal-duration='1850'] {
  transition-duration: 1.85s; }

[data-sal][data-sal-duration='1900'] {
  transition-duration: 1.9s; }

[data-sal][data-sal-duration='1950'] {
  transition-duration: 1.95s; }

[data-sal][data-sal-duration='2000'] {
  transition-duration: 2s; }

[data-sal][data-sal-delay='50'] {
  transition-delay: 0.05s; }

[data-sal][data-sal-delay='100'] {
  transition-delay: 0.1s; }

[data-sal][data-sal-delay='150'] {
  transition-delay: 0.15s; }

[data-sal][data-sal-delay='200'] {
  transition-delay: 0.2s; }

[data-sal][data-sal-delay='250'] {
  transition-delay: 0.25s; }

[data-sal][data-sal-delay='300'] {
  transition-delay: 0.3s; }

[data-sal][data-sal-delay='350'] {
  transition-delay: 0.35s; }

[data-sal][data-sal-delay='400'] {
  transition-delay: 0.4s; }

[data-sal][data-sal-delay='450'] {
  transition-delay: 0.45s; }

[data-sal][data-sal-delay='500'] {
  transition-delay: 0.5s; }

[data-sal][data-sal-delay='550'] {
  transition-delay: 0.55s; }

[data-sal][data-sal-delay='600'] {
  transition-delay: 0.6s; }

[data-sal][data-sal-delay='650'] {
  transition-delay: 0.65s; }

[data-sal][data-sal-delay='700'] {
  transition-delay: 0.7s; }

[data-sal][data-sal-delay='750'] {
  transition-delay: 0.75s; }

[data-sal][data-sal-delay='800'] {
  transition-delay: 0.8s; }

[data-sal][data-sal-delay='850'] {
  transition-delay: 0.85s; }

[data-sal][data-sal-delay='900'] {
  transition-delay: 0.9s; }

[data-sal][data-sal-delay='950'] {
  transition-delay: 0.95s; }

[data-sal][data-sal-delay='1000'] {
  transition-delay: 1s; }

[data-sal][data-sal-easing='linear'] {
  transition-timing-function: linear; }

[data-sal][data-sal-easing='ease'] {
  transition-timing-function: ease; }

[data-sal][data-sal-easing='ease-in'] {
  transition-timing-function: ease-in; }

[data-sal][data-sal-easing='ease-out'] {
  transition-timing-function: ease-out; }

[data-sal][data-sal-easing='ease-in-out'] {
  transition-timing-function: ease-in-out; }

[data-sal][data-sal-easing='ease-in-cubic'] {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

[data-sal][data-sal-easing='ease-out-cubic'] {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

[data-sal][data-sal-easing='ease-in-out-cubic'] {
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }

[data-sal][data-sal-easing='ease-in-circ'] {
  transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335); }

[data-sal][data-sal-easing='ease-out-circ'] {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); }

[data-sal][data-sal-easing='ease-in-out-circ'] {
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86); }

[data-sal][data-sal-easing='ease-in-expo'] {
  transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035); }

[data-sal][data-sal-easing='ease-out-expo'] {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

[data-sal][data-sal-easing='ease-in-out-expo'] {
  transition-timing-function: cubic-bezier(1, 0, 0, 1); }

[data-sal][data-sal-easing='ease-in-quad'] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-sal][data-sal-easing='ease-out-quad'] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-sal][data-sal-easing='ease-in-out-quad'] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-sal][data-sal-easing='ease-in-quart'] {
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22); }

[data-sal][data-sal-easing='ease-out-quart'] {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

[data-sal][data-sal-easing='ease-in-out-quart'] {
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1); }

[data-sal][data-sal-easing='ease-in-quint'] {
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

[data-sal][data-sal-easing='ease-out-quint'] {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

[data-sal][data-sal-easing='ease-in-out-quint'] {
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); }

[data-sal][data-sal-easing='ease-in-sine'] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-sal][data-sal-easing='ease-out-sine'] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-sal][data-sal-easing='ease-in-out-sine'] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-sal][data-sal-easing='ease-in-back'] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-sal][data-sal-easing='ease-out-back'] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-sal][data-sal-easing='ease-in-out-back'] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

/**
  * Animations
  */
[data-sal|='fade'] {
  opacity: 0;
  transition-property: opacity; }

[data-sal|='fade'].sal-animate,
body.sal-disabled [data-sal|='fade'] {
  opacity: 1; }

[data-sal|='slide'] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-sal='slide-up'] {
  transform: translateY(20%); }

[data-sal='slide-down'] {
  transform: translateY(-20%); }

[data-sal='slide-left'] {
  transform: translateX(20%); }

[data-sal='slide-right'] {
  transform: translateX(-20%); }

[data-sal|='slide'].sal-animate,
body.sal-disabled [data-sal|='slide'] {
  opacity: 1;
  transform: none; }

[data-sal|='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-sal='zoom-in'] {
  transform: scale(0.5); }

[data-sal='zoom-out'] {
  transform: scale(1.1); }

[data-sal|='zoom'].sal-animate,
body.sal-disabled [data-sal|='zoom'] {
  opacity: 1;
  transform: none; }

[data-sal|='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-sal='flip-left'] {
  transform: perspective(2000px) rotateY(-91deg); }

[data-sal='flip-right'] {
  transform: perspective(2000px) rotateY(91deg); }

[data-sal='flip-up'] {
  transform: perspective(2000px) rotateX(-91deg); }

[data-sal='flip-down'] {
  transform: perspective(2000px) rotateX(91deg); }

[data-sal|='flip'].sal-animate,
body.sal-disabled [data-sal|='flip'] {
  transform: none; }

/**************************************
    Header Styles
***************************************/
/*--------------------------
    Header Styles  
--------------------------*/
.edu-header .logo a {
  display: block;
  height: 82px;
  line-height: 82px;
  transition: min-height 1s ease !important; }
  .edu-header .logo a img {
    max-height: 35px; }
  @media only screen and (max-width: 1199px) {
    .edu-header .logo a {
      height: 80px;
      line-height: 80px; } }

.edu-header.disable-transparent .logo a {
  height: 90px;
  line-height: 90px; }

.edu-header.header-relative {
  position: relative; }

.edu-header.header-transparent {
  /* position: absolute; */
  left: -1.5px;
  right: 0;
  width: auto;
  backdrop-filter: inherit;
  z-index: 9; 
background-position: top;box-shadow: 2px 3px 9px 0px #2c2a2a14;}

.edu-header.header-sticky {
  transition: height .5s ease-in;
  max-width: 100%;
  z-index: 99; }
  .edu-header.header-sticky.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-white) !important;
    width: 100%;
    height: 80px;
    animation: headerSticky .95s ease forwards;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
    z-index: 10; }
    .edu-header.header-sticky.sticky .logo a {
      height: 80px;
      line-height: 80px; }

/*------------------------
    Header Style One  
--------------------------*/
.header-default {
  max-width: 100%;
  background-color: var(--color-white);
  color: #fff;
  font-size: 12px;
  z-index: 99;
  position: relative; }
  .header-default .header-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }

/*------------------------
    Header Style Two  
--------------------------*/
.header-style-2 {
  padding: 0 200px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .header-style-2 {
      padding: 0 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-style-2 {
      padding: 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-style-2 {
      padding: 0 30px; } }
  @media only screen and (max-width: 767px) {
    .header-style-2 {
      padding: 0 15px; } }
  .header-style-2 .header-menu-bar {
    display: flex;
    align-items: center; }

.white-box-icon {
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  outline: none;
  color: var(--color-primary);
  border: 0 none;
  padding: 0;
  background: var(--color-white);
  display: block;
  font-size: 16px; }
  @media only screen and (max-width: 767px) {
    .white-box-icon {
      height: 40px;
      line-height: 40px;
      width: 40px; } }

@keyframes headerSticky {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

/*--------------------------
    Navigation Styles 
----------------------------*/
.mainmenu-nav .mainmenu {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap; }
  .mainmenu-nav .mainmenu > li > a {
    color: var(--color-heading);
    font-size: 16px;
    font-weight: 600;
    padding: 0 20px;
    display: block;
    height: 80px;
    line-height: 80px;
    transition: 0.3s; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .mainmenu-nav .mainmenu > li > a {
        padding: 0 17px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mainmenu-nav .mainmenu > li > a {
        padding: 0 12px; } }
    .mainmenu-nav .mainmenu > li > a:hover, .mainmenu-nav .mainmenu > li > a.active {
      color: var(--color-primary) !important; }
  .mainmenu-nav .mainmenu li {
    margin-top: 0;
    margin-bottom: 0;
    position: relative; }
    .mainmenu-nav .mainmenu li.has-droupdown > a {
      position: relative; }
      .mainmenu-nav .mainmenu li.has-droupdown > a::after {
        position: absolute;
        content: "\e969";
        font-family: 'icomoon';
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 600; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .mainmenu-nav .mainmenu li.has-droupdown > a::after {
            right: 0px; } }
    .mainmenu-nav .mainmenu li.has-droupdown .submenu {
      min-width: 250px;
      height: auto;
      position: absolute;
      top: 90%;
      left: 0;
      z-index: 90;
      opacity: 0;
      visibility: hidden;
      text-align: left;
      padding: 20px 0;
      transition: 0.3s;
      background-color: var(--color-white);
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.04);
      border-bottom: 2px solid var(--color-primary); }
      .mainmenu-nav .mainmenu li.has-droupdown .submenu li {
        position: relative; }
        .mainmenu-nav .mainmenu li.has-droupdown .submenu li a {
          font-size: 16px;
          font-weight: 600;
          padding: 3px 15px;
          display: block;
          color: var(--color-heading);
          margin: 0 10px; }
          .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover {
            color: var(--color-primary) !important;
            background: var(--color-darker); }
          .mainmenu-nav .mainmenu li.has-droupdown .submenu li a.active {
            color: var(--color-primary) !important;
            background: var(--color-darker); }
        .mainmenu-nav .mainmenu li.has-droupdown .submenu li.has-droupdown > a::after {
          content: "\e92c";
          font-family: 'icomoon'; }
        .mainmenu-nav .mainmenu li.has-droupdown .submenu li.has-droupdown .submenu {
          left: 100%;
          top: 90%;
          z-index: 90;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s; }
        .mainmenu-nav .mainmenu li.has-droupdown .submenu li.has-droupdown:hover .submenu {
          left: 100%;
          top: 0;
          z-index: 90;
          opacity: 1;
          visibility: visible; }
    .mainmenu-nav .mainmenu li.has-droupdown:hover > .submenu {
      opacity: 1;
      visibility: visible;
      top: 100%; }

/*------------------------
    Header Quote  
-------------------------*/
.header-quote {
  display: flex;
  justify-content: flex-end;
  margin: 0 -17px;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .header-quote {
      margin: 0 -10px; } }
  .header-quote .quote-icon {
    margin: 0 17px; }
    @media only screen and (max-width: 767px) {
      .header-quote .quote-icon {
        margin: 0 10px; } }
    .header-quote .quote-icon a,
    .header-quote .quote-icon button {
      padding: 0;
      border: 0 none;
      width: auto;
      background: transparent;
      display: flex;
      align-items: center; }
      .header-quote .quote-icon a i,
      .header-quote .quote-icon button i {
        color: var(--color-heading);
        font-size: 16px;
        transition: 0.3s; }
      .header-quote .quote-icon a:hover i,
      .header-quote .quote-icon button:hover i {
        color: var(--color-primary); }

/*------------------------------
    Popup Menu Styles  
--------------------------------*/
.popup-mobile-menu {
  z-index: 9999;
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s ease-out; }
  .popup-mobile-menu .inner {
    width: 320px;
    z-index: 999;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
    height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    left: -150px;
    transition: all .5s ease-out; }
    .popup-mobile-menu .inner .header-top {
      display: flex;
      border-bottom: 1px solid var(--color-border);
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px; }
      .popup-mobile-menu .inner .header-top .logo a img {
        max-height: 45px; }
      .popup-mobile-menu .inner .header-top .close-menu .close-button {
        background: var(--color-white);
        border: 0 none;
        color: var(--color-heading);
        width: 40px;
        height: 40px;
        font-size: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06); }
  .popup-mobile-menu.active {
    visibility: visible;
    opacity: 1; }
    .popup-mobile-menu.active .inner {
      opacity: 1;
      left: 0;
      overflow-y: auto; }
  .popup-mobile-menu .mainmenu {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 15px 20px; }
    .popup-mobile-menu .mainmenu li {
      margin: 0; }
      .popup-mobile-menu .mainmenu li a {
        padding: 8px 0;
        display: block;
        font-size: 16px;
        font-weight: 500; }
        .popup-mobile-menu .mainmenu li a.active {
          color: var(--color-primary) !important; }
      .popup-mobile-menu .mainmenu li + li {
        border-top: 1px solid var(--color-border); }
    .popup-mobile-menu .mainmenu .has-droupdown .submenu {
      padding: 0;
      max-width: 100%;
      list-style: none;
      padding-left: 14px;
      display: none; }
      .popup-mobile-menu .mainmenu .has-droupdown .submenu li a {
        font-size: 15px; }
        .popup-mobile-menu .mainmenu .has-droupdown .submenu li a.active {
          color: var(--color-primary) !important; }
    .popup-mobile-menu .mainmenu .has-droupdown > a {
      position: relative; }
      .popup-mobile-menu .mainmenu .has-droupdown > a::after {
        position: absolute;
        content: "\e935";
        font-family: 'icomoon' !important;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.4s; }
      .popup-mobile-menu .mainmenu .has-droupdown > a.open::after {
        content: "\e934"; }
    .popup-mobile-menu .mainmenu .rn-megamenu {
      padding: 0;
      max-width: 100%;
      list-style: none;
      padding-left: 14px;
      display: none; }
    .popup-mobile-menu .mainmenu .mega-menu-item {
      list-style: none;
      padding: 0;
      margin: 0;
      padding-left: 0; }
      .popup-mobile-menu .mainmenu .mega-menu-item li a {
        font-size: 15px; }
      .popup-mobile-menu .mainmenu .mega-menu-item li:last-child {
        border-bottom: 1px solid var(--color-border); }
    .popup-mobile-menu .mainmenu .single-mega-item:last-child .mega-menu-item li:last-child {
      border-bottom-color: transparent; }

/*----------------------
    Search Popup  
-----------------------*/
.edu-search-popup {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transform: scale(0.95); }
  .edu-search-popup.open {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }
  .edu-search-popup .close-button {
    position: absolute;
    right: 30px;
    top: 30px; }
    .edu-search-popup .close-button .close-trigger {
      background: var(--color-white);
      border: 0 none;
      color: var(--color-heading);
      width: 40px;
      height: 40px;
      font-size: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06); }
  .edu-search-popup .inner {
    position: absolute;
    top: 46%;
    width: 100%; }
    .edu-search-popup .inner .search-form {
      text-align: center;
      max-width: 1170px;
      margin: 0 auto;
      position: relative; }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .edu-search-popup .inner .search-form {
          padding: 0 20px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .edu-search-popup .inner .search-form {
          padding: 0 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .edu-search-popup .inner .search-form {
          padding: 0 20px; } }
      @media only screen and (max-width: 767px) {
        .edu-search-popup .inner .search-form {
          padding: 0 20px; } }
      .edu-search-popup .inner .search-form input {
        height: 60px;
        border: 0 none;
        border-radius: 5px;
        background: #FFFFFF;
        padding: 0 30px;
        padding-right: 55px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px; }
      .edu-search-popup .inner .search-form .submit-button {
        position: absolute;
        right: 30px;
        padding: 0;
        margin: 0;
        border: 0 none;
        outline: none;
        color: var(--color-heading);
        background: transparent;
        top: 50%;
        transform: translateY(-50%); }
  .edu-search-popup::before {
    position: absolute;
    background: #2D284E;
    opacity: 0.7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1; }

/**************************************
    Elemnts Styles
***************************************/
/*-------------------
 * Button
---------------------*/
.wishlist-btn {
  background: rgba(45, 40, 78, 0.2);
  backdrop-filter: blur(10px);
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  border: 0 none; }
  .wishlist-btn i {
    color: var(--color-white);
    font-size: 15px; }

.button-group {
  margin: -5px; }
  .button-group a {
    margin: 5px; }

a.btn-transparent,
button.btn-transparent {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-primary);
  display: inline-block;
  position: relative;
  transition: 0.3s; }
  a.btn-transparent::after,
  button.btn-transparent::after {
    position: absolute;
    content: "";
    left: auto;
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: var(--color-primary);
    transition: 0.3s; }
  a.btn-transparent i,
  button.btn-transparent i {
    padding-left: 10px;
    top: 3px;
    position: relative; }
  a.btn-transparent.sm-size,
  button.btn-transparent.sm-size {
    font-size: 16px;
    line-height: 26px; }
  a.btn-transparent.heading-color,
  button.btn-transparent.heading-color {
    color: var(--color-heading); }
    a.btn-transparent.heading-color i,
    button.btn-transparent.heading-color i {
      color: var(--color-heading); }
    a.btn-transparent.heading-color:hover,
    button.btn-transparent.heading-color:hover {
      color: var(--color-primary); }
      a.btn-transparent.heading-color:hover i,
      button.btn-transparent.heading-color:hover i {
        color: var(--color-primary); }
  a.btn-transparent:hover::after,
  button.btn-transparent:hover::after {
    width: 100%;
    left: 0;
    right: auto; }

a.edu-btn,
button.edu-btn {
  border-radius: 5px;
    display: inline-block;
    height: 50px;
    line-height: 45px;
    color: var(--color-white);
    background: var(--color-primary);
    padding: 0 30px;
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0;
    transition: 0.4s;
    font-family: var(--font-primary);
    border: 0 none;}
  a.edu-btn i,
  button.edu-btn i {
    padding-left: 10px;
    position: relative;
    top: 3px; }
  a.edu-btn.btn-medium,
  button.edu-btn.btn-medium {
    height: 50px;
    line-height: 50px;
    padding: 0 25px;
    font-size: 16px; }
    a.edu-btn.btn-medium.header-button,
    button.edu-btn.btn-medium.header-button {
      line-height: 47px; }
      a.edu-btn.btn-medium.header-button.bg-color-white,
      button.edu-btn.btn-medium.header-button.bg-color-white {
        color: var(--color-primary); }
    a.edu-btn.btn-medium.header-button.purchase-button,
    button.edu-btn.btn-medium.header-button.purchase-button {
      line-height: 50px; }
  a.edu-btn.btn-small,
  button.edu-btn.btn-small {
    height: 36px;
    line-height: 36px;
    padding: 0 13px;
    font-size: 14px;
    font-weight: 600; }
    a.edu-btn.btn-small i,
    button.edu-btn.btn-small i {
      padding-left: 7px; }
  a.edu-btn.left-icon i,
  button.edu-btn.left-icon i {
    padding-left: 0;
    padding-right: 10px; }
  a.edu-btn:hover,
  button.edu-btn:hover {
    background: var(--color-dark);
    color: var(--color-white); }
  a.edu-btn.btn-dark,
  button.edu-btn.btn-dark {
    background: var(--color-dark);
    color: var(--color-white); }
    a.edu-btn.btn-dark:hover,
    button.edu-btn.btn-dark:hover {
      color: var(--color-white);
      background: var(--color-primary); }
  a.edu-btn.btn-shade,
  button.edu-btn.btn-shade {
    background: var(--color-shade);
    color: var(--color-heading); }
    a.edu-btn.btn-shade:hover,
    button.edu-btn.btn-shade:hover {
      color: var(--color-white);
      background: var(--color-primary); }
  a.edu-btn.btn-secondary,
  button.edu-btn.btn-secondary {
    background: var(--color-secondary);
    color: var(--color-white); }
    a.edu-btn.btn-secondary:hover,
    button.edu-btn.btn-secondary:hover {
      color: var(--color-white);
      background: var(--color-primary); }
  a.edu-btn.btn-bg-alt,
  button.edu-btn.btn-bg-alt {
    background: rgba(82, 95, 225, 0.1);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    font-weight: 700;
    font-size: 18px;
    line-height: 59px; }
    a.edu-btn.btn-bg-alt:hover,
    button.edu-btn.btn-bg-alt:hover {
      color: var(--color-white);
      background: var(--color-primary);
      border-color: var(--color-primary); }
  a.edu-btn.btn-white,
  button.edu-btn.btn-white {
    background: var(--color-white);
    color: var(--color-primary); }
    a.edu-btn.btn-white:hover,
    button.edu-btn.btn-white:hover {
      color: var(--color-white);
      background: var(--color-secondary); }
  a.edu-btn.btn-border,
  button.edu-btn.btn-border {
    background: transparent;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
    line-height: 58px; }

.bg-color-primary a.edu-btn.btn-white:hover,
.bg-color-primary button.edu-btn.btn-white:hover {
  background: var(--color-secondary); }

.round-btn {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: var(--color-white);
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07); }
  .round-btn i {
    font-size: 24px;
    color: var(--color-primary);
    display: inline-block; }

@keyframes rotateIt {
  to {
    transform: rotate(-360deg); } }

/*--------------------------------
    Brands Area  
------------------------------*/
.eduvibe-home-five-brands .client-logo {
  transition: all .4s;
  display: inline-block; }
  @media only screen and (max-width: 991px) {
    .eduvibe-home-five-brands .client-logo {
      margin-bottom: 30px; } }
  .eduvibe-home-five-brands .client-logo:hover .logo-main,
  .eduvibe-home-five-brands .client-logo .logo-hover {
    display: none; }
  .eduvibe-home-five-brands .client-logo:hover .logo-hover {
    display: block !important; }
  .eduvibe-home-five-brands .client-logo img {
    display: flex; }

/*---------------------------
 * Section Title
----------------------------*/
.section-title .pre-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-primary);
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 0.1em; }

.section-title .title {
  margin-bottom: 0;
  font-size: 30px; font-weight: 700;}
  .section-title .title-h2{
    margin-bottom: 0;
    font-size: 25px; font-weight: 500;}
  @media only screen and (max-width: 575px) {
    .section-title .title {
      font-size: 32px; } }
  @media only screen and (max-width: 767px) {
    .section-title .title br {
      display: none; } }

.section-title.text-white .pre-title {
  color: var(--color-white); }

.section-title.text-white .title {
  color: var(--color-white); }

/*---------------------------
 * Home 1 Course
----------------------------*/
.eduvibe-home-one-course .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-one-course .shape-image.shape-image-1 {
    top: 59px;
    left: -246px; }
  .eduvibe-home-one-course .shape-image.shape-image-2 {
    top: 144px;
    right: -194px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
  .eduvibe-home-one-course .shape-image.shape-image-3 {
    bottom: 53px;
    right: -163px; }
  .eduvibe-home-one-course .shape-image.shape-image-4 {
    bottom: 157px;
    left: -226px;
    animation: eduvibe-vsm-y-reverse-move 3.5s alternate infinite linear; }

/*---------------------------
 * Home 1 Testimonial
----------------------------*/
 
  .eduvibe-testimonial-one .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-testimonial-one .shape-image.shape-image-1 {
      top: 2px;
      left: -232px; }
    .eduvibe-testimonial-one .shape-image.shape-image-2 {
      top: 19px;
      right: -167px; }
    .eduvibe-testimonial-one .shape-image.shape-image-3 {
      bottom: 2px;
      right: -265px; }
    .eduvibe-testimonial-one .shape-image.shape-image-4 {
      bottom: -68px;
      left: -49px; }

/*---------------------------
 * Home 1 Video
----------------------------*/
.eduvibe-home-one-video .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-one-video .shape-image.shape-image-1 {
    bottom: 80px;
    left: -232px;
    animation: eduvibe-rotate-x 15s infinite linear; }
  .eduvibe-home-one-video .shape-image.shape-image-2 {
    top: 20px;
    right: -262px; }
  .eduvibe-home-one-video .shape-image.shape-image-3 {
    bottom: 80px;
    right: -242px; }

/*---------------------------
 * Home 1 Instructor
----------------------------*/
 
  .eduvibe-home-one-instructor .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-one-instructor .shape-image.shape-image-1 {
      bottom: 29px;
      left: -59px; }
    .eduvibe-home-one-instructor .shape-image.shape-image-2 {
      top: 49px;
      right: -53px; }

/*---------------------------
 * Newsletter 1
----------------------------*/
.newsletter-style-1 .shape-image {
  position: absolute;
  z-index: -1; }
  .newsletter-style-1 .shape-image.shape-image-1 {
    top: -68px;
    left: -20px; }
  .newsletter-style-1 .shape-image.shape-image-2 {
    top: -34px;
    right: -263px; }
  .newsletter-style-1 .shape-image.shape-image-3 {
    bottom: -95px;
    left: -178px; }

/*---------------------------
 * Home 1 Blog
----------------------------*/
.eduvibe-home-one-blog {
 
  position: relative; }
  .eduvibe-home-one-blog .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-one-blog .shape-image.shape-image-1 {
      top: 71px;
      left: -228px;
      animation: eduvibe-vsm-y-move 5.6s alternate infinite linear; }
    .eduvibe-home-one-blog .shape-image.shape-image-2 {
      top: -20px;
      right: -215px;
      z-index: 0; }
      @media only screen and (max-width: 1650px) {
        .eduvibe-home-one-blog .shape-image.shape-image-2 {
          right: 5px;
          top: 45px; } }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .eduvibe-home-one-blog .shape-image.shape-image-2 {
          display: none; } }
    .eduvibe-home-one-blog .shape-image.shape-image-3 {
      bottom: -102px;
      right: -260px; }
    .eduvibe-home-one-blog .shape-image.shape-image-4 {
      bottom: -74px;
      left: -208px; }

/*---------------------------
 * Home 2 Service
----------------------------*/
.eduvibe-service-four .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-service-four .shape-image.shape-image-1 {
    bottom: 92px;
    left: -180px; }
  .eduvibe-service-four .shape-image.shape-image-2 {
    top: 71px;
    right: -52px; }
  .eduvibe-service-four .shape-image.shape-image-3 {
    bottom: -39px;
    right: -219px; }

/*---------------------------
 * About 3
----------------------------*/
 
  .about-style-3 .shape-image {
    position: absolute;
    z-index: -1; }
    .about-style-3 .shape-image.shape-image-1 {
      top: -3px;
      left: -259px; }
    .about-style-3 .shape-image.shape-image-2 {
      top: -31px;
      right: -269px; }
    .about-style-3 .shape-image.shape-image-3 {
      bottom: -31px;
      right: -209px; }
    .about-style-3 .shape-image.shape-image-4 {
      bottom: -61px;
      left: -269px; }
  .about-style-3 .shape-dot-wrapper .shape {
    position: absolute;
    z-index: -1; }
    .about-style-3 .shape-dot-wrapper .shape .shape-dot {
      display: inline-block;
      border-radius: 100%; }
    .about-style-3 .shape-dot-wrapper .shape.shape-1 {
      bottom: -44px;
      left: -272px; }
      .about-style-3 .shape-dot-wrapper .shape.shape-1 .shape-dot {
        width: 50px;
        height: 50px;
        background: var(--color-extra06);
        opacity: 0.8; }
    .about-style-3 .shape-dot-wrapper .shape.shape-2 {
      top: 14px;
      right: -228px; }
      .about-style-3 .shape-dot-wrapper .shape.shape-2 .shape-dot {
        width: 26px;
        height: 26px;
        background: var(--color-extra08);
        opacity: 0.35; }

/*---------------------------
 * Home 2 Course
----------------------------*/
.eduvibe-home-two-course .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-two-course .shape-image.shape-image-1 {
    top: 177px;
    left: -203px;
    animation: eduvibe-vsm-y-move 4.5s alternate infinite linear; }
  .eduvibe-home-two-course .shape-image.shape-image-2 {
    bottom: 390px;
    right: -262px; }
  .eduvibe-home-two-course .shape-image.shape-image-3 {
    bottom: -40px;
    right: -17px;
    animation: eduvibe-vsm-y-reverse-move 4s alternate infinite linear; }
  .eduvibe-home-two-course .shape-image.shape-image-4 {
    bottom: -65px;
    left: -38px;
    animation: eduvibe-sm-x-move 4s alternate infinite linear; }

/*---------------------------
 * Home 2 Event
----------------------------*/
 
  .eduvibe-home-two-event .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-two-event .shape-image.shape-image-1 {
      top: -263px;
      left: -153px; }
    .eduvibe-home-two-event .shape-image.shape-image-2 {
      top: 101px;
      right: -28px; }
    .eduvibe-home-two-event .shape-image.shape-image-3 {
      bottom: -40px;
      right: -78px; }
    .eduvibe-home-two-event .shape-image.shape-image-4 {
      bottom: -90px;
      left: -208px; }
  .eduvibe-home-two-event .shape-dot-wrapper .shape {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-two-event .shape-dot-wrapper .shape .shape-dot {
      display: inline-block;
      border-radius: 100%; }
    .eduvibe-home-two-event .shape-dot-wrapper .shape.shape-1 {
      top: -232px;
      right: -124px; }
      .eduvibe-home-two-event .shape-dot-wrapper .shape.shape-1 .shape-dot {
        width: 48px;
        height: 48px;
        border: 8px solid rgba(248, 111, 3, 0.3); }

/*---------------------------
 * Home 2 Testimonial
----------------------------*/
 
  .eduvibe-home-two-testimonial .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-two-testimonial .shape-image.shape-image-1 {
      bottom: -53px;
      left: -193px; }
    .eduvibe-home-two-testimonial .shape-image.shape-image-2 {
      top: 27px;
      right: -183px; }

/*---------------------------
 * Home 2 Counter
----------------------------*/
.eduvibe-home-two-counter .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-two-counter .shape-image.shape-image-1 {
    bottom: -33px;
    left: -33px; }
  .eduvibe-home-two-counter .shape-image.shape-image-2 {
    bottom: 143px;
    right: -243px; }

/*---------------------------
 * Home 2 Blog
----------------------------*/
.eduvibe-home-two-blog {
  position: relative; }
  .eduvibe-home-two-blog .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-two-blog .shape-image.shape-image-1 {
      top: -50px;
      left: -198px; }
      .eduvibe-home-two-blog .shape-image.shape-image-1 img {
        max-width: 60%; }
    .eduvibe-home-two-blog .shape-image.shape-image-2 {
      top: 112px;
      right: 107px;
      z-index: 0; }
    .eduvibe-home-two-blog .shape-image.shape-image-2 {
      top: 112px;
      right: 107px;
      z-index: 0; }
      @media only screen and (max-width: 1650px) {
        .eduvibe-home-two-blog .shape-image.shape-image-2 {
          right: 30px;
          top: 45px; } }
    .eduvibe-home-two-blog .shape-image.shape-image-3 {
      bottom: -84px;
      right: -218px; }
    .eduvibe-home-two-blog .shape-image.shape-image-4 {
      bottom: -62px;
      left: -50px; }

/*---------------------------
 * Home 3 Service
----------------------------*/
.eduvibe-service-five .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-service-five .shape-image.shape-image-1 {
    top: 81px;
    right: -46px; }
  .eduvibe-service-five .shape-image.shape-image-2 {
    bottom: -40px;
    left: -250px; }
    .eduvibe-service-five .shape-image.shape-image-2 img {
      max-width: 80%; }

.eduvibe-service-five .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-service-five .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-service-five .shape-dot-wrapper .shape.shape-1 {
    top: 111px;
    right: -50px; }
    .eduvibe-service-five .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 130px;
      height: 130px;
      background: var(--color-tertiary);
      opacity: 0.4; }

/*---------------------------
 * Home 3 About
----------------------------*/
.home-three-about {
  position: relative; }
  .home-three-about .shape-image {
    position: absolute;
    z-index: -1; }
    .home-three-about .shape-image.shape-image-1 {
      top: 54px;
      right: -196px; }
    .home-three-about .shape-image.shape-image-2 {
      bottom: 27px;
      right: -108px; }

/*---------------------------
 * Home 3 Course
----------------------------*/
 
  .eduvibe-home-three-course .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-three-course .shape-image.shape-image-1 {
      top: -260px;
      left: -130px;
      animation: eduvibe-sm-x-move 6s alternate infinite linear; }
      .eduvibe-home-three-course .shape-image.shape-image-1 img {
        transform: rotate(90deg); }
    .eduvibe-home-three-course .shape-image.shape-image-2 {
      top: 484px;
      left: -282px;
      animation: eduvibe-vsm-y-reverse-move 5.5s alternate infinite linear; }
    .eduvibe-home-three-course .shape-image.shape-image-3 {
      top: 152px;
      right: -211px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    .eduvibe-home-three-course .shape-image.shape-image-4 {
      bottom: 49px;
      right: -224px; }
    .eduvibe-home-three-course .shape-image.shape-image-5 {
      bottom: -47px;
      left: -289px; }

/*---------------------------
 * Home 3 Video
----------------------------*/
.eduvibe-home-three-video { 
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top 15px left 10%; }
  .eduvibe-home-three-video .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-three-video .shape-image.shape-image-1 {
      top: -59px;
      right: -199px; }
    .eduvibe-home-three-video .shape-image.shape-image-2 {
      bottom: -47px;
      right: -63px; }
    .eduvibe-home-three-video .shape-image.shape-image-3 {
      bottom: -91px;
      left: -229px; }

/*---------------------------
 * Home 3 Accordion
----------------------------*/
.eduvibe-home-three-accordion .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-three-accordion .shape-image.shape-image-2 {
    bottom: inherit;
    left: -170px;
    top: 56px; }
  .eduvibe-home-three-accordion .shape-image.shape-image-3 {
    bottom: 16px;
    left: inherit;
    right: -200px;
    top: inherit; }

.eduvibe-home-three-accordion .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-three-accordion .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-home-three-accordion .shape-dot-wrapper .shape.shape-1 {
    top: 195px;
    left: -91px; }
    .eduvibe-home-three-accordion .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 200px;
      height: 200px;
      border: 34px solid rgba(248, 111, 3, 0.3); }

/*---------------------------
 * Home 3 Testimonial
----------------------------*/
 
  .eduvibe-home-three-testimonial .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-three-testimonial .shape-image.shape-image-1 {
      left: -173px;
      top: 16px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    .eduvibe-home-three-testimonial .shape-image.shape-image-2 {
      top: -34px;
      right: -268px; }
    .eduvibe-home-three-testimonial .shape-image.shape-image-3 {
      bottom: -45px;
      left: -37px; }
    .eduvibe-home-three-testimonial .shape-image.shape-image-4 {
      bottom: -25px;
      right: -218px; }

/*---------------------------
 * Home 3 Event
----------------------------*/
.eduvibe-home-three-event .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-three-event .shape-image.shape-image-1 {
    left: -173px;
    top: 16px; }
  .eduvibe-home-three-event .shape-image.shape-image-2 {
    top: -34px;
    right: -180px; }
  .eduvibe-home-three-event .shape-image.shape-image-3 {
    bottom: -45px;
    right: -237px; }
  .eduvibe-home-three-event .shape-image.shape-image-4 {
    bottom: -25px;
    left: -218px; }

/*---------------------------
 * Home 3 Nesletter
----------------------------*/

  .eduvibe-home-three-newsletter .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-three-newsletter .shape-image.shape-image-1 {
      left: -63px;
      top: -24px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    .eduvibe-home-three-newsletter .shape-image.shape-image-2 {
      top: -4px;
      right: 100px; }
    .eduvibe-home-three-newsletter .shape-image.shape-image-3 {
      right: -194px;
      bottom: -104px; }
    .eduvibe-home-three-newsletter .shape-image.shape-image-4 {
      left: 216px;
      bottom: -48px; }

/*---------------------------
 * Home 4 Service
----------------------------*/
.eduvibe-home-four-service .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-four-service .shape-image.shape-image-1 {
    top: 56px;
    left: -180px;
    animation: eduvibe-rotate-x 7s infinite linear; }
  .eduvibe-home-four-service .shape-image.shape-image-2 {
    top: 12px;
    right: -180px; }
  .eduvibe-home-four-service .shape-image.shape-image-3 {
    bottom: -38px;
    right: -25px; }
  .eduvibe-home-four-service .shape-image.shape-image-4 {
    bottom: -48px;
    left: -55px; }

/*---------------------------
 * Home 4 About
----------------------------*/
.eduvibe-home-four-about .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-four-about .shape-image.shape-image-1 {
    bottom: 231px;
    left: -190px; }
  .eduvibe-home-four-about .shape-image.shape-image-2 {
    top: -59px;
    right: -297px; }
  .eduvibe-home-four-about .shape-image.shape-image-3 {
    bottom: 10px;
    right: -185px; }

.eduvibe-home-four-about .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-four-about .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-home-four-about .shape-dot-wrapper .shape.shape-1 {
    bottom: 140px;
    left: -21px;
    z-index: 2; }
    .eduvibe-home-four-about .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 40px;
      height: 40px;
      border: 5.4px solid rgba(248, 111, 3, 0.5);
      animation: eduvibe-zoom2 2s infinite alternate; }

/*---------------------------
 * Home 4 Course
----------------------------*/

  .eduvibe-home-four-courses .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-four-courses .shape-image.shape-image-1 {
      top: 6px;
      left: -150px; }
    .eduvibe-home-four-courses .shape-image.shape-image-2 {
      top: 38px;
      right: -175px; }
    .eduvibe-home-four-courses .shape-image.shape-image-3 {
      bottom: 30px;
      right: -230px; }
    .eduvibe-home-four-courses .shape-image.shape-image-4 {
      bottom: 80px;
      left: -189px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
  .eduvibe-home-four-courses .shape-dot-wrapper .shape {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-four-courses .shape-dot-wrapper .shape .shape-dot {
      display: inline-block;
      border-radius: 100%; }
    .eduvibe-home-four-courses .shape-dot-wrapper .shape.shape-1 {
      bottom: 13px;
      right: -196px; }
      .eduvibe-home-four-courses .shape-dot-wrapper .shape.shape-1 .shape-dot {
        width: 40px;
        height: 40px;
        border: 5.4px solid rgba(251, 124, 86, 0.3);
        animation: eduvibe-zoom2 2s infinite alternate; }

/*---------------------------
 * Home 4 Video PopUp
----------------------------*/

  @media only screen and (max-width: 991px) {
    .eduvibe-home-four-video {
      background-image: none; } }
  .eduvibe-home-four-video .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-four-video .shape-image.shape-image-1 {
      top: -33px;
      left: -30px; }
    .eduvibe-home-four-video .shape-image.shape-image-2 {
      bottom: -50px;
      left: -48px; }

/*---------------------------
 * Home 4 Team
----------------------------*/
.eduvibe-home-four-team {
  padding: 130px 0 275px;
   }
  @media only screen and (max-width: 991px) {
    .eduvibe-home-four-team {
      padding: 80px 0 160px; } }
  .eduvibe-home-four-team .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-four-team .shape-image.shape-image-1 {
      top: 38px;
      left: -181px;
      animation: eduvibe-sm-x-move 3.5s alternate infinite linear; }
    .eduvibe-home-four-team .shape-image.shape-image-2 {
      top: -24px;
      right: -216px; }
    .eduvibe-home-four-team .shape-image.shape-image-3 {
      bottom: -30px;
      right: -151px; }

/*---------------------------
 * Home 4 Testimonial
----------------------------*/
.eduvibe-home-four-testimonial .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-four-testimonial .shape-image.shape-image-1 {
    bottom: 5px;
    left: -200px; }
  .eduvibe-home-four-testimonial .shape-image.shape-image-2 {
    top: 0px;
    right: -200px; }
  .eduvibe-home-four-testimonial .shape-image.shape-image-3 {
    bottom: 0px;
    right: -121px; }

/*---------------------------
 * Home 4 Blog
----------------------------*/
 
  .eduvibe-home-four-blog .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-four-blog .shape-image.shape-image-1 {
      top: 5px;
      left: -200px; }
    .eduvibe-home-four-blog .shape-image.shape-image-2 {
      bottom: -34px;
      right: -35px; }
    .eduvibe-home-four-blog .shape-image.shape-image-3 {
      bottom: -54px;
      left: -56px; }

/*---------------------------
 * FAQ Page
----------------------------*/
.eduvibe-faq-page .animated-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-faq-page .animated-image.shape-image-1 {
    top: 60px;
    left: -59px;
    animation: eduvibe-sm-x-move 3s alternate infinite linear; }
    .eduvibe-faq-page .animated-image.shape-image-1 img {
      transform: rotate(90deg); }
  .eduvibe-faq-page .animated-image.shape-image-2 {
    top: 56px;
    right: -223px; }
  .eduvibe-faq-page .animated-image.shape-image-3 {
    bottom: 56px;
    right: -223px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }

.eduvibe-faq-page .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-faq-page .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-faq-page .shape-dot-wrapper .shape.shape-1 {
    bottom: 89px;
    left: -184px; }
    .eduvibe-faq-page .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 48px;
      height: 48px;
      border: 8px solid var(--color-extra07);
      animation: eduvibe-zoom1 2.6s infinite alternate; }

/*---------------------------
 * 404 Page
----------------------------*/

  .eduvibe-404-page .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-404-page .shape-image.shape-image-1 {
      top: 165px;
      left: -21px; }
    .eduvibe-404-page .shape-image.shape-image-2 {
      top: 100px;
      right: -247px; }
    .eduvibe-404-page .shape-image.shape-image-3 {
      top: 520px;
      right: 50px; }
    .eduvibe-404-page .shape-image.shape-image-4 {
      bottom: -61px;
      right: -247px; }
    .eduvibe-404-page .shape-image.shape-image-5 {
      left: 40px;
      bottom: -63px; }
    .eduvibe-404-page .shape-image.shape-image-6 {
      bottom: 180px;
      left: -200px; }

/*---------------------------
 * Coming Soon Page
----------------------------*/

  .eduvibe-coming-soon .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-coming-soon .shape-image.shape-image-1 {
      top: -6px;
      left: -110px; }
    .eduvibe-coming-soon .shape-image.shape-image-2 {
      top: -83px;
      right: -107px; }
    .eduvibe-coming-soon .shape-image.shape-image-3 {
      bottom: 252px;
      right: -111px; }
    .eduvibe-coming-soon .shape-image.shape-image-4 {
      bottom: -100px;
      right: -234px; }
    .eduvibe-coming-soon .shape-image.shape-image-5 {
      bottom: -60px;
      left: -84px; }
    .eduvibe-coming-soon .shape-image.shape-image-6 {
      top: 390px;
      left: 0; }

/*---------------------------
 * Contact Us Page
----------------------------*/
.eduvibe-contact-us .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-contact-us .shape-image.shape-image-1 {
    top: 132px;
    left: -40px; }
  .eduvibe-contact-us .shape-image.shape-image-2 {
    top: 95px;
    right: -59px; }
  .eduvibe-contact-us .shape-image.shape-image-3 {
    bottom: 79px;
    right: -197px; }

/*---------------------------
 * Contact Me Page
----------------------------*/
.eduvibe-contact-me-top .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-contact-me-top .shape-image.shape-image-1 {
    bottom: 113px;
    left: -200px; }
  .eduvibe-contact-me-top .shape-image.shape-image-2 {
    right: -40px;
    top: 100px; }
  .eduvibe-contact-me-top .shape-image.shape-image-3 {
    top: 0;
    right: -250px; }
  .eduvibe-contact-me-top .shape-image.shape-image-4 {
    bottom: 6px;
    right: -200px; }

.eduvibe-contact-me-bottom .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-contact-me-bottom .shape-image.shape-image-1 {
    top: 94px;
    left: -56px; }
  .eduvibe-contact-me-bottom .shape-image.shape-image-2 {
    top: 173px;
    right: -147px; }
  .eduvibe-contact-me-bottom .shape-image.shape-image-3 {
    bottom: -80px;
    right: -269px; }
  .eduvibe-contact-me-bottom .shape-image.shape-image-4 {
    bottom: -40px;
    left: -29px; }
  .eduvibe-contact-me-bottom .shape-image.shape-image-5 {
    bottom: -90px;
    left: -219px; }

/*---------------------------
 * Pricing Plan Page
----------------------------*/
.eduvibe-pricing-plan-page .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-pricing-plan-page .shape-image.shape-image-1 {
    top: 324px;
    left: -199px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }

.eduvibe-pricing-plan-page .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-pricing-plan-page .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-pricing-plan-page .shape-dot-wrapper .shape.shape-1 {
    bottom: -6px;
    right: -46px; }
    .eduvibe-pricing-plan-page .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 110px;
      height: 110px;
      border: 18px solid rgba(255, 164, 27, 0.15); }

/*---------------------------
 * Home 5 Categories
----------------------------*/
.eduvibe-home-five-cats .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-five-cats .shape-image.shape-image-1 {
    top: 80px;
    left: -235px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
  .eduvibe-home-five-cats .shape-image.shape-image-2 {
    top: 70px;
    right: -239px; }
  .eduvibe-home-five-cats .shape-image.shape-image-3 {
    bottom: 12px;
    right: 5px; }

.eduvibe-home-five-cats .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-five-cats .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-home-five-cats .shape-dot-wrapper .shape.shape-1 {
    bottom: -6px;
    right: -46px; }
    .eduvibe-home-five-cats .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 110px;
      height: 110px;
      border: 18px solid rgba(255, 164, 27, 0.15); }

/*---------------------------
 * Home 5 About
----------------------------*/
.eduvibe-home-five-about .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-five-about .shape-image.shape-image-1 {
    top: 58px;
    left: -24px; }
  .eduvibe-home-five-about .shape-image.shape-image-2 {
    top: 48px;
    right: -224px; }
  .eduvibe-home-five-about .shape-image.shape-image-3 {
    bottom: -62px;
    right: -164px; }
  .eduvibe-home-five-about .shape-image.shape-image-4 {
    bottom: 28px;
    left: -104px; }
  .eduvibe-home-five-about .shape-image.shape-image-5 {
    top: 188px;
    left: -224px; }

/*---------------------------
 * Home 5 Course
----------------------------*/
.eduvibe-home-five-course {
  background-image: url(../images/bg/home-five-courses.webp); }
  .eduvibe-home-five-course .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-five-course .shape-image.shape-image-1 {
      top: -62px;
      left: -296px; }
    .eduvibe-home-five-course .shape-image.shape-image-2 {
      top: 19px;
      right: -217px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    .eduvibe-home-five-course .shape-image.shape-image-3 {
      bottom: -55px;
      right: -207px; }
    .eduvibe-home-five-course .shape-image.shape-image-4 {
      bottom: -52px;
      left: -268px; }

/*---------------------------
 * Home 5 Instructor
----------------------------*/
.eduvibe-home-five-instructor .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-five-instructor .shape-image.shape-image-1 {
    top: 198px;
    left: -35px; }
  .eduvibe-home-five-instructor .shape-image.shape-image-2 {
    top: 36px;
    right: -123px; }
  .eduvibe-home-five-instructor .shape-image.shape-image-3 {
    bottom: 19px;
    right: -193px; }
  .eduvibe-home-five-instructor .shape-image.shape-image-4 {
    bottom: 91px;
    left: -165px; }

/*---------------------------
 * Home 5 Progress
----------------------------*/

  .eduvibe-home-five-progress .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-five-progress .shape-image.shape-image-1 {
      top: -40px;
      left: -110px;
      animation: eduvibe-sm-x-move 4s alternate infinite linear; }
    .eduvibe-home-five-progress .shape-image.shape-image-2 {
      bottom: 110px;
      right: -242px;
      animation: eduvibe-vsm-y-move 4s alternate infinite linear; }
    .eduvibe-home-five-progress .shape-image.shape-image-3 {
      bottom: -49px;
      left: -242px; }

/*---------------------------
 * Home 5 Testimonial
----------------------------*/
.eduvibe-home-five-testimonial .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-home-five-testimonial .shape-image.shape-image-1 {
    top: 38px;
    right: -230px; }
  .eduvibe-home-five-testimonial .shape-image.shape-image-2 {
    bottom: -15px;
    right: -183px; }
  .eduvibe-home-five-testimonial .shape-image.shape-image-3 {
    bottom: -45px;
    left: -183px; }

/*---------------------------
 * Home 5 Blog
----------------------------*/
.eduvibe-home-five-blog {
  position: relative; }
  .eduvibe-home-five-blog .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-home-five-blog .shape-image.shape-image-1 {
      top: 30px;
      left: -228px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    .eduvibe-home-five-blog .shape-image.shape-image-2 {
      top: 143px;
      right: 87px;
      z-index: 0; }
      @media only screen and (max-width: 1650px) {
        .eduvibe-home-five-blog .shape-image.shape-image-2 {
          top: 45px;
          right: 30px; } }
    .eduvibe-home-five-blog .shape-image.shape-image-3 {
      bottom: 38px;
      right: -240px; }
    .eduvibe-home-five-blog .shape-image.shape-image-4 {
      bottom: -45px;
      left: -30px; }

/*---------------------------
 * About Us 1 Service
----------------------------*/
.eduvibe-about-us-one-service {
  position: relative; }
  .eduvibe-about-us-one-service .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-about-us-one-service .shape-image.shape-image-1 {
      top: 150px;
      left: -148px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    .eduvibe-about-us-one-service .shape-image.shape-image-2 {
      top: 125px;
      right: -44px; }
    .eduvibe-about-us-one-service .shape-image.shape-image-3 {
      bottom: 13px;
      right: -200px; }

/*---------------------------
 * About Us 1 Team
----------------------------*/
.eduvibe-about-one-team {
  position: relative;
 }
  .eduvibe-about-one-team .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-about-one-team .shape-image.shape-image-1 {
      top: 41px;
      left: -228px; }
    .eduvibe-about-one-team .shape-image.shape-image-2 {
      top: 150px;
      right: 85px;
      z-index: 0; }
      @media only screen and (max-width: 1650px) {
        .eduvibe-about-one-team .shape-image.shape-image-2 {
          right: 30px;
          top: 45px; } }
    .eduvibe-about-one-team .shape-image.shape-image-3 {
      bottom: -42px;
      right: -240px; }
    .eduvibe-about-one-team .shape-image.shape-image-4 {
      bottom: -54px;
      left: -198px;
      animation: eduvibe-vsm-y-reverse-move 3.5s alternate infinite linear; }

/*---------------------------
 * Home 4 Service
----------------------------*/
.eduvibe-about-one-service .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-one-service .shape-image.shape-image-1 {
    bottom: -7px;
    left: -50px; }
  .eduvibe-about-one-service .shape-image.shape-image-2 {
    top: 42px;
    right: -220px; }

.eduvibe-about-one-service .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-one-service .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-about-one-service .shape-dot-wrapper .shape.shape-1 {
    bottom: -34px;
    left: -48px; }
    .eduvibe-about-one-service .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 130px;
      height: 130px;
      background: var(--color-tertiary);
      opacity: 0.4; }
  .eduvibe-about-one-service .shape-dot-wrapper .shape.shape-2 {
    top: 83px;
    left: -131px; }
    .eduvibe-about-one-service .shape-dot-wrapper .shape.shape-2 .shape-dot {
      width: 12px;
      height: 12px;
      background: #9EDBE2;
      animation: eduvibe-zoom1 1s infinite alternate; }
  .eduvibe-about-one-service .shape-dot-wrapper .shape.shape-3 {
    bottom: -16px;
    right: -63px; }
    .eduvibe-about-one-service .shape-dot-wrapper .shape.shape-3 .shape-dot {
      width: 8px;
      height: 8px;
      background: var(--color-secondary);
      animation: eduvibe-zoom1 1s infinite alternate; }

/*--------------------------------
 * Home About Us 2 Service & Video
----------------------------------*/
.eduvibe-about-us-two-service .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-us-two-service .shape-image.shape-image-1 {
    top: 42px;
    left: -180px;
    animation: eduvibe-rotate-x 7s infinite linear; }
  .eduvibe-about-us-two-service .shape-image.shape-image-2 {
    bottom: -28px;
    right: -27px; }

.eduvibe-about-us-two-video .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-us-two-video .shape-image.shape-image-1 {
    bottom: 66px;
    left: -120px;
    animation: eduvibe-sm-x-move 4.5s alternate infinite linear; }
  .eduvibe-about-us-two-video .shape-image.shape-image-2 {
    bottom: 90px;
    right: -120px;
    animation: eduvibe-rotate-x 7s infinite linear; }

/*---------------------------
 * Home About Us 2 Award
----------------------------*/
.eduvibe-about-two-award .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-two-award .shape-image.shape-image-1 {
    top: -255px;
    left: -170px; }
  .eduvibe-about-two-award .shape-image.shape-image-2 {
    top: 97px;
    right: -39px; }
  .eduvibe-about-two-award .shape-image.shape-image-3 {
    bottom: -30px;
    left: -30px; }

.eduvibe-about-two-award .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-two-award .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-about-two-award .shape-dot-wrapper .shape.shape-1 {
    top: -243px;
    right: -134px; }
    .eduvibe-about-two-award .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 30px;
      height: 30px;
      border: 5px solid rgba(255, 164, 27, 0.3);
      animation: eduvibe-zoom2 2s infinite alternate; }

/*---------------------------
 * Home 5 Instructor
----------------------------*/
.eduvibe-about-us-two-instructor {
  background-image: url(../images/bg/about-us-two-team-team.webp); }
  .eduvibe-about-us-two-instructor .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-about-us-two-instructor .shape-image.shape-image-1 {
      top: 198px;
      left: -35px; }
    .eduvibe-about-us-two-instructor .shape-image.shape-image-2 {
      top: 36px;
      right: -123px; }
    .eduvibe-about-us-two-instructor .shape-image.shape-image-3 {
      bottom: 19px;
      right: -193px; }
    .eduvibe-about-us-two-instructor .shape-image.shape-image-4 {
      bottom: 91px;
      left: -165px; }

/*---------------------------
 * Home About Us 3 Mission
----------------------------*/
.eduvibe-about-three-mission .animated-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-three-mission .animated-image.shape-image-1 {
    top: 128px;
    left: -60px; }

.eduvibe-about-three-mission .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-three-mission .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-about-three-mission .shape-dot-wrapper .shape.shape-1 {
    top: 38px;
    left: 188px; }
    .eduvibe-about-three-mission .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 150px;
      height: 150px;
      background: #FFA41B;
      opacity: 0.6;
      border-radius: 5px; }
  .eduvibe-about-three-mission .shape-dot-wrapper .shape.shape-2 {
    top: -17px;
    right: -37px; }
    .eduvibe-about-three-mission .shape-dot-wrapper .shape.shape-2 .shape-dot {
      width: 100px;
      height: 100px;
      background: rgba(251, 124, 86, 0.6); }

/*---------------------------
 * Home About Us 3 Team
----------------------------*/
.eduvibe-about-three-team .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-three-team .shape-image.shape-image-1 {
    top: 96px;
    left: -37px; }
  .eduvibe-about-three-team .shape-image.shape-image-2 {
    top: 8px;
    right: -240px; }
  .eduvibe-about-three-team .shape-image.shape-image-3 {
    bottom: -10px;
    right: -150px; }
  .eduvibe-about-three-team .shape-image.shape-image-4 {
    bottom: -21px;
    left: -220px; }

/*---------------------------
 * Home About Us 3 funfact
----------------------------*/
.eduvibe-about-three-funfact .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-three-funfact .shape-image.shape-image-1 {
    top: 169px;
    left: -137px;
    animation: eduvibe-sm-x-move 2.5s alternate infinite linear; }
    .eduvibe-about-three-funfact .shape-image.shape-image-1 img {
      transform: rotate(90deg); }
  .eduvibe-about-three-funfact .shape-image.shape-image-2 {
    top: 8px;
    right: -200px;
    animation: eduvibe-sm-x-reverse-move 2.5s alternate infinite linear; }
    .eduvibe-about-three-funfact .shape-image.shape-image-2 img {
      transform: rotate(90deg); }

/*---------------------------
 * About 3 Testimonial
----------------------------*/
.eduvibe-about-three-testimonial .shape-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-three-testimonial .shape-image.shape-image-1 {
    left: -173px;
    top: 56px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
  .eduvibe-about-three-testimonial .shape-image.shape-image-2 {
    top: -14px;
    right: -268px; }
  .eduvibe-about-three-testimonial .shape-image.shape-image-3 {
    bottom: -45px;
    left: -37px; }

.eduvibe-about-three-testimonial .shape-dot-wrapper .shape {
  position: absolute;
  z-index: -1; }
  .eduvibe-about-three-testimonial .shape-dot-wrapper .shape .shape-dot {
    display: inline-block;
    border-radius: 100%; }
  .eduvibe-about-three-testimonial .shape-dot-wrapper .shape.shape-1 {
    bottom: -25px;
    right: -218px; }
    .eduvibe-about-three-testimonial .shape-dot-wrapper .shape.shape-1 .shape-dot {
      width: 70px;
      height: 70px;
      border: 12px solid #FFDCB4; }

.eduvibe-home-4-hero-svg path {
  -webkit-animation: none;
  animation: none;
  stroke-dasharray: 3000;
  stroke-dashoffset: 3000; }

.sal-animate.eduvibe-home-4-hero-svg path {
  -webkit-animation: eduvibe-svg-dash 6.5s linear forwards;
  animation: eduvibe-svg-dash 6.5s linear forwards; }

@-webkit-keyframes eduvibe-svg-dash {
  to {
    stroke-dashoffset: 0; } }

@keyframes eduvibe-svg-dash {
  to {
    stroke-dashoffset: 0; } }

/*---------------------------
 Card Styles  
-----------------------------*/
.course-activation-item-2 .edu-card.card-type-2 {
  margin: 20px 0; }

.video-gallery-overlay-area {
  padding-top: 400px !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-gallery-overlay-area {
      padding-top: 80px !important; } }
  @media only screen and (max-width: 767px) {
    .video-gallery-overlay-area {
      padding-top: 80px !important; } }

.course-three-wrapper {
  position: relative;
  z-index: 1; }
  .course-three-wrapper .video-section-overlayto-another {
    margin-bottom: -400px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .course-three-wrapper .video-section-overlayto-another {
        margin-bottom: 0; } }
    @media only screen and (max-width: 767px) {
      .course-three-wrapper .video-section-overlayto-another {
        margin-bottom: 0; } }

.price-list {
  margin: -7.5px; }
  .price-list .price {
    margin: 7.5px;
    display: inline-block; }
  .price-list .current-price {
    font-weight: 700; }
  .price-list .old-price {
    font-weight: 500;
    text-decoration: line-through; }
  .price-list.price-style-01 {
    margin: -9px; }
    .price-list.price-style-01 .price {
      margin: 9px; }
    .price-list.price-style-01 .current-price {
      border-radius: 3px;
      background: var(--color-primary);
      font-weight: 700;
      font-size: 16px;
      color: var(--color-white);
      height: 36px;
      line-height: 36px;
      padding: 0 15px;
      display: inline-block; }
  .price-list.price-style-02 {
    margin: -7.5px; }
    .price-list.price-style-02 .price {
      margin: 7.5px; }
    .price-list.price-style-02 .current-price {
      font-weight: 700;
      font-size: 20px;
      color: var(--color-heading); }
    .price-list.price-style-02 .old-price {
      font-weight: 600; }
  .price-list.price-style-03 {
    margin: -6px; }
    .price-list.price-style-03 .price {
      margin: 6px; }
    .price-list.price-style-03 .current-price {
      font-weight: 700;
      font-size: 20px;
      color: var(--color-primary);
      font-size: 20px; }
    .price-list.price-style-03 .old-price {
      font-weight: 500; }

.edu-card {
  overflow: hidden; }
  .edu-card .thumbnail {
    position: relative; }
    .edu-card .thumbnail a {
      display: block;
      overflow: hidden; }
      .edu-card .thumbnail a img {
        transition: 0.4s;
        overflow: hidden; }
    .edu-card .thumbnail .top-position {
      position: absolute;
      z-index: 2; }
    .edu-card .thumbnail .wishlist-top-right {
      position: absolute;
      z-index: 2; }
  .edu-card.card-type-1 {
    background-color: var(--color-shade);
    transition: 0.4s; }
    .edu-card.card-type-1 .inner .thumbnail {
      position: relative; }
      .edu-card.card-type-1 .inner .thumbnail a {
        display: block;
        overflow: hidden; }
        .edu-card.card-type-1 .inner .thumbnail a img {
          transition: 0.4s;
          overflow: hidden; }
      .edu-card.card-type-1 .inner .thumbnail .top-position.left-top {
        left: 15px;
        top: 15px; }
      .edu-card.card-type-1 .inner .thumbnail .top-position.right-top {
        right: 15px;
        top: 15px; }
      .edu-card.card-type-1 .inner .thumbnail .wishlist-top-right {
        right: 20px;
        top: 20px; }
    .edu-card.card-type-1 .inner .content {
      padding: 0px; }
      @media only screen and (max-width: 767px) {
        .edu-card.card-type-1 .inner .content {
          padding: 25px 20px 25px; } }
      .edu-card.card-type-1 .inner .content .title {
        margin-bottom: 15px;
        margin-top: 10px; }
      .edu-card.card-type-1 .inner .content .edu-rating {
        margin-bottom: 25px; }
      .edu-card.card-type-1 .inner .content .card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--color-border);
        padding-top: 25px; }
    .edu-card.card-type-1:hover {
      background: var(--color-white);
      box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.05); }
    .edu-card.card-type-1.bg-white {
      background-color: var(--color-white);
      transition: 0.4s; }
      .edu-card.card-type-1.bg-white:hover {
        background: var(--color-white);
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06); }
  .edu-card.card-type-2 {
    background-color: var(--color-shade);
    padding: 20px 20px 30px;
    transition: 0.4s; }
    .edu-card.card-type-2 .inner .thumbnail a {
      border-radius: 3px; }
      .edu-card.card-type-2 .inner .thumbnail a img {
        border-radius: 3px; }
    .edu-card.card-type-2 .inner .thumbnail .top-position.left-top {
      left: 0;
      top: 10px; }
    .edu-card.card-type-2 .inner .thumbnail .wishlist-top-right {
      right: 20px;
      top: 10px; }
    .edu-card.card-type-2 .inner .content {
      padding-top: 25px; }
      .edu-card.card-type-2 .inner .content .card-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px; }
      .edu-card.card-type-2 .inner .content .title {
        margin-bottom: 20px; }
      .edu-card.card-type-2 .inner .content .card-bottom {
        display: flex;
        align-items: center;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid var(--color-border); }
    .edu-card.card-type-2:hover {
      background-color: var(--color-white);
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06); }
  .edu-card.card-type-3 {
    background-color: var(--color-white);
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.06);
    position: relative; }
    .edu-card.card-type-3 .inner .thumbnail a {
      border-radius: 3px; }
      .edu-card.card-type-3 .inner .thumbnail a img {
        border-radius: 3px; }
    .edu-card.card-type-3 .inner .thumbnail .top-position.left-bottom {
      left: 30px;
      bottom: -19px; }
      @media only screen and (max-width: 767px) {
        .edu-card.card-type-3 .inner .thumbnail .top-position.left-bottom {
          left: 20px; } }
    .edu-card.card-type-3 .inner .thumbnail .wishlist-top-right {
      right: 20px;
      top: 20px; }
    .edu-card.card-type-3 .inner .content {
      padding: 44px 30px 30px; }
      @media only screen and (max-width: 767px) {
        .edu-card.card-type-3 .inner .content {
          padding: 44px 20px 20px; } }
      .edu-card.card-type-3 .inner .content .card-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .edu-card.card-type-3 .inner .content .card-top {
            flex-wrap: wrap; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .edu-card.card-type-3 .inner .content .card-top {
            flex-wrap: wrap; } }
        @media only screen and (max-width: 767px) {
          .edu-card.card-type-3 .inner .content .card-top {
            flex-wrap: wrap; } }
        .edu-card.card-type-3 .inner .content .card-top .author-meta {
          margin-right: 10px; }
      .edu-card.card-type-3 .inner .content .title {
        margin-bottom: 20px; }
      .edu-card.card-type-3 .inner .content .card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--color-border);
        padding-top: 25px;
        flex-wrap: wrap; }
    .edu-card.card-type-3 .card-hover-action {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-primary);
      opacity: 0;
      transition: 0.4s;
      box-shadow: 0px 12px 30px rgba(82, 95, 225, 0.1);
      border-radius: 5px;
      padding: 30px;
      z-index: 2; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .edu-card.card-type-3 .card-hover-action {
          padding: 30px 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .edu-card.card-type-3 .card-hover-action {
          padding: 30px 20px; } }
      @media only screen and (max-width: 767px) {
        .edu-card.card-type-3 .card-hover-action {
          padding: 30px 20px; } }
      .edu-card.card-type-3 .card-hover-action .content-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px; }
        .edu-card.card-type-3 .card-hover-action .content-top .top-status-bar .eduvibe-status {
          background: rgba(255, 255, 255, 0.15);
          border: 0 none;
          line-height: 33px; }
        .edu-card.card-type-3 .card-hover-action .content-top .top-wishlist-bar .wishlist-btn {
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(10px); }
      .edu-card.card-type-3 .card-hover-action .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px; }
        .edu-card.card-type-3 .card-hover-action .title a {
          color: var(--color-white); }
      .edu-card.card-type-3 .card-hover-action .description {
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 25px; }
      .edu-card.card-type-3 .card-hover-action .price-list {
        margin-bottom: 25px; }
        .edu-card.card-type-3 .card-hover-action .price-list .price {
          color: var(--color-white); }
          .edu-card.card-type-3 .card-hover-action .price-list .price.old-price {
            color: rgba(255, 255, 255, 0.8); }
      .edu-card.card-type-3 .card-hover-action .hover-bottom-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        flex-wrap: wrap; }
        .edu-card.card-type-3 .card-hover-action .hover-bottom-content .author-meta .author-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          color: var(--color-white); }
        .edu-card.card-type-3 .card-hover-action .hover-bottom-content .edu-meta li {
          color: var(--color-white); }
          .edu-card.card-type-3 .card-hover-action .hover-bottom-content .edu-meta li i {
            color: var(--color-white); }
    .edu-card.card-type-3:hover .card-hover-action {
      opacity: 1; }
    .edu-card.card-type-3.variation-2 {
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05); }
      .edu-card.card-type-3.variation-2 .inner .thumbnail .top-position.left-bottom {
        left: 30px;
        bottom: -13.5px; }
      .edu-card.card-type-3.variation-2 .inner .edu-meta.meta-01 {
        margin: 0 -10px; }
        .edu-card.card-type-3.variation-2 .inner .edu-meta.meta-01 li {
          font-weight: 500;
          line-height: 26px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          font-size: 14px; }
      .edu-card.card-type-3.variation-2 .inner .eduvibe-status.status-03 {
        color: var(--color-white);
        font-size: 14px;
        font-weight: 600;
        height: 27px;
        line-height: 27px;
        padding: 0 10px;
        background: var(--color-primary);
        border-radius: 4px;
        display: inline-block;
        border: 0 none; }
      .edu-card.card-type-3.variation-2 .inner .content {
        padding: 32px 30px 30px; }
        .edu-card.card-type-3.variation-2 .inner .content .title {
          margin-bottom: 20px;
          font-size: 20px;
          margin-top: 15px;
          line-height: 32px; }
        .edu-card.card-type-3.variation-2 .inner .content p {
          margin-bottom: 0; }
  .edu-card.card-type-4 {
    background-color: var(--color-shade);
    transition: 0.4s; }
    .edu-card.card-type-4 .inner {
      display: flex; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .edu-card.card-type-4 .inner {
          display: block; } }
      @media only screen and (max-width: 767px) {
        .edu-card.card-type-4 .inner {
          display: block; } }
      .edu-card.card-type-4 .inner .thumbnail {
        flex-basis: 38.33%; }
        .edu-card.card-type-4 .inner .thumbnail a {
          height: 100%;
          display: block; }
          .edu-card.card-type-4 .inner .thumbnail a img {
            height: 100%;
            object-fit: cover; }
            @media only screen and (max-width: 767px) {
              .edu-card.card-type-4 .inner .thumbnail a img {
                max-height: 330px; } }
        .edu-card.card-type-4 .inner .thumbnail .top-position.left-top {
          left: 10px;
          top: 10px; }
        .edu-card.card-type-4 .inner .thumbnail .wishlist-top-right {
          right: 10px;
          top: 10px; }
      .edu-card.card-type-4 .inner .content {
        flex-basis: 61.66%;
        padding: 30px 25px; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .edu-card.card-type-4 .inner .content {
            padding: 30px 18px; } }
        @media only screen and (max-width: 767px) {
          .edu-card.card-type-4 .inner .content {
            padding: 30px 18px; } }
        .edu-card.card-type-4 .inner .content .card-top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px; }
        .edu-card.card-type-4 .inner .content .title {
          margin-bottom: 20px;
          margin-top: 20px; }
        .edu-card.card-type-4 .inner .content .card-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap; }
    .edu-card.card-type-4:hover {
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
      background-color: var(--color-white); }
    .edu-card.card-type-4.bg-white {
      background-color: var(--color-white); }
  .edu-card.card-type-5 {
    background: var(--color-white);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06); }
    .edu-card.card-type-5 .inner {
      padding: 15px 15px 0; }
      .edu-card.card-type-5 .inner .thumbnail a {
        height: 100%;
        display: block;
        border-radius: 3px; }
        .edu-card.card-type-5 .inner .thumbnail a img {
          height: 100%;
          border-radius: 3px; }
      .edu-card.card-type-5 .inner .thumbnail .top-position.left-top {
        left: 10px;
        top: 10px; }
      .edu-card.card-type-5 .inner .thumbnail .wishlist-top-right {
        right: 10px;
        top: 10px; }
      .edu-card.card-type-5 .inner .content {
        padding: 25px 15px; }
        .edu-card.card-type-5 .inner .content .card-top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px; }
        .edu-card.card-type-5 .inner .content .title {
          margin-bottom: 20px;
          margin-top: 15px; }
        .edu-card.card-type-5 .inner .content .card-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px; }
  .edu-card.card-type-6 {
    background: var(--color-white);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06); }
    .edu-card.card-type-6 .inner {
      padding: 15px; }
      .edu-card.card-type-6 .inner .thumbnail {
        margin-bottom: 15px; }
        .edu-card.card-type-6 .inner .thumbnail a {
          height: 100%;
          display: block;
          border-radius: 3px; }
          .edu-card.card-type-6 .inner .thumbnail a img {
            height: 100%;
            border-radius: 3px; }
        .edu-card.card-type-6 .inner .thumbnail .top-position.left-top {
          left: 10px;
          top: 10px; }
      .edu-card.card-type-6 .inner .content .title {
        margin-bottom: 12px;
        margin-top: 10px;
        font-size: 18px;
        line-height: 28px; }
      .edu-card.card-type-6 .inner .content p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 5px; }
  .edu-card:hover .thumbnail a img {
    transform: scale(1.1); }

/*-----------------------
    Card Styles  
-------------------------*/
.edu-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0; }
  .edu-meta.meta-01 {
    margin: 0 -20px; }
    @media only screen and (max-width: 767px) {
      .edu-meta.meta-01 {
        margin: 0 -6px; } }
    .edu-meta.meta-01 li {
      font-weight: 500;
      line-height: 26px;
      margin: 0 20px;
      display: flex;
      align-items: center; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .edu-meta.meta-01 li:first-child {
          margin-right: 10px; }
        .edu-meta.meta-01 li:last-child {
          margin-left: 10px; } }
      @media only screen and (max-width: 767px) {
        .edu-meta.meta-01 li {
          margin: 0 6px; } }
      .edu-meta.meta-01 li i {
        color: var(--color-body);
        margin-right: 8px; }
  .edu-meta.meta-02 {
    margin: 0 -20px; }
    .edu-meta.meta-02 li {
      font-weight: 500;
      line-height: 26px;
      margin: 0 20px;
      display: flex;
      align-items: center; }
      .edu-meta.meta-02 li i {
        color: var(--color-primary);
        margin-right: 10px; }
  .edu-meta.meta-03 {
    margin: -5px -12px; }
    .edu-meta.meta-03 li {
      font-weight: 600;
      line-height: 22px;
      margin: 5px 12px;
      display: flex;
      align-items: center;
      font-size: 14px; }
      .edu-meta.meta-03 li i {
        color: var(--color-primary);
        margin-right: 10px;
        width: 24px;
        height: 24px;
        position: relative;
        z-index: 1;
        text-align: center;
        line-height: 24px;
        border-radius: 2px; }
        .edu-meta.meta-03 li i::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          background: var(--color-primary);
          width: 100%;
          height: 100%;
          z-index: -1;
          opacity: 0.1;
          border-radius: 2px; }
      .edu-meta.meta-03 li.meta-lessons i {
        color: var(--color-secondary); }
        .edu-meta.meta-03 li.meta-lessons i::after {
          background: var(--color-secondary); }
      .edu-meta.meta-03 li.meta-clock i {
        color: var(--color-primary); }
        .edu-meta.meta-03 li.meta-clock i::after {
          background: var(--color-primary); }
      .edu-meta.meta-03 li.meta-user i {
        color: var(--color-tertiary); }
        .edu-meta.meta-03 li.meta-user i::after {
          background: var(--color-tertiary); }
      .edu-meta.meta-03 li:last-child {
        margin-right: 0; }
  .edu-meta.meta-04 {
    margin: -5px -6px; }
    .edu-meta.meta-04 li {
      font-weight: 600;
      line-height: 22px;
      margin: 5px 6px;
      display: flex;
      align-items: center;
      font-size: 14px; }
      .edu-meta.meta-04 li i {
        color: var(--color-secondary);
        margin-right: 8px;
        font-size: 16px; }

.edu-rating.rating-default {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .edu-rating.rating-default .rating {
    margin-right: 10px; }

/*----------------------
    Author Meta  
-----------------------*/
.author-meta {
  display: flex;
  align-items: center; }
  .author-meta .author-thumb a {
    display: block;
    transition: 0.4s; }
    .author-meta .author-thumb a img {
      max-height: 34px;
      border-radius: 100%;
      margin-right: 10px;
      display: inline-block; }
    .author-meta .author-thumb a .author-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-heading);
      transition: 0.4s; }
    .author-meta .author-thumb a:hover .author-title {
      color: var(--color-primary); }

/*-----------------------
 Badge Style  
------------------------*/
.badge-transparent {
  display: inline-block;
  color: var(--color-primary);
  font-weight: 700;
  font-size: 20px; }

.status-group {
  margin: -5px; }
  .status-group .eduvibe-status {
    margin: 5px; }

.status-style-5 {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -17px; }
  .status-style-5 .eduvibe-status {
    margin: 5px 17px; }

.eduvibe-status.status-01 {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  background: var(--color-white);
  display: inline-block;
  border-radius: 2px; }
  .eduvibe-status.status-01.bg-primary-color {
    color: var(--color-white); }
  .eduvibe-status.status-01.bg-secondary-color {
    color: var(--color-white); }

.eduvibe-status.status-02 {
  color: var(--color-white);
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  background: var(--color-secondary);
  border-radius: 0 2px 2px 0;
  display: flex;
  align-items: center; }
  .eduvibe-status.status-02 i {
    margin-right: 5px; }

.eduvibe-status.status-03 {
  color: var(--color-white);
  font-size: 14px;
  font-weight: 600;
  height: 34px;
  line-height: 30px;
  padding: 0 15px;
  background: var(--color-primary);
  border-radius: 2px;
  display: inline-block;
  border: 2px solid var(--color-white); }

.eduvibe-status.status-04 {
  color: var(--color-white);
  font-size: 14px;
  font-weight: 600;
  height: 27px;
  line-height: 27px;
  padding: 0 10px;
  background: var(--color-secondary);
  border-radius: 2px;
  display: inline-block;
  text-transform: uppercase; }

.eduvibe-status.status-05 {
  color: var(--color-body);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: 0.4s; }
  .eduvibe-status.status-05 i {
    color: var(--color-primary);
    margin-right: 9px; }
  .eduvibe-status.status-05:hover {
    color: var(--color-primary); }

.eduvibe-status.status-06 {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 700;
  height: 36px;
  line-height: 36px;
  padding: 0 20px 0 15px;
  background: var(--color-primary);
  display: inline-block;
  border-radius: 0px 18px 18px 0px; }

.badge-default {
  border-radius: 3px;
  background: var(--color-primary);
  font-weight: 700;
  font-size: 16px;
  color: var(--color-white);
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  display: inline-block; }

/*--------------------------
    Pagination Styles  
----------------------------*/
.edu-pagination {
  margin: -5px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .edu-pagination li {
    margin: 5px;
    list-style: none; }
    .edu-pagination li a {
      width: 40px;
      height: 40px;
      background: var(--color-shade);
      display: inline-block;
      line-height: 40px;
      border-radius: 100%;
      text-align: center;
      color: var(--color-body);
      transition: 0.4s;
      font-size: 16px;
      font-weight: 500; }
      .edu-pagination li a i {
        font-size: 22px;
        font-weight: 500; }
    .edu-pagination li button {
      width: 40px;
      height: 40px;
      background: var(--color-shade);
      display: inline-block;
      line-height: 40px;
      border-radius: 100%;
      text-align: center;
      color: var(--color-body);
      transition: 0.4s;
      font-size: 16px;
      font-weight: 500;
      border: 0;
      border-color: transparent; }
      .edu-pagination li button i {
        font-size: 22px;
        font-weight: 500; }
    .edu-pagination li.active a,
    .edu-pagination li.active button, .edu-pagination li:hover a,
    .edu-pagination li:hover button {
      background: var(--color-primary);
      color: var(--color-white); }

/*-------------------------
    Event Grid Style  
--------------------------*/
.edu-event .top-position {
  position: absolute;
  z-index: 2; }
  .edu-event .top-position.left-top {
    left: 0;
    top: 25px; }

.edu-event .event-meta {
  display: flex;
  align-items: center; }
  .edu-event .event-meta li {
    font-weight: 500;
    font-size: 16px; }
    @media only screen and (max-width: 767px) {
      .edu-event .event-meta li {
        font-size: 14px; } }
    .edu-event .event-meta li i {
      padding-right: 8px;
      color: var(--color-primary); }

.edu-event.event-grid-1 .inner {
  background: var(--color-white);
  transition: 0.4s; }
  .edu-event.event-grid-1 .inner .thumbnail {
    position: relative;
    overflow: hidden; }
    .edu-event.event-grid-1 .inner .thumbnail a {
      display: block; }
      .edu-event.event-grid-1 .inner .thumbnail a img {
        width: 100%;
        transition: 0.4s;
        overflow: hidden;
        border-radius: 5px 5px 0 0; }
  .edu-event.event-grid-1 .inner .content {
    padding: 30px; }
    @media only screen and (max-width: 767px) {
      .edu-event.event-grid-1 .inner .content {
        padding: 20px; } }
    .edu-event.event-grid-1 .inner .content .title {
      margin-top: 18px;
      margin-bottom: 18px; }
      @media only screen and (max-width: 767px) {
        .edu-event.event-grid-1 .inner .content .title {
          margin-top: 0px;
          margin-bottom: 10px; } }
  .edu-event.event-grid-1 .inner:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
    border-radius: 5px; }

.edu-event.event-grid-1.bg-shade .inner {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07); }

.edu-event.event-list .inner {
  display: flex;
  padding: 20px 40px 20px 20px;
  background: var(--color-shade);
  transition: 0.4s;
  border-radius: 5px;
  flex-wrap: wrap; }
  @media only screen and (max-width: 1199px) {
    .edu-event.event-list .inner {
      padding: 20px; } }
  .edu-event.event-list .inner .thumbnail {
    position: relative;
    overflow: hidden;
    flex-basis: 13%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .edu-event.event-list .inner .thumbnail {
        flex-basis: 17%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .edu-event.event-list .inner .thumbnail {
        flex-basis: 25%; } }
    @media only screen and (max-width: 767px) {
      .edu-event.event-list .inner .thumbnail {
        flex-basis: 32%; } }
    @media only screen and (max-width: 479px) {
      .edu-event.event-list .inner .thumbnail {
        flex-basis: 100%; } }
    .edu-event.event-list .inner .thumbnail a {
      display: block; }
      .edu-event.event-list .inner .thumbnail a img {
        width: 100%;
        transition: 0.4s;
        overflow: hidden;
        border-radius: 3px;
        min-width: 140px;
        min-height: auto;
        object-fit: cover; }
  .edu-event.event-list .inner .content {
    flex-basis: 87%;
    padding-left: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .edu-event.event-list .inner .content {
        display: block;
        flex-basis: 83%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .edu-event.event-list .inner .content {
        display: block;
        flex-basis: 75%; } }
    @media only screen and (max-width: 767px) {
      .edu-event.event-list .inner .content {
        display: block;
        flex-basis: 68%;
        padding-left: 20px; } }
    @media only screen and (max-width: 479px) {
      .edu-event.event-list .inner .content {
        flex-basis: 100%;
        padding-left: 0;
        margin-top: 20px; } }
    .edu-event.event-list .inner .content .title {
      margin-bottom: 15px; }
      @media only screen and (max-width: 767px) {
        .edu-event.event-list .inner .content .title {
          font-size: 18px; } }
    .edu-event.event-list .inner .content .event-meta {
      margin: 0 -18px;
      flex-wrap: wrap; }
      .edu-event.event-list .inner .content .event-meta li {
        margin: 0 18px;
        display: flex;
        align-items: center; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .edu-event.event-list .inner .content .event-meta li {
            margin: 0 5px;
            font-size: 14px; } }
        .edu-event.event-list .inner .content .event-meta li i {
          color: var(--color-body);
          font-size: 18px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .edu-event.event-list .inner .read-more-btn {
      margin-top: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-event.event-list .inner .read-more-btn {
      margin-top: 20px; } }
  @media only screen and (max-width: 767px) {
    .edu-event.event-list .inner .read-more-btn {
      margin-top: 25px; } }
  @media only screen and (max-width: 767px) {
    .edu-event.event-list .inner .read-more-btn a.edu-btn {
      padding: 0 25px;
      height: 50px;
      line-height: 50px;
      font-size: 16px; } }
  .edu-event.event-list .inner:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
    background: var(--color-white); }

.edu-event.event-list.bg-white .inner {
  background: #FFFFFF;
  transition: 0.4s; }

.edu-event.event-list.bg-white:hover .inner {
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.05);
  background: var(--color-white); }

/*-------------------------
    Team Styles  
--------------------------*/
.team-area-shape-position {
  position: relative; }
  .team-area-shape-position .shape-wrapper .shape {
    position: absolute; }
    .team-area-shape-position .shape-wrapper .shape.shape-1 {
      bottom: 86px;
      right: 5%; }
    .team-area-shape-position .shape-wrapper .shape.shape-2 {
      top: 130px;
      left: 136px; }
    .team-area-shape-position .shape-wrapper .shape.shape-3 {
      top: 148px;
      right: 88px; }
    .team-area-shape-position .shape-wrapper .shape.shape-4 {
      bottom: 115px;
      left: 127px; }

.team-share-info {
  margin: -3px; }
  .team-share-info a {
    width: 34px;
    height: 34px;
    background: var(--color-white);
    line-height: 38px;
    text-align: center;
    border-radius: 100%;
    font-size: 14px;
    color: var(--color-primary);
    margin: 3px;
    transition: 0.4s; }
    .team-share-info a:hover {
      background: var(--color-primary);
      color: var(--color-white); }

.edu-instructor-1 {
  text-align: center; }
  .edu-instructor-1 .edu-instructor {
    border-radius: 100%;
    position: relative;
    z-index: 2;
    transition: 0.3s; }
    .edu-instructor-1 .edu-instructor::after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 100%;
      transition: 0.5s all; }
    .edu-instructor-1 .edu-instructor .inner {
      position: relative;
      padding: 20px; }
      .edu-instructor-1 .edu-instructor .inner .thumbnail a {
        display: block;
        position: relative; }
        .edu-instructor-1 .edu-instructor .inner .thumbnail a::after {
          background: var(--color-dark);
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          opacity: 0;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .edu-instructor-1 .edu-instructor .inner .thumbnail a img {
          width: 100%;
          border-radius: 100%; }
      .edu-instructor-1 .edu-instructor .inner .team-share-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2; }
        .edu-instructor-1 .edu-instructor .inner .team-share-info a {
          display: inline-block;
          opacity: 0;
          visibility: hidden;
          transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
          transform: translateY(8px) scale(0.8); }
          .edu-instructor-1 .edu-instructor .inner .team-share-info a:nth-child(1) {
            transition-delay: .07692s; }
          .edu-instructor-1 .edu-instructor .inner .team-share-info a:nth-child(2) {
            transition-delay: .15385s; }
          .edu-instructor-1 .edu-instructor .inner .team-share-info a:nth-child(3) {
            transition-delay: .23077s; }
  .edu-instructor-1 .edu-instructor-info {
    margin-top: 25px; }
    .edu-instructor-1 .edu-instructor-info .title {
      margin-bottom: 5px; }
    .edu-instructor-1 .edu-instructor-info .desc {
      display: inline-block;
      color: var(--color-body);
      font-weight: 500;
      font-size: 16px;
      transition: 0.4s; }
  .edu-instructor-1:hover .edu-instructor::after {
    transform: rotate(90deg); }
  .edu-instructor-1:hover .edu-instructor .team-share-info a {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .edu-instructor-1:hover .edu-instructor .inner .thumbnail a::after {
    opacity: 0.4; }
  .edu-instructor-1:hover .edu-instructor-info .desc {
    color: var(--color-primary); }

.edu-instructor-2 {
  text-align: center; }
  .edu-instructor-2 .edu-instructor {
    border-radius: 100%;
    position: relative;
    z-index: 2;
    transition: 0.3s; }
    .edu-instructor-2 .edu-instructor .inner {
      position: relative; }
      .edu-instructor-2 .edu-instructor .inner .thumbnail a {
        display: block;
        position: relative; }
        .edu-instructor-2 .edu-instructor .inner .thumbnail a::after {
          background: linear-gradient(180deg, rgba(45, 40, 78, 0) 0.01%, rgba(45, 40, 78, 0) 15.58%, rgba(45, 40, 78, 0.75) 100%);
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          opacity: 0;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          border-radius: 5px;
          border-radius: 185px 185px 5px 5px; }
        .edu-instructor-2 .edu-instructor .inner .thumbnail a img {
          width: 100%;
          border-radius: 185px 185px 5px 5px;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .edu-instructor-2 .edu-instructor .inner .team-share-info {
        position: absolute;
        z-index: 2;
        bottom: 40px;
        width: 100%;
        text-align: center; }
        .edu-instructor-2 .edu-instructor .inner .team-share-info a {
          width: 40px;
          height: 40px;
          line-height: 43px;
          display: inline-block;
          opacity: 0;
          visibility: hidden;
          transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
          transform: translateY(8px) scale(0.8); }
          .edu-instructor-2 .edu-instructor .inner .team-share-info a:nth-child(1) {
            transition-delay: .07692s; }
          .edu-instructor-2 .edu-instructor .inner .team-share-info a:nth-child(2) {
            transition-delay: .15385s; }
          .edu-instructor-2 .edu-instructor .inner .team-share-info a:nth-child(3) {
            transition-delay: .23077s; }
          .edu-instructor-2 .edu-instructor .inner .team-share-info a:nth-child(4) {
            transition-delay: .31077s; }
  .edu-instructor-2 .edu-instructor-info {
    margin-top: 25px; }
    .edu-instructor-2 .edu-instructor-info .title {
      margin-bottom: 5px; }
    .edu-instructor-2 .edu-instructor-info .desc {
      display: inline-block;
      color: var(--color-body);
      font-weight: 500;
      font-size: 16px;
      transition: 0.4s; }
  .edu-instructor-2:hover .edu-instructor .team-share-info a {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .edu-instructor-2:hover .edu-instructor .inner .thumbnail a::after {
    border-radius: 5px;
    opacity: 1; }
  .edu-instructor-2:hover .edu-instructor .inner .thumbnail a img {
    border-radius: 5px; }
  .edu-instructor-2:hover .edu-instructor-info .desc {
    color: var(--color-primary); }

.edu-instructor-3 {
  text-align: center; }
  .edu-instructor-3 .edu-instructor {
    border-radius: 100%;
    position: relative;
    z-index: 2;
    transition: 0.3s; }
    .edu-instructor-3 .edu-instructor .inner {
      position: relative; }
      .edu-instructor-3 .edu-instructor .inner .thumbnail a {
        display: block;
        position: relative; }
        .edu-instructor-3 .edu-instructor .inner .thumbnail a img {
          width: 100%;
          border-radius: 5px; }
      .edu-instructor-3 .edu-instructor .inner .edu-instructor-info {
        position: absolute;
        z-index: 2;
        bottom: -20px;
        width: 100%;
        text-align: left;
        background: #fff;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        padding: 25px 30px;
        opacity: 0;
        transition: 0.4s;
        left: 40px; }
        @media only screen and (max-width: 767px) {
          .edu-instructor-3 .edu-instructor .inner .edu-instructor-info {
            width: calc(100% - 30px); } }
        .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .title {
          margin-bottom: 0;
          font-size: 20px;
          line-height: 32px;
          font-weight: 700; }
        .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .desc {
          display: inline-block;
          color: var(--color-body);
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          transition: 0.4s;
          margin-bottom: 15px; }
        .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .team-share-info a {
          width: 40px;
          height: 40px;
          line-height: 43px;
          display: inline-block;
          transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99); }
          .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .team-share-info a:nth-child(1) {
            transition-delay: .07692s; }
          .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .team-share-info a:nth-child(2) {
            transition-delay: .15385s; }
          .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .team-share-info a:nth-child(3) {
            transition-delay: .23077s; }
          .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .team-share-info a:nth-child(4) {
            transition-delay: .31077s; }
        .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .team-share-info.bg-transparent {
          margin: -12px; }
          .edu-instructor-3 .edu-instructor .inner .edu-instructor-info .team-share-info.bg-transparent a {
            background: transparent;
            color: var(--color-primary);
            width: auto;
            height: auto;
            line-height: inherit;
            margin: 12px; }
  .edu-instructor-3.eduvibe-hover-active .edu-instructor .inner .edu-instructor-info {
    opacity: 1;
    left: 20px; }
    @media only screen and (max-width: 767px) {
      .edu-instructor-3.eduvibe-hover-active .edu-instructor .inner .edu-instructor-info {
        left: 0; } }
  .edu-instructor-3.edu-instructor-hover-visible:hover .edu-instructor-info {
    opacity: 1;
    left: 20px; }
    @media only screen and (max-width: 767px) {
      .edu-instructor-3.edu-instructor-hover-visible:hover .edu-instructor-info {
        left: 0; } }

/*------------------------
    Service Style  
--------------------------*/
.service-wrapper-1 {
  position: relative; }
  .service-wrapper-1 .shape-image {
    position: absolute;
    z-index: -1; }
    .service-wrapper-1 .shape-image.shape-image-1 {
      top: -28px;
      left: -206px; }
    .service-wrapper-1 .shape-image.shape-image-2 {
      top: -51px;
      right: -257px; }
    .service-wrapper-1 .shape-image.shape-image-3 {
      bottom: 80px;
      right: -37px; }
    .service-wrapper-1 .shape-image.shape-image-4 {
      bottom: -25px;
      left: -201px; }

 

.service-card-1 {
  background: var(--color-white);
  overflow: hidden;
  text-align: center;
  position: relative;
  transition: 0.4s; }
  .service-card-1::after {
    position: absolute;
    content: "";
    left: 0;
    width: 0;
    height: 2px;
    background: var(--color-primary);
    transition: 0.4s;
    bottom: 0; }
  .service-card-1 .inner .thumbnail a img {
    width: 100%; }
  .service-card-1 .inner .content {
    padding: 30px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .service-card-1 .inner .content {
        padding: 25px 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .service-card-1 .inner .content {
        padding: 25px 20px; } }
    @media only screen and (max-width: 767px) {
      .service-card-1 .inner .content {
        padding: 25px 20px; } }
    .service-card-1 .inner .content .course-total {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      display: inline-block;
      margin-bottom: 5px;
      color: var(--color-primary); }
    .service-card-1 .inner .content .title {
      margin-bottom: 20px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .service-card-1 .inner .content .title {
          margin-bottom: 6px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .service-card-1 .inner .content .title {
          margin-bottom: 6px; } }
      @media only screen and (max-width: 767px) {
        .service-card-1 .inner .content .title {
          margin-bottom: 6px; } }
    .service-card-1 .inner .content .description {
      margin-bottom: 0; }
  .service-card-1:hover {
    transform: translateY(-15px); }
    .service-card-1:hover::after {
      width: 100%; }

/*----------------------
    Service Style Two  
-----------------------*/
.service-card-2 {
  padding: 40px 30px;
  background: #EAF8F6;
  border-radius: 5px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-card-2 {
      padding: 40px 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-card-2 {
      padding: 40px 20px; } }
  @media only screen and (max-width: 767px) {
    .service-card-2 {
      padding: 40px 20px; } }
  .service-card-2 .inner {
    text-align: center; }
    .service-card-2 .inner .icon {
      position: relative;
      display: inline-block; }
      .service-card-2 .inner .icon a {
        width: 80px;
        height: 80px;
        background: var(--color-white);
        line-height: 80px;
        text-align: center;
        border-radius: 100%;
        margin: 0 auto;
        display: block; }
      .service-card-2 .inner .icon .shape-list .shape {
        position: absolute;
        z-index: 2; }
        .service-card-2 .inner .icon .shape-list .shape.shape-1 {
          bottom: 0;
          right: 0;
          transition: 0.4s;
          opacity: 0; }
        .service-card-2 .inner .icon .shape-list .shape.shape-2 {
          left: 0;
          top: 50%;
          transition: 0.4s;
          opacity: 0; }
        .service-card-2 .inner .icon .shape-list .shape.shape-3 {
          top: 0;
          right: 0;
          transition: 0.4s;
          opacity: 0; }
    .service-card-2 .inner .content .title {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 15px;
      margin-top: 25px; }
    .service-card-2 .inner .content .description {
      margin-bottom: 0; }
  .service-card-2.card-bg-2 {
    background: #FFF3EE; }
  .service-card-2.card-bg-3 {
    background: #EFF4FC; }
  .service-card-2.card-bg-4 {
    background: #FFF1F1; }
  .service-card-2:hover .inner .icon .shape-list .shape.shape-1 {
    bottom: -8px;
    right: 3px;
    opacity: 1; }
  .service-card-2:hover .inner .icon .shape-list .shape.shape-2 {
    left: -20px;
    top: 50%;
    opacity: 1; }
  .service-card-2:hover .inner .icon .shape-list .shape.shape-3 {
    top: -5px;
    right: -1px;
    opacity: 1; }

/*----------------------
    Service Style Three  
-----------------------*/
.video-section-overlayto-another {
  position: relative;
  margin-bottom: -281px;
  z-index: 1; }

.service-wrapper-3 {
  position: relative;
  z-index: 1;
  padding-bottom: 0px; }
  .service-wrapper-3 .about-us-two-service-wrapper {
    background-image: url(../images/bg/about-us-two-service.webp); 
  padding: 60px 0;}

.service-card-3 {
  padding: 30px 30px;
  background: var(--color-white);
  border-radius: 5px;
  transition: 0.4s;
  position: relative;
  z-index: 2; }
  .service-card-3 .inner {
    text-align: center; }
    .service-card-3 .inner .icon {
      position: relative;
      display: inline-block;
      margin-bottom: 25px; }
      .service-card-3 .inner .icon a {
        width: 70px;
        height: 70px;
        background: var(--color-primary);
        text-align: center;
        margin: 0 auto;
        border-radius: 35px 35px 3px 3px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .service-card-3 .inner .icon a i {
          font-size: 32px;
          color: var(--color-white); }
    .service-card-3 .inner .content .title {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 15px; }
    .service-card-3 .inner .content .description {
      margin-bottom: 20px; }
  .service-card-3.shape-bg-1 .inner .icon a {
    background: rgba(113, 82, 233, 0.15); }
    .service-card-3.shape-bg-1 .inner .icon a i {
      color: var(--color-tertiary-2); }
  .service-card-3.shape-bg-1:hover .inner .icon a {
    background: var(--color-tertiary-2); }
  .service-card-3.shape-bg-2 .inner .icon a {
    background: rgba(255, 164, 27, 0.15); }
    .service-card-3.shape-bg-2 .inner .icon a i {
      color: var(--color-tertiary); }
  .service-card-3.shape-bg-2:hover .inner .icon a {
    background: var(--color-tertiary); }
  .service-card-3.shape-bg-3 .inner .icon a {
    background: rgba(82, 95, 225, 0.15); }
    .service-card-3.shape-bg-3 .inner .icon a i {
      color: var(--color-primary); }
  .service-card-3.shape-bg-3:hover .inner .icon a {
    background: var(--color-primary); }
  .service-card-3.shape-bg-4 .inner .icon a {
    background: rgba(248, 111, 3, 0.15); }
    .service-card-3.shape-bg-4 .inner .icon a i {
      color: var(--color-secondary); }
  .service-card-3.shape-bg-4:hover .inner .icon a {
    background: var(--color-secondary); }
  .service-card-3.text-left .inner {
    text-align: left; }
  .service-card-3.bg-grey {
    background: #F5F5F5;
    height: 100%; }
    .service-card-3.bg-grey:hover {
      background: var(--color-white);
      box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.07); }
  .service-card-3:hover {
    transform: translateY(-20px);
    box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.07); }
    .service-card-3:hover .inner .icon a i {
      color: #ffffff; }

/*-------------------------------
    Service Style Four  
---------------------------------*/
.service-card-4 {
  height: 100%; }
  .service-card-4 .inner {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 40px 35px;
    text-align: center;
    transition: 0.4s;
    height: 100%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .service-card-4 .inner {
        padding: 25px 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .service-card-4 .inner {
        padding: 25px 14px; } }
    @media only screen and (max-width: 767px) {
      .service-card-4 .inner {
        padding: 25px 14px; } }
    .service-card-4 .inner .icon {
      position: relative; }
      .service-card-4 .inner .icon i {
        display: block;
        color: var(--color-primary);
        font-size: 35px; }
      .service-card-4 .inner .icon .subtitle {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.4s; }
    .service-card-4 .inner .content .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 15px;
      margin-top: 25px; }
      .service-card-4 .inner .content .title a {
        transition: 0.4s;
        color: var(--color-heading); }
    .service-card-4 .inner .content .description {
      margin-bottom: 0;
      transition: 0.4s; }
  .service-card-4:hover .inner {
    background: var(--color-primary); }
    .service-card-4:hover .inner .icon i {
      opacity: 0; }
    .service-card-4:hover .inner .icon .subtitle {
      opacity: 1;
      color: var(--color-white); }
    .service-card-4:hover .inner .content .title a {
      color: var(--color-white); }
    .service-card-4:hover .inner .content .description {
      color: var(--color-white); }

/*-------------------------------
    Service Style Five  
---------------------------------*/
.service-card-5 .inner {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 20px;
  transition: 0.4s;
  display: flex;
  align-items: center; }
  .service-card-5 .inner .icon {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.02);
    border-radius: 2px;
    transition: 0.4s;
    justify-content: center;
    margin-right: 20px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .service-card-5 .inner .icon {
        margin-right: 15px; } }
    .service-card-5 .inner .icon i {
      color: var(--color-primary);
      font-size: 30px;
      transition: 0.4s; }
  .service-card-5 .inner .content .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
    transition: 0.4s; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .service-card-5 .inner .content .title {
        font-size: 17px;
        line-height: 28px; } }
  .service-card-5 .inner .content .description {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    transition: 0.4s; }

.service-card-5:hover .inner {
  background: var(--color-primary); }
  .service-card-5:hover .inner .icon {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.15); }
    .service-card-5:hover .inner .icon i {
      color: var(--color-white); }
  .service-card-5:hover .inner .content .title a {
    color: var(--color-white); }
  .service-card-5:hover .inner .content .description {
    color: var(--color-white); }

/*-------------------------------
    Service Style 8  
---------------------------------*/
.service-activation-item5 .single-slick-card {
  margin-bottom: 20px; }

.service-card-8 .inner {
  background: #EAF8F6;
  border-radius: 5px;
  text-align: center;
  padding: 30px;
  position: relative;
  transition: 0.4s;
  margin-bottom: 20px; }
  .service-card-8 .inner .icon {
    position: relative;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 100%;
    justify-content: center;
    margin: 0 auto; }
  .service-card-8 .inner .content {
    margin-top: 20px; }
    .service-card-8 .inner .content .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 5px; }
    .service-card-8 .inner .content span {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px; }
  .service-card-8 .inner .hover-action {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.4s;
    opacity: 0; }
    .service-card-8 .inner .hover-action .read-more-btn {
      width: 40px;
      height: 40px;
      background: var(--color-primary);
      color: var(--color-white);
      display: inline-block;
      line-height: 43px;
      border-radius: 100%;
      font-size: 20px; }

.service-card-8.shape-bg-2 .inner {
  background: #FFF3EE; }

.service-card-8.shape-bg-3 .inner {
  background: #FAEFFA; }

.service-card-8.shape-bg-4 .inner {
  background: #FFF1F1; }

.service-card-8.shape-bg-5 .inner {
  background: #EFF4FC; }

.service-card-8:hover .inner {
  padding-bottom: 50px;
  margin-bottom: -20px; }
  .service-card-8:hover .inner .hover-action {
    opacity: 1; }

/*-------------------------------
    Service Style 7  
---------------------------------*/
.service-card-single {
  position: relative; }
  .service-card-single:first-child::after {
    content: "";
    width: 155px;
    height: 43px;
    right: -19%;
    top: 40px;
    background-size: cover;
    background-position: center center;
    position: absolute; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .service-card-single:first-child::after {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .service-card-single:first-child::after {
        display: none; } }
  .service-card-single:last-child::after {
    content: "";
    width: 155px;
    height: 43px;
    left: -19%;
    top: 0;
    background-size: cover;
    background-position: center center;
    position: absolute; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .service-card-single:last-child::after {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .service-card-single:last-child::after {
        display: none; } }

.service-card-7 {
  position: relative; }
  .service-card-7 .arrow-icon {
    position: absolute;
    left: 100%;
    top: 40px;
    z-index: 1; }
  .service-card-7 .inner {
    text-align: center; }
    .service-card-7 .inner .icon {
      position: relative;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      background: var(--color-secondary);
      border-radius: 100%;
      justify-content: center;
      margin: 0 auto;
      color: var(--color-white);
      margin-bottom: 35px; }
      .service-card-7 .inner .icon i {
        color: var(--color-white);
        font-size: 32px;
        display: flex; }
    .service-card-7 .inner .content .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 15px; }
    .service-card-7 .inner .content p {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px; }
  .service-card-7.shape-bg-2 .inner .icon {
    background: var(--color-primary); }
  .service-card-7.shape-bg-3 .inner .icon {
    background: #FFA41B; }

/*-----------------------------
    Single Elements Styles  
------------------------------*/
.short-by p {
  margin: 0;
  font-weight: 500;
  font-size: 16px; }
  .short-by p span {
    font-weight: 600;
    color: var(--color-heading); }

.edu-search-box {
  position: relative;
  display: inline-block; }
  .edu-search-box input {
    height: 60px;
    background: var(--color-shade);
    font-weight: 600;
    font-size: 16px;
    color: var(--color-body);
    padding: 0 25px;
    min-width: 350px;
    border: 0 none;
    border-radius: 5px;
    padding-right: 48px; }
    @media only screen and (max-width: 479px) {
      .edu-search-box input {
        min-width: 100%; } }
  .edu-search-box .search-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    padding: 0;
    border: 0 none;
    background: transparent; }
    .edu-search-box .search-button i {
      font-size: 17px; }

.edu-shorting-style-1 {
  display: inline-block;
  position: relative; }
  .edu-shorting-style-1 .icon {
    display: flex;
    align-items: center;
    margin-right: 17px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px; }
    .edu-shorting-style-1 .icon i {
      font-size: 20px;
      color: var(--color-body); }
  .edu-shorting-style-1 .edu-select {
    transition: 0.3s;
    line-height: 28px;
    outline: none;
    color: var(--color-body);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0 none;
    border-radius: var(--radius);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding-right: 30px;
    border-radius: 5px;
    background-color: #F5F5F5;
    max-width: 256px;
    align-items: center;
    padding: 0 30px;
    height: 60px;
    cursor: pointer;
    min-width: 256px;
    cursor: pointer; }

/*----------------------------
    Course Sidebar Styles 
-----------------------------*/
.edu-course-widget {
  background: var(--color-shade);
  padding: 20px 30px 30px;
  border-radius: 5px; }
  .edu-course-widget .widget-title {
    border-bottom: 1px solid rgba(45, 40, 78, 0.07);
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px; }
  .edu-course-widget .content {
    margin-top: 30px; }
    .edu-course-widget .content .edu-form-check {
      position: relative; }
      .edu-course-widget .content .edu-form-check + .edu-form-check {
        margin-top: 25px; }
  .edu-course-widget.widget-category label {
    display: flex;
    justify-content: space-between; }

/*--------------------------
    Testimonial Area  
----------------------------*/
.satisfied-learner {
  padding-right: 70px;
  position: relative; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .satisfied-learner {
      padding-right: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .satisfied-learner {
      padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .satisfied-learner {
      padding-right: 0; } }
  .satisfied-learner .thumbnail img {
    width: 100%;
    border-radius: 5px; }
  .satisfied-learner .trophy-content {
    width: 235px;
    background: var(--color-white);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
    display: flex;
    border-radius: 5px;
    padding: 20px 25px;
    align-items: center;
    position: absolute;
    top: 97px;
    left: -164px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .satisfied-learner .trophy-content {
        top: auto;
        left: 10px;
        bottom: 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .satisfied-learner .trophy-content {
        top: auto;
        left: 10px;
        bottom: 10px; } }
    @media only screen and (max-width: 767px) {
      .satisfied-learner .trophy-content {
        top: auto;
        left: 10px;
        bottom: 10px; } }
    .satisfied-learner .trophy-content .icon {
      min-width: 49px;
      min-height: 49px;
      object-fit: cover;
      margin-right: 20px; }
    .satisfied-learner .trophy-content .content .text {
      font-weight: 700;
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 0; }
    .satisfied-learner .trophy-content .content span {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px; }

.testimonial-style-1 {
  margin-top: 25px; }
  @media only screen and (max-width: 1199px) {
    .testimonial-style-1 {
      margin-top: 0px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-style-1.edu-slick-button.slick-button-left .slick-dots {
      bottom: -10px; } }
  .testimonial-style-1 .single-testimonial .inner .quote-sign {
    margin-bottom: 50px;
    display: inline-block; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .testimonial-style-1 .single-testimonial .inner .quote-sign {
        margin-bottom: 20px; } }
    @media only screen and (max-width: 991px) {
      .testimonial-style-1 .single-testimonial .inner .quote-sign {
        margin-bottom: 20px;
        margin-top: 20px; } }
  .testimonial-style-1 .single-testimonial .inner .description {
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5; }
    @media only screen and (max-width: 991px) {
      .testimonial-style-1 .single-testimonial .inner .description {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .testimonial-style-1 .single-testimonial .inner .description {
        margin-bottom: 30px; } }
  .testimonial-style-1 .single-testimonial .inner .client-information {
    display: flex;
    align-items: center; }
    .testimonial-style-1 .single-testimonial .inner .client-information .thumb {
      margin-right: 20px;
      min-width: 80px; }
      .testimonial-style-1 .single-testimonial .inner .client-information .thumb img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        object-fit: cover; }
    .testimonial-style-1 .single-testimonial .inner .client-information .info .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 5px; }
    .testimonial-style-1 .single-testimonial .inner .client-information .info .designation {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-primary); }

/*-----------------------------
    Testimonial Card Layout  
------------------------------*/
.testimonial-card-box {
  padding: 15px; }
  .testimonial-card-box .inner {
    position: relative;
    background: var(--color-white);
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 40px; }
    .testimonial-card-box .inner::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 33px 0 0 27px;
      border-color: transparent transparent transparent var(--color-white);
      top: calc(99% - 1px);
      transform: rotate(90deg);
      z-index: 2;
      border-bottom-right-radius: 4px; }
    .testimonial-card-box .inner .description {
      margin-bottom: 22px; }
    .testimonial-card-box .inner .client-info {
      display: flex;
      align-items: center;
      margin-bottom: 22px; }
      .testimonial-card-box .inner .client-info .thumbnail {
        margin-right: 15px; }
        .testimonial-card-box .inner .client-info .thumbnail img {
          max-height: 55px;
          height: 55px;
          object-fit: cover;
          border-radius: 3px; }
      .testimonial-card-box .inner .client-info .content .title {
        margin-bottom: 1px;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px; }
        @media only screen and (max-width: 767px) {
          .testimonial-card-box .inner .client-info .content .title {
            font-size: 18px;
            line-height: 29px; } }
      .testimonial-card-box .inner .client-info .content .designation {
        color: var(--color-primary);
        font-weight: 500; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-card-box.variation-2 {
      padding: 0; } }
  @media only screen and (max-width: 767px) {
    .testimonial-card-box.variation-2 {
      padding: 0; } }
  .testimonial-card-box.variation-2 .inner {
    padding: 50px;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .testimonial-card-box.variation-2 .inner {
        padding: 50px 30px; } }
    .testimonial-card-box.variation-2 .inner::after {
      display: none; }
    .testimonial-card-box.variation-2 .inner .rating {
      margin-bottom: 40px; }
      @media only screen and (max-width: 767px) {
        .testimonial-card-box.variation-2 .inner .rating {
          margin-bottom: 20px; } }
    .testimonial-card-box.variation-2 .inner .description {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px; }
      @media only screen and (max-width: 767px) {
        .testimonial-card-box.variation-2 .inner .description {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 28px; } }
    .testimonial-card-box.variation-2 .inner .client-info {
      margin-bottom: 0; }
      @media only screen and (max-width: 479px) {
        .testimonial-card-box.variation-2 .inner .client-info {
          display: block; } }
      .testimonial-card-box.variation-2 .inner .client-info .thumbnail {
        margin-right: 20px; }
        @media only screen and (max-width: 479px) {
          .testimonial-card-box.variation-2 .inner .client-info .thumbnail {
            margin-right: 0;
            margin-bottom: 20px; } }
        .testimonial-card-box.variation-2 .inner .client-info .thumbnail img {
          max-height: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 100%; }
  .testimonial-card-box.variation-3 {
    padding: 0; }
    .testimonial-card-box.variation-3 .description {
      margin-bottom: 64px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 400; }
      @media only screen and (max-width: 767px) {
        .testimonial-card-box.variation-3 .description {
          margin-bottom: 30px;
          font-size: 18px;
          line-height: 28px; } }
    .testimonial-card-box.variation-3 .client-info {
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .testimonial-card-box.variation-3 .client-info .thumbnail {
        margin-right: 15px; }
        .testimonial-card-box.variation-3 .client-info .thumbnail img {
          max-height: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 100%; }
      .testimonial-card-box.variation-3 .client-info .content .title {
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px; }
      .testimonial-card-box.variation-3 .client-info .content .designation {
        color: var(--color-primary);
        font-weight: 500; }

.testimonial-style-5 {
  position: relative; }
  .testimonial-style-5::before {
    position: absolute;
    content: "";
    background: var(--color-primary);
    width: 610px;
    top: 0;
    height: 100%;
    left: 0; }
    @media only screen and (min-width: 1401px) and (max-width: 1750px) {
      .testimonial-style-5::before {
        width: 29%; } }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .testimonial-style-5::before {
        width: 27%; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .testimonial-style-5::before {
        width: 29%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .testimonial-style-5::before {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .testimonial-style-5::before {
        display: none; } }
  .testimonial-style-5 .banner-thumbnail {
    display: inline-block;
    position: relative;
    z-index: 1; }
    .testimonial-style-5 .banner-thumbnail::after {
      border: 5px solid rgba(251, 124, 86, 0.5);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 20px;
      border-radius: 250px;
      z-index: -1; }
      @media only screen and (max-width: 767px) {
        .testimonial-style-5 .banner-thumbnail::after {
          display: none; } }
    .testimonial-style-5 .banner-thumbnail img {
      border-radius: 250px; }
  .testimonial-style-5 .content-wrapper {
    position: relative;
    z-index: 1; }
    .testimonial-style-5 .content-wrapper .comment-images {
      position: absolute;
      right: 0;
      z-index: -1;
      bottom: -8%; }
  .testimonial-style-5 .main-image {
    position: absolute;
    left: 0;
    top: 0; }

/*--------------------------
    Testimonial Style Two  
----------------------------*/
.testimonial-nav-content .description {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 30px; }

.testimonial-nav-content .client-info {
  margin-bottom: 40px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-nav-content .client-info {
      margin-bottom: 10px; } }
  @media only screen and (max-width: 767px) {
    .testimonial-nav-content .client-info {
      margin-bottom: 10px; } }
  .testimonial-nav-content .client-info .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0; }
  .testimonial-nav-content .client-info .designation {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-primary); }

.testimonial-nav-wrapper {
  max-width: 264px; }

.testimonial-nav-button .single-thumbnail {
  padding: 5px;
  border: 5px solid transparent;
  border-radius: 100%;
  transition: 0.4s;
  position: relative; }
  .testimonial-nav-button .single-thumbnail img {
    max-width: 68px;
    width: 68px;
    display: inline-block;
    height: 68px;
    border: 8px solid #fff;
    border-radius: 100%;
    cursor: pointer; }

.testimonial-nav-button .slick-current.slick-active .single-thumbnail .loader-container {
  opacity: 1; }

.loader-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1; }
  .loader-container .circle-loader-wrap {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(82, 95, 225, 0.2);
    border-radius: 100%;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg); }
    .loader-container .circle-loader-wrap:after {
      content: '';
      position: absolute;
      left: 15px;
      top: 15px;
      width: auto;
      height: auto;
      border-radius: 50%;
      background-color: white; }
    .loader-container .circle-loader-wrap div {
      overflow: hidden;
      position: absolute;
      width: 50%;
      height: 100%; }
    .loader-container .circle-loader-wrap .loader {
      position: absolute;
      left: 100%;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 1000px;
      background-color: var(--color-primary); }
    .loader-container .circle-loader-wrap .left-wrap {
      left: 0; }
      .loader-container .circle-loader-wrap .left-wrap .loader {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transform-origin: 0 50% 0;
        -webkit-transform-origin: 0 50% 0;
        animation: loading-left 4s infinite linear;
        -webkit-animation: loading-left 4s infinite linear; }
    .loader-container .circle-loader-wrap .right-wrap {
      left: 50%; }
      .loader-container .circle-loader-wrap .right-wrap .loader {
        left: -100%;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        transform-origin: 100% 50% 0;
        -webkit-transform-origin: 100% 50% 0;
        animation: loading-right 4s infinite linear;
        -webkit-animation: loading-right 4s infinite linear; }

@keyframes loading-left {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(180deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(180deg); } }

@-webkit-keyframes loading-left {
  0% {
    -webkit-transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(180deg); }
  50% {
    -webkit-transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(180deg); } }

@keyframes loading-right {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(180deg); } }

@-webkit-keyframes loading-right {
  0% {
    -webkit-transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(180deg); } }

/*---------------------
 Feature Styles  
-------------------------*/
.feature-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -15px; }
  .feature-list-wrapper .feature-list {
    flex-basis: 50%;
    padding: 15px; }
    @media only screen and (max-width: 575px) {
      .feature-list-wrapper .feature-list {
        flex-basis: 100%; } }

.feature-list .icon {
  margin-bottom: 25px; }
  .feature-list .icon i {
    font-size: 50px;
    color: var(--color-secondary); }

.feature-list .content .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px; }

.feature-list .content p {
  margin-bottom: 0; }

.feature-thumbnail {
  position: relative;
  z-index: 2;
  right: -60px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .feature-thumbnail {
      right: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature-thumbnail {
      right: 0; } }
  @media only screen and (max-width: 767px) {
    .feature-thumbnail {
      right: 0; } }
  .feature-thumbnail .circle-image {
    position: absolute;
    top: -11px;
    right: 7px;
    z-index: -1;
    width: 97%; }
    @media only screen and (max-width: 767px) {
      .feature-thumbnail .circle-image {
        display: none; } }

.radius-round {
  border-radius: 100%; }

/*----------------------
 Feature Style Two  
-------------------------*/
.feature-style-2 {
  display: flex;
  flex-wrap: wrap; }
  .feature-style-2 .single-feature {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    margin-top: 25px;
    flex-wrap: wrap; }
    .feature-style-2 .single-feature .icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background: rgba(251, 124, 86, 0.15);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px; }
      .feature-style-2 .single-feature .icon i {
        color: var(--color-secondary); }
    .feature-style-2 .single-feature .content .feature-title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      margin-bottom: 0; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .feature-style-2 .single-feature .content .feature-title {
          font-size: 16px; } }

/*----------------------
    Feature Style Three  
-------------------------*/
.feature-style-3 .feature-content .feature-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: var(--color-secondary);
  margin-bottom: 5px; }

.feature-style-3 .feature-content .feature-description {
  margin-bottom: 0; }

/*----------------------
    Feature Style Four  
-------------------------*/
.feature-style-4 .edu-feature-list {
  display: flex; }
  .feature-style-4 .edu-feature-list .icon {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: rgba(251, 124, 86, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px; }
    .feature-style-4 .edu-feature-list .icon i {
      color: var(--color-secondary);
      font-size: 26px; }
  .feature-style-4 .edu-feature-list .content .title {
    margin-bottom: 5px; }
  .feature-style-4 .edu-feature-list .content p {
    margin-bottom: 30px; }
  .feature-style-4 .edu-feature-list.color-var-2 .icon {
    background: rgba(82, 95, 225, 0.1); }
    .feature-style-4 .edu-feature-list.color-var-2 .icon i {
      color: var(--color-primary); }

/*----------------------
    Feature Style 5  
-------------------------*/
.feature-style-5 .edu-feature-list {
  display: flex; }
  .feature-style-5 .edu-feature-list .icon {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px; }
    .feature-style-5 .edu-feature-list .icon i {
      color: var(--color-white);
      font-size: 30px; }
  .feature-style-5 .edu-feature-list .content .title {
    margin-bottom: 5px; }
  .feature-style-5 .edu-feature-list .content p {
    margin-bottom: 0; }
  .feature-style-5 .edu-feature-list.color-var-2 .icon {
    background: #FFA41B; }
    .feature-style-5 .edu-feature-list.color-var-2 .icon i {
      color: var(--color-white); }

/*----------------------
    Feature Style 6  
-------------------------*/
.feature-style-6 .edu-feature-list {
  display: flex; }
  .feature-style-6 .edu-feature-list .icon {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: rgba(251, 124, 86, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px; }
    .feature-style-6 .edu-feature-list .icon i {
      color: var(--color-secondary);
      font-size: 30px; }
  .feature-style-6 .edu-feature-list .content .title {
    margin-bottom: 5px; }
  .feature-style-6 .edu-feature-list .content p {
    margin-bottom: 30px; }
  .feature-style-6 .edu-feature-list.color-var-2 .icon {
    background: rgba(82, 95, 225, 0.1); }
    .feature-style-6 .edu-feature-list.color-var-2 .icon i {
      color: var(--color-primary); }
  .feature-style-6 .edu-feature-list.color-variation-1 .icon {
    background: rgba(255, 164, 27, 0.1); }
  .feature-style-6 .edu-feature-list.color-variation-2 .icon {
    background: rgba(82, 95, 225, 0.1); }
  .feature-style-6 .edu-feature-list.color-variation-3 .icon {
    background: rgba(251, 124, 86, 0.1); }

/*------------------------
    About Styles  
-------------------------*/
.about-feature-list .our-feature {
  display: flex; }
  .about-feature-list .our-feature .icon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    background: var(--color-secondary);
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 15px; }
    .about-feature-list .our-feature .icon i {
      color: var(--color-white);
      font-size: 24px; }
  .about-feature-list .our-feature .feature-content .feature-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 5px; }
  .about-feature-list .our-feature .feature-content .feature-description {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0; }
  .about-feature-list .our-feature + .our-feature {
    margin-top: 30px; }

/*----------------------
    About Style One  
------------------------*/
.about-style-1 {
  position: relative;
  z-index: 1; }
  .about-style-1 .about-image-gallery {
    margin-right: 100px;
    position: relative; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about-style-1 .about-image-gallery {
        margin-right: 60px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-style-1 .about-image-gallery {
        margin-right: 60px; } }
    @media only screen and (max-width: 767px) {
      .about-style-1 .about-image-gallery {
        margin-right: 0; } }
    .about-style-1 .about-image-gallery .image-1 {
      border-radius: 5px;
      width: 100%; }
    .about-style-1 .about-image-gallery .image-2 {
      position: absolute;
      bottom: -61px;
      right: -50px;
      border-radius: 5.2px; }
      @media only screen and (max-width: 767px) {
        .about-style-1 .about-image-gallery .image-2 {
          position: absolute;
          right: 0;
          border-radius: 5px;
          margin-top: 30px;
          transform: none !important;
          object-fit: cover; } }
      @media only screen and (max-width: 575px) {
        .about-style-1 .about-image-gallery .image-2 {
          display: none; } }
      .about-style-1 .about-image-gallery .image-2 img {
        border-radius: 5px; }
    .about-style-1 .about-image-gallery .badge-inner {
      position: absolute;
      top: 42px;
      left: -75px;
      width: 150px;
      height: 150px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(8px);
      border-radius: 100%; }
      @media only screen and (max-width: 575px) {
        .about-style-1 .about-image-gallery .badge-inner {
          display: none; } }
      .about-style-1 .about-image-gallery .badge-inner .image-3 {
        animation: rotateIt 7s linear infinite;
        border-radius: 100%; }
    .about-style-1 .about-image-gallery .shape-image-1 {
      position: absolute;
      bottom: -35px;
      left: -35px;
      z-index: -1; }
  .about-style-1 .inner .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    padding-left: 17px;
    position: relative;
    margin-top: 40px; }
    .about-style-1 .inner .description::before {
      position: absolute;
      content: "";
      left: 0;
      width: 2px;
      height: 46px;
      background: var(--color-primary);
      top: 50%;
      transform: translateY(-50%); }
  .about-style-1 .inner .read-more-btn {
    margin-top: 40px; }
  .about-style-1 .inner .about-parallax-2 {
    position: absolute;
    right: 88px;
    top: 379px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .about-style-1 .inner .about-parallax-2 {
        right: 8px; } }

/*----------------------
    About Style Two  
------------------------*/
.about-style-2 {
  position: relative; }
  .about-style-2 .about-image-gallery {
    position: relative; }
    .about-style-2 .about-image-gallery .eduvibe-about-1-img-wrapper {
      position: relative; }
      .about-style-2 .about-image-gallery .eduvibe-about-1-img-wrapper .image-1 {
        position: relative;
        margin-left: -40px;
        width: 460px;
        min-width: 460px;
        height: 460px;
        min-height: 460px;
        border-radius: 100%;
        z-index: 3; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .about-style-2 .about-image-gallery .eduvibe-about-1-img-wrapper .image-1 {
            margin-left: 0; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .about-style-2 .about-image-gallery .eduvibe-about-1-img-wrapper .image-1 {
            margin-left: 0; } }
        @media only screen and (max-width: 767px) {
          .about-style-2 .about-image-gallery .eduvibe-about-1-img-wrapper .image-1 {
            margin-left: 0;
            width: 100%;
            min-width: auto;
            min-height: auto;
            height: auto; } }
      .about-style-2 .about-image-gallery .eduvibe-about-1-img-wrapper .eduvibe-about-blur img {
        position: absolute;
        left: -50px;
        width: 739px;
        height: 739px;
        z-index: 0;
        top: -140px; }
        @media only screen and (max-width: 1199px) {
          .about-style-2 .about-image-gallery .eduvibe-about-1-img-wrapper .eduvibe-about-blur img {
            display: none; } }
    .about-style-2 .about-image-gallery .image-2 {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 100%; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-style-2 .about-image-gallery .image-2 {
          right: 101px; } }
    .about-style-2 .about-image-gallery .finished-session {
      background: var(--color-white);
      box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.05);
      width: 170px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      position: absolute;
      left: -120px;
      top: 8%;
      z-index: 3; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .about-style-2 .about-image-gallery .finished-session {
          left: 0;
          top: -9%; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-style-2 .about-image-gallery .finished-session {
          left: 0;
          top: -9%; } }
      @media only screen and (max-width: 767px) {
        .about-style-2 .about-image-gallery .finished-session {
          left: 0;
          top: 0;
          width: 120px;
          height: 120px; } }
      .about-style-2 .about-image-gallery .finished-session .inner .text {
        font-weight: 700;
        font-size: 32px;
        color: var(--color-primary);
        line-height: 44px;
        display: block; }
        @media only screen and (max-width: 767px) {
          .about-style-2 .about-image-gallery .finished-session .inner .text {
            font-size: 22px;
            line-height: 29px; } }
      .about-style-2 .about-image-gallery .finished-session .inner .finished-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        display: inline-block;
        color: var(--color-dark); }
  .about-style-2 .circle-image-wrapper {
    position: absolute;
    width: 272px;
    height: 272px;
    right: 52px;
    bottom: -57px;
    z-index: 3; }
    .about-style-2 .circle-image-wrapper .circle-image {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 272px;
      height: 272px;
      z-index: -1; }
      .about-style-2 .circle-image-wrapper .circle-image span {
        border-color: #FFA41B;
        left: 26px; }
        @media only screen and (max-width: 991px) {
          .about-style-2 .circle-image-wrapper .circle-image span {
            left: -51px; } }
    @media only screen and (max-width: 767px) {
      .about-style-2 .circle-image-wrapper {
        display: none; } }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .about-style-2 .circle-image-wrapper {
        right: 30px; } }

.circle-image-wrapper {
  position: relative;
  display: inline-block;
  z-index: 1; }
  .circle-image-wrapper .circle-image {
    z-index: -1; }

/*----------------------
    About Style 3  
------------------------*/
.about-style-3 {
  position: relative; }
  .about-style-3 .gappery-wrapper .gallery-image {
    position: relative; }
    .about-style-3 .gappery-wrapper .gallery-image img {
      border-radius: 5.2px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-style-3 .gappery-wrapper .gallery-image img {
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .about-style-3 .gappery-wrapper .gallery-image img {
          width: 100%; } }
    .about-style-3 .gappery-wrapper .gallery-image .icon-badge {
      width: 60px;
      height: 60px;
      background: var(--color-primary);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%); }
      .about-style-3 .gappery-wrapper .gallery-image .icon-badge i {
        color: var(--color-white);
        font-size: 20px; }
    .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status {
      background: #FFFFFF;
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      display: inline-block;
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%); }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status {
          left: 10px;
          top: auto;
          transform: none;
          bottom: 10px; } }
      @media only screen and (max-width: 767px) {
        .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status {
          left: 10px;
          top: auto;
          transform: none;
          bottom: 10px; } }
      .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status .inner {
        display: flex;
        padding: 16px 15px;
        align-items: center; }
        .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status .inner .icon {
          width: 40px;
          height: 40px;
          background: rgba(251, 124, 86, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          margin-right: 10px; }
          .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status .inner .icon i {
            color: var(--color-white); }
        .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status .inner .content {
          text-align: left; }
          .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status .inner .content .title {
            color: var(--color-secondary);
            font-weight: 700;
            font-size: 20px;
            line-height: 16px;
            margin-bottom: 0; }
          .about-style-3 .gappery-wrapper .gallery-image.gallery-image-3 .student-like-status .inner .content .subtitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px; }

/*----------------------
    About Style 4  
------------------------*/
.about-style-4 {
  position: relative; }
  .about-style-4 .gallery-wrapper {
    position: relative;
    text-align: center;
    margin-right: 120px;
    margin-left: -50px;
    z-index: 2; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about-style-4 .gallery-wrapper {
        text-align: left;
        margin-left: 130px;
        margin-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-style-4 .gallery-wrapper {
        margin-right: 50px; } }
    @media only screen and (max-width: 767px) {
      .about-style-4 .gallery-wrapper {
        margin-right: 0;
        margin-left: 0;
        text-align: left; } }
    .about-style-4 .gallery-wrapper img {
      border-radius: 5px; }
    @media only screen and (max-width: 767px) {
      .about-style-4 .gallery-wrapper .image-1 {
        width: 100%; } }
    .about-style-4 .gallery-wrapper .image-2 {
      position: absolute;
      left: -95px;
      z-index: -1;
      top: 88px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .about-style-4 .gallery-wrapper .image-2 {
          left: -120px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-style-4 .gallery-wrapper .image-2 {
          left: 3%; } }
      @media only screen and (max-width: 767px) {
        .about-style-4 .gallery-wrapper .image-2 {
          display: none; } }
    .about-style-4 .gallery-wrapper .image-3 {
      position: absolute;
      right: -73px;
      bottom: 100px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .about-style-4 .gallery-wrapper .image-3 {
          left: 370px;
          right: inherit; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-style-4 .gallery-wrapper .image-3 {
          right: 0; } }
      @media only screen and (max-width: 767px) {
        .about-style-4 .gallery-wrapper .image-3 {
          right: 0; } }
      @media only screen and (max-width: 575px) {
        .about-style-4 .gallery-wrapper .image-3 {
          display: none; } }
      .about-style-4 .gallery-wrapper .image-3 img {
        border: 3px solid #FFFFFF; }

/*----------------------
    About Style 6  
------------------------*/
.about-style-6 .about-image-gallery {
  position: relative;
  z-index: 1; }
  .about-style-6 .about-image-gallery img {
    border-radius: 265px 265px 5px 5px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-style-6 .about-image-gallery img {
        border-radius: 500px 500px 0 0; } }
    @media only screen and (max-width: 767px) {
      .about-style-6 .about-image-gallery img {
        border-radius: 500px 500px 0 0; } }
  .about-style-6 .about-image-gallery .badge-icon {
    position: absolute;
    bottom: 173px;
    left: -75px;
    width: 130px;
    height: 130px;
    background: #FFFFFF;
    border-radius: 100%;
    padding: 5px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about-style-6 .about-image-gallery .badge-icon {
        bottom: auto;
        left: 0;
        top: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-style-6 .about-image-gallery .badge-icon {
        bottom: auto;
        left: 0;
        top: 0; } }
    @media only screen and (max-width: 767px) {
      .about-style-6 .about-image-gallery .badge-icon {
        bottom: auto;
        left: 0;
        top: 0; } }
    .about-style-6 .about-image-gallery .badge-icon .badge-inner {
      padding: 5px;
      background: #FB7C56;
      border-radius: 100%;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center; }
      .about-style-6 .about-image-gallery .badge-icon .badge-inner::after {
        width: 20px;
        height: 20px;
        background: var(--color-white);
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: ""; }
      .about-style-6 .about-image-gallery .badge-icon .badge-inner img {
        animation: rotateIt 7s linear infinite; }
  .about-style-6 .about-image-gallery::after {
    position: absolute;
    content: "";
    border: 5px solid var(--color-secondary);
    width: 97%;
    height: 95%;
    left: 40px;
    bottom: -20px;
    border-radius: 265px 265px 5px 5px;
    z-index: -1; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-style-6 .about-image-gallery::after {
        border-radius: 500px 500px 0 0; } }
    @media only screen and (max-width: 767px) {
      .about-style-6 .about-image-gallery::after {
        display: none; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-style-6 .inner {
    padding-left: 40px; } }

.about-style-6 .inner .feature-list-wrapper {
  display: flex;
  margin: 0;
  margin-top: -30px; }
  .about-style-6 .inner .feature-list-wrapper .feature-list {
    flex-basis: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-heading);
    margin-top: 30px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about-style-6 .inner .feature-list-wrapper .feature-list {
        flex-basis: 100%;
        margin-top: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-style-6 .inner .feature-list-wrapper .feature-list {
        margin-top: 15px; } }
    @media only screen and (max-width: 767px) {
      .about-style-6 .inner .feature-list-wrapper .feature-list {
        margin-top: 15px; } }
    @media only screen and (max-width: 575px) {
      .about-style-6 .inner .feature-list-wrapper .feature-list {
        flex-basis: 100%; } }
    .about-style-6 .inner .feature-list-wrapper .feature-list i {
      margin-right: 14px;
      color: var(--color-secondary);
      font-size: 24px; }

.about-style-6 .inner .info-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-heading);
  margin-left: 30px;
  transition: 0.4s; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-style-6 .inner .info-btn {
      margin-left: 10px; } }
  @media only screen and (max-width: 575px) {
    .about-style-6 .inner .info-btn {
      margin-left: 0;
      margin-top: 20px;
      display: block; } }
  .about-style-6 .inner .info-btn:hover {
    color: var(--color-secondary); }
  .about-style-6 .inner .info-btn i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    text-align: center;
    margin-right: 15px;
    background: rgba(251, 124, 86, 0.15);
    color: var(--color-secondary);
    display: inline-block; }

.edu-mission-vision-area .thumbnail {
  margin: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-mission-vision-area .thumbnail {
      flex-wrap: wrap; } }
  @media only screen and (max-width: 767px) {
    .edu-mission-vision-area .thumbnail {
      flex-wrap: wrap; } }
  .edu-mission-vision-area .thumbnail img {
    border-radius: 5px; }
  .edu-mission-vision-area .thumbnail .shape-image {
    padding: 15px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .edu-mission-vision-area .thumbnail .shape-image {
        flex-basis: 50%; } }
    @media only screen and (max-width: 767px) {
      .edu-mission-vision-area .thumbnail .shape-image {
        flex-basis: 50%; } }
    @media only screen and (max-width: 575px) {
      .edu-mission-vision-area .thumbnail .shape-image {
        flex-basis: 100%;
        text-align: center; } }

@media only screen and (max-width: 991px) {
  .eduvibe-about-three-mission .thumbnail img {
    transform: none !important; } }

/*----------------------
    Why Choose Us 1
------------------------*/
.edu-choose-us-area-one .thumbnail {
  margin-left: -151px;
  margin-right: -76px;
  margin-top: -50px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .edu-choose-us-area-one .thumbnail {
      margin-left: -60px; } }
  @media only screen and (max-width: 1199px) {
    .edu-choose-us-area-one .thumbnail {
      margin-left: -120px;
      margin-right: 0; } }
  @media only screen and (max-width: 991px) {
    .edu-choose-us-area-one .thumbnail {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0; } }

.edu-choose-us-area-one.edu-section-gap {
  padding: 180px 0 130px !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-choose-us-area-one.edu-section-gap {
      padding: 80px 0 !important; } }
  @media only screen and (max-width: 767px) {
    .edu-choose-us-area-one.edu-section-gap {
      padding: 80px 0 !important; } }

/*-------------------------
    Newsletter Styles  
-------------------------*/
.newsletter-form-style-1 .input-box {
  position: relative;
  display: inline-block; }
  @media only screen and (max-width: 767px) {
    .newsletter-form-style-1 .input-box {
      display: block; } }
  .newsletter-form-style-1 .input-box input {
    height: 60px;
    background: var(--color-white);
    border: 0 none;
    padding: 0 52px;
    min-width: 570px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    border-radius: 5px; }
    @media only screen and (max-width: 767px) {
      .newsletter-form-style-1 .input-box input {
        min-width: 100%;
        height: 60px; } }
    .newsletter-form-style-1 .input-box input.large-input-box {
      height: 80px;
      min-width: 670px;
      padding-right: 190px; }
      @media only screen and (max-width: 767px) {
        .newsletter-form-style-1 .input-box input.large-input-box {
          min-width: 100%;
          padding-right: 20px;
          padding-left: 20px; } }
  .newsletter-form-style-1 .input-box .mail-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 26px;
    font-size: 16px;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .newsletter-form-style-1 .input-box .mail-icon {
        display: none; } }
  .newsletter-form-style-1 .input-box .send-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 27px;
    border: 0 none;
    padding: 0;
    background: transparent; }
    .newsletter-form-style-1 .input-box .send-button i {
      font-size: 20px;
      color: var(--color-primary); }
  .newsletter-form-style-1 .input-box .edu-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px; }
    @media only screen and (max-width: 767px) {
      .newsletter-form-style-1 .input-box .edu-btn {
        position: static;
        transform: none;
        margin-left: 0;
        margin-top: 20px; } }

.newsletter-style-2 {
  position: relative; }
  .newsletter-style-2 input {
    height: 60px;
    background: var(--color-white);
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-body);
    border-radius: 5px;
    border: 0 none;
    padding-right: 146px; }
  .newsletter-style-2 .subscribe-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    z-index: 2; }

.newsletter-style-3 {
  position: relative; }
  .newsletter-style-3 .contact-info {
    margin-left: 43px; }
    @media only screen and (max-width: 575px) {
      .newsletter-style-3 .contact-info {
        margin-left: 0;
        margin-top: 20px; } }
    .newsletter-style-3 .contact-info a {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: var(--color-white);
      transition: 0.4s;
      position: relative;
      display: inline-block;
      z-index: 1; }
      .newsletter-style-3 .contact-info a::after {
        position: absolute;
        content: "";
        left: auto;
        right: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background: var(--color-white);
        transition: 0.3s; }
      .newsletter-style-3 .contact-info a i {
        margin-right: 5px; }
      .newsletter-style-3 .contact-info a:hover::after {
        width: 100%;
        left: 0;
        right: auto; }

.newsletter-style-5 {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  min-width: 1400px;
  padding: 100px 115px;
  background-image: url(../images/bg/newsletter-home-4-bg.webp); }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .newsletter-style-5 {
      min-width: auto; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .newsletter-style-5 {
      min-width: auto;
      padding: 50px 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .newsletter-style-5 {
      min-width: auto;
      padding: 50px 40px; } }
  @media only screen and (max-width: 767px) {
    .newsletter-style-5 {
      min-width: auto;
      padding: 50px 40px; } }

.newsletter-overlay-to-another {
  position: relative;
  margin-top: -145px; }
  @media only screen and (max-width: 991px) {
    .newsletter-overlay-to-another {
      margin-top: -80px; } }

.newsletter-to-top {
  padding-top: 273px !important; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .newsletter-to-top {
      padding-top: 224px !important; } }
  @media only screen and (max-width: 767px) {
    .newsletter-to-top {
      padding-top: 224px !important; } }

/*---------------------------
    Social Share Style
---------------------------*/
.social-share {
  margin: -7px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .social-share li {
    margin: 7px; }
    .social-share li a {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      font-size: 18px;
      color: var(--color-white);
      transition: 0.4s;
      text-align: center; }
      .social-share li a:hover {
        background: var(--color-primary);
        color: var(--color-white); }
  .social-share.bg-transparent li a {
    background: transparent;
    border: 1px solid rgba(45, 40, 78, 0.1);
    color: var(--color-primary);
    border-radius: 100%; }
    .social-share.bg-transparent li a:hover {
      border-color: var(--color-primary);
      background: var(--color-primary);
      color: var(--color-white); }
  .social-share.icon-transparent {
    margin: 0 -15px; }
    .social-share.icon-transparent li {
      margin: 0 15px; }
      .social-share.icon-transparent li a {
        background: transparent;
        height: auto;
        width: auto;
        color: var(--color-body);
        transition: 0.4s;
        line-height: initial; }
        .social-share.icon-transparent li a:hover {
          color: var(--color-primary); }
  .social-share.border-style {
    margin: 0 -7px; }
    .social-share.border-style li {
      margin: 0 7px; }
      .social-share.border-style li a {
        background: transparent;
        height: 40px;
        width: 40px;
        color: var(--color-primary);
        transition: 0.4s;
        line-height: 42px;
        text-align: center;
        border: 1px solid rgba(45, 40, 78, 0.1);
        border-radius: 100%;
        font-size: 16px; }
        .social-share.border-style li a:hover {
          color: var(--color-white);
          background: var(--color-primary);
          border-color: var(--color-primary); }
  .social-share.medium-size li a {
    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 16px; }

/*-------------------------
    Back To Top  
---------------------------*/
@-webkit-keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } }

.paginacontainer {
  height: 3000px; }

.rn-progress-parent {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px #f8e3c3;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear; }
  .rn-progress-parent.rn-backto-top-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .rn-progress-parent::after {
    position: absolute;
    font-family: 'icomoon' !important;
    content: "\e90f";
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    color: var(--color-tertiary);
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }
  .rn-progress-parent:hover::after {
    color: var(--color-tertiary); }
  .rn-progress-parent::before {
    position: absolute;
    font-family: 'feather' !important;
    content: "\e914";
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    opacity: 0;
    background: #0d0d12;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }
  .rn-progress-parent svg path {
    fill: none; }
  .rn-progress-parent svg.rn-back-circle path {
    stroke: var(--color-tertiary);
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }

/*--------------------------
    Workshop Style One  
----------------------------*/
.workshop-style-1 .workshop-inner .description {
  color: var(--color-white);
  margin-top: 10px;
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .workshop-style-1 .workshop-inner .description {
      margin-top: 20px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .workshop-style-1 .workshop-inner .description {
      margin-top: 20px;
      margin-bottom: 20px; } }

/*--------------------------
    Video Popup Styles  
----------------------------*/
.video-gallery-1 {
  padding: 8px;
  background: var(--color-white);
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.07);
  border-radius: 10px; }

.video-popup-wrapper {
  position: relative; }
  .video-popup-wrapper.video-popup-two img {
 
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center center;
    mask-position: center center; }
  .video-popup-wrapper .position-to-top {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .video-popup-wrapper img {
    border-radius: 5px; }

.video-play-btn {
  border: 0;
  width: 50px;
  height: 50px;
  margin-left: -30px;
  margin-top: -30px;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  border-radius: 100%;
  background: var(--color-white);
  margin: 0 auto;
  display: inline-block; }
  .video-play-btn.with-animation::after, .video-play-btn.with-animation::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    border-radius: 50%;
    border: 1.2px solid var(--color-white); }
  .video-play-btn.with-animation::before {
    -webkit-animation: ripple 2s linear infinite;
    animation: ripple 2s linear infinite; }
  .video-play-btn.with-animation::after {
    -webkit-animation: ripple 2s linear 1s infinite;
    animation: ripple 2s linear 1s infinite; }
  .video-play-btn.size-60 {
    width: 60px;
    height: 60px;
    font-size: 20px; }
    @media only screen and (max-width: 767px) {
      .video-play-btn.size-60 {
        width: 50px;
        height: 50px;
        font-size: inherit; } }
    @media only screen and (max-width: 575px) {
      .video-play-btn.size-60 {
        width: 45px;
        height: 45px; } }
  .video-play-btn.size-80 {
    width: 80px;
    height: 80px;
    font-size: 24px; }
    @media only screen and (max-width: 1199px) {
      .video-play-btn.size-80 {
        width: 60px;
        height: 60px;
        font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .video-play-btn.size-80 {
        width: 50px;
        height: 50px;
        font-size: inherit; } }
    @media only screen and (max-width: 575px) {
      .video-play-btn.size-80 {
        width: 45px;
        height: 45px; } }
    .video-play-btn.size-80 .play-icon::before {
      transform: translate(-6px, -12px);
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 21px solid var(--color-white); }
    .video-play-btn.size-80.btn-secondary-color .play-icon::before {
      border-left: 21px solid var(--color-white); }
  .video-play-btn .play-icon {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .video-play-btn .play-icon::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translate(-5px, -8px);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 17px solid var(--color-primary); }
    .video-play-btn .play-icon.course-details-video-popup::before {
      border-left: 15px solid var(--color-primary); }
  .video-play-btn.color-secondary .play-icon::before {
    border-left: 17px solid var(--color-secondary); }
  .video-play-btn.color-secondary.size-80 .play-icon::before {
    border-left: 21px solid var(--color-secondary); }
  .video-play-btn.btn-large {
    width: 60px;
    height: 60px;
    margin-left: 0;
    margin-top: 0; }
  .video-play-btn.btn-theme-color {
    color: #ffffff;
    background: var(--color-primary); }
    .video-play-btn.btn-theme-color::after {
      border-color: var(--color-primary); }
    .video-play-btn.btn-theme-color::before {
      border-color: var(--color-primary); }
  .video-play-btn.btn-secondary-color {
    color: #ffffff;
    background: var(--color-secondary); }
    .video-play-btn.btn-secondary-color::after {
      border-color: var(--color-secondary); }
    .video-play-btn.btn-secondary-color::before {
      border-color: var(--color-secondary); }
    .video-play-btn.btn-secondary-color .play-icon::before {
      border-left: 15px solid var(--color-white); }

.video-style-2 {
  position: relative;
  z-index: 1; }
  .video-style-2::after {
    position: absolute;
    content: "";
    background: var(--color-white);
    top: 0;
    right: 0;
    height: 100%;
    width: calc( 50% + 250px);
    z-index: -1;
    border-radius: 10px 0px 0px 10px; }
    @media only screen and (max-width: 991px) {
      .video-style-2::after {
        display: none; } }
  .video-style-2 .side-shape-image {
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translateY(-50%) translateX(50%); }
    .video-style-2 .side-shape-image img {
      animation: eduvibe-rotate-x 30s infinite linear; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .video-style-2 .side-shape-image {
        display: none; } }

/*keyframes-css*/
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2.3);
    transform: scale(2.3);
    opacity: 0; } }

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2.3);
    transform: scale(2.3);
    opacity: 0; } }

@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35); }
  100% {
    opacity: 0; } }

@keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35); }
  100% {
    opacity: 0; } }

/*------------------------------
    Choose Us Style  
-------------------------------*/
.choose-us-style-1 {
  position: relative; }
  .choose-us-style-1 .inner .section-title .title {
    margin-bottom: 40px; }
  .choose-us-style-1 .chosse-us-thumbnail {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 236px; }
  .choose-us-style-1 .right-side-thumb {
    margin-right: -149px; }
    @media only screen and (max-width: 991px) {
      .choose-us-style-1 .right-side-thumb {
        margin-right: 0; } }

@media only screen and (max-width: 991px) {
  .eduvibe-home-two-counter .choose-us-2 {
    margin-top: 25px; } }

/*----------------------------
    Call To Action Styles  
-----------------------------*/
.call-action-style-1 {
  padding-top: 24px;
  padding-left: 50px; }
  .call-action-style-1 .inner .content .pre-title {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: block;
    margin-bottom: 5px; }
  .call-action-style-1 .inner .content .title {
    font-size: 24px;
    line-height: 36px; }
  .call-action-style-1 .thumbnail {
    position: relative;
    z-index: 1; }
    .call-action-style-1 .thumbnail .shape-image {
      position: absolute;
      top: 18px;
      right: 13px;
      z-index: -1; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .call-action-style-1 .thumbnail img.main-img {
        margin-bottom: -15px; } }
  .call-action-style-1.box-layout {
    padding: 46px 50px;
    position: relative; }
    .call-action-style-1.box-layout .inner .shape-image-2 {
      position: absolute;
      top: 26px;
      right: 30px;
      pointer-events: none; }
      @media only screen and (max-width: 575px) {
        .call-action-style-1.box-layout .inner .shape-image-2 {
          display: none; } }

/*-----------------------
    Slick Slider  
-------------------------*/
.edu-testimonial-activation.edu-slick-button {
  margin-bottom: 62px; }
  .edu-testimonial-activation.edu-slick-button .edu-slick-button .slick-dots {
    bottom: -74px; }

.course-activation-item-2 .edu-slick-button .slick-dots {
  bottom: -45px; }

.slick-activation-wrapper {
  margin: 0 -15px;
  position: relative; }
  @media only screen and (max-width: 575px) {
    .slick-activation-wrapper {
      margin: 0; } }
  .slick-activation-wrapper .single-slick-card {
    padding: 0 15px; }
    @media only screen and (max-width: 575px) {
      .slick-activation-wrapper .single-slick-card {
        padding: 0; } }

.single-slick-card .edu-card.card-type-5 {
  box-shadow: none; }

.edu-slick-button .slick-dots {
  bottom: -84px;
  margin: 0 -7px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-slick-button .slick-dots {
      bottom: -60px; } }
  @media only screen and (max-width: 767px) {
    .edu-slick-button .slick-dots {
      bottom: -60px; } }
  .edu-slick-button .slick-dots li {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    padding: 0;
    transition: 0.4s;
    line-height: 24px;
    position: relative;
    margin: 0 1px;
    display: inline-block; }
    .edu-slick-button .slick-dots li::before {
      border: 2px solid transparent;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      transition: 0.2s;
      opacity: 0;
      border-radius: 100%;
      left: 0;
      top: 0;
      transform: scale(0.2); }
    .edu-slick-button .slick-dots li.slick-active {
      margin: 0 3px; }
    .edu-slick-button .slick-dots li button {
      width: 10px;
      height: 10px;
      background: rgba(82, 95, 225, 0.5);
      border-radius: 100%;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%); }
      .edu-slick-button .slick-dots li button::before {
        display: none; }
    .edu-slick-button .slick-dots li.slick-active::before {
      border-color: var(--color-primary);
      opacity: 1;
      transform: scale(1); }
    .edu-slick-button .slick-dots li.slick-active button {
      background: var(--color-primary); }

.edu-slick-button.slick-button-left .slick-dots {
  bottom: -80px;
  margin: 0px -6px;
  text-align: left;
  display: flex !important;
  align-items: center; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-slick-button.slick-button-left .slick-dots {
      bottom: -50px; } }
  @media only screen and (max-width: 767px) {
    .edu-slick-button.slick-button-left .slick-dots {
      bottom: -50px; } }
  .edu-slick-button.slick-button-left .slick-dots li {
    border-radius: 100%;
    transition: 0.4s;
    position: relative;
    margin: 0 6px;
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px; }
    .edu-slick-button.slick-button-left .slick-dots li::before {
      border: 2px solid transparent;
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      transition: 0.3s;
      opacity: 0;
      border-radius: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .edu-slick-button.slick-button-left .slick-dots li.slick-active::before {
      border-color: var(--color-primary);
      opacity: 1;
      width: 100%;
      height: 100%; }

.edu-slick-button.instructor-profile-courses .slick-dots {
  bottom: -46px; }

@media only screen and (max-width: 1199px) {
  .edu-slick-button.instructor-profile-courses .slick-list {
    margin-bottom: 30px; } }

.slick-arrow-style-2 {
  position: relative; }
  .slick-arrow-style-2 .slide-arrow {
    position: absolute;
    right: 50px;
    width: 50px;
    height: 50px;
    background: rgba(82, 95, 225, 0.15);
    transition: 0.3s;
    color: var(--color-primary);
    line-height: 50px;
    text-align: center;
    border: 0 none;
    bottom: 50px;
    border-radius: 3px;
    font-size: 18px;
    z-index: 2; }
    @media only screen and (max-width: 479px) {
      .slick-arrow-style-2 .slide-arrow {
        right: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        bottom: 20px; } }
    .slick-arrow-style-2 .slide-arrow:hover {
      background: var(--color-primary);
      color: var(--color-white); }
    .slick-arrow-style-2 .slide-arrow.prev-arrow {
      right: 115px; }
      @media only screen and (max-width: 479px) {
        .slick-arrow-style-2 .slide-arrow.prev-arrow {
          right: 74px; } }

.edu-slick-arrow-top .slide-arrow {
  position: absolute;
  right: 15px;
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  transition: 0.3s;
  color: var(--color-primary);
  line-height: 50px;
  text-align: center;
  border: 0 none;
  font-size: 18px;
  top: -120px; }
  @media only screen and (max-width: 767px) {
    .edu-slick-arrow-top .slide-arrow {
      top: auto;
      bottom: -30px;
      right: auto;
      left: 80px; } }
  .edu-slick-arrow-top .slide-arrow:hover {
    background: var(--color-primary);
    color: var(--color-white); }
  .edu-slick-arrow-top .slide-arrow.prev-arrow {
    right: 80px; }
    @media only screen and (max-width: 767px) {
      .edu-slick-arrow-top .slide-arrow.prev-arrow {
        right: auto;
        left: 15px; } }

/*------------------------
    Winner Area Styles  
--------------------------*/
.edu-winner-card {
  background: var(--color-white);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 40px;
  transition: 0.4s;
  text-align: center; }
  .edu-winner-card .inner .icon {
    margin-bottom: 30px; }
    .edu-winner-card .inner .icon img {
      max-height: 50px; }
  .edu-winner-card .inner .content .subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: inline-block; }
  .edu-winner-card .inner .content .title {
    font-weight: 700;
    margin-bottom: 0;
    transition: 0.4s; }
  .edu-winner-card:hover {
    transform: translateY(-15px); }
    .edu-winner-card:hover .inner .content .title {
      color: var(--color-primary); }

/*----------------------------
    Counterup Styles  
-----------------------------*/
.edu-counterup {
  background: var(--color-white);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  text-align: center;
  padding: 50px 30px; }
  .edu-counterup .odometer.odometer-auto-theme,
  .edu-counterup .odometer.odometer-theme-default {
    font-family: inherit;
    letter-spacing: 1px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .edu-counterup {
      padding: 30px 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-counterup {
      padding: 30px 15px; } }
  @media only screen and (max-width: 767px) {
    .edu-counterup {
      padding: 30px 15px; } }
  .edu-counterup .inner .icon {
    margin-bottom: 25px; }
    .edu-counterup .inner .icon img {
      max-height: 60px; }
  .edu-counterup .inner .content .counter {
    font-weight: 800;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 0; }
  .edu-counterup .inner .content span {
    display: inline-block; }

.counterup-overlay-top {
  position: relative;
  margin-top: -145px;
  padding-top: 275px !important; }

.counterup-style-2 {
  position: relative;
  z-index: 2; }
  .counterup-style-2 .line-separator {
    position: relative; }
    .counterup-style-2 .line-separator::after {
      position: absolute;
      content: "";
      height: 160px;
      background: rgba(255, 255, 255, 0.15);
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px; }
      @media only screen and (max-width: 575px) {
        .counterup-style-2 .line-separator::after {
          display: none; } }
    .counterup-style-2 .line-separator:last-child::after {
      display: none; }
    .counterup-style-2 .line-separator.color-var-2::after {
      background: #2D284E;
      opacity: 0.1; }

.edu-counterup-2 .odometer.odometer-auto-theme,
.edu-counterup-2 .odometer.odometer-theme-default {
  font-family: inherit;
  letter-spacing: 1px; }

.edu-counterup-2 .inner {
  text-align: center; }
  .edu-counterup-2 .inner .icon {
    margin-bottom: 7px; }
    .edu-counterup-2 .inner .icon i {
      color: var(--color-white);
      font-size: 30px; }
  .edu-counterup-2 .inner .content .counter {
    color: var(--color-white);
    font-weight: 800;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 5px; }
    .edu-counterup-2 .inner .content .counter .after-icon {
      color: var(--color-white);
      position: relative;
      display: inline-block;
      left: -7px; }
  .edu-counterup-2 .inner .content .subtitle {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-white); }

.edu-counterup-2.variation-2 .inner {
  text-align: center; }
  .edu-counterup-2.variation-2 .inner .icon i {
    color: var(--color-primary);
    font-size: 50px; }
  .edu-counterup-2.variation-2 .inner .content .counter {
    color: var(--color-heading); }
    .edu-counterup-2.variation-2 .inner .content .counter .after-icon {
      color: var(--color-heading); }
  .edu-counterup-2.variation-2 .inner .content .subtitle {
    color: var(--color-body); }

.edu-countdown-area {
  background-image: url(../images/bg/about-us-two-countdown.webp); }
  .edu-countdown-area .countdown .countdown-container .countdown-value {
    color: var(--color-secondary); }

.countdown {
  display: flex;
  margin: -15px;
  justify-content: center;
  flex-wrap: wrap; }
  .countdown .countdown-container {
    margin: 15px !important;
    position: relative;
    background-color: var(--color-white);
    padding: 15px 28px;
    border-radius: 5px; }
    .countdown .countdown-container .countdown-heading {
      display: block;
      color: var(--color-body);
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 0;
      display: block;
      line-height: 18px;
      font-weight: 700;
      letter-spacing: 0.05em; }
    .countdown .countdown-container .countdown-value {
      display: block;
      font-size: 32px;
      color: var(--color-heading);
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 1.38; }
    .countdown .countdown-container:after {
      content: ":";
      top: 50%;
      transform: translateY(-50%);
      right: -17px;
      position: absolute;
      font-size: 32px;
      color: var(--color-white);
      font-weight: 700; }
    .countdown .countdown-container:last-child::after {
      display: none; }

.countdown-style-2 .countdown {
  display: flex;
  margin: -15px;
  justify-content: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 767px) {
    .countdown-style-2 .countdown {
      margin: -5px !important; } }
  .countdown-style-2 .countdown .countdown-container {
    margin: 15px !important;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 40px 10px;
    border-radius: 5px;
    transition: 0.4s;
    min-width: 180px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .countdown-style-2 .countdown .countdown-container {
        padding: 10px;
        min-width: auto;
        margin: 5px !important; } }
    .countdown-style-2 .countdown .countdown-container .countdown-heading {
      display: block;
      color: var(--color-heading);
      text-align: center;
      text-transform: uppercase;
      margin-top: 0;
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      transition: 0.4s; }
      @media only screen and (max-width: 767px) {
        .countdown-style-2 .countdown .countdown-container .countdown-heading {
          font-size: 14px; } }
    .countdown-style-2 .countdown .countdown-container .countdown-value {
      display: block;
      font-weight: 800;
      font-size: 68px;
      line-height: 1.24;
      color: var(--color-heading);
      text-align: center;
      position: relative;
      transition: 0.4s; }
      @media only screen and (max-width: 767px) {
        .countdown-style-2 .countdown .countdown-container .countdown-value {
          font-size: 24px; } }
    .countdown-style-2 .countdown .countdown-container:after {
      display: none; }
    .countdown-style-2 .countdown .countdown-container:last-child::after {
      display: none; }
    .countdown-style-2 .countdown .countdown-container:hover {
      background: var(--color-primary);
      box-shadow: 0px 10px 30px rgba(82, 95, 225, 0.3); }
      .countdown-style-2 .countdown .countdown-container:hover .countdown-heading {
        color: var(--color-white); }
      .countdown-style-2 .countdown .countdown-container:hover .countdown-value {
        color: var(--color-white); }

.eduvibe-about-two-countdown .countdown-style-1 {
  padding: 0 290px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eduvibe-about-two-countdown .countdown-style-1 {
      padding: 0 60px; } }
  @media only screen and (max-width: 991px) {
    .eduvibe-about-two-countdown .countdown-style-1 {
      padding: 0px; } }

/*--------------------------------
    Breadcrumb Area  
------------------------------*/
.breadcrumb-style-1 .page-title .title {
  margin-bottom: 5px; }

.breadcrumb-style-1 .edu-breadcrumb {
  align-items: center; }
  .breadcrumb-style-1 .edu-breadcrumb li {
    margin: 0; }
    .breadcrumb-style-1 .edu-breadcrumb li.breadcrumb-item {
      color: var(--color-body); }
    .breadcrumb-style-1 .edu-breadcrumb li.separator i {
      font-size: 20px;
      margin: 0 5px;
      position: relative;
      display: inline-block;
      top: 2px; }

.grid-metro3 {
  margin: 0 -15px; }
  .grid-metro3 .grid-metro-item {
    width: 33.33%;
    padding: 0 15px;
    margin-top: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .grid-metro3 .grid-metro-item {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .grid-metro3 .grid-metro-item {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .grid-metro3 .grid-metro-item {
        width: 100%; } }
  .grid-metro3 .resizer {
    width: 33.33%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .grid-metro3 .resizer {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .grid-metro3 .resizer {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .grid-metro3 .resizer {
        width: 100%; } }

.eduvibe-home-two-course .grid-metro3 .grid-metro-item {
  width: 33.33%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eduvibe-home-two-course .grid-metro3 .grid-metro-item {
      width: 50%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .eduvibe-home-two-course .grid-metro3 .grid-metro-item {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .eduvibe-home-two-course .grid-metro3 .grid-metro-item {
      width: 100%; } }

.eduvibe-home-two-course .grid-metro3 .resizer {
  width: 33.33%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eduvibe-home-two-course .grid-metro3 .resizer {
      width: 50%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .eduvibe-home-two-course .grid-metro3 .resizer {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .eduvibe-home-two-course .grid-metro3 .resizer {
      width: 100%; } }

.grid-metro4 {
  margin: 0 -15px; }
  .grid-metro4 .grid-metro-item {
    width: 25%;
    padding: 0 15px;
    margin-top: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .grid-metro4 .grid-metro-item {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .grid-metro4 .grid-metro-item {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .grid-metro4 .grid-metro-item {
        width: 100%; } }
  .grid-metro4 .resizer {
    width: 25%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .grid-metro4 .resizer {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .grid-metro4 .resizer {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .grid-metro4 .resizer {
        width: 100%; } }

.grid-metro5 {
  margin: 0 -15px; }
  .grid-metro5 .grid-metro-item {
    width: 20%;
    padding: 0 15px;
    margin-top: 30px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .grid-metro5 .grid-metro-item {
        width: 33.33%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .grid-metro5 .grid-metro-item {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .grid-metro5 .grid-metro-item {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .grid-metro5 .grid-metro-item {
        width: 100%; } }
  .grid-metro5 .resizer {
    width: 20%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .grid-metro5 .resizer {
        width: 33.33%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .grid-metro5 .resizer {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .grid-metro5 .resizer {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .grid-metro5 .resizer {
        width: 100%; } }

/*-------------------------
Accordion Style 
-------------------------*/
.edu-tab-nav {
  margin: -7.5px;
  border: 0 none;
  justify-content: center; }
  .edu-tab-nav li {
    margin: 7.5px; }
    .edu-tab-nav li.nav-item button.nav-link {
      background: #F5F5F5;
      border-radius: 4px;
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      color: var(--color-heading);
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      position: relative;
      z-index: 1;
      border: 0 none; }
      @media only screen and (max-width: 767px) {
        .edu-tab-nav li.nav-item button.nav-link {
          padding: 0 15px; } }
      .edu-tab-nav li.nav-item button.nav-link::after {
        position: absolute;
        content: "";
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 77px;
        height: 92%;
        left: -5px;
        top: -3px;
        z-index: -1;
        transition: 0.4s;
        opacity: 0; }
      .edu-tab-nav li.nav-item button.nav-link.active {
        background: var(--color-primary);
        color: var(--color-white); }
        .edu-tab-nav li.nav-item button.nav-link.active::after {
          opacity: 0.4; }

.accordion-shape-1 {
  position: relative;
  z-index: 1; }
  .accordion-shape-1 .shape-image {
    position: absolute;
    z-index: -1;
    top: 156px;
    left: 276px; }

.edu-accordion-area .gallery-wrapper {
  padding-right: 55px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-accordion-area .gallery-wrapper {
      padding-right: 15px; } }
  @media only screen and (max-width: 767px) {
    .edu-accordion-area .gallery-wrapper {
      padding-right: 15px; } }

.accordion-style-1 .edu-accordion-header {
  margin-bottom: 0; }

.accordion-style-1 .edu-accordion-button {
  font-size: 20px;
  border: 0 none;
  background: transparent;
  border-top: 1px solid #EEEEEE;
  width: 100%;
  text-align: left;
  padding: 20px 0;
  font-weight: 700;
  line-height: 32px;
  position: relative; }
  .accordion-style-1 .edu-accordion-button::after {
    position: absolute;
    content: "\e935";
    font-family: 'icomoon';
    color: var(--color-heading);
    font-size: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.4s; }
  .accordion-style-1 .edu-accordion-button[aria-expanded="true"]::after {
    content: "\e934"; }

.accordion-style-1 .edu-accordion-body {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px; }

.accordion-style-1 .edu-accordion-item:last-child .edu-accordion-button {
  border-bottom: 1px solid #EEEEEE; }

.accordion-style-1 .edu-accordion-item:last-child.active-item .edu-accordion-button {
  border-bottom: 0px; }

.accordion-style-1 .edu-accordion-item:last-child.active-item {
  border-bottom: 1px solid #EEEEEE; }

.edu-accordion-02 {
  background: #FFFFFF; }
  .edu-accordion-02 .edu-accordion-button {
    font-size: 20px;
    border: 0 none;
    background: transparent;
    width: 100%;
    text-align: left;
    padding: 13px 35px;
    font-weight: 700;
    line-height: 32px;
    position: relative;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    transition: 0.4s; }
    .edu-accordion-02 .edu-accordion-button::after {
      position: absolute;
      content: "\e969";
      font-family: 'icomoon';
      color: var(--color-heading);
      font-size: 20px;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.4s; }
    .edu-accordion-02 .edu-accordion-button[aria-expanded="true"]::after {
      content: "\EA78";
      font-family: "remixicon"; }
  .edu-accordion-02 .edu-accordion-body {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    padding: 0 35px;
    padding-bottom: 35px; }
    .edu-accordion-02 .edu-accordion-body ul li {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      padding: 14px 0;
      align-items: center;
      margin: 0; }
      .edu-accordion-02 .edu-accordion-body ul li .text i {
        margin-right: 8px; }
      .edu-accordion-02 .edu-accordion-body ul li + li {
        border-top: 1px solid #eee; }
      .edu-accordion-02 .edu-accordion-body ul li:last-child {
        padding-bottom: 0; }
  .edu-accordion-02 .edu-accordion-item {
    transition: 0.4s; }
    .edu-accordion-02 .edu-accordion-item:last-child .edu-accordion-button {
      border-bottom: 1px solid #EEEEEE; }
    .edu-accordion-02 .edu-accordion-item.bg-active {
      background: #FFFFFF;
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
      border-radius: 5px; }
      .edu-accordion-02 .edu-accordion-item.bg-active .edu-accordion-button {
        border: 0 none;
        color: var(--color-primary); }
        .edu-accordion-02 .edu-accordion-item.bg-active .edu-accordion-button::after {
          color: var(--color-primary); }
    .edu-accordion-02 .edu-accordion-item + .edu-accordion-item {
      margin-top: 20px; }
  .edu-accordion-02.variation-2 .edu-accordion-button {
    border: transparent;
    background: #F5F5F5;
    border-radius: 10px;
    padding: 31px 32px;
    padding-right: 69px; }
    @media only screen and (max-width: 1199px) {
      .edu-accordion-02.variation-2 .edu-accordion-button {
        padding: 18px 25px;
        font-size: 18px;
        line-height: 22px; } }
    .edu-accordion-02.variation-2 .edu-accordion-button::after {
      content: "\e935";
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.02);
      color: var(--color-primary);
      line-height: 32px;
      text-align: center;
      border-radius: 100%; }
    .edu-accordion-02.variation-2 .edu-accordion-button[aria-expanded="true"]::after {
      content: "\e934";
      font-family: 'icomoon';
      background: #E0F7F4;
      color: var(--color-primary); }
  .edu-accordion-02.variation-2 .edu-accordion-item .edu-accordion-body {
    padding: 0 31px;
    padding-bottom: 35px; }
    .edu-accordion-02.variation-2 .edu-accordion-item .edu-accordion-body p {
      margin-bottom: 0; }
    @media only screen and (max-width: 1199px) {
      .edu-accordion-02.variation-2 .edu-accordion-item .edu-accordion-body {
        padding: 0px 21px;
        padding-bottom: 25px; } }
  .edu-accordion-02.variation-2 .edu-accordion-item.bg-active {
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px; }
    .edu-accordion-02.variation-2 .edu-accordion-item.bg-active .edu-accordion-button {
      border: 0 none;
      color: var(--color-primary);
      background: transparent; }
  .edu-accordion-02.variation-2 .edu-accordion-item + .edu-accordion-item {
    margin-top: 30px; }
  .edu-accordion-02.variation-2.landing-page-accordion .edu-accordion-button[aria-expanded="true"]::after {
    background: var(--color-primary);
    color: #FFFFFF; }
  .edu-accordion-02.variation-2.landing-page-accordion .edu-accordion-item.bg-active .edu-accordion-button {
    color: var(--color-heading); }

.edu-privacy-policy p:last-child {
  margin-bottom: 0; }

.edu-privacy-policy h1,
.edu-privacy-policy h2,
.edu-privacy-policy h3,
.edu-privacy-policy h4,
.edu-privacy-policy h5,
.edu-privacy-policy h6 {
  margin-bottom: 15px; }

/* Purchase Guide  */
.gallery-column {
  display: flex;
  flex-wrap: wrap;
  margin: -15px; }
  .gallery-column.gallery-column-2 .gallery-image {
    flex-basis: 50%;
    padding: 15px; }

/*------------------------
    Error Styles  
-------------------------*/
.edu-error-style {
  padding-bottom: 130px; }
  .edu-error-style .content .title {
    margin-bottom: 20px; }
  .edu-error-style .content .description {
    margin-bottom: 40px; }
  @media only screen and (max-width: 991px) {
    .edu-error-style {
      padding-bottom: 80px; } }

.circle-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0; }
  .circle-image span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-secondary);
    pointer-events: none;
    animation: eduvibe-rotate-animate 13s linear infinite; }
    .circle-image span:nth-child(1) {
      border-radius: 38% 62% 64% 36% / 43% 35% 65% 57%; }
    .circle-image span:nth-child(2) {
      animation-direction: reverse;
      border-radius: 41% 59% 40% 60% / 65% 66% 34% 35%; }
    .circle-image span:nth-child(3) {
      animation-duration: 3s;
      border-radius: 73% 27% 56% 44% / 57% 74% 26% 43%; }

@keyframes eduvibe-rotate-animate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Comming Soon Style  */
.edu-coming-soon-style {
  text-align: center; }
  .edu-coming-soon-style .content .logo {
    margin-bottom: 80px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .edu-coming-soon-style .content .logo {
        margin-bottom: 40px; } }
    @media only screen and (max-width: 767px) {
      .edu-coming-soon-style .content .logo {
        margin-bottom: 40px; } }
    .edu-coming-soon-style .content .logo a {
      display: block; }
  .edu-coming-soon-style .content .title {
    font-weight: 800;
    font-size: 72px;
    line-height: 1.25;
    margin-bottom: 80px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .edu-coming-soon-style .content .title {
        font-size: 60px;
        margin-bottom: 40px; } }
    @media only screen and (max-width: 767px) {
      .edu-coming-soon-style .content .title {
        font-size: 40px;
        margin-bottom: 40px; } }
  .edu-coming-soon-style .content .countdown-style-2 {
    margin-bottom: 80px; }
    @media only screen and (max-width: 991px) {
      .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container {
        min-width: 150px;
        padding: 25px 10px; }
        .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container .countdown-value {
          font-size: 55px; }
        .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container .countdown-heading {
          font-size: 22px; } }
    @media only screen and (max-width: 767px) {
      .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container {
        min-width: 100px;
        padding: 15px 10px; }
        .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container .countdown-value {
          font-size: 35px; }
        .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container .countdown-heading {
          font-size: 20px; } }
    @media only screen and (max-width: 575px) {
      .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container {
        min-width: 80px;
        padding: 15px 10px; }
        .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container .countdown-value {
          font-size: 25px; }
        .edu-coming-soon-style .content .countdown-style-2 .countdown .countdown-container .countdown-heading {
          font-size: 16px; } }
  .edu-coming-soon-style .content .description {
    margin: 0 auto 40px;
    max-width: 760px; }

.newsletter-form-style-2 {
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 767px) {
    .newsletter-form-style-2 {
      display: block; } }
  .newsletter-form-style-2 input {
    min-width: 407px;
    width: 407px;
    background: #FFFFFF;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border: 0 none;
    padding: 0 25px;
    font-weight: 500;
    height: 60px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .newsletter-form-style-2 input {
        min-width: 300px;
        width: 300px; } }
    @media only screen and (max-width: 767px) {
      .newsletter-form-style-2 input {
        min-width: auto;
        width: 100%; } }
  .newsletter-form-style-2 .edu-btn {
    margin-left: 10px; }
    @media only screen and (max-width: 767px) {
      .newsletter-form-style-2 .edu-btn {
        margin-left: 0;
        margin-top: 20px; } }

/* --------------------
Gallery Item 
------------------- */
.filters-button-group {
  margin: -7.5px;
  flex-wrap: wrap; }
  .filters-button-group button {
    height: 36px;
    padding: 0 20px;
    width: auto;
    border: 0 none;
    background: #F5F5F5;
    border-radius: 3px;
    color: var(--color-heading);
    margin: 7.5px;
    font-weight: 600;
    transition: 0.4s; }
    .filters-button-group button.is-checked, .filters-button-group button:hover {
      background: var(--color-primary);
      color: var(--color-white); }

.edu-gallery-grid-item {
  display: block;
  border-radius: 5px;
  padding: 0 15px !important; }
  .edu-gallery-grid-item .edu-gallery-grid {
    position: relative; }
    .edu-gallery-grid-item .edu-gallery-grid img {
      border-radius: 5px; }
    .edu-gallery-grid-item .edu-gallery-grid::before {
      background: linear-gradient(180deg, rgba(82, 95, 225, 0.3) 10.75%, #525fe1 85%);
      border-radius: 5px;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.4s; }
    .edu-gallery-grid-item .edu-gallery-grid .zoom-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.4s;
      opacity: 0; }
      .edu-gallery-grid-item .edu-gallery-grid .zoom-icon i {
        color: var(--color-white);
        font-size: 25px; }
    .edu-gallery-grid-item .edu-gallery-grid .hover-action {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 30px 40px;
      z-index: 1;
      transition: 0.4s;
      opacity: 0; }
      @media only screen and (max-width: 767px) {
        .edu-gallery-grid-item .edu-gallery-grid .hover-action {
          padding: 20px 20px; } }
      .edu-gallery-grid-item .edu-gallery-grid .hover-action .hover-content .hover-text .title {
        color: var(--color-white);
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 0; }
        @media only screen and (max-width: 767px) {
          .edu-gallery-grid-item .edu-gallery-grid .hover-action .hover-content .hover-text .title {
            font-size: 20px;
            line-height: 32px; } }
  .edu-gallery-grid-item:hover .edu-gallery-grid::before {
    opacity: 1; }
  .edu-gallery-grid-item:hover .edu-gallery-grid .zoom-icon {
    opacity: 1; }
  .edu-gallery-grid-item:hover .edu-gallery-grid .hover-action {
    opacity: 1; }

/*--------------------------
    Pricing Table  
----------------------------*/
 

.pricing-billing-duration {
  text-align: right; }
  @media only screen and (max-width: 767px) {
    .pricing-billing-duration {
      text-align: left; } }
  .pricing-billing-duration ul {
    background: rgba(82, 95, 225, 0.08);
    border-radius: 5px;
    padding: 10px;
    display: inline-block; }
    .pricing-billing-duration ul .nav-item {
      float: left;
      margin: 0; }
      .pricing-billing-duration ul .nav-item .nav-link {
        height: 40px;
        line-height: 40px;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: var(--color-heading);
        border: 0 none;
        background: transparent;
        padding: 0 25px;
        border-radius: 3px; }
        .pricing-billing-duration ul .nav-item .nav-link.active {
          background: var(--color-primary);
          color: var(--color-white); }

.pricing-table {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 60px 64px;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pricing-table {
      padding: 60px 30px; } }
  @media only screen and (max-width: 767px) {
    .pricing-table {
      padding: 60px 30px; } }
  .pricing-table .pricing-header {
    margin-bottom: 40px;
    text-align: center; }
    .pricing-table .pricing-header .price-wrap .monthly-pricing {
      display: none; }
    .pricing-table .pricing-header .price-wrap .amount {
      font-weight: 800;
      font-size: 40px;
      line-height: 56px;
      color: var(--color-primary); }
    .pricing-table .pricing-header .price-wrap .duration {
      color: var(--color-primary);
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      display: inline-block;
      margin-left: -4px; }
    .pricing-table .pricing-header .title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 10px; }
  .pricing-table .pricing-body {
    margin-bottom: 40px; }
    .pricing-table .pricing-body .list-item li {
      display: flex;
      align-items: center;
      margin: 0; }
      .pricing-table .pricing-body .list-item li i {
        color: var(--color-secondary);
        margin-right: 10px; }
      .pricing-table .pricing-body .list-item li + li {
        margin-top: 16px; }
  .pricing-table.active {
    transform: translateY(-20px); }
    .pricing-table.active .edu-badge {
      background: var(--color-secondary);
      padding: 0 17px;
      position: absolute;
      right: 0;
      top: 15px;
      height: 30px;
      line-height: 30px;
      padding-left: 25px; }
      .pricing-table.active .edu-badge span {
        font-weight: 700;
        font-size: 14px;
        color: var(--color-white);
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase; }
      .pricing-table.active .edu-badge::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 24px 0 24px 23px;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        right: 87%;
        top: 50%;
        content: "";
        transform: translateY(-50%); }

/*-------------------------
    Profile Details 
--------------------------*/
.instructor-profile-left {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 40px 20px; }
  .instructor-profile-left .inner {
    text-align: center; }
    .instructor-profile-left .inner .thumbnail {
      max-width: 230px;
      max-height: 230px;
      margin: 0 auto;
      margin-bottom: 30px; }
      .instructor-profile-left .inner .thumbnail img {
        border-radius: 100%;
        width: 100%;
        height: 100%; }
    .instructor-profile-left .inner .content .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 2px; }
    .instructor-profile-left .inner .content .subtitle {
      font-weight: 600;
      line-height: 26px;
      color: var(--color-primary);
      display: block;
      margin-bottom: 25px; }
    .instructor-profile-left .inner .content .contact-with-info {
      margin-bottom: 25px; }
      .instructor-profile-left .inner .content .contact-with-info p {
        margin-bottom: 5px; }
        .instructor-profile-left .inner .content .contact-with-info p span {
          font-weight: 600;
          color: var(--color-heading); }
        .instructor-profile-left .inner .content .contact-with-info p a {
          color: var(--color-body); }
    .instructor-profile-left .inner .content .contact-btn {
      margin-top: 40px; }

/* Course Statictatic  */
.course-statistic-wrapper .line-separator {
  position: relative; }
  .course-statistic-wrapper .line-separator + .line-separator::before {
    position: absolute;
    content: "";
    background: #FFFFFF;
    height: 66px;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.15;
    left: 0; }

.course-statistic .inner .total {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  color: var(--color-white);
  margin-bottom: 0; }

.course-statistic .inner p {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-white);
  margin-bottom: 0;
  letter-spacing: 0.1em;
  text-transform: uppercase; }

.single-progress {
  position: relative;
  overflow: hidden; }
  .single-progress .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-heading);
    margin-bottom: 10px; }

.progress {
  height: 6px;
  overflow: visible;
  background-color: rgba(82, 95, 225, 0.15);
  border-radius: 2px; }
  .progress .progress-bar {
    border-radius: 2px;
    width: 0;
    height: 100%;
    background-color: var(--color-primary);
    transition: width .5s ease;
    overflow: visible; }
  .progress .progress-number {
    position: absolute;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    top: 0;
    right: 0;
    z-index: 1;
    color: var(--color-heading); }

/*-------------------------
    Contact Us Area  
---------------------------*/
.contact-address-card-1 {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 3px 15px;
  text-align: left;
  transition: 0.4s; }
  .contact-address-card-1 .inner .icon {
    background: rgba(82, 95, 225, 0.1);
    width: 50px;
    height: 50px;
    padding: 0 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s; }
    .contact-address-card-1 .inner .icon i {
      color: var(--color-primary);
      font-size: 24px;
      transition: 0.4s; }
  .contact-address-card-1 .inner .content .title {
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
    transition: 0.4s; }
  .contact-address-card-1 .inner .content p {
    margin-bottom: 0;
    transition: 0.4s; }
    .contact-address-card-1 .inner .content p a {
      color: var(--color-body);
      transition: 0.4s; }
      .contact-address-card-1 .inner .content p a:hover {
        color: var(--color-primary); }
  .contact-address-card-1.phone .inner .icon {
    background: rgba(251, 124, 86, 0.1); }
    .contact-address-card-1.phone .inner .icon i {
      color: var(--color-secondary); }
  .contact-address-card-1.email .inner .icon {
    background: rgba(255, 164, 27, 0.1); }
    .contact-address-card-1.email .inner .icon i {
      color: #FFA41B; }
  .contact-address-card-1:hover {
    background: var(--color-secondary);
    transform: translateY(-15px); }
    .contact-address-card-1:hover .inner .icon {
      background: var(--color-white); }
      .contact-address-card-1:hover .inner .icon i {
        color: var(--color-secondary); }
    .contact-address-card-1:hover .inner .content .title {
      color: var(--color-white); }
    .contact-address-card-1:hover .inner .content p {
      color: var(--color-white); }
      .contact-address-card-1:hover .inner .content p a {
        color: var(--color-white); }

.contact-address-card-2 {
  background: #FFFFFF;
  box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 70px 40px;
  text-align: center;
  transition: 0.4s; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-address-card-2 {
      padding: 60px 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-address-card-2 {
      padding: 60px 20px; } }
  .contact-address-card-2 .inner .icon {
    margin-bottom: 45px; }
  .contact-address-card-2 .inner .content .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px; }
  .contact-address-card-2 .inner .content p {
    margin-bottom: 0;
    line-height: 26px; }
    .contact-address-card-2 .inner .content p .subtitle {
      font-weight: 600;
      color: var(--color-heading); }
    .contact-address-card-2 .inner .content p .text {
      font-weight: 500; }
  .contact-address-card-2:hover {
    transform: translateY(-15px); }

.contact-address-bottom-shape {
  position: relative;
  z-index: 1; }
  .contact-address-bottom-shape .bg-shape-image {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1; }

/*------------------------------
    Contact Form Style One 
-------------------------------*/
.rwt-dynamic-form .form-group input {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 0 30px;
  border: 1px solid transparent;
  transition: 0.4s;
  outline: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  height: 60px; }
  .rwt-dynamic-form .form-group input:focus {
    border-color: var(--color-primary); }

.rwt-dynamic-form .form-group textarea {
  background: #F5F5F5;
  padding: 17px 30px;
  min-height: 180px;
  border: 0 none;
  border: 1px solid transparent;
  transition: 0.4s;
  outline: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  border-radius: 5px; }
  .rwt-dynamic-form .form-group textarea:focus {
    border-color: var(--color-primary); }

.rwt-dynamic-form .success-message {
  margin-top: 20px;
  color: #019267; }

/*---------------------------
    About Me 
----------------------------*/
.about-me-1 .thumbnail {
  position: relative;
  border-radius: 5px; }
  .about-me-1 .thumbnail::before {
    background: linear-gradient(360deg, #2D284E 0%, rgba(45, 40, 78, 0) 70.5%);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px; }
  .about-me-1 .thumbnail img {
    border-radius: 5px; }
  .about-me-1 .thumbnail .content-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 50px 20px; }
    @media only screen and (max-width: 767px) {
      .about-me-1 .thumbnail .content-overlay {
        padding: 20px 20px; } }
    .about-me-1 .thumbnail .content-overlay .title {
      margin-bottom: 0;
      color: var(--color-white);
      font-size: 32px;
      line-height: 44px; }
      @media only screen and (max-width: 767px) {
        .about-me-1 .thumbnail .content-overlay .title {
          font-size: 22px;
          line-height: 32px; } }

.google-map.alignwide {
  margin-left: -105px;
  margin-right: -105px;
  min-width: 1170px;
  width: auto; }
  @media only screen and (max-width: 1650px) {
    .google-map.alignwide {
      margin-left: 0;
      margin-right: 0;
      min-width: auto; } }
  .google-map.alignwide iframe {
    width: 100%; }

/*-----------------------
    Shop Style  
------------------------*/
.eduvibe-shop-single-product .inner {
  text-align: center; }
  .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper {
    position: relative;
    transform-style: preserve-3d;
    perspective: 1000px; }
    .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper a {
      display: inline-block; }
      .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper a img {
        width: 100%;
        border-radius: 5px;
        transition: 0.4s;
        overflow: hidden; }
    .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info {
      position: absolute;
      bottom: 32px;
      left: 0;
      width: 100%;
      transition: 0.4s;
      opacity: 1;
      z-index: 1; }
      .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info ul {
        display: flex;
        flex-direction: column; }
        .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info ul li {
          position: relative;
          margin: 0 auto; }
          .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info ul li a {
            width: 152px;
            height: 40px;
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 40px;
            background: var(--color-white);
            color: var(--color-primary);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info ul li a .eduvibe-product-popup-icon {
              margin-right: 4px;
              display: inline-flex; }
          .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info ul li + li::after {
            position: absolute;
            content: "";
            background: rgba(255, 255, 255, 0.2);
            width: 1px;
            height: 100%;
            left: 0;
            top: 0; }
          .eduvibe-shop-single-product .inner .eduvibe-single-product-thumb-wrapper .product-hover-info ul li:first-child {
            margin-bottom: 8px; }
  .eduvibe-shop-single-product .inner .content {
    padding-top: 20px; }
    .eduvibe-shop-single-product .inner .content .product-author {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      display: block;
      margin-bottom: 5px; }
    .eduvibe-shop-single-product .inner .content .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 5px; }
    .eduvibe-shop-single-product .inner .content .price-list.price-style-03 {
      margin: -8px; }
      .eduvibe-shop-single-product .inner .content .price-list.price-style-03 .price {
        margin: 8px; }
      .eduvibe-shop-single-product .inner .content .price-list.price-style-03 .current-price {
        font-size: 18px;
        line-height: 28px;
        color: var(--color-primary); }
      .eduvibe-shop-single-product .inner .content .price-list.price-style-03 .old-price {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px; }

.eduvibe-shop-single-product:hover .inner .eduvibe-single-product-thumb-wrapper .product-hover-info {
  opacity: 1; }

/*-----------------------
    Brand Styles  
-----------------------*/
.brand-list li {
  margin: 0;
  text-align: center; }
  .brand-list li a {
    display: block;
    text-align: center; }
    .brand-list li a img {
      display: inline-block;
      text-align: center; }

/*--------------------------
    Checkout Page Style  
----------------------------*/
.checkout-page-style .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 15px;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 30px; }

.checkout-page-style input {
  background: #F5F5F5;
  border-radius: 5px;
  border: 0 none;
  height: 60px;
  padding: 0 25px;
  font-weight: 500;
  color: var(--color-body);
  border: 1px solid transparent; }
  .checkout-page-style input:focus {
    border-color: var(--color-primary); }

.checkout-page-style select {
  border: 1px solid #6F6B80;
  border-radius: 5px;
  padding: 0 25px;
  height: 60px;
  font-weight: 500;
  color: var(--color-body);
  position: relative;
 }

.checkout-page-style textarea {
  background: #F5F5F5;
  border-radius: 5px;
  border: 0 none;
  padding: 15px 25px;
  font-weight: 500;
  color: var(--color-body);
  min-height: 170px;
  resize: none;
  border: 1px solid transparent; }
  .checkout-page-style textarea:focus {
    border-color: var(--color-primary); }

.checkout-page-style .box-label {
  margin-bottom: 8px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-heading); }

.checkout-page-style .shop_table {
  margin-bottom: 20px; }
  .checkout-page-style .shop_table thead tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-heading);
    border-bottom: 1px solid #EEEEEE;
    padding: 10px 0;
    margin-bottom: 10px; }
  .checkout-page-style .shop_table tbody tr td {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-body);
    border: 0 none;
    padding: 5px 0; }
  .checkout-page-style .shop_table tbody tr:first-child td {
    padding-top: 20px; }
  .checkout-page-style .shop_table tfoot tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-heading);
    padding: 5px 0; }
  .checkout-page-style .shop_table tfoot tr.order-subtotal th {
    font-weight: 500;
    color: var(--color-body); }

/**************************************
    Blog Styles
***************************************/
/*-------------------------
    Blog Grid Styles  
---------------------------*/
.blog-shape-position {
  position: relative;
  z-index: 1; }
  .blog-shape-position .shape-image {
    position: absolute;
    z-index: -1;
    top: 171px;
    left: 138px; }
  .blog-shape-position.shape-position-3 .shape-image {
    top: 70px;
    left: 106px; }

.blog-meta {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -25px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-meta {
      margin: -5px -10px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-meta {
      margin: -5px -10px; } }
  @media only screen and (max-width: 767px) {
    .blog-meta {
      margin: -5px -10px; } }
  .blog-meta li {
    margin: 5px 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .blog-meta li {
        margin: 5px 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .blog-meta li {
        margin: 5px 10px; } }
    @media only screen and (max-width: 767px) {
      .blog-meta li {
        margin: 5px 10px; } }
    .blog-meta li i,
    .blog-meta li img {
      padding-right: 8px;
      position: relative;
      top: 2px; }
    .blog-meta li a {
      color: var(--color-primary); }
    .blog-meta li.blog-author a {
      color: var(--color-body); }

/* Blog Grid Styles  */
.edu-blog .top-position {
  position: absolute;
  z-index: 2; }
  .edu-blog .top-position.left-top {
    left: 20px;
    top: 20px; }
  .edu-blog .top-position.left-bottom {
    left: 0;
    bottom: 0;
    border-radius: 0px 5px 0px 0px; }

.edu-blog .thumbnail {
  position: relative; }
  .edu-blog .thumbnail a {
    display: block;
    overflow: hidden; }
    .edu-blog .thumbnail a img {
      transition: 0.4s;
      overflow: hidden;
      width: 100%; }

.edu-blog .blog-card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.edu-blog.blog-type-1 {
  background: var(--color-shade);
  overflow: hidden;
  position: relative; }
  .edu-blog.blog-type-1 .inner .content {
    padding: 35px; }
    @media only screen and (max-width: 767px) {
      .edu-blog.blog-type-1 .inner .content {
        padding: 22px; } }
    .edu-blog.blog-type-1 .inner .content .blog-meta {
      margin-bottom: 20px; }
    .edu-blog.blog-type-1 .inner .content .title {
      margin-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .edu-blog.blog-type-1 .inner .content .title {
          margin-bottom: 14px; } }
    .edu-blog.blog-type-1 .inner .content .description {
      font-weight: 500;
      margin-bottom: 0; }

.edu-blog.blog-type-2 {
  background: var(--color-shade);
  overflow: hidden;
  position: relative;
  transition: 0.4s; }
  .edu-blog.blog-type-2 .inner .thumbnail img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .edu-blog.blog-type-2 .inner .content {
    padding: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .edu-blog.blog-type-2 .inner .content {
        padding: 20px; } }
    @media only screen and (max-width: 767px) {
      .edu-blog.blog-type-2 .inner .content {
        padding: 20px; } }
    .edu-blog.blog-type-2 .inner .content .title {
      margin-bottom: 20px;
      margin-top: 20px; }
      @media only screen and (max-width: 767px) {
        .edu-blog.blog-type-2 .inner .content .title {
          margin-bottom: 14px;
          margin-top: 14px; } }
  .edu-blog.blog-type-2.variation-2 {
    padding: 15px;
    background: #FFFFFF;
    transition: 0.4s; }
    .edu-blog.blog-type-2.variation-2 .inner .content {
      padding: 15px;
      padding-top: 0; }
      .edu-blog.blog-type-2.variation-2 .inner .content .blog-date-status {
        width: 64px;
        height: 64px;
        background: var(--color-primary);
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -32px;
        margin-bottom: 18px;
        position: relative;
        z-index: 1; }
        .edu-blog.blog-type-2.variation-2 .inner .content .blog-date-status span {
          font-weight: bold;
          font-size: 20px;
          display: block;
          color: var(--color-white);
          text-align: center; }
          .edu-blog.blog-type-2.variation-2 .inner .content .blog-date-status span.day {
            line-height: 1;
            margin-bottom: 1px; }
          .edu-blog.blog-type-2.variation-2 .inner .content .blog-date-status span.month {
            line-height: 1; }
      .edu-blog.blog-type-2.variation-2 .inner .content .title {
        margin-bottom: 0;
        margin-top: 14px; }
    .edu-blog.blog-type-2.variation-2:hover {
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.04); }
  .edu-blog.blog-type-2:hover {
    background: var(--color-white);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05); }
  .edu-blog.blog-type-2.bg-white {
    background: var(--color-white); }
    .edu-blog.blog-type-2.bg-white:hover {
      background: var(--color-white);
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05); }

.edu-blog.blog-type-3 {
  background: var(--color-shade);
  overflow: hidden;
  position: relative;
  transition: 0.4s; }
  .edu-blog.blog-type-3 .inner {
    padding: 30px; }
    .edu-blog.blog-type-3 .inner .content .title {
      margin-bottom: 30px;
      margin-top: 20px; }
      @media only screen and (max-width: 767px) {
        .edu-blog.blog-type-3 .inner .content .title {
          margin-bottom: 20px; } }
    .edu-blog.blog-type-3 .inner .thumbnail {
      margin-top: 30px;
      overflow: hidden; }
      .edu-blog.blog-type-3 .inner .thumbnail a {
        border-radius: 3px; }
        .edu-blog.blog-type-3 .inner .thumbnail a img {
          border-radius: 3px; }
  .edu-blog.blog-type-3:hover {
    background: var(--color-white);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05); }

.edu-blog.blog-type-4 {
  background: var(--color-white);
  overflow: hidden;
  position: relative;
  height: 100%; }
  .edu-blog.blog-type-4 .inner .content {
    padding: 25px 30px; }
    .edu-blog.blog-type-4 .inner .content .blog-meta {
      margin: -5px -7.5px; }
      .edu-blog.blog-type-4 .inner .content .blog-meta li {
        font-size: 14px;
        line-height: 22px;
        margin: 5px 7.5px; }
    .edu-blog.blog-type-4 .inner .content .title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px; }

.edu-blog:hover .inner .thumbnail a img {
  transform: scale(1.1); }

.edu-blog.blog-overlay-transparent {
  position: relative; }
  .edu-blog.blog-overlay-transparent .thumbnail {
    overflow: hidden; }
    .edu-blog.blog-overlay-transparent .thumbnail a {
      position: relative;
      display: block;
      overflow: hidden;
      border-radius: 5px; }
      .edu-blog.blog-overlay-transparent .thumbnail a::after {
        background: linear-gradient(180deg, rgba(45, 40, 78, 0) 0%, #2D284E 100%);
        border-radius: 5px;
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%; }
      .edu-blog.blog-overlay-transparent .thumbnail a img {
        border-radius: 5px;
        overflow: hidden; }
  .edu-blog.blog-overlay-transparent .content {
    position: absolute;
    bottom: 0;
    padding: 40px;
    left: 0; }
    @media only screen and (max-width: 767px) {
      .edu-blog.blog-overlay-transparent .content {
        padding: 20px; } }
    .edu-blog.blog-overlay-transparent .content .eduvibe-status-list {
      margin-bottom: 5px; }
      .edu-blog.blog-overlay-transparent .content .eduvibe-status-list .eduvibe-status-transparent {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: var(--color-white);
        display: inline-block; }
    .edu-blog.blog-overlay-transparent .content .title {
      margin-bottom: 25px;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px; }
      @media only screen and (max-width: 767px) {
        .edu-blog.blog-overlay-transparent .content .title {
          margin-bottom: 16px;
          font-size: 24px;
          line-height: 32px; } }
      .edu-blog.blog-overlay-transparent .content .title a {
        color: var(--color-white); }
    .edu-blog.blog-overlay-transparent .content .blog-meta {
      margin: -5px -7.5px; }
      .edu-blog.blog-overlay-transparent .content .blog-meta li {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        margin: 5px 7.5px;
        color: var(--color-white); }
        .edu-blog.blog-overlay-transparent .content .blog-meta li a {
          color: var(--color-white); }

/*--------------------------
    Blog Sidebar Styles 
----------------------------*/
.edu-blog-widget {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 30px; }
  @media only screen and (max-width: 767px) {
    .edu-blog-widget {
      padding: 20px; } }
  .edu-blog-widget .widget-title {
    padding-bottom: 20px;
    margin-bottom: 28px;
    border-bottom: 1px solid rgba(45, 40, 78, 0.07);
    font-weight: 700;
    font-size: 24px;
    line-height: 36px; }
  .edu-blog-widget.widget-search .blog-search {
    position: relative; }
    .edu-blog-widget.widget-search .blog-search input {
      border: 0 none;
      background: var(--color-white);
      height: 60px;
      width: 100%;
      border-radius: 3px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      padding: 0 72px 0 25px; }
    .edu-blog-widget.widget-search .blog-search .search-button {
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--color-primary);
      border: 0 none;
      height: 100%;
      width: 60px;
      text-align: center;
      color: var(--color-white);
      font-size: 20px;
      border-radius: 0px 3px 3px 0px; }
  .edu-blog-widget.widget-categories .category-list li {
    margin: 0; }
    .edu-blog-widget.widget-categories .category-list li a {
      background: #FFFFFF;
      border-radius: 3px;
      height: 60px;
      line-height: 60px;
      padding: 0 25px;
      display: flex;
      justify-content: space-between;
      position: relative; }
      .edu-blog-widget.widget-categories .category-list li a::after {
        position: absolute;
        content: "";
        background: #EEEEEE;
        height: 30px;
        width: 1px;
        right: 60px;
        top: 50%;
        transform: translateY(-50%); }
    .edu-blog-widget.widget-categories .category-list li + li {
      margin-top: 15px; }
  .edu-blog-widget.widget-latest-post .latest-post {
    display: flex;
    align-items: center;
    position: relative; }
    .edu-blog-widget.widget-latest-post .latest-post .thumbnail {
      margin-right: 25px; }
      @media only screen and (max-width: 767px) {
        .edu-blog-widget.widget-latest-post .latest-post .thumbnail {
          margin-right: 10px; } }
      .edu-blog-widget.widget-latest-post .latest-post .thumbnail a img {
        border-radius: 3px;
        max-height: 96px;
        object-fit: cover; }
    .edu-blog-widget.widget-latest-post .latest-post .post-content .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0; }
    .edu-blog-widget.widget-latest-post .latest-post + .latest-post {
      padding-top: 20px;
      margin-top: 20px; }
      .edu-blog-widget.widget-latest-post .latest-post + .latest-post::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba(45, 40, 78, 0.07);
        content: ""; }
  .edu-blog-widget.widget-about .about-wrapper {
    text-align: center; }
    .edu-blog-widget.widget-about .about-wrapper .thumbnail {
      margin-bottom: 20px; }
      .edu-blog-widget.widget-about .about-wrapper .thumbnail img {
        max-height: 160px;
        border-radius: 100%; }
    .edu-blog-widget.widget-about .about-wrapper .about-content .title {
      margin-bottom: 15px; }
    .edu-blog-widget.widget-about .about-wrapper .about-content p {
      margin-bottom: 20px; }
  .edu-blog-widget.widget-instagram .instagram-list {
    display: flex;
    flex-wrap: wrap;
    margin: -7.5px; }
    .edu-blog-widget.widget-instagram .instagram-list a {
      flex-basis: 33.33%;
      display: block;
      padding: 7.5px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .edu-blog-widget.widget-instagram .instagram-list a {
          flex-basis: 20%; } }
      @media only screen and (max-width: 767px) {
        .edu-blog-widget.widget-instagram .instagram-list a {
          flex-basis: 20%; } }
      @media only screen and (max-width: 575px) {
        .edu-blog-widget.widget-instagram .instagram-list a {
          flex-basis: 50%; } }
      .edu-blog-widget.widget-instagram .instagram-list a img {
        border-radius: 3px;
        width: 100%; }

.tag-list {
  margin: -5px; }
  .tag-list a {
    background: #FFFFFF;
    border-radius: 2px;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    margin: 5px;
    height: 40px;
    padding: 0 14px;
    transition: 0.4s; }
    .tag-list a:hover {
      background: var(--color-primary) !important;
      color: var(--color-white); }
  .tag-list.bg-shade a {
    background: #F5F5F5; }

/*-----------------------------
    Sidebar Style Two  
-------------------------------*/
.edu-blog-widget-2 .widget-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 30px; }

.edu-blog-widget-2.widget-search .blog-search {
  position: relative; }
  .edu-blog-widget-2.widget-search .blog-search input {
    border: 0 none;
    background: #F5F5F5;
    height: 60px;
    width: 100%;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 0 72px 0 25px; }
  .edu-blog-widget-2.widget-search .blog-search .search-button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: 0 none;
    height: 100%;
    width: 60px;
    text-align: center;
    color: var(--color-primary);
    font-size: 20px;
    border-radius: 0; }

.edu-blog-widget-2.widget-categories .category-list li {
  margin: 0; }
  .edu-blog-widget-2.widget-categories .category-list li a {
    display: flex;
    justify-content: space-between;
    position: relative; }
  .edu-blog-widget-2.widget-categories .category-list li + li {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #EEEEEE; }

.edu-blog-widget-2.widget-latest-post .latest-post {
  display: flex;
  align-items: center;
  position: relative; }
  .edu-blog-widget-2.widget-latest-post .latest-post .thumbnail {
    margin-right: 25px; }
    .edu-blog-widget-2.widget-latest-post .latest-post .thumbnail a {
      display: block; }
      .edu-blog-widget-2.widget-latest-post .latest-post .thumbnail a img {
        border-radius: 5px;
        max-height: 100px;
        object-fit: cover; }
  .edu-blog-widget-2.widget-latest-post .latest-post .post-content .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0; }
  .edu-blog-widget-2.widget-latest-post .latest-post + .latest-post {
    padding-top: 20px;
    margin-top: 20px; }
    .edu-blog-widget-2.widget-latest-post .latest-post + .latest-post::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: rgba(45, 40, 78, 0.07);
      content: ""; }

/*----------------------------
    Blog Details Styles  
------------------------------*/
.social-share-action {
  position: relative; }
  .social-share-action .social-share-hover-action {
    position: absolute;
    bottom: calc(100% + 16px);
    background: #FFFFFF;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 15px;
    min-width: 150px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.4s;
    visibility: hidden; }
    .social-share-action .social-share-hover-action::after {
      position: absolute;
      content: "";
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #FFFFFF transparent transparent transparent;
      left: 50%;
      transform: translateX(-50%); }
    .social-share-action .social-share-hover-action .inner {
      margin: 0 -15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .social-share-action .social-share-hover-action .inner a {
        display: inline-block;
        margin: 0 15px;
        color: var(--color-body);
        transition: 0.4s; }
        .social-share-action .social-share-hover-action .inner a:hover {
          color: var(--color-primary); }
  .social-share-action:hover .social-share-hover-action {
    opacity: 1;
    visibility: visible; }

.blog-details-1 .block-alignwide {
  margin-left: -105px;
  margin-right: -105px;
  max-width: 1170px;
  width: auto; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-details-1 .block-alignwide {
      margin-left: 0;
      margin-right: 0;
      max-width: initial; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-details-1 .block-alignwide {
      margin-left: 0;
      margin-right: 0;
      max-width: initial; } }
  @media only screen and (max-width: 767px) {
    .blog-details-1 .block-alignwide {
      margin-left: 0;
      margin-right: 0;
      max-width: initial; } }
  .blog-details-1 .block-alignwide img {
    width: 100%; }

.content-blog-top .title {
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 32px; }

.content-blog-top .blog-meta {
  margin-bottom: 30px; }
  .content-blog-top .blog-meta li.blog-author a {
    color: var(--color-body); }

.blog-main-content .gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
  margin-bottom: 15px; }
  .blog-main-content .gallery li {
    padding: 15px;
    margin: 0; }
    .blog-main-content .gallery li img {
      border-radius: 5px; }
  .blog-main-content .gallery.gallery-column-2 li {
    flex-basis: 50%; }
    @media only screen and (max-width: 575px) {
      .blog-main-content .gallery.gallery-column-2 li {
        flex-basis: 100%; } }

.blog-main-content .blockquote-style-1 {
  border-radius: 4px;
  background: var(--color-primary);
  padding: 25px 50px;
  color: var(--color-white);
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  font-style: normal;
  margin-bottom: 30px;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .blog-main-content .blockquote-style-1 {
      padding: 25px; } }
  .blog-main-content .blockquote-style-1 .quote-image {
    position: absolute;
    right: 30px;
    bottom: 28px;
    opacity: 0.2;
    z-index: -1; }

.list-style-1 li {
  display: flex;
  align-items: flex-start;
  margin: 15px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px; }
  .list-style-1 li i {
    color: var(--color-primary);
    margin-right: 13px;
    margin-top: 5px; }

.list-style-2 {
  list-style: disc;
  padding-left: 17px;
  margin-bottom: 20px; }
  .list-style-2 li {
    margin: 15px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px; }

.column-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
  margin-bottom: 15px; }
  .column-gallery li {
    padding: 15px;
    margin: 0; }
    .column-gallery li img {
      border-radius: 5px; }
  .column-gallery.gallery-column-2 li {
    flex-basis: 50%; }
    @media only screen and (max-width: 575px) {
      .column-gallery.gallery-column-2 li {
        flex-basis: 100%; } }

.blog-tag-and-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #EEEEEE;
  flex-wrap: wrap; }
  @media only screen and (max-width: 575px) {
    .blog-tag-and-share {
      display: block; } }
  .blog-tag-and-share .blog-share {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 575px) {
      .blog-tag-and-share .blog-share {
        margin-top: 20px; } }
    .blog-tag-and-share .blog-share .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: var(--color-heading);
      padding-right: 20px; }
  .blog-tag-and-share .share-btn {
    width: 40px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 2px;
    padding: 0;
    border: 0 none;
    color: var(--color-primary);
    transition: 0.4s; }
    .blog-tag-and-share .share-btn:hover {
      background: var(--color-primary);
      color: var(--color-white); }

.blog-author-wrapper {
  padding: 30px 0;
  display: flex; }
  @media only screen and (max-width: 575px) {
    .blog-author-wrapper {
      display: block; } }
  .blog-author-wrapper .thumbnail {
    min-width: 160px;
    max-height: 160px;
    margin-right: 40px; }
    @media only screen and (max-width: 767px) {
      .blog-author-wrapper .thumbnail {
        margin-right: 20px; } }
    @media only screen and (max-width: 575px) {
      .blog-author-wrapper .thumbnail {
        min-width: auto;
        max-height: initial;
        margin-right: 0;
        margin-bottom: 20px; } }
    .blog-author-wrapper .thumbnail img {
      height: 100%;
      object-fit: cover;
      border-radius: 5px; }
  .blog-author-wrapper .author-content .title {
    margin-bottom: 10px; }
  .blog-author-wrapper .author-content p {
    margin-bottom: 20px; }
  .blog-author-wrapper .author-content .social-share.icon-transparent {
    margin: 0 -15px; }
    .blog-author-wrapper .author-content .social-share.icon-transparent li {
      margin: 0 15px; }
      .blog-author-wrapper .author-content .social-share.icon-transparent li a {
        color: var(--color-primary);
        font-size: 14px; }

/*---------------------------
    Blog Details Style Two  
------------------------------*/
.style-variation2 .content-blog-top .title {
  margin-top: 0; }

.style-variation2 .content-blog-top .blog-meta {
  margin-bottom: 25px; }
  .style-variation2 .content-blog-top .blog-meta li.blog-author a {
    color: var(--color-body); }

.style-variation2 .blockquote-style-1 {
  background: #F5F5F5;
  border-radius: 4px;
  border-left: 3px solid var(--color-primary);
  color: var(--color-heading); }
  .style-variation2 .blockquote-style-1 .quote-image {
    opacity: 0.7; }

/*---------------------------
    Blog Details Style 3  
------------------------------*/
.style-variation3 .content-blog-top .title {
  margin-top: 0;
  margin-bottom: 30px; }

.style-variation3 .content-blog-top .blog-meta {
  margin-bottom: 0; }
  .style-variation3 .content-blog-top .blog-meta li.blog-author a {
    color: var(--color-body); }

.style-variation3 .blockquote-style-1 {
  background: #FFFFFF;
  border-radius: 5px;
  border-top: 3px solid var(--color-primary);
  color: var(--color-primary);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.06);
  text-align: center;
  padding: 25px 68px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .style-variation3 .blockquote-style-1 {
      padding: 25px 30px; } }
  @media only screen and (max-width: 767px) {
    .style-variation3 .blockquote-style-1 {
      padding: 25px 30px; } }
  .style-variation3 .blockquote-style-1 .quote-image {
    opacity: 0.7;
    right: 15px;
    bottom: 8px; }

/*-----------------------------
    Blog Comments Style  
------------------------------*/
.comment-list-wrapper .comment {
  display: flex; }
  @media only screen and (max-width: 575px) {
    .comment-list-wrapper .comment {
      flex-direction: column; }
      .comment-list-wrapper .comment .comment-content {
        margin-top: 20px; } }
  .comment-list-wrapper .comment .thumbnail {
    min-width: 70px;
    width: 70px;
    max-height: 70px;
    border-radius: 100%;
    margin-right: 25px; }
    .comment-list-wrapper .comment .thumbnail img {
      border-radius: 100%;
      width: 100%; }
  .comment-list-wrapper .comment .comment-content .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0; }
  .comment-list-wrapper .comment .comment-content .date {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-body);
    display: block;
    margin-bottom: 20px; }
  .comment-list-wrapper .comment .comment-content p {
    margin-bottom: 20px; }
  .comment-list-wrapper .comment .comment-content .reply-btn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px; }
    .comment-list-wrapper .comment .comment-content .reply-btn i {
      margin-right: 10px; }
  .comment-list-wrapper .comment + .comment {
    border-top: 1px solid #EEEEEE;
    padding-top: 30px;
    margin-top: 30px; }

/*-----------------------------
    Blog Comments Form
------------------------------*/
.comment-form-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; }
  .comment-form-top .blog-page-title {
    margin-bottom: 0; }
  .comment-form-top .cancle-btn a {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    transition: 0.3s; }
    .comment-form-top .cancle-btn a:hover {
      color: var(--color-primary); }

.comment-form-style-1 .comment-note {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px; }

.comment-form-style-1 input,
.comment-form-style-1 textarea {
  background: #F5F5F5;
  border-radius: 5px;
  border: 0 none;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid transparent; }
  .comment-form-style-1 input:focus,
  .comment-form-style-1 textarea:focus {
    border-color: var(--color-primary); }

.comment-form-style-1 input {
  padding: 0 30px;
  height: 60px;
  line-height: 60px; }

.comment-form-style-1 textarea {
  min-height: 180px;
  padding: 17px 30px; }

/*--------------------------------
    Blog Style Pagination 
---------------------------------*/
.blog-pagination-list {
  background-color: #F5F5F5;
  border-radius: 5px;
  background-image: inherit;
  transition: 0.4s;
  position: relative;
  z-index: 2; }
  .blog-pagination-list::after {
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: 0.4s;
    border-radius: 5px; }
  .blog-pagination-list a {
    display: flex;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 1;
    color: var(--color-body); }
    .blog-pagination-list a span {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      transition: 0.4s;
      position: relative;
      z-index: 2; }
    .blog-pagination-list a i {
      font-size: 20px;
      transition: 0.4s;
      margin-right: 20px; }
    .blog-pagination-list a::before {
      position: absolute;
      content: "";
      background: #2D284E;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition: 0.4s;
      opacity: 0;
      border-radius: 5px; }
  .blog-pagination-list:hover::after {
    opacity: 1; }
  .blog-pagination-list:hover a {
    color: var(--color-white); }
    .blog-pagination-list:hover a::before {
      opacity: 0.79; }
  .blog-pagination-list.next-post {
    text-align: right; }
    .blog-pagination-list.next-post a i {
      margin-right: 0;
      margin-left: 20px; }
  .blog-pagination-list.style-variation-2 {
    transition: 0.4s;
    border-bottom: 2px solid transparent; }
    .blog-pagination-list.style-variation-2 a {
      color: var(--color-heading); }
      .blog-pagination-list.style-variation-2 a::before {
        display: none; }
    .blog-pagination-list.style-variation-2::after {
      display: none; }
    .blog-pagination-list.style-variation-2:hover {
      background: #FFFFFF;
      border-color: var(--color-primary);
      box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.06); }

/**************************************
    Template Styles
***************************************/
/*-----------------------
 * Banner Style One
------------------------*/
.height-850 {
  min-height: 850px;
  width: 100%;
  padding: 240px 0 190px; }
  @media only screen and (max-width: 767px) {
    .height-850 {
      min-height: auto;
      width: 100%;
      padding: 250px 0; } }

.height-940 {
  min-height: 940px;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .height-940 {
      min-height: auto;
      width: 100%;
      padding: 250px 0; } }

.banner-style-1 {
  position: relative;
  z-index: 2; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-style-1 {
      height: auto;
      min-height: auto;
      padding: 100px 0;
      padding-top: 150px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-style-1 {
      height: auto;
      min-height: auto;
      padding: 100px 0;
      padding-top: 150px; } }
  @media only screen and (max-width: 767px) {
    .banner-style-1 {
      height: auto;
      min-height: auto;
      padding: 100px 0;
      padding-top: 150px; } }
  .banner-style-1 .banner-right-content {
    position: relative;
    margin-top: 138px; }
    @media only screen and (max-width: 1199px) {
      .banner-style-1 .banner-right-content {
        margin-top: 0px; } }
    .banner-style-1 .banner-right-content .edu-card {
      min-width: 272px; }
      @media only screen and (max-width: 767px) {
        .banner-style-1 .banner-right-content .edu-card {
          min-width: inherit; } }
  .banner-style-1 .banner-left-content {
    margin-top: 120px; }
    @media only screen and (max-width: 1199px) {
      .banner-style-1 .banner-left-content {
        margin-top: 0; } }
  .banner-style-1 .inner .content .pre-title {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-primary);
    display: block;
    margin-bottom: 10px; }
  .banner-style-1 .inner .content .title {
    font-weight: 800;
    font-size: 72px;
    line-height: 1.25;
    margin-bottom: 30px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-1 .inner .content .title {
        font-size: 52px;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-1 .inner .content .title {
        font-size: 45px; } }
    @media only screen and (max-width: 767px) {
      .banner-style-1 .inner .content .title {
        font-size: 38px; } }
  .banner-style-1 .inner .content .description {
    margin-bottom: 60px;
    padding-left: 20px;
    position: relative;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-1 .inner .content .description {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-1 .inner .content .description {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .banner-style-1 .inner .content .description {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px; } }
    .banner-style-1 .inner .content .description::before {
      position: absolute;
      content: "";
      left: 0;
      width: 2px;
      height: 73%;
      background: var(--color-primary);
      top: 50%;
      transform: translateY(-50%); }
  .banner-style-1 .work-shop {
    min-width: 193px;
    max-width: 193px;
    background: var(--color-white);
    padding: 20px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin: 25px -15px 0 auto; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-1 .work-shop {
        min-width: 100%;
        max-width: 100%;
        margin-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-1 .work-shop {
        min-width: 100%;
        max-width: 100%;
        margin-right: 0; } }
    @media only screen and (max-width: 767px) {
      .banner-style-1 .work-shop {
        margin-right: 0;
        margin-left: 0; } }
    .banner-style-1 .work-shop .inner .thumbnail img {
      width: 40px;
      height: 40px;
      object-fit: cover; }
    .banner-style-1 .work-shop .inner .content .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin: 10px 0 0; }
    .banner-style-1 .work-shop .inner .content .time {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      margin-bottom: 20px; }
  .banner-style-1 .shape-round {
    position: absolute;
    left: -312px;
    top: 130px;
    z-index: -1; }
  .banner-style-1 .scroll-down-btn {
    position: absolute;
    top: 100%;
    left: calc(50% - 30px);
    transform: translateY(-50%);
    z-index: 1; }
  .banner-style-1 .shape-dot-wrapper .shape {
    position: absolute;
    z-index: -1; }
    .banner-style-1 .shape-dot-wrapper .shape.shape-1 {
      top: 97px;
      left: -189px; }
    .banner-style-1 .shape-dot-wrapper .shape.shape-2 {
      top: 53px;
      left: 580px; }
    .banner-style-1 .shape-dot-wrapper .shape.shape-3 {
      top: 95px;
      right: -160px; }
    .banner-style-1 .shape-dot-wrapper .shape.shape-4 {
      bottom: 10px;
      right: -173px; }
    .banner-style-1 .shape-dot-wrapper .shape.shape-5 {
      bottom: -23px;
      left: 654px; }
    .banner-style-1 .shape-dot-wrapper .shape.shape-6 {
      bottom: -37px;
      left: -255px; }
  .banner-style-1 .eduvibe-hero-one-video .thumbnail {
    margin: 30px -149px 0 -23px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-1 .eduvibe-hero-one-video .thumbnail {
        margin: 0 -70px 0 -30px; } }
    @media only screen and (max-width: 1199px) {
      .banner-style-1 .eduvibe-hero-one-video .thumbnail {
        margin: 0 -30px 0 -30px; } }
    @media only screen and (max-width: 575px) {
      .banner-style-1 .eduvibe-hero-one-video .thumbnail {
        margin: -30px 0 0 -30px; } }

/*-------------------------
    Banner Style Two  
--------------------------*/
.banner-style-2 {
 
  position: relative;
  z-index: 1;
  min-height: 930px; }
  @media only screen and (max-width: 1199px) {
    .banner-style-2 {
      min-height: 800px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-style-2 {
      padding: 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-style-2 {
      padding: 150px 30px;
      height: auto;
      padding-bottom: 0; } }
  @media only screen and (max-width: 767px) {
    .banner-style-2 {
      padding: 150px 0px;
      height: auto;
      padding-bottom: 0; } }
  .banner-style-2 .inner {
    position: relative;
    margin-top: 100px; }
    @media only screen and (max-width: 1199px) {
      .banner-style-2 .inner {
        margin-top: 0px; } }
    .banner-style-2 .inner .content .pre-title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-primary);
      margin-bottom: 10px;
      display: block;
      padding-left: 60px;
      position: relative; }
      .banner-style-2 .inner .content .pre-title::before {
        left: 0;
        top: 13px;
        width: 50px;
        height: 2px;
        background: var(--color-primary);
        content: "";
        position: absolute; }
    .banner-style-2 .inner .content .title {
      font-weight: 800;
      font-size: 68px;
      line-height: 1.24;
      margin-bottom: 30px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-style-2 .inner .content .title {
          font-size: 45px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-style-2 .inner .content .title {
          font-size: 40px;
          margin-bottom: 15px; } }
      @media only screen and (max-width: 767px) {
        .banner-style-2 .inner .content .title {
          font-size: 38px;
          margin-bottom: 15px; } }
      @media only screen and (max-width: 575px) {
        .banner-style-2 .inner .content .title {
          font-size: 32px; } }
    .banner-style-2 .inner .content .description {
      margin-bottom: 60px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-style-2 .inner .content .description {
          margin-bottom: 40px; } }
      @media only screen and (max-width: 767px) {
        .banner-style-2 .inner .content .description {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 25px; } }
    .banner-style-2 .inner .content .arrow-sign {
      position: absolute;
      right: -30px;
      bottom: -50px; }
      .banner-style-2 .inner .content .arrow-sign img {
        max-width: 70%; }
      @media only screen and (min-width: 1750px) {
        .banner-style-2 .inner .content .arrow-sign {
          right: -50px;
          bottom: -90px; }
          .banner-style-2 .inner .content .arrow-sign img {
            max-width: 100%; } }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .banner-style-2 .inner .content .arrow-sign {
          right: -50px;
          bottom: -30px; }
          .banner-style-2 .inner .content .arrow-sign img {
            max-width: 50%; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-style-2 .inner .content .arrow-sign {
          right: -110px;
          bottom: -12px; }
          .banner-style-2 .inner .content .arrow-sign img {
            max-width: 40%; } }
  .banner-style-2 .banner-thumbnail {
    position: absolute;
    bottom: 0;
    right: 111px;
    z-index: 1; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .banner-style-2 .banner-thumbnail {
        right: 31px; } }
    .banner-style-2 .banner-thumbnail img {
      max-width: calc(100% - 60px); }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-2 .banner-thumbnail {
        right: 0; }
        .banner-style-2 .banner-thumbnail img.girl-thumb {
          max-width: 90%; } }
    @media only screen and (max-width: 1199px) {
      .banner-style-2 .banner-thumbnail {
        right: -138px; }
        .banner-style-2 .banner-thumbnail img.girl-thumb {
          max-width: 80%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-2 .banner-thumbnail {
        position: static;
        text-align: right;
        margin-top: -110px; }
        .banner-style-2 .banner-thumbnail img.girl-thumb {
          max-width: 70%; } }
    @media only screen and (max-width: 767px) {
      .banner-style-2 .banner-thumbnail {
        position: static; }
        .banner-style-2 .banner-thumbnail img.girl-thumb {
          max-width: 100%; } }
  .banner-style-2 .banner-bg {
    position: absolute;
    bottom: 3px;
    right: 67px;
    z-index: 0; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-2 .banner-bg {
        right: 0; } }
    .banner-style-2 .banner-bg img.girl-bg {
      max-width: calc(90% - 10px); }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .banner-style-2 .banner-bg img.girl-bg {
          max-width: 90%; } }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .banner-style-2 .banner-bg {
        right: -40px; } }
    @media only screen and (max-width: 1199px) {
      .banner-style-2 .banner-bg {
        right: -218px; }
        .banner-style-2 .banner-bg img.girl-bg {
          max-width: 80%; } }
  .banner-style-2 .shape-image {
    position: absolute;
    z-index: -1; }
    .banner-style-2 .shape-image.shape-image-1 {
      top: 231px;
      left: 50px; }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .banner-style-2 .shape-image.shape-image-1 {
          top: 170px;
          left: -10px; } }
    .banner-style-2 .shape-image.shape-image-2 {
      top: 240px;
      right: 43%; }
    .banner-style-2 .shape-image.shape-image-3 {
      top: 250px;
      right: 90px; }

/*-------------------------
    Banner Style Three  
--------------------------*/
.banner-style-3 {
  position: relative;
  z-index: 1;
  overflow: hidden;
 }
  @media only screen and (max-width: 991px) {
    .banner-style-3 {
      padding: 160px 0 100px; }
      .banner-style-3 .height-940 {
        height: inherit;
        min-height: inherit; } }
  @media only screen and (max-width: 767px) {
    .banner-style-3 {
      padding: 140px 0 70px; }
      .banner-style-3 .height-940 {
        padding: 0; } }
  .banner-style-3 .content {
    margin-top: 90px; }
    @media only screen and (max-width: 991px) {
      .banner-style-3 .content {
        margin-top: 0px; } }
    .banner-style-3 .content .pre-title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-primary);
      display: block;
      margin-bottom: 5px; }
    .banner-style-3 .content .title {
      font-weight: 800;
      font-size: 68px;
      line-height: 1.25;
      margin-bottom: 25px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-style-3 .content .title {
          font-size: 50px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-style-3 .content .title {
          font-size: 40px; } }
      @media only screen and (max-width: 767px) {
        .banner-style-3 .content .title {
          font-size: 40px; } }
    .banner-style-3 .content .description {
      margin-bottom: 60px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-style-3 .content .description {
          margin-bottom: 30px; } }
      @media only screen and (max-width: 767px) {
        .banner-style-3 .content .description {
          margin-bottom: 30px;
          font-size: 18px;
          line-height: 28px; } }
  .banner-style-3 .banner-image .banner-main-image {
    margin-right: -45px;
    margin-bottom: -260px;
    text-align: right; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-3 .banner-image .banner-main-image {
        margin-right: 70px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-3 .banner-image .banner-main-image {
        margin-right: 50px; } }
    @media only screen and (max-width: 991px) {
      .banner-style-3 .banner-image .banner-main-image {
        margin-right: 0;
        text-align: left;
        margin-bottom: 0; } }
  .banner-style-3 .banner-image .img-02 {
    position: absolute;
    right: -232px;
    bottom: 125px; }
    @media only screen and (min-width: 1401px) and (max-width: 1650px) {
      .banner-style-3 .banner-image .img-02 {
        right: -152px;
        bottom: 190px;
        max-width: 240px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-3 .banner-image .img-02 {
        right: -88px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-3 .banner-image .img-02 {
        right: -88px;
        max-width: 240px; } }
    @media only screen and (max-width: 991px) {
      .banner-style-3 .banner-image .img-02 {
        display: none; } }
  .banner-style-3 .banner-image .img-03 {
    position: absolute;
    right: 0;
    bottom: 435px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-3 .banner-image .img-03 {
        right: 140px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-3 .banner-image .img-03 {
        right: 90px;
        bottom: 345px; } }
    @media only screen and (max-width: 991px) {
      .banner-style-3 .banner-image .img-03 {
        display: none; } }
  .banner-style-3 .shape-image {
    position: absolute;
    z-index: -1; }
    .banner-style-3 .shape-image.shape-image-1 {
      left: -114px;
      top: 30px; }
    .banner-style-3 .shape-image.shape-image-2 {
      right: 45%;
      top: 105px; }
    .banner-style-3 .shape-image.shape-image-3 {
      right: -260px;
      top: 20px; }
    .banner-style-3 .shape-image.shape-image-4 {
      right: -241px;
      bottom: -50px; }
    .banner-style-3 .shape-image.shape-image-5 {
      left: -180px;
      bottom: -100px; }

/*-------------------------
    Banner Style Four  
--------------------------*/
.banner-style-4 {
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .banner-style-4 .home-four-banner-wrapper {
    min-height: 870px; }
    @media only screen and (max-width: 1199px) {
      .banner-style-4 .home-four-banner-wrapper {
        min-height: auto;
        width: 100%;
        width: 100%;
        padding: 120px 0 100px; } }
    @media only screen and (max-width: 991px) {
      .banner-style-4 .home-four-banner-wrapper {
        padding: 70px 0 150px; } }
  .banner-style-4 .banner-image {
    position: relative;
    padding: 35px;
    left: 115px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-4 .banner-image {
        padding: 25px;
        left: 10px;
        transform: scale(0.9); } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-4 .banner-image {
        left: 0;
        width: 74%;
        margin: 0 auto; } }
    @media only screen and (max-width: 767px) {
      .banner-style-4 .banner-image {
        left: 0;
        margin: 0 auto;
        padding: 30px; } }
    @media only screen and (max-width: 575px) {
      .banner-style-4 .banner-image {
        padding: 20px; } }
    .banner-style-4 .banner-image .thumbnail img {
      width: 500px;
      max-height: 500px;
      border-radius: 100%;
      object-fit: cover; }
    .banner-style-4 .banner-image .round-images {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s; }
    .banner-style-4 .banner-image .learner-badge {
      background: #FFFFFF;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      padding: 10px;
      width: 160px;
      height: 160px;
      border-radius: 100%;
      position: absolute;
      left: -80px;
      top: 25%; }
      @media only screen and (max-width: 575px) {
        .banner-style-4 .banner-image .learner-badge {
          display: none; } }
      .banner-style-4 .banner-image .learner-badge .badge-inner {
        background: #FFA41B;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .banner-style-4 .banner-image .learner-badge .badge-inner span {
          display: block;
          color: #FFFFFF;
          font-weight: 600; }
          .banner-style-4 .banner-image .learner-badge .badge-inner span.viewer {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px; }
  .banner-style-4 .content .pre-title {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-primary);
    display: block;
    margin-bottom: 5px; }
  .banner-style-4 .content .title {
    font-weight: 800;
    font-size: 72px;
    line-height: 1.25;
    margin-bottom: 25px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-4 .content .title {
        font-size: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-4 .content .title {
        font-size: 40px; } }
    @media only screen and (max-width: 767px) {
      .banner-style-4 .content .title {
        font-size: 40px; } }
  .banner-style-4 .content .description {
    margin-bottom: 60px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-4 .content .description {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .banner-style-4 .content .description {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px; } }
  .banner-style-4 .shape-image {
    position: absolute;
    z-index: -1; }
    .banner-style-4 .shape-image.shape-image-1 {
      left: -110px;
      top: -50px; }
    .banner-style-4 .shape-image.shape-image-2 {
      right: 48%;
      top: -32px; }
    .banner-style-4 .shape-image.shape-image-3 {
      right: -210px;
      top: 10px; }
    .banner-style-4 .shape-image.shape-image-4 {
      right: 360px;
      bottom: -90px; }
    .banner-style-4 .shape-image.shape-image-5 {
      left: 463px;
      bottom: 270px; }
    .banner-style-4 .shape-image.shape-image-6 {
      left: 150px;
      bottom: -100px; }
    .banner-style-4 .shape-image.shape-image-left {
      left: 0;
      bottom: 8px; }
    .banner-style-4 .shape-image.shape-image-right {
      right: 0;
      bottom: 7px; }
  .banner-style-4 .social-text-share {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -175px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .banner-style-4 .social-text-share {
        left: -105px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-4 .social-text-share {
        left: -70px; } }
    @media only screen and (max-width: 1199px) {
      .banner-style-4 .social-text-share {
        position: absolute;
        top: inherit;
        transform: none;
        left: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        bottom: -40px;
        margin: 0 -20px; } }
    @media only screen and (max-width: 991px) {
      .banner-style-4 .social-text-share {
        bottom: -65px; } }
    .banner-style-4 .social-text-share li {
      margin: 0; }
      .banner-style-4 .social-text-share li a {
        display: inline-block;
        transform: rotate(-90deg);
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 20px 0;
        position: relative; }
        @media only screen and (max-width: 1199px) {
          .banner-style-4 .social-text-share li a {
            transform: none;
            margin: 0px 20px; } }
        .banner-style-4 .social-text-share li a::before {
          position: absolute;
          content: "";
          background: var(--color-primary);
          bottom: 0;
          width: 0;
          opacity: 0;
          left: 0;
          transition: 0.4s;
          height: 2px; }
        .banner-style-4 .social-text-share li a:hover::before {
          width: 100%;
          opacity: 1; }

/*-------------------------
    Banner Style five  
--------------------------*/
.banner-style-5 {
  position: relative;
  z-index: 0;
  background-image: url('../images/bg/home-five-courses.webp'); 
}
  .banner-style-5 .wrapper {
    height: 400px; 
 
  }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-5 .wrapper {
        height: auto;
        padding-top: 120px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-5 .wrapper {
        height: auto;
        padding-top: 120px; } }
    @media only screen and (max-width: 767px) {
      .banner-style-5 .wrapper {
        height: auto;
        padding-top: 20px; } }
  .banner-style-5 .content .title {
    font-weight: 800;
    font-size: 45px;
    line-height: 40px;
    margin-bottom: 30px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .banner-style-5 .content .title {
        font-size: 35px;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-5 .content .title {
        font-size: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-5 .content .title {
        font-size: 40px;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .banner-style-5 .content .title {
        font-size: 25px;
        margin-bottom: 10px; } }
  .banner-style-5 .content .description {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 60px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-5 .content .description {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-5 .content .description {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .banner-style-5 .content .description {
        margin-bottom: 30px; } }
  .banner-style-5 .content .read-more-btn {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 575px) {
      .banner-style-5 .content .read-more-btn {
        display: block; } }
  @media only screen and (max-width: 575px) {
    .banner-style-5 .content .video-btn-wrapper {
      margin-left: 0;
      margin-top: 20px; } }
  .banner-style-5 .content .video-play-btn {
    width: 60px;
    height: 60px;
    background: rgba(251, 124, 86, 0.15);
    margin: inherit;
    margin-left: 30px; }
    @media only screen and (max-width: 575px) {
      .banner-style-5 .content .video-play-btn {
        margin-left: 0;
        margin-top: 0; } }
    .banner-style-5 .content .video-play-btn::before {
      display: none; }
    .banner-style-5 .content .video-play-btn::after {
      display: none; }
    .banner-style-5 .content .video-play-btn .play-icon::before {
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 15px solid var(--color-secondary); }
  .banner-style-5 .content .video-btn-wrapper {
    display: flex;
    align-items: center; }
    .banner-style-5 .content .video-btn-wrapper .video-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin-left: 20px;
      display: inline-block;
      color: var(--color-heading); }
  .banner-style-5 .banner-image {
    position: absolute;
    bottom: 0;
    right: 13%; }
    @media only screen and (max-width: 1650px) {
      .banner-style-5 .banner-image {
        right: 4%; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-style-5 .banner-image {
        position: relative;
        bottom: 0;
        right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-style-5 .banner-image {
        position: relative;
        bottom: 0;
        right: 0;
        text-align: center; } }
    @media only screen and (max-width: 767px) {
      .banner-style-5 .banner-image {
        position: relative;
        bottom: 0;
        right: 0;
        text-align: center; } }
    .banner-style-5 .banner-image img {
      border-top-right-radius: 500px;
      border-top-left-radius: 500px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-style-5 .banner-image img {
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .banner-style-5 .banner-image img {
          width: 100%; } }
    .banner-style-5 .banner-image .card-info {
      position: absolute;
      left: -10%;
      top: 19%; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-style-5 .banner-image .card-info {
          left: 0; } }
      @media only screen and (max-width: 767px) {
        .banner-style-5 .banner-image .card-info {
          left: 10px;
          top: auto;
          bottom: 10px; } }
      .banner-style-5 .banner-image .card-info .inner {
        background: #FFFFFF;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        padding: 20px 30px; }
        .banner-style-5 .banner-image .card-info .inner .name {
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
          color: var(--color-heading); }
          .banner-style-5 .banner-image .card-info .inner .name span {
            font-size: 14px;
            color: var(--color-body);
            font-weight: 400; }
        .banner-style-5 .banner-image .card-info .inner .rating-wrapper span {
          display: inline-block;
          margin-left: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px; }
  .banner-style-5 .shape-image {
    position: absolute;
    z-index: -1; }
    .banner-style-5 .shape-image.shape-image-1 {
      bottom: 235px;
      left: 168px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
      @media only screen and (max-width: 1650px) {
        .banner-style-5 .shape-image.shape-image-1 {
          left: 28px; } }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .banner-style-5 .shape-image.shape-image-1 {
          display: none; } }
    .banner-style-5 .shape-image.shape-image-2 {
      top: 183px;
      left: 73px; }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .banner-style-5 .shape-image.shape-image-2 {
          top: 160px;
          left: -40px; } }
    .banner-style-5 .shape-image.shape-image-3 {
      top: 180px;
      left: 52%; }
    .banner-style-5 .shape-image.shape-image-4 {
      left: 42%;
      bottom: 370px; }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .banner-style-5 .shape-image.shape-image-4 {
          bottom: 200px; } }
    .banner-style-5 .shape-image.shape-image-5 {
      bottom: 116px;
      left: 49%; }
    .banner-style-5 .shape-image.shape-image-6 {
      left: 50px;
      bottom: 90px; }

/*-------------------------
    Banner Style Six  
--------------------------*/
.banner-style-6 {
  position: relative;
  z-index: 2; }
  .banner-style-6 .scroll-down-btn {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 30px);
    z-index: 1; }
    @media only screen and (max-width: 575px) {
      .banner-style-6 .scroll-down-btn {
        bottom: 15px; } }
    @media only screen and (min-width: 1750px) {
      .banner-style-6 .scroll-down-btn {
        bottom: 20px; } }
    @media only screen and (min-width: 1800px) {
      .banner-style-6 .scroll-down-btn {
        bottom: 0px; } }
    @media only screen and (min-width: 1900px) {
      .banner-style-6 .scroll-down-btn {
        bottom: -20px; } }
  .banner-style-6 .content {
    position: relative;
    z-index: 1; }
    .banner-style-6 .content .pre-title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-primary);
      display: block;
      margin-bottom: 10px; }
    .banner-style-6 .content .title {
      font-weight: 800;
      font-size: 68px;
      line-height: 1.24;
      margin-bottom: 60px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-style-6 .content .title {
          font-size: 50px;
          margin-bottom: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-style-6 .content .title {
          font-size: 50px;
          margin-bottom: 30px; } }
      @media only screen and (max-width: 767px) {
        .banner-style-6 .content .title {
          font-size: 40px;
          margin-bottom: 30px; } }
      @media only screen and (max-width: 575px) {
        .banner-style-6 .content .title {
          font-size: 28px;
          margin-bottom: 20px; } }
  .banner-style-6 .shape-image {
    position: absolute;
    z-index: -1; }
    .banner-style-6 .shape-image.shape-image-1 {
      top: 180px;
      left: 32%; }
    .banner-style-6 .shape-image.shape-image-2 {
      top: 253px;
      right: 182px; }
    .banner-style-6 .shape-image.shape-image-3 {
      bottom: 100px;
      right: 35%; }
    .banner-style-6 .shape-image.shape-image-4 {
      bottom: 195px;
      left: 117px; }
    .banner-style-6 .shape-image.shape-image-5 {
      bottom: 360px;
      right: 192px; }
  .banner-style-6 .gallery-image {
    position: absolute;
    z-index: -1; }
    .banner-style-6 .gallery-image.gallery-image-1 {
      top: 180px;
      left: 175px;
      z-index: 1; }
    .banner-style-6 .gallery-image.gallery-image-2 {
      top: 353px;
      left: 117px;
      z-index: -1; }
    .banner-style-6 .gallery-image.gallery-image-3 {
      bottom: 100px;
      left: 340px; }
    .banner-style-6 .gallery-image.gallery-image-4 {
      bottom: 200px;
      right: 281px; }
    .banner-style-6 .gallery-image.gallery-image-5 {
      bottom: 91px;
      right: 6%;
      z-index: -1; }
    .banner-style-6 .gallery-image.gallery-image-6 {
      top: 201px;
      right: 281px; }
    @media only screen and (min-width: 1401px) and (max-width: 1750px) {
      .banner-style-6 .gallery-image img {
        max-width: 90%; }
      .banner-style-6 .gallery-image.gallery-image-1 {
        left: 20px; }
      .banner-style-6 .gallery-image.gallery-image-2 {
        top: 350px;
        left: 40px; }
      .banner-style-6 .gallery-image.gallery-image-3 {
        left: 120px;
        bottom: 150px; }
      .banner-style-6 .gallery-image.gallery-image-4 {
        bottom: 290px;
        right: 0px; }
      .banner-style-6 .gallery-image.gallery-image-5 {
        bottom: 170px;
        right: 120px; }
      .banner-style-6 .gallery-image.gallery-image-6 {
        right: 0; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .banner-style-6 .gallery-image.gallery-image-1 {
        left: 0; }
      .banner-style-6 .gallery-image.gallery-image-2 {
        top: 305px;
        left: 70px; }
      .banner-style-6 .gallery-image.gallery-image-3 {
        left: 200px;
        bottom: 150px; }
      .banner-style-6 .gallery-image.gallery-image-4 {
        right: 130px; }
      .banner-style-6 .gallery-image.gallery-image-5 {
        bottom: 91px;
        right: 0px; }
      .banner-style-6 .gallery-image.gallery-image-6 {
        right: 0; }
      .banner-style-6 .gallery-image img {
        max-width: 80%; } }

/* Event Details  */
.edu-event-details .thumbnail img {
  border-radius: 5px; }

.edu-event-details .content {
  margin-top: 60px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-event-details .content {
      margin-top: 30px; } }
  @media only screen and (max-width: 767px) {
    .edu-event-details .content {
      margin-top: 30px; } }
  .edu-event-details .content .title {
    font-weight: 800;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .edu-event-details .content .title {
        font-size: 28px;
        line-height: 1.4; } }
  .edu-event-details .content .column-gallery li img {
    border-radius: 5px;
    width: 100%; }

.eduvibe-widget {
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 30px 40px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eduvibe-widget {
      padding: 30px 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .eduvibe-widget {
      padding: 30px 20px; } }
  @media only screen and (max-width: 767px) {
    .eduvibe-widget {
      padding: 30px 20px; } }
  .eduvibe-widget .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px; }

.eduvibe-sidebar {
  padding: 0 50px;
  margin-top: -50px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .eduvibe-sidebar {
      margin-top: 30px; } }
  @media only screen and (max-width: 767px) {
    .eduvibe-sidebar {
      margin-top: 30px;
      padding: 0 20px; } }
  .eduvibe-sidebar.course-details-sidebar {
    padding: 0 0px 0 20px;
    margin-left: -20px; }
    @media only screen and (max-width: 1199px) {
      .eduvibe-sidebar.course-details-sidebar {
        margin-left: 0px;
        padding: 0px; } }
    .eduvibe-sidebar.course-details-sidebar .eduvibe-widget {
      padding: 20px 20px;
      border-radius: 10px; }
      .eduvibe-sidebar.course-details-sidebar .eduvibe-widget .eduvibe-widget-details {
        padding: 0 20px 20px; }

.eduvibe-widget-details .widget-content ul li {
  display: flex;
  justify-content: space-between; }
  .eduvibe-widget-details .widget-content ul li i {
    color: var(--color-primary);
    margin-right: 10px; }
  .eduvibe-widget-details .widget-content ul li img.eduvibe-course-sidebar-img-icon {
    max-width: 16px;
    margin-right: 10px; }
  .eduvibe-widget-details .widget-content ul li span {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    display: inline-block; }
  .eduvibe-widget-details .widget-content ul li + li {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #EEEEEE; }

/*----------------------------
    Product Details Styles 
------------------------------*/
.edu-product-details-style .content .edu-rating {
  margin-bottom: 20px; }
  .edu-product-details-style .content .edu-rating .rating-count {
    font-weight: 600;
    color: var(--color-dark); }

.edu-product-details-style .content .subtitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  display: block;
  margin-bottom: 10px; }

.edu-product-details-style .content .title {
  margin-bottom: 15px; }

.edu-product-details-style .content p {
  margin-top: 25px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 1199px) {
    .edu-product-details-style .content p {
      margin-top: 15px;
      margin-bottom: 20px; } }

.edu-product-details-style .content .product-action {
  display: flex; }
  @media only screen and (max-width: 575px) {
    .edu-product-details-style .content .product-action {
      display: block; } }
  @media only screen and (max-width: 575px) {
    .edu-product-details-style .content .product-action .add-to-cart-btn {
      margin-top: 20px; } }

.edu-product-details-style .content .product-feature {
  margin-top: 40px; }
  @media only screen and (max-width: 1199px) {
    .edu-product-details-style .content .product-feature {
      margin-top: 20px; } }
  .edu-product-details-style .content .product-feature li {
    font-weight: 400;
    margin: 5px 0; }
    .edu-product-details-style .content .product-feature li span {
      font-weight: 700;
      color: var(--color-heading); }
    .edu-product-details-style .content .product-feature li a {
      position: relative;
      display: inline-block; }
      .edu-product-details-style .content .product-feature li a::after {
        position: absolute;
        content: ",";
        right: -3px; }
    .edu-product-details-style .content .product-feature li a:last-child:after {
      display: none; }

.edu-product-details-style .content .edu-quontity-btn {
  min-width: 144px; }

.edu-product-details-style .content .add-to-cart-btn {
  width: 100%;
  margin-left: 15px; }
  @media only screen and (max-width: 575px) {
    .edu-product-details-style .content .add-to-cart-btn {
      margin-left: 0px; } }
  .edu-product-details-style .content .add-to-cart-btn a.edu-btn {
    width: 100%;
    text-align: center; }

.edu-product-details-style .content .pro-qty .qtybtn {
  right: 28px; }

@media only screen and (max-width: 991px) {
  .edu-product-details-style .content .pro-qty input {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  .edu-product-details-style .content .pro-qty input {
    text-align: center; } }

.edu-product-details-style .content .eduvibe-product-details-hr {
  margin-top: 37px;
  padding-bottom: 1px; }

.edu-product-details-style .thumbnail {
  padding-right: 62px; }
  @media only screen and (max-width: 1199px) {
    .edu-product-details-style .thumbnail {
      padding-right: 0px; } }
  .edu-product-details-style .thumbnail img.eduvibe-product-main-img {
    border-radius: 5px; }

.pro-qty {
  position: relative;
  display: inline-block; }
  .pro-qty input {
    height: 60px;
    border: 0 none;
    background: #F5F5F5;
    border-radius: 5px;
    max-width: 144px;
    margin-right: 15px;
    text-align: center;
    color: var(--color-heading);
    font-weight: 700; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .pro-qty input {
        text-align: left; } }
    @media only screen and (max-width: 767px) {
      .pro-qty input {
        text-align: left; } }
  .pro-qty .qtybtn {
    position: absolute;
    top: 10px;
    right: 38px;
    font-size: 20px;
    cursor: pointer; }
  .pro-qty .dec.qtybtn {
    top: auto;
    bottom: 10px; }

.product-description-nav {
  border-bottom: 1px solid #EEEEEE;
  justify-content: center;
  margin-bottom: 35px; }
  .product-description-nav .nav-item {
    margin: 0 25px; }
    .product-description-nav .nav-item button.nav-link {
      background: transparent;
      border: 0 none;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      padding: 0;
      padding-bottom: 15px;
      color: var(--color-heading);
      position: relative;
      transition: 0.4s; }
      .product-description-nav .nav-item button.nav-link::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        height: 2px;
        background: var(--color-primary);
        transition: 0.4s;
        width: 0; }
      .product-description-nav .nav-item button.nav-link:hover, .product-description-nav .nav-item button.nav-link.active {
        color: var(--color-primary); }
        .product-description-nav .nav-item button.nav-link:hover::after, .product-description-nav .nav-item button.nav-link.active::after {
          width: 100%; }

.product-description-content p:last-child {
  margin-bottom: 0; }

.product-description-content .comment-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.product-description-content .notification-text .title {
  font-size: 16px;
  line-height: 26px;
  margin-right: 10px; }

.edu-product-modal .modal-dialog {
  max-width: 1170px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .edu-product-modal .modal-dialog {
      max-width: 900px;
      margin: 0 auto; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .edu-product-modal .modal-dialog {
      max-width: 800px;
      margin: 0 auto; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-product-modal .modal-dialog {
      max-width: 600px;
      margin: 0 auto; } }
  @media only screen and (max-width: 767px) {
    .edu-product-modal .modal-dialog {
      max-width: 400px;
      margin: 0 auto; } }
  @media only screen and (max-width: 479px) {
    .edu-product-modal .modal-dialog {
      max-width: 320px;
      margin: 0 auto; } }

.edu-product-modal .edu-modal-body {
  padding: 70px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .edu-product-modal .edu-modal-body {
      padding: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-product-modal .edu-modal-body {
      padding: 30px; } }
  @media only screen and (max-width: 767px) {
    .edu-product-modal .edu-modal-body {
      padding: 20px; } }

.edu-product-modal .edu-modal-header {
  position: absolute;
  left: calc(100% + 20px);
  z-index: 2; }
  .edu-product-modal .edu-modal-header button {
    padding: 0;
    background: transparent;
    margin: 0;
    outline: none;
    opacity: 1;
    width: 25px;
    height: 25px; }
    @media only screen and (max-width: 767px) {
      .edu-product-modal .edu-modal-header button {
        width: 20px;
        height: 20px; } }
    .edu-product-modal .edu-modal-header button:focus {
      box-shadow: none; }
    .edu-product-modal .edu-modal-header button i {
      color: var(--color-white); }

.eduvibe-product-modal-popup.edu-product-modal .edu-modal-header {
  left: calc(100% + 20px);
  top: -30px; }
  @media only screen and (max-width: 1650px) {
    .eduvibe-product-modal-popup.edu-product-modal .edu-modal-header {
      top: 0px;
      left: calc(100% + 10px); } }

.eduvibe-product-modal-popup .modal-content {
  border-radius: 5px; }

.eduvibe-product-modal-popup .edu-modal-body {
  padding: 70px 100px 70px 70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .eduvibe-product-modal-popup .edu-modal-body {
      padding: 50px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eduvibe-product-modal-popup .edu-modal-body {
      padding: 30px; } }
  @media only screen and (max-width: 991px) {
    .eduvibe-product-modal-popup .edu-modal-body {
      padding: 30px; } }

.eduvibe-product-modal-popup.eduvibe-product-modal-popup .thumbnail {
  padding-right: 0; }
  .eduvibe-product-modal-popup.eduvibe-product-modal-popup .thumbnail img {
    height: 545px;
    width: 350px;
    object-fit: cover; }
    @media only screen and (max-width: 991px) {
      .eduvibe-product-modal-popup.eduvibe-product-modal-popup .thumbnail img {
        height: auto;
        width: 100%; } }

/*-----------------------
    Cart Styles  
-------------------------*/
.cart-table table {
  min-width: 1170px; }
  @media only screen and (max-width: 767px) {
    .cart-table table {
      min-width: 950px; } }
  .cart-table table thead tr {
    background: #F5F5F5;
    border-radius: 5px;
    border: 0 none; }
    .cart-table table thead tr th {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      padding: 24px 0;
      border: 0 none; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .cart-table table thead tr th {
          padding: 24px 10px;
          font-size: 18px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .cart-table table thead tr th {
          padding: 24px 10px;
          font-size: 18px; } }
      @media only screen and (max-width: 767px) {
        .cart-table table thead tr th {
          padding: 24px 10px;
          font-size: 18px; } }
      .cart-table table thead tr th:first-child {
        padding-left: 30px;
        border-radius: 5px 0 0 5px; }
      .cart-table table thead tr th:last-child {
        border-radius: 0 5px 5px 0; }
  .cart-table table tbody tr .pro-thumbnail img {
    max-height: 90px;
    width: 60px;
    min-width: 60px;
    border-radius: 5px;
    object-fit: cover; }
  .cart-table table tbody tr .pro-thumbnail span {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: var(--color-heading);
    display: inline-block;
    margin-left: 20px;
    transition: 0.4s; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .cart-table table tbody tr .pro-thumbnail span {
        font-size: 16px;
        margin-left: 5px; } }
    @media only screen and (max-width: 767px) {
      .cart-table table tbody tr .pro-thumbnail span {
        font-size: 16px;
        margin-left: 5px; } }
    .cart-table table tbody tr .pro-thumbnail span:hover {
      color: var(--color-primary); }
  .cart-table table tbody tr:first-child td {
    border-top: transparent; }
  .cart-table table tbody tr td {
    padding: 30px 0;
    border: 0 none;
    border-bottom: 1px solid #EEEEEE; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .cart-table table tbody tr td {
        padding: 30px 6px; } }
    @media only screen and (max-width: 767px) {
      .cart-table table tbody tr td {
        padding: 30px 6px; } }
    .cart-table table tbody tr td:first-child {
      padding-left: 30px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .cart-table table tbody tr td:first-child {
          padding-left: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .cart-table table tbody tr td:first-child {
          padding-left: 0; } }
      @media only screen and (max-width: 767px) {
        .cart-table table tbody tr td:first-child {
          padding-left: 0; } }
  .cart-table table tbody tr .pro-price,
  .cart-table table tbody tr .pro-subtotal {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px; }
  .cart-table table tbody tr .pro-remove a {
    color: var(--color-heading);
    transition: 0.4s; }
    .cart-table table tbody tr .pro-remove a:hover {
      color: var(--color-secondary); }

.cart-table .pro-qty input {
  max-width: 144px;
  margin-right: 0;
  padding-right: 39px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart-table .pro-qty input {
      max-width: 104px; } }
  @media only screen and (max-width: 767px) {
    .cart-table .pro-qty input {
      max-width: 104px; } }

.cart-summary .title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px; }

.cart-summary .cart-summary-inner {
  border-bottom: 1px solid #EEEEEE;
  border-right: 1px solid #EEEEEE;
  border-top: 1px solid #EEEEEE;
  border-left: 1px solid #EEEEEE;
  border-radius: 5px; }
  .cart-summary .cart-summary-inner li {
    display: flex;
    margin: 0; }
    .cart-summary .cart-summary-inner li span {
      display: block;
      flex-basis: 50%;
      padding: 17px 40px;
      text-align: center;
      font-weight: 600; }
      .cart-summary .cart-summary-inner li span.subtitle {
        border-right: 1px solid #EEEEEE;
        font-size: 16px;
        line-height: 26px;
        color: var(--color-heading);
        text-align: left; }
    .cart-summary .cart-summary-inner li + li {
      border-top: 1px solid #EEEEEE; }

.coupon-code-btn {
  position: relative;
  display: inline-block; }
  .coupon-code-btn input {
    background: #F5F5F5;
    border-radius: 5px;
    border: 0 none;
    height: 60px;
    min-width: 470px;
    padding-left: 53px;
    padding-right: 163px; }
    @media only screen and (max-width: 767px) {
      .coupon-code-btn input {
        min-width: auto;
        padding-left: 30px;
        padding-right: 30px; } }
  .coupon-code-btn i {
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translateY(-50%);
    font-size: 20px; }
    @media only screen and (max-width: 767px) {
      .coupon-code-btn i {
        display: none; } }
  .coupon-code-btn .edu-btn {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 5px 5px 0;
    height: 100%; }
    @media only screen and (max-width: 767px) {
      .coupon-code-btn .edu-btn {
        position: static;
        border-radius: 5px;
        height: auto;
        margin-top: 20px; } }

/*-------------------------
    Price Filter  
--------------------------*/
.slider__range--output {
  margin-top: 14px; }

.price--output input {
  border: 0 none; }

.price--output span {
  color: #333;
  font-size: 14px; }

.price--filter {
  display: inline-block; }

.price--output {
  display: inline-block; }

.ui-widget-content {
  background: #D7D6DC none repeat scroll 0 0;
  border: 0 none;
  color: var(--color-primary); }

.ui-slider-range.ui-widget-header.ui-corner-all {
  background: var(--color-primary) none repeat scroll 0 0; }

.ui-slider-horizontal {
  height: 4px; }

.ui-slider .ui-slider-handle {
  border-radius: 100%;
  cursor: default;
  height: 20px;
  position: absolute;
  touch-action: none;
  width: 20px;
  z-index: 2;
  outline: none;
  cursor: pointer; }

.ui-slider-horizontal .ui-slider-handle {
  top: -9px;
  margin-left: -3px; }

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: var(--color-primary) none repeat scroll 0 0;
  border: 0 none;
  color: var(--color-primary); }

.ui-slider-range.ui-widget-header.ui-corner-all {
  background: var(--color-primary) none repeat scroll 0 0; }

.ui-slider-horizontal {
  margin-bottom: 8px;
  margin-top: 15px; }

.price__output--wrap {
  display: flex;
  align-items: center; }
  .price__output--wrap .price--output {
    display: flex;
    align-items: center;
    flex-grow: 1; }
    .price__output--wrap .price--output span {
      color: var(--color-body);
      display: inline-block;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px; }
    .price__output--wrap .price--output input {
      border: 0 none;
      flex-grow: 1;
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      width: 100px;
      color: var(--color-body);
      padding: 0 6px; }
  .price__output--wrap .price--filter {
    display: inline-block;
    margin-top: -2px; }
    .price__output--wrap .price--filter a.btn {
      padding: 2px 10px;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-radius: 6px;
      line-height: 16px; }

.ui-slider .ui-slider-handle::after {
  position: absolute;
  content: "";
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

/*---------------------------
    Course Details 
-----------------------------*/
.edu-course-details-area .main-image {
  margin-left: -75px;
  margin-right: -75px; }
  @media only screen and (max-width: 991px) {
    .edu-course-details-area .main-image {
      margin-left: 0px;
      margin-right: 0px; } }
  .edu-course-details-area .main-image img {
    height: 570px;
    object-fit: cover;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .edu-course-details-area .main-image img {
        height: auto; } }

.course-details-content {
  margin-right: 30px; }
  @media only screen and (max-width: 1199px) {
    .course-details-content {
      margin-right: 0px; } }
  .course-details-content .content-top {
    display: flex;
    margin-top: 60px;
    margin-bottom: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .course-details-content .content-top {
        margin-top: 30px;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .course-details-content .content-top {
        margin-top: 30px;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 575px) {
      .course-details-content .content-top {
        display: block; } }
    .course-details-content .content-top .author-meta {
      margin-right: 50px; }
      @media only screen and (max-width: 575px) {
        .course-details-content .content-top .author-meta {
          margin-right: 0;
          margin-bottom: 10px; } }
  .course-details-content .title {
    font-weight: 800;
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 50px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .course-details-content .title {
        font-size: 30px;
        margin-bottom: 40px; } }
    @media only screen and (max-width: 767px) {
      .course-details-content .title {
        font-size: 30px;
        margin-bottom: 40px; } }
  .course-details-content .edu-course-tab {
    border: 0 none;
    background: #F5F5F5;
    border-radius: 5px;
    justify-content: space-between; }
    .course-details-content .edu-course-tab .nav-item {
      margin: 0 40px; }
      @media only screen and (max-width: 1199px) {
        .course-details-content .edu-course-tab .nav-item {
          margin: 0 20px; } }
      @media only screen and (max-width: 575px) {
        .course-details-content .edu-course-tab .nav-item {
          margin: 0 10px; } }
      .course-details-content .edu-course-tab .nav-item .nav-link {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        border: 0 none;
        background: transparent;
        padding: 16px 0;
        position: relative;
        color: var(--color-heading); }
        @media only screen and (max-width: 767px) {
          .course-details-content .edu-course-tab .nav-item .nav-link {
            padding: 10px 0;
            font-size: 16px; } }
        .course-details-content .edu-course-tab .nav-item .nav-link::after {
          position: absolute;
          content: "";
          background: var(--color-primary);
          border-radius: 1px 1px 0px 0px;
          height: 2px;
          width: 0;
          transition: 0.4s;
          left: 0%;
          bottom: 0;
          opacity: 0; }
        .course-details-content .edu-course-tab .nav-item .nav-link.active, .course-details-content .edu-course-tab .nav-item .nav-link:hover {
          color: var(--color-primary); }
          .course-details-content .edu-course-tab .nav-item .nav-link.active::after, .course-details-content .edu-course-tab .nav-item .nav-link:hover::after {
            width: 100%;
            opacity: 1; }
  .course-details-content .course-tab-content {
    margin-top: 40px; }
  .course-details-content .rating-box {
    background: #FFFFFF;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    text-align: center;
    min-width: 200px;
    padding: 29px 10px; }
    .course-details-content .rating-box .rating-number {
      font-weight: 800;
      font-size: 72px;
      line-height: 90px;
      color: var(--color-heading); }
    .course-details-content .rating-box span {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px; }

.review-wrapper .single-progress-bar {
  position: relative; }

.review-wrapper .rating-text {
  display: inline-block;
  position: relative;
  top: 19px; }

.review-wrapper .progress {
  max-width: 83%;
  margin-left: 38px;
  height: 12px;
  background: #EEEEEE; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .review-wrapper .progress {
      max-width: 80%; } }
  .review-wrapper .progress .progress-bar {
    background-color: #FFA41B; }

.review-wrapper span.rating-value {
  position: absolute;
  right: 0;
  top: 50%; }

.course-author-wrapper {
  padding: 0;
  display: flex; }
  @media only screen and (max-width: 575px) {
    .course-author-wrapper {
      flex-direction: column; } }
  .course-author-wrapper .thumbnail {
    min-width: 200px;
    max-height: 232px;
    margin-right: 30px; }
    .course-author-wrapper .thumbnail img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px; }
      @media only screen and (max-width: 575px) {
        .course-author-wrapper .thumbnail img {
          width: auto;
          margin-bottom: 20px; } }
  .course-author-wrapper .author-content .title {
    margin-bottom: 2px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px; }
  .course-author-wrapper .author-content .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-primary);
    display: block;
    margin-bottom: 20px; }
  .course-author-wrapper .author-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px; }
  @media only screen and (max-width: 575px) {
    .course-author-wrapper .author-content {
      margin-top: 20px; } }

.edu-comment {
  display: flex; }
  @media only screen and (max-width: 575px) {
    .edu-comment {
      flex-direction: column; } }
  .edu-comment .thumbnail {
    min-width: 70px;
    width: 70px;
    max-height: 70px;
    border-radius: 100%;
    margin-right: 25px; }
    .edu-comment .thumbnail img {
      border-radius: 100%;
      width: 100%; }
  .edu-comment .comment-content .comment-top {
    display: flex;
    align-items: center; }
  .edu-comment .comment-content .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-right: 15px; }
  .edu-comment .comment-content .subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    display: block;
    margin-bottom: 10px;
    color: var(--color-heading); }
  @media only screen and (max-width: 575px) {
    .edu-comment .comment-content {
      margin-top: 20px; } }
  .edu-comment + .edu-comment {
    border-top: 1px solid #EEEEEE;
    padding-top: 30px;
    margin-top: 30px; }

.course-details-card {
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  padding: 30px; }
  .course-details-card .course-details-two-content p:last-child {
    margin-bottom: 0; }

/*------------------------
    Preview Styles  
-------------------------*/
.pre-section-title .pretitle {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  line-height: 26px; }

.eduvibe-landing-banner {
 
  position: relative;
  z-index: 1; }
  .eduvibe-landing-banner .height-800 {
    min-height: 800px;
    width: 100%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .eduvibe-landing-banner .height-800 {
        min-height: 680px; } }
    @media only screen and (max-width: 991px) {
      .eduvibe-landing-banner .height-800 {
        padding: 160px 0 30px; }
        .eduvibe-landing-banner .height-800 .height-940 {
          height: inherit;
          min-height: inherit; } }
    @media only screen and (max-width: 767px) {
      .eduvibe-landing-banner .height-800 {
        padding: 140px 0 70px; }
        .eduvibe-landing-banner .height-800 .height-940 {
          min-height: auto;
          padding: 0; } }
  .eduvibe-landing-banner .content {
    margin-top: 82px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .eduvibe-landing-banner .content {
        margin-top: 30px; } }
    @media only screen and (max-width: 991px) {
      .eduvibe-landing-banner .content {
        margin-top: 30px; } }
    @media only screen and (max-width: 575px) {
      .eduvibe-landing-banner .content {
        margin-top: 0px; } }
    .eduvibe-landing-banner .content .title {
      font-weight: 800;
      font-size: 56px;
      line-height: 1.24;
      margin-bottom: 40px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .eduvibe-landing-banner .content .title {
          font-size: 50px;
          margin-bottom: 35px; } }
      @media only screen and (max-width: 991px) {
        .eduvibe-landing-banner .content .title {
          font-size: 40px;
          margin-bottom: 30px; } }
      @media only screen and (max-width: 575px) {
        .eduvibe-landing-banner .content .title {
          font-size: 28px;
          margin-bottom: 20px; } }
    .eduvibe-landing-banner .content p {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 63px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .eduvibe-landing-banner .content p {
          margin-bottom: 50px; } }
      @media only screen and (max-width: 991px) {
        .eduvibe-landing-banner .content p {
          margin-bottom: 45px; } }
      @media only screen and (max-width: 767px) {
        .eduvibe-landing-banner .content p {
          margin-bottom: 30px; } }
      @media only screen and (max-width: 767px) {
        .eduvibe-landing-banner .content p br {
          display: none; } }
    .eduvibe-landing-banner .content .btn-group-vertical {
      flex-flow: row wrap;
      justify-content: flex-start; }
      @media only screen and (max-width: 991px) {
        .eduvibe-landing-banner .content .btn-group-vertical {
          justify-content: flex-start; } }
      @media only screen and (max-width: 479px) {
        .eduvibe-landing-banner .content .btn-group-vertical {
          flex-direction: column; } }
      .eduvibe-landing-banner .content .btn-group-vertical .left-button {
        margin-right: 20px; }
        @media only screen and (max-width: 479px) {
          .eduvibe-landing-banner .content .btn-group-vertical .left-button {
            margin-right: 0px;
            margin-bottom: 20px; } }
      .eduvibe-landing-banner .content .btn-group-vertical .bg-white {
        color: var(--color-primary); }
        .eduvibe-landing-banner .content .btn-group-vertical .bg-white:hover {
          color: var(--color-secondary);
          cursor: pointer; }
  .eduvibe-landing-banner .banner-image {
    margin-right: -260px;
    margin-left: -92px;
    margin-top: 77px; }
    @media only screen and (max-width: 1650px) {
      .eduvibe-landing-banner .banner-image {
        margin-right: -228px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .eduvibe-landing-banner .banner-image {
        margin-right: -173px;
        margin-left: -160px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .eduvibe-landing-banner .banner-image {
        margin-top: 0px; } }
    @media only screen and (max-width: 991px) {
      .eduvibe-landing-banner .banner-image {
        margin: -100px -30px 0 -140px; } }
    @media only screen and (max-width: 575px) {
      .eduvibe-landing-banner .banner-image {
        margin: -70px 0px 0 -100px; } }
    @media only screen and (max-width: 479px) {
      .eduvibe-landing-banner .banner-image {
        margin: -50px 0px 0 -60px; } }
    @media only screen and (max-width: 1650px) {
      .eduvibe-landing-banner .banner-image img {
        width: 90%; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .eduvibe-landing-banner .banner-image img {
        width: 85%; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .eduvibe-landing-banner .banner-image img {
        width: 75%; } }
    @media only screen and (max-width: 991px) {
      .eduvibe-landing-banner .banner-image img {
        width: 100%; } }
  .eduvibe-landing-banner .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-landing-banner .shape-image.shape-image-1 {
      top: 180px;
      left: -60px; }
    .eduvibe-landing-banner .shape-image.shape-image-2 {
      bottom: 35px;
      right: -270px; }
    .eduvibe-landing-banner .shape-image.shape-image-3 {
      bottom: 55px;
      left: -90px; }

/*-----------------------
    Course Layout Style    
--------------------------*/
 
  .edu-demo-course-layout .shape-image {
    position: absolute;
    z-index: -1; }
    .edu-demo-course-layout .shape-image.shape-image-1 {
      top: 0px;
      left: -200px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
    .edu-demo-course-layout .shape-image.shape-image-2 {
      top: -10px;
      right: -230px; }
    .edu-demo-course-layout .shape-image.shape-image-3 {
      bottom: -64px;
      left: -216px;
      animation: eduvibe-sm-x-move 3.5s alternate infinite linear; }

/*-----------------------
    InnerPages    
--------------------------*/
.background-marque {
  /* background-image: url(../images/bg/landing-page-innerpages.png); */
  background-repeat: repeat-x;
  background-size: auto;
  height: 770px;
  position: relative;
  z-index: 1; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .background-marque {
      height: 600px; } }
  @media only screen and (max-width: 767px) {
    .background-marque {
      height: 400px; } }

/*-----------------------
    Ecommerce Layout Style    
--------------------------*/
.edu-demo-ecommerce-layout {
 
  padding: 192px 0 163px !important;
  position: relative; }
  .edu-demo-ecommerce-layout .pre-section-title {
    margin-top: 55px;
    margin-right: -30px; }
    @media only screen and (max-width: 1199px) {
      .edu-demo-ecommerce-layout .pre-section-title {
        margin-top: 0; } }
    @media only screen and (max-width: 991px) {
      .edu-demo-ecommerce-layout .pre-section-title {
        margin-right: 0px; } }
  .edu-demo-ecommerce-layout .thumbnail {
    margin-right: -158px; }
    @media only screen and (max-width: 991px) {
      .edu-demo-ecommerce-layout .thumbnail {
        margin-right: 0px; } }
  .edu-demo-ecommerce-layout .shape-image {
    position: absolute;
    z-index: -1; }
    .edu-demo-ecommerce-layout .shape-image.shape-image-1 {
      top: -49px;
      left: -240px; }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .edu-demo-ecommerce-layout .shape-image.shape-image-1 {
          left: -125px; } }
    .edu-demo-ecommerce-layout .shape-image.shape-image-2 {
      top: -80px;
      right: -145px; }
    .edu-demo-ecommerce-layout .shape-image.shape-image-3 {
      bottom: -22px;
      right: -74px; }
    .edu-demo-ecommerce-layout .shape-image.shape-image-4 {
      bottom: -93px;
      left: -200px;
      animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .edu-demo-ecommerce-layout .shape-image.shape-image-4 {
          left: -135px; } }
  @media only screen and (max-width: 1199px) {
    .edu-demo-ecommerce-layout {
      padding: 130px 0 !important; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .edu-demo-ecommerce-layout {
      padding: 80px 0 !important; } }
  @media only screen and (max-width: 767px) {
    .edu-demo-ecommerce-layout {
      padding: 80px 0 !important; } }

/*-----------------------
    Demo Style   
--------------------------*/
.landing-home-demo-area .shape-image {
  position: absolute;
  z-index: -1; }
  .landing-home-demo-area .shape-image.shape-image-1 {
    top: 82px;
    left: -210px; }
  .landing-home-demo-area .shape-image.shape-image-2 {
    top: 219px;
    right: -167px;
    animation: eduvibe-rotate-x 7s infinite linear; }
  .landing-home-demo-area .shape-image.shape-image-3 {
    bottom: 132px;
    right: -165px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
  .landing-home-demo-area .shape-image.shape-image-4 {
    bottom: 372px;
    left: -169px; }

.single-demo {
  text-align: center; }
  .single-demo.coming-soon a {
    pointer-events: none; }
  .single-demo .thumbnail {
    position: relative; }
    .single-demo .thumbnail .thumbnail-link {
      display: block;
      padding: 5px;
      background-color: #fff;
      box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      position: relative; }
      .single-demo .thumbnail .thumbnail-link::before {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(2px);
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.4s; }
      .single-demo .thumbnail .thumbnail-link img {
        width: 100%;
        border-radius: 5px; }
    .single-demo .thumbnail .hover-action {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.9);
      z-index: 1;
      transition: 0.4s;
      opacity: 0; }
      .single-demo .thumbnail .hover-action a.edu-btn {
        height: 50px;
        line-height: 50px;
        padding: 0 24px; }
  .single-demo .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-top: 30px; }
  .single-demo.inner-demo .thumbnail .thumbnail-link::before {
    display: none; }
  .single-demo:hover .thumbnail .thumbnail-link::before {
    opacity: 1; }
  .single-demo:hover .thumbnail .hover-action {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }

.demo-feature .inner {
  text-align: center; }
  .demo-feature .inner .icon {
    background: #FFFFFF;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .demo-feature .inner .icon img {
      max-height: 80px; }
  .demo-feature .inner .title {
    margin-bottom: 0;
    margin-top: 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px; }

.pv-list-style li {
  list-style: disc; }
  .pv-list-style li::marker {
    color: var(--color-secondary); }

.pv-footer {
  position: relative;
  z-index: 1;
  background-color: var(--color-dark); }
  .pv-footer .purchase-btn {
    margin-right: 14px; }
    .pv-footer .purchase-btn:hover {
      background-color: #FFFFFF;
      color: var(--color-secondary); }
  .pv-footer .button-group .bg-white {
    color: var(--color-primary); }
    .pv-footer .button-group .bg-white:hover {
      color: var(--color-secondary);
      cursor: pointer; }
  .pv-footer .student-like-status {
    background: #FFFFFF;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: inline-block;
    position: absolute;
    left: -154px;
    top: -46px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .pv-footer .student-like-status {
        left: -54px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .pv-footer .student-like-status {
        left: 50px; } }
    .pv-footer .student-like-status .inner {
      display: flex;
      padding: 16px 15px;
      align-items: center; }
      .pv-footer .student-like-status .inner .icon {
        width: 40px;
        height: 40px;
        background: rgba(251, 124, 86, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin-right: 10px; }
        .pv-footer .student-like-status .inner .icon i {
          color: var(--color-white); }
      .pv-footer .student-like-status .inner .content {
        text-align: left; }
        .pv-footer .student-like-status .inner .content .title {
          color: var(--color-secondary);
          font-weight: 700;
          font-size: 20px;
          line-height: 16px;
          margin-bottom: 0; }
        .pv-footer .student-like-status .inner .content .subtitle {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px; }
  .pv-footer .work-shop {
    min-width: 193px;
    max-width: 193px;
    background: var(--color-white);
    padding: 20px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: absolute;
    left: -264px;
    bottom: -67px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .pv-footer .work-shop {
        right: 2%;
        top: 57%; } }
    .pv-footer .work-shop .inner .thumbnail img {
      width: 40px;
      height: 40px;
      object-fit: cover; }
    .pv-footer .work-shop .inner .content .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0; }
    .pv-footer .work-shop .inner .content .time {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      margin-bottom: 20px; }
    @media only screen and (min-width: 1401px) and (max-width: 1750px) {
      .pv-footer .work-shop {
        left: -107px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .pv-footer .work-shop {
        left: -3px;
        bottom: -117px; } }
  .pv-footer .trophy-content {
    background: var(--color-white);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
    display: flex;
    border-radius: 5px;
    padding: 10px 17px;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: -30px;
    display: none; }
    .pv-footer .trophy-content .icon {
      object-fit: cover;
      margin-right: 14px; }
      .pv-footer .trophy-content .icon img {
        max-width: 35px; }
    .pv-footer .trophy-content .content {
      margin-top: 5px; }
      .pv-footer .trophy-content .content .text {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0;
        line-height: 1; }
      .pv-footer .trophy-content .content span {
        font-weight: 600;
        font-size: 12px; }
    @media only screen and (min-width: 1750px) {
      .pv-footer .trophy-content {
        display: flex; } }
  .pv-footer .card-info {
    position: absolute;
    right: -101px;
    bottom: -87px; }
    @media only screen and (max-width: 1650px) {
      .pv-footer .card-info {
        right: -0px;
        bottom: -117px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1450px) {
      .pv-footer .card-info {
        display: none !important; } }
    .pv-footer .card-info .inner {
      background: #FFFFFF;
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      padding: 10px 20px; }
      .pv-footer .card-info .inner .name {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        color: var(--color-heading); }
        .pv-footer .card-info .inner .name span {
          font-size: 14px;
          color: var(--color-body);
          font-weight: 400; }
      .pv-footer .card-info .inner .rating-wrapper .rating i {
        font-size: 14px; }
      .pv-footer .card-info .inner .rating-wrapper span {
        display: inline-block;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px; }
  .pv-footer .shape-dot-wrapper .shape {
    position: absolute;
    z-index: -1; }
    .pv-footer .shape-dot-wrapper .shape.shape-1 {
      right: -110px;
      top: -83px; }
      .pv-footer .shape-dot-wrapper .shape.shape-1 img {
        border-radius: 8px; }
    .pv-footer .shape-dot-wrapper .shape.shape-2 {
      right: 20px;
      bottom: -128px; }
      @media only screen and (max-width: 1650px) {
        .pv-footer .shape-dot-wrapper .shape.shape-2 {
          right: 120px; } }
      .pv-footer .shape-dot-wrapper .shape.shape-2 img {
        height: 185px;
        width: 145px;
        object-fit: cover;
        border-radius: 4px; }
  @media only screen and (max-width: 1199px) {
    .pv-footer {
      height: 470px; }
      .pv-footer .trophy-content {
        margin: 70px auto 0; } }
  @media only screen and (max-width: 767px) {
    .pv-footer {
      padding: 80px 0 !important; } }

/*------------------------
    Features
--------------------------*/
.landing-demo-features .shape-image {
  position: absolute;
  z-index: -1; }
  .landing-demo-features .shape-image.shape-image-1 {
    top: 290px;
    left: -140px;
    animation: eduvibe-vsm-y-move 3.5s alternate infinite linear; }
  .landing-demo-features .shape-image.shape-image-2 {
    bottom: 545px;
    right: -200px; }
  .landing-demo-features .shape-image.shape-image-3 {
    bottom: 125px;
    left: -150px; }

/*------------------------
    FAQ  
--------------------------*/
.landing-demo-faq-wrapper .animate-image {
  position: absolute;
  z-index: -1; }
  .landing-demo-faq-wrapper .animate-image.shape-image-1 {
    top: -10px;
    right: -150px; }
  .landing-demo-faq-wrapper .animate-image.shape-image-2 {
    bottom: 30px;
    left: -180px; }

/*------------------------
    Header Splash Two  
--------------------------*/
.eduvibe-splash-header .header-menu-bar {
  display: flex;
  align-items: center; }

/*-------------------------------
    Overall HTML Specific Styles  
    overall.scss
---------------------------------*/
.header-style-2 a.edu-btn.btn-medium {
  line-height: 46px; }

.header-style-2 a.edu-btn.btn-medium.header-purchase-btn {
  line-height: 50px; }

.edu-accordion-01 .edu-accordion-item:last-child .edu-accordion-button:not(.collapsed) {
  border-bottom: 0px solid; }

.edu-accordion-01 .edu-accordion-item:last-child .accordion-collapse.show {
  border-bottom: 1px solid #eee; }

.eduvibe-course-one-carousel .edu-card.card-type-3 {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05); }

.eduvibe-course-one-carousel .single-slick-card {
  margin-bottom: 20px; }

.edu-event.event-grid-1.bg-shade .inner {
  background: #f5f5f5;
  background: var(--color-shade);
  box-shadow: inherit !important; }

.eduvibe-post-share span {
  font-weight: 600;
  color: var(--color-dark);
  margin-right: 15px; }

.eduvibe-post-share a {
  color: var(--body-color); }

.eduvibe-post-share a:not(:last-child) {
  margin-right: 25px; }

.team-activation-03 .edu-instructor-3 .edu-instructor {
  margin-bottom: 45px; }

.team-activation-03.edu-slick-button .slick-dots {
  bottom: -54px; }

.instructor-profile-courses.slick-dotted.slick-slider {
  margin-bottom: 0px; }

.edu-blog.blog-type-2.variation-2.bg-color-gray {
  background: var(--color-shade); }

@media (max-width: 991px) {
  .eduvibe-masonry-grid {
    margin-top: 45px; } }

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1170px; } }

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1170px; } }

/**************************************
    Footer Styles
***************************************/
/*===========================
    Start Footer Area 
=============================*/
.footer-style-default {
  position: relative;
  z-index: 1; }
  .footer-style-default .footer-top {
    padding: 130px 0 30px 0; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer-style-default .footer-top {
        padding: 80px 0; } }
    @media only screen and (max-width: 767px) {
      .footer-style-default .footer-top {
        padding: 80px 15px; } }
  .footer-style-default .widget-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--color-white); }
  .footer-style-default .description {
    color: var(--color-white);
    margin-top: 34px;
    margin-bottom: 40px; }
  .footer-style-default .information-list li {
    color: var(--color-white);
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    display: flex; }
    .footer-style-default .information-list li i {
      font-size: 15px;
      margin-right: 12px;
      position: relative;
      top: 5px; }
    .footer-style-default .information-list li a {
      color: var(--color-white);
      transition: 0.3s; }
    .footer-style-default .information-list li + li {
      margin-top: 5px; }
    .footer-style-default .information-list li:hover a {
      color: var(--color-primary); }
  .footer-style-default .edu-footer-widget .inner {
    margin-top: 34px; }
  .footer-style-default .edu-footer-widget .footer-link {
    list-style: none;
    padding: 0; }
    .footer-style-default .edu-footer-widget .footer-link li {
      margin-top: 0;
      margin-bottom: 0; }
      .footer-style-default .edu-footer-widget .footer-link li a {
        font-size: 16px;
        display: block;
        color: var(--color-white);
        line-height: 26px; }
        .footer-style-default .edu-footer-widget .footer-link li a i {
          margin-right: 10px;
          font-size: 14px; }
      .footer-style-default .edu-footer-widget .footer-link li + li {
        margin-top: 20px; }
      .footer-style-default .edu-footer-widget .footer-link li:hover a {
        color: var(--color-primary); }
  .footer-style-default .edu-footer-widget.explore-widget {
    margin-left: 65px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .footer-style-default .edu-footer-widget.explore-widget {
        margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer-style-default .edu-footer-widget.explore-widget {
        margin-left: 0; } }
    @media only screen and (max-width: 767px) {
      .footer-style-default .edu-footer-widget.explore-widget {
        margin-left: 0; } }
  .footer-style-default .edu-footer-widget.quick-link-widget {
    margin-left: 65px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .footer-style-default .edu-footer-widget.quick-link-widget {
        margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer-style-default .edu-footer-widget.quick-link-widget {
        margin-left: 0; } }
    @media only screen and (max-width: 767px) {
      .footer-style-default .edu-footer-widget.quick-link-widget {
        margin-left: 0; } }

.eduvibe-footer-one {
  background: #231F40; }
  .eduvibe-footer-one .shape-image {
    position: absolute;
    z-index: -1; }
    .eduvibe-footer-one .shape-image.shape-image-1 {
      top: -11px;
      left: -263px; }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .eduvibe-footer-one .shape-image.shape-image-1 {
          top: -51px;
          left: -130px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .eduvibe-footer-one .shape-image.shape-image-1 {
          top: -51px;
          left: -150px; } }
    .eduvibe-footer-one .shape-image.shape-image-2 {
      bottom: -83px;
      right: -200px; }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .eduvibe-footer-one .shape-image.shape-image-2 {
          bottom: -13px;
          right: -100px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .eduvibe-footer-one .shape-image.shape-image-2 {
          bottom: -13px;
          right: -150px; } }

/*---------------------------------
 Footer with Call To Action  
----------------------------------*/
.edu-footer-with-cta {
  position: relative;
  z-index: 2;
  margin-top: 138px;
  background: #231F40; }
  @media only screen and (max-width: 767px) {
    .edu-footer-with-cta {
      margin-top: 0; } }
  .edu-footer-with-cta .cta-with-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: -138px; }
    @media only screen and (max-width: 767px) {
      .edu-footer-with-cta .cta-with-overlay {
        position: static;
        top: 0;
        padding-bottom: 80px; } }
  .edu-footer-with-cta .footer-style-default {
    padding-top: 138px;
    position: static; }

/*---------------------------------
 Footer 2
----------------------------------*/
.eduvibe-footer-two .edu-btn {
  margin-bottom: 30px; }

@media only screen and (max-width: 575px) {
  .eduvibe-footer-two {
    padding-bottom: 80px; }
    .eduvibe-footer-two .edu-btn {
      margin-bottom: 0px; }
    .eduvibe-footer-two .call-action-style-1,
    .eduvibe-footer-two .call-action-style-1.box-layout {
      padding: 30px; } }

.eduvibe-footer-two .footer-style-default {
  padding-top: 138px;
  position: static; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .eduvibe-footer-two .footer-style-default {
      padding-top: 418px; } }
  @media only screen and (max-width: 767px) {
    .eduvibe-footer-two .footer-style-default {
      padding-top: 0px; } }

.eduvibe-footer-two .animate-image {
  position: absolute;
  z-index: -1; }
  .eduvibe-footer-two .animate-image.shape-image-1 {
    top: -11px;
    left: -263px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .eduvibe-footer-two .animate-image.shape-image-1 {
        top: -51px;
        left: -130px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .eduvibe-footer-two .animate-image.shape-image-1 {
        top: -51px;
        left: -150px; } }
  .eduvibe-footer-two .animate-image.shape-image-2 {
    bottom: -83px;
    right: -200px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .eduvibe-footer-two .animate-image.shape-image-2 {
        bottom: -13px;
        right: -100px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .eduvibe-footer-two .animate-image.shape-image-2 {
        bottom: -13px;
        right: -150px; } }

/*-------------------------
    Copyright Styles  
--------------------------*/
.copyright-default p {
  color: var(--color-white); }
  .copyright-default p a {
    color: var(--color-white);
    transition: 0.4s; }
    .copyright-default p a:hover {
      color: var(--color-primary); }

      .logo {
        min-width: 150px;
        width: 160px;
    }

    .accordion-button {
      font-size: 1.6rem!important;
    }
    .accordion-body {
     font-size: 15px!important;
  }
  .title-inner {
    font-size: 20px;
    font-weight: 600;
}
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%)!important;
    z-index: 999!important;
}

.eduvibe-home-three-one-video{
   background-image: url("../images/bg/newsletter-home-4-bg.webp");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;

}
.edu-winner-card-left{
  text-align: left!important;
}


/* @media(max-width:520px){
    p{
      font-size: 14px!important;
    }
    .section-title .title {
      font-size: 18px;
  }
  .slider-area img {
    display: none;
    }
    .service-card-3 .inner .content .title{
      font-size: 18px!important;
      line-height: 25px;
    }
    a.btn-transparent.sm-size, button.btn-transparent.sm-size {
      font-size: 12px;
      line-height: 26px;
  }
  a.edu-btn, button.edu-btn {
    border-radius: 5px;
    display: inline-block;
    height: 38px!important;
    line-height: 32px!important;
    color: var(--color-white);
    background: var(--color-primary);
    padding: 0 23px;
    font-size: 14px!important;
    font-weight: 700;
    margin: 10px 0!important;
    transition: 0.4s;
    font-family: var(--font-primary);
    border: 0 none;
}
.section-title .title-h2 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}
.edu-accordion-02 .edu-accordion-button {
  font-size: 15px;
  border: 0 none;
  background: transparent;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  font-weight: 700;
  line-height: 25px;
  position: relative;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  transition: 0.4s;
}
.title-inner {
  font-size: 16px;
  font-weight: 600;
}
.workshop-inner li {
  font-size: 12px;
}
.edu-winner-card li {
  font-size: 12px;
}
.footer-style-default .widget-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: var(--color-white);
}
.footer-style-default .edu-footer-widget .footer-link li a {
  font-size: 12px;
  display: block;
  color: var(--color-white);
  line-height: 10px;
}
.widget-information h2 {
  font-size: 14px;
}
.widget-information h2 {
  font-size: 12px;
}
} */