 
 
 
.modal-header {
    border-bottom: 1px solid #ffffff!important;
}
.modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid black;
}
 

.uparrow {
    position: fixed;
    left: 0;
    width: 60px;
    height: 60px;
    bottom: 20px;
    border-radius: 50%;
    font-size: 40px;
    color: white;
    background-color: #8d0f17;
    padding: 0px 10px;
    z-index: 999;
}
.uparrow :hover i {
    color: #fff!important;
}