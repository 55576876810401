
@media(max-width:560px){
    :root {       
        overflow-x: hidden;
    }
 
}
 a{
  text-decoration: none!important;
 }
 @media(max-width:580px){
    /* body{
        
        padding: 20px!important;
     } */
 }
@media(max-width:768px){
    .carousel-control-next {
        right: 0!important;
    }
    .carousel-control-prev {
        left: 0!important;
    }
    /* body{
        overflow-x: hidden!important;
    } */
}

.slick-prev, .slick-next {
    display: none!important;
}
.contact-info a {
    font-size: 17px;
    color: #6f6b80;
}
.contact-address-card-1:hover .contact-info a {
    color: #ffffff;
}
.contact-address-card-1:hover .inner .content a {
    color: #ffffff;
}