.link-text{
    color: rgb(11, 11, 135)!important;
    
}
.link-text:hover{
    color: #0b0836!important
}
.list-items li{
    margin-bottom: 0px;
    margin-top: 0px;
}
.customreadmore {
    font-size: 14px!important;
    margin: 10px 0!important;
}
/* .flex-row-rev{
    flex-direction: row-reverse;
} */
@media(max-width:560px){
    /* .colum-rev{
        flex-direction: column-reverse!important;
    } */
    .flex-row-reverse{
        flex-direction: none;
    }
}
