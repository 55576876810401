.service-card-3.bg-grey {
    background: #fff!important;
    height: 100%;
    border: 1px solid #7a7a7a4a;
}
.bg-color-white {
    background-color: #fcfcfc !important;
}
.edu-winner-card {   
    box-shadow: -1px 10px 30px rgb(0 0 0 / 17%);  
}
.content h1 i {
    color: #0c0c95;
    font-size: 40px;
}
.service-card-3 .inner .content .title {
    font-size: 24px!important;
    line-height: 32px;
    margin-bottom: 15px;
    font-weight: 700;
}
.client-testimonials h1 i {
    font-size: 50px;
    color: #ffffff;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 20px;
}
.start-rating span i.ri-star-fill {
    color: #07ffc9;
    font-size: 20px;
}
 
.bg-color-white {
    background-color: #f0f0f0 !important;
}
.rwt-dynamic-form .form-group input {
    background: #ffffff;
    border-radius: 5px;
    padding: 0 30px;
    border: 1px solid #2883e5;
    transition: 0.4s;
    outline: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    height: 60px;
}
.testi-section {
    background-color: #2b387c;
    color: #fff;
}
.testi-section h1, .testi-section h4, .testi-section p {
    color: #fff;
}
.slick-dots li button:before {
    color: #fff!important;
}

@media(max-width:560px){
    .slider-area {
        text-align: center;
    }
    .slider-area img {
        width: 190px;
    }
}